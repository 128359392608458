import { AnimationItem } from 'lottie-web';
import { useEffect, useState } from 'react';

import { api } from '../../../../services/http.service';

export const useAnimatedImage = (
  type: string,
  value: string,
  isImageAnimated: boolean = false
): AnimationItem | null => {
  const [animationData, setAnimationData] = useState<AnimationItem | null>(
    null
  );

  useEffect(() => {
    const fetchAnimationData = async () => {
      if (type !== 'image' || !isImageAnimated) return;

      const lottieValue = value.replace('.png', '.json');
      try {
        const response = await api.get<AnimationItem>(lottieValue);
        setAnimationData(response.data);
      } catch (error) {
        console.error('Error loading Lottie animation:', error);
      }
    };

    fetchAnimationData();
  }, [type, value, isImageAnimated]);

  return animationData;
};

import { useFeatureValue } from '@growthbook/growthbook-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../../context/atoms/User';
import { GuidesAPI } from '../../shared/api';

const MASTERING_GPT_GUIDE_NAME = 'Mastering ChatGPT-4';
const NEW_GUIDE_NAME = 'First Steps to Profit with AI';

type Props = {
  onSuccess?: () => void;
};

export function useGuidesListQuery(props?: Props) {
  const userData = useRecoilValue(userDataQuery);

  const growthbookStartRegisterDate = useFeatureValue(
    'start_register_date_of_new_users_to_ab_guide_first_guide_test',
    ''
  );

  const isUserInTheABGuideFirstGuideTest = useMemo(() => {
    return dayjs(userData?.registered_at).isAfter(growthbookStartRegisterDate);
  }, [userData, growthbookStartRegisterDate]);

  return useQuery({
    queryKey: ['guides-list'],
    queryFn: () =>
      GuidesAPI.getGuidesList().then(({ data }) => {
        props?.onSuccess?.();

        return data.items.filter(v => {
          if (!isUserInTheABGuideFirstGuideTest) {
            return v;
          }

          return v;
        });
      }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

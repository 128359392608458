import http from '../services/http.service';

export const userApi = {
  setOnboarded: async (user_id: number | string) => {
    return http.post(`/users/onboard/${user_id}/`, {});
  },
  get: async (user_id: any) => {
    return http
      .get(`/users/users/${user_id}/`)
      .then(response => response.data)
      .catch(reason => null);
  },
  changeLanguage: async (userId: any, language: string) => {
    return http
      .patch(`/users/users/${userId}/`, { language })
      .then(response => response.data)
      .catch(reason => console.log(reason));
  },
  getTourPassed: async (user_id: any, tour_name: string) => {
    return http
      .get(`users/${user_id}/tours/?name=${tour_name}`)
      .then(res => res.data)
      .catch(reason => console.log(reason));
  },
  postTourPassed: async (user_id: any, tour_name: string) => {
    return http
      .post(`/users/${user_id}/tours/`, { name: tour_name })
      .then(res => res);
  },
  unsubscribe: async (subscription_id: string) => {
    return http
      .post(`/users/subscriptions/cancel/`, {
        subscription_id: subscription_id,
      })
      .then(response => response.data)
      .catch(reason => console.log(reason));
  },
  restoreSubscription: async (subscription_id: string) => {
    return http
      .post(`/users/subscriptions/restore/`, {
        subscription_id: subscription_id,
      })
      .then(response => response.data)
      .catch(reason => console.log(reason));
  },
  extendSubscription: async (user_id: any, subscription_id: string) => {
    return http
      .post(`/users/subscriptions/extend/`, {
        user_id: user_id,
        subscription_id: subscription_id,
      })
      .then(response => response.data)
      .catch(reason => console.log(reason));
  },
};

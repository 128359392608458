import { GuideProgressByIdType } from '../../../guides/entities/guide-progress';

export function getIsLastLesson(data: GuideProgressByIdType): boolean {
  let nonCompletedCount = 0;

  for (const unitId in data.units) {
    const unit = data.units[unitId];

    for (const lessonId in unit.lessons) {
      const lesson = unit.lessons[lessonId];

      if (lesson.status !== 'completed') {
        nonCompletedCount++;

        if (nonCompletedCount > 1) {
          return false;
        }
      }
    }
  }

  return nonCompletedCount === 1;
}

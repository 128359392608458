import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePatchGoalMutation } from '../../../../entities/personal-profile';
import { UiSpinner } from '../../../ai-bots/shared/ui/spinner';
import { UiPrimaryButton } from '../../../shared/ui';
import { GoalButton } from '../../features/goal-button';
import { PersonalGoalAnalytics } from '../../shared/analytics';
import { getGoalTitle } from '../../shared/lib';
import { usePersonalGoalStore } from '../../shared/model';
import './update-screen.css';

export function UpdateScreen({
  place,
  events,
}: {
  place: 'home_page' | 'profile';
  events: {
    onUpdated: () => void;
  };
}) {
  const { t } = useTranslation();

  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const { currentGoal } = usePersonalGoalStore();
  const goalTitle = getGoalTitle(currentGoal);

  const patchGoalMutation = usePatchGoalMutation();

  useEffect(() => {
    if (patchGoalMutation.isError) {
      showErrorToast();
    }
  }, [patchGoalMutation.isError]);

  function showErrorToast() {
    setIsErrorToastVisible(true);

    setTimeout(() => {
      setIsErrorToastVisible(false);
    }, 2000);
  }

  function updateGoal() {
    patchGoalMutation
      .mutateAsync(currentGoal)
      .then(() => {
        PersonalGoalAnalytics.onUpdateButtonClick({
          place,
          selected_goal: currentGoal,
          status: 'success',
        });
        events.onUpdated();
      })
      .catch(cause => {
        PersonalGoalAnalytics.onUpdateButtonClick({
          place,
          selected_goal: currentGoal,
          status: 'error',
        });
        throw new Error('ERROR_UPDATE_USER_PROFILE', { cause });
      });
  }

  return (
    <section className="goal-update-screen">
      {patchGoalMutation.isLoading && (
        <div className="goal-update-screen__loading">
          <UiSpinner />
        </div>
      )}

      <AnimatePresence>
        {isErrorToastVisible && (
          <motion.div
            key="update-screen-error-toast"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            exit={{ y: -20, opacity: 0 }}
            className="goal-update-screen__error"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                fill="#D43333"
                d="M8 2.667a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 8 2.667ZM1.333 8a6.667 6.667 0 1 1 13.334 0A6.667 6.667 0 0 1 1.334 8Zm3.862-2.805c.26-.26.683-.26.943 0L8 7.057l1.862-1.862a.667.667 0 1 1 .943.943L8.943 8l1.862 1.862a.667.667 0 1 1-.943.943L8 8.943l-1.862 1.862a.667.667 0 0 1-.943-.943L7.057 8 5.195 6.138a.667.667 0 0 1 0-.943Z"
              />
            </svg>
            <strong>{t('ai-bots.default-error')}</strong>
          </motion.div>
        )}
      </AnimatePresence>

      <h2 className="goal-update-screen__title">
        {t('goals.update-screen.title')}
      </h2>
      <p className="goal-update-screen__description">
        {t('goals.update-screen.description')}
      </p>

      <GoalButton
        title={goalTitle}
        className="goal-update-screen__info"
        isDisabled
      />

      <UiPrimaryButton
        className="goal-update-screen__action-button"
        disabled={patchGoalMutation.isLoading}
        onClick={updateGoal}
      >
        {patchGoalMutation.isLoading
          ? t('guides.loading')
          : t('goals.update-screen.action-button')}
      </UiPrimaryButton>
    </section>
  );
}

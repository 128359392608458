import classNames from 'classnames';

import './assets/upsell-offer.css';

export function UpsellOffer({
  className,
  image,
  title,
  discountPriceText,
  fullPrice,
  discountPercent,
  isActive,
  onSelect,
  badgeText,
}: {
  className?: string;
  image: string;
  title: string;
  discountPriceText: string;
  fullPrice: string;
  discountPercent: string;
  isActive: boolean;
  onSelect: () => void;
  badgeText?: string;
}) {
  function onOfferSelect() {
    if (!isActive) {
      onSelect();
    }
  }

  return (
    <>
      {badgeText && (
        <div className="upsell-offer__best-deal-badge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 13"
            fill="none"
          >
            <path
              d="M8.7465 0.373323L5.05317 4.06666C4.8065 4.31332 4.6665 4.65332 4.6665 5.00666V11.6667C4.6665 12.4 5.2665 13 5.99984 13H11.9998C12.5332 13 13.0132 12.68 13.2265 12.1933L15.3998 7.11999C15.9598 5.79999 14.9932 4.33332 13.5598 4.33332H9.79317L10.4265 1.27999C10.4932 0.946656 10.3932 0.606656 10.1532 0.366656C9.75984 -0.0200105 9.13317 -0.0200105 8.7465 0.373323ZM1.99984 13C2.73317 13 3.33317 12.4 3.33317 11.6667V6.33332C3.33317 5.59999 2.73317 4.99999 1.99984 4.99999C1.2665 4.99999 0.666504 5.59999 0.666504 6.33332V11.6667C0.666504 12.4 1.2665 13 1.99984 13Z"
              fill="white"
            />
          </svg>
          <span className="upsell-offer__best-deal-badge-text">
            {badgeText}
          </span>
        </div>
      )}

      <div
        role="button"
        className={classNames(
          'upsell-offer',
          badgeText ? 'upsell-cascade__offers__best-deal' : className,
          {
            'upsell-offer--active': isActive,
          }
        )}
        onClick={onOfferSelect}
        style={{ paddingTop: badgeText ? 0 : '' }}
      >
        <div
          className="upsell-offer__image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>

        <div className="upsell-offer__container">
          <div className="upsell-offer__content">
            <h4 className="upsell-offer__title">{title}</h4>
            <div className="upsell-offer__pricing">
              <div className="upsell-offer__full-price">{fullPrice}</div>
              <div className="upsell-offer__discount">{discountPriceText}</div>
              <div className="upsell-offer__discount-percent">
                {discountPercent}
              </div>
            </div>
          </div>

          <div className="upsell-offer__checkmark">
            {isActive ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
              >
                <rect
                  width="15"
                  height="15"
                  x=".5"
                  y=".5"
                  fill="#5653FE"
                  rx="7.5"
                />
                <rect
                  width="15"
                  height="15"
                  x=".5"
                  y=".5"
                  stroke="#5653FE"
                  rx="7.5"
                />
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11.733 5.2 6.6 10.333 4.267 8"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
              >
                <rect
                  width="15"
                  height="15"
                  x=".5"
                  y=".5"
                  fill="#fff"
                  rx="7.5"
                />
                <rect
                  width="15"
                  height="15"
                  x=".5"
                  y=".5"
                  stroke="#C4C7CC"
                  rx="7.5"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

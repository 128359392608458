import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.css';

export function MessageLoading() {
  return (
    <div className="ai-bot-message ai-bot-message_ai-message">
      <img
        width="24"
        height="24"
        src={AiAvatarIcon}
        role="presentation"
        alt=""
      />
      <div className="ai-bot-message__loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
        >
          <rect width="8" height="8" fill="#898E99" rx="4" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
        >
          <rect width="8" height="8" fill="#A6AAB3" rx="4" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="8"
          fill="none"
        >
          <rect width="7" height="7" y=".5" fill="#C4C7CC" rx="3.5" />
        </svg>
      </div>
    </div>
  );
}

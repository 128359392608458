import { cloneDeep, last, size, toLower } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AiBot } from '../../../entities/ai-bot';
import type {
  AiBotMessageType,
  AiBotNewMessage,
} from '../../../entities/message';
import {
  useCreateMessageMutation,
  useSessionMessagesQuery,
} from '../../../entities/session';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { scrollToBottom } from '../../../shared/utils';
import { ChatContainer } from './ChatContainer';
import './ai-bot-chat.css';

export function AiBotSessionChat({
  chatBotId,
  sessionId,
  onShowInfo,
  onDeleteSession,
  onOpenImage,
}: {
  chatBotId: string;
  sessionId: string;
  onShowInfo?: (info: AiBot) => void;
  onDeleteSession?: () => void;
  onOpenImage?: (url: string) => void;
}) {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState<AiBotNewMessage | null>(null);
  const createMessageMutation = useCreateMessageMutation();
  const messagesQuery = useSessionMessagesQuery({
    sessionId,
    refetchOnInterval: true,
  });
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId, sessionId });

  useEffect(() => {
    scrollToBottom();
  }, [messagesQuery.data]);

  useEffect(() => {
    if (newMessage && createMessageMutation.isError) {
      setNewMessage({
        ...newMessage,
        errorMessage: t('ai-bots.default-error'),
      });
    }
  }, [createMessageMutation.isError]);

  useEffect(() => {
    if (messagesQuery.data) {
      onReceivesAnswer(messagesQuery.data);
    }
  }, [messagesQuery.data]);

  function onReceivesAnswer(data?: Array<AiBotMessageType>) {
    const lastMessage = last(data);

    if (!lastMessage?.response) {
      return;
    }

    const response = toLower(lastMessage.response);
    //TODO: localize error message
    const errorMessage = toLower('Something went wrong. Please try again');

    if (response.includes(errorMessage)) {
      return ChatPageAnalytics.onReceivesAnswer({
        status: 'error',
        errorMessage: lastMessage.response,
      });
    }

    ChatPageAnalytics.onReceivesAnswer({
      status: 'ok',
      errorMessage: null,
    });
  }

  function refetchMessagesQuery() {
    messagesQuery
      .refetch()
      .then(() => setNewMessage(null))
      .catch(cause => {
        throw new Error('ERROR_REFETCH_MESSAGES', { cause });
      });
  }

  function submitSessionMessage(text: string, sessionId: string) {
    return createMessageMutation
      .mutateAsync({ text, sessionId })
      .then(() => refetchMessagesQuery())
      .catch(cause => {
        //TODO: send event to bug tracker and add chatbot_id, sessionId tag
        const error = new Error('ERROR_CREATE_NEW_MESSAGE', { cause });

        ChatPageAnalytics.onReceivesAnswer({
          status: 'error',
          errorMessage: `${error.message} -> ${cause.message}`,
        });
      });
  }

  function onSubmitMessage({
    text,
    isResend = false,
  }: {
    text: string;
    isResend?: boolean;
  }) {
    setNewMessage({ sessionId, text, errorMessage: '' });

    submitSessionMessage(text, sessionId);

    if (!isResend) {
      ChatPageAnalytics.onFormSubmit({
        promptLength: size(text),
      });
    }
  }

  function onRegeneratePrompt() {
    if (newMessage) {
      const message = cloneDeep(newMessage);
      setNewMessage(null);
      onSubmitMessage({
        text: message.text,
        isResend: true,
      });

      ChatPageAnalytics.onFormSubmit({
        resend: true,
        promptLength: size(message.text),
      });
    }
  }

  function onFormClick() {
    ChatPageAnalytics.onFormClick();
  }

  return (
    <ChatContainer
      chatBotId={chatBotId}
      newMessage={newMessage}
      isDeleteSessionButtonVisible={Boolean(sessionId)}
      isMessagesLoading={messagesQuery.isLoading}
      messages={messagesQuery.data}
      onShowInfo={chatBotDetails => onShowInfo?.(chatBotDetails)}
      onDeleteSession={() => onDeleteSession?.()}
      onSubmitMessage={text => onSubmitMessage({ text })}
      onRegeneratePrompt={onRegeneratePrompt}
      onFormClick={onFormClick}
      onOpenImage={onOpenImage}
    />
  );
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useChatBotDetailsQuery } from '../../../entities/chat-details';
import { DetailsButton } from '../../../entities/details-button';
import { ChatDetailsBottomSheet } from '../../../feature/chat-details-bottomsheet';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { FullPageError } from '../../../shared/errors';
import { UiNavigation } from '../../../shared/ui';
import { AiBotNewChat } from '../../../widgets/chat';

export function AiBotsNewChatPageV2() {
  const { t } = useTranslation();
  const location = useLocation<{ from?: string }>();
  const navigation = useHistory();
  const params = useParams<{ chatBotId: string }>();
  const { chatBotId } = params;

  const [isDetailsBottomSheetVisible, setIsDetailsBottomSheetVisible] =
    useState(false);

  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId });

  const chatDetailsQuery = useChatBotDetailsQuery({ chatBotId });

  useEffect(() => {
    if (chatDetailsQuery.data) {
      ChatPageAnalytics.chatPageView({
        location: 'new_chat',
      });
    }
  }, [chatDetailsQuery.data]);

  const isCameFromSessionsPage =
    location.state?.from?.includes('ai-bots/sessions');

  function onGoBack() {
    if (isCameFromSessionsPage) {
      navigation.replace('/ai-bots/sessions');
    } else {
      navigation.replace('/ai-bots');
    }
  }

  const newChatTitle = t('ai-bots.chat.new-title');

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={newChatTitle}
        action={
          !chatDetailsQuery.isLoading ? (
            <DetailsButton
              onClick={() => setIsDetailsBottomSheetVisible(true)}
            />
          ) : (
            <></>
          )
        }
        onBackButtonClicked={onGoBack}
      />

      {chatDetailsQuery.isError ? (
        <FullPageError
          withFooter={false}
          onRetry={() => chatDetailsQuery.refetch()}
        />
      ) : (
        <AiBotNewChat
          chatBotId={chatBotId}
          onShowInfo={() => setIsDetailsBottomSheetVisible(true)}
        />
      )}

      <ChatDetailsBottomSheet
        chatBotId={chatBotId}
        chatDetails={chatDetailsQuery.data}
        isVisible={isDetailsBottomSheetVisible}
        onDismiss={() => setIsDetailsBottomSheetVisible(false)}
      />
    </main>
  );
}

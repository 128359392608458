import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../../../../../api/userApi';
import {
  localOnboardState,
  userQuery,
} from '../../../../../context/atoms/User';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { allowedArrows } from '../../../shared/lib';
import JumpInIllustration from './assets/jump-in-illustration.png';
import './assets/start-onboarding-page.css';

export function StartOnboardingPage() {
  const navigation = useHistory();
  const user = useRecoilValue(userQuery);
  const setLocalOnboard = useSetRecoilState(localOnboardState);

  const queryParams = getQueryParamsFromURI();
  const userArrow = queryParams?.arrow;

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const arrow = allowedArrows.includes(userArrow) ? userArrow : 'main';

  const { t } = useTranslation(undefined, {
    keyPrefix: `onboarding-redesign.${arrow}.start-onboarding`,
  });

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  function onNext() {
    OnboardingV2Analytics.onStartOnboardingReady({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    if (arrow === 'main') {
      return navigation.push('/onboarding/v2/intro-lesson?lesson=lesson-1');
    }
    navigation.push(
      `/onboarding/v2/intro-lesson?lesson=lesson-1&arrow=${userArrow}`
    );
  }

  function onSkip() {
    OnboardingV2Analytics.onSkipForNow({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.push('/', {
      from: 'onboarding',
    });
  }

  useEffect(() => {
    OnboardingV2Analytics.onStartOnboardingPageView({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }, [aiPathwayExperiment]);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="start-onboarding-page min-vh-full"
    >
      <div className="start-onboarding-page__content">
        <h1>{t('title')}</h1>
        <p>{t('text')}</p>
        <img
          className="start-onboarding-page__image"
          src={JumpInIllustration}
          alt=""
        />
      </div>
      <div className="start-onboarding-page__actions">
        <UiPrimaryButton onClick={onNext}>{t('start')}</UiPrimaryButton>
        <button
          className="start-onboarding-page__actions-button"
          onClick={onSkip}
        >
          {t('skip')}
        </button>
      </div>
    </motion.section>
  );
}

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import * as Sentry from '@sentry/react';
import { FC, Suspense } from 'react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import './App.scss';
import { AppRoutes } from './AppRoutes';
import { globalColors } from './globalColors';
import './i18n';
import AuthRoutes from './modules/Auth/AuthRoutes';
import { Loader } from './modules/shared';
import ErrorFallback from './modules/shared/ErrorFallback/ErrorFallback';
import { analyticsService } from './services/analytics.service';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
  plugins: [new BugsnagPluginReact()],
});

analyticsService.init();

const theme = {
  colors: globalColors,
};

const App: FC = () => {
  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  const gtmParams = {
    id: process.env.REACT_APP_GTM_ID as string,
  };

  return (
    <ErrorBoundary>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <HelmetProvider>
          <GTMProvider state={gtmParams}>
            <BrowserRouter>
              <Suspense fallback={<Loader />}>
                <ThemeProvider theme={theme}>
                  <Switch>
                    <Route path="/auth" component={AuthRoutes} />
                    <Route path="*" component={AppRoutes} />
                  </Switch>
                </ThemeProvider>
              </Suspense>
            </BrowserRouter>
          </GTMProvider>
        </HelmetProvider>
      </Sentry.ErrorBoundary>
    </ErrorBoundary>
  );
};

export default App;

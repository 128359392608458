import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AiBotsCategoriesAPI } from '../api';

export function useAiBotsCategoriesQuery() {
  return useQuery({
    queryKey: ['ai-bots-categories'],
    queryFn: () =>
      AiBotsCategoriesAPI.getAiBotsCategories().then(({ data }) => data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useSaveUserInterests() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['save-user-interests'],
    mutationFn: ({ categoryIds }: { categoryIds: string[] }) =>
      AiBotsCategoriesAPI.saveUserIntersets(categoryIds).then(res => res.data),
    onSuccess() {
      return queryClient.invalidateQueries(['ai-bots-categories']);
    },
  });
}

import { AppFooter } from '../../../../../shared';
import { UiHeader } from '../../header';
import './ui-base-layout.css';

export function UiBaseLayout({
  children,
}: {
  children?: string | JSX.Element | JSX.Element[];
}) {
  return (
    <main className="ui-base-layout min-vh-full">
      <UiHeader />
      <section className="ui-base-layout__content">{children}</section>
      <AppFooter />
    </main>
  );
}

import { motion, useAnimationControls } from 'framer-motion';
import { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { MainScreen, SelectorScreen, UpdateScreen } from '../../screens';
import { PersonalGoalAnalytics } from '../../shared/analytics';

const MAIN_SCREEN = 'main-screen';
const SELECTOR_SCREEN = 'selector-screen';
const UPDATE_SCREEN = 'update-screen';

type ScreenType =
  | typeof MAIN_SCREEN
  | typeof SELECTOR_SCREEN
  | typeof UPDATE_SCREEN;

function GoalScreens({
  place,
  currentScreen,
  events,
}: {
  place: 'home_page' | 'profile';
  currentScreen: ScreenType;
  events: {
    onGoToSelectorScreen: () => void;
    onDismiss: () => void;
    onGoToUpdateScreen: () => void;
    onGoalUpdated: () => void;
  };
}) {
  switch (currentScreen) {
    case MAIN_SCREEN:
      return (
        <MainScreen
          place={place}
          events={{
            onEdit: events.onGoToSelectorScreen,
            onClose: events.onDismiss,
          }}
        />
      );
    case SELECTOR_SCREEN:
      return (
        <SelectorScreen
          place={place}
          events={{
            onClose: events.onDismiss,
            onContinue: events.onGoToUpdateScreen,
          }}
        />
      );
    case UPDATE_SCREEN:
      return (
        <UpdateScreen
          place={place}
          events={{
            onUpdated: events.onGoalUpdated,
          }}
        />
      );
    default:
      return null;
  }
}

export function GoalSelectorBottomSheet({
  isVisible,
  events,
  place,
}: {
  isVisible: boolean;
  place: 'home_page' | 'profile';
  events: {
    onDismiss: () => void;
    onGoalUpdated?: () => void;
  };
}) {
  const animationControls = useAnimationControls();
  const [currentScreen, setCurrentScreen] = useState<ScreenType>(MAIN_SCREEN);

  useEffect(() => {
    if (isVisible) {
      PersonalGoalAnalytics.view({
        place,
      });
    }
  }, [isVisible]);

  async function onGoToScreen(screen: ScreenType) {
    await animationControls.start({
      opacity: [0.5, 0],
      transition: { duration: 0.2 },
    });

    setCurrentScreen(screen);

    await animationControls.start({
      opacity: [0, 1],
      transition: { duration: 0.2 },
    });
  }

  function onCloseBottomSheet() {
    events.onDismiss();
    setTimeout(() => {
      setCurrentScreen(MAIN_SCREEN);
    }, 500);
  }

  function onGoalUpdated() {
    onCloseBottomSheet();
    events.onGoalUpdated?.();
  }

  return (
    <BottomSheet
      key="goal-selector-bottomsheet"
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={onCloseBottomSheet}
    >
      <motion.section initial={{ opacity: 1 }} animate={animationControls}>
        <GoalScreens
          place={place}
          currentScreen={currentScreen}
          events={{
            onGoToSelectorScreen: () => onGoToScreen(SELECTOR_SCREEN),
            onGoalUpdated,
            onGoToUpdateScreen: () => onGoToScreen(UPDATE_SCREEN),
            onDismiss: onCloseBottomSheet,
          }}
        />
      </motion.section>
    </BottomSheet>
  );
}

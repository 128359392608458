import {
  GuideDetailsType,
  GuideLessonType,
} from '../../../guides/entities/guide';
import { GuideProgressByIdType } from '../../../guides/entities/guide-progress';

export function getFirstNotCompletedLesson(
  data: GuideDetailsType,
  progress: GuideProgressByIdType
): GuideLessonType {
  for (let unit of data.content) {
    for (let lesson of unit.content) {
      const lessonStatus = progress.units[unit.id].lessons[lesson.id].status;

      if (lessonStatus !== 'completed') {
        return lesson;
      }
    }
  }

  return data.content[0].content[0];
}

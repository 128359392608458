import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { UiPrimaryButton } from '../../../../shared/ui';
import ThumbIcon from './assets/thumb-icon.png';
import './next-task-confirmation-bottomsheet.css';

export function NextTaskConfirmationBottomSheet(props: {
  isVisible: boolean;
  events: {
    onHide: () => void;
    onConfirm: (isDontShowAgainChecked: boolean) => void;
  };
}) {
  const { t } = useTranslation();
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);

  return (
    <BottomSheet
      key="next-task-confirmation-bottomsheet"
      expandOnContentDrag={true}
      open={props.isVisible}
      onDismiss={() => props.events.onHide()}
    >
      <div className="next-task-confirmation-bottomsheet">
        <div className="next-task-confirmation-bottomsheet__header">
          <h4 className="next-task-confirmation-bottomsheet__title">
            {t('challenges.next-task-confirmation-bottomsheet.title')}
          </h4>
          <button
            type="button"
            className="next-task-confirmation-bottomsheet__close-button"
            onClick={props.events.onHide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <rect width="24" height="24" fill="#F1F1F1" rx="4" />
              <path
                fill="#24234C"
                d="M7.53 7.528c.26-.26.682-.26.942 0l3.529 3.529 3.528-3.529a.667.667 0 0 1 .943.943L12.943 12l3.53 3.528a.667.667 0 0 1-.944.943l-3.528-3.528-3.529 3.528a.667.667 0 0 1-.943-.943L11.058 12 7.529 8.47a.667.667 0 0 1 0-.943Z"
              />
            </svg>
          </button>
        </div>

        <h5 className="next-task-confirmation-bottomsheet__subtitle">
          {t('challenges.next-task-confirmation-bottomsheet.subtitle')}
        </h5>

        <p className="next-task-confirmation-bottomsheet__description">
          <img
            width="28"
            height="28"
            src={ThumbIcon}
            role="presentation"
            alt=""
          />

          <span>
            {t('challenges.next-task-confirmation-bottomsheet.description')}
          </span>
        </p>

        <label className="next-task-confirmation-bottomsheet__checkbox">
          <input
            type="checkbox"
            checked={isDontShowAgainChecked}
            onChange={e => setIsDontShowAgainChecked(e.target.checked)}
          />
          <span>
            {t(
              'challenges.next-task-confirmation-bottomsheet.checkbox-message'
            )}
          </span>
        </label>

        <UiPrimaryButton
          className="next-task-confirmation-bottomsheet__action"
          onClick={() => props.events.onConfirm(isDontShowAgainChecked)}
        >
          {t('challenges.next-task-confirmation-bottomsheet.action')}
        </UiPrimaryButton>
      </div>
    </BottomSheet>
  );
}

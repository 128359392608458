import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../shared/ui';
import { GuideType } from '../../../entities/guide';
import { GuideDuration } from '../../../entities/guide-duration';
import { useGuidesOnboarding } from '../../../entities/guides-onboarding';
import './ongoing-guide.css';

export function OngoingGuide({
  guide,
  progress,
  onContinue,
}: {
  guide: GuideType;
  progress: number;
  onContinue: (guide: GuideType) => void;
}) {
  const { t } = useTranslation();
  const { isGuidesOnboardingVisible } = useGuidesOnboarding();

  return (
    <div className="ongoing-guide">
      <img
        width="80"
        height="80"
        src={guide.image}
        role="presentation"
        alt=""
      />

      <GuideDuration
        className="ongoing-guide__duration"
        unitsCount={guide.units_count}
        duration={guide.duration}
      />

      <h1 className="ongoing-guide__title">{guide.name}</h1>
      <h2 className="ongoing-guide__description">{guide.description}</h2>
      <div className="ongoing-guide__progress">
        <div
          className="ongoing-guide__progress-line"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <div className="ongoing-guide__actions">
        {isGuidesOnboardingVisible && (
          <div className="ongoing-guide__tip">{t('guides.tip-text')}</div>
        )}

        <UiPrimaryButton onClick={() => onContinue(guide)}>
          {t('guides.continue-learning-button')}
        </UiPrimaryButton>
      </div>
    </div>
  );
}

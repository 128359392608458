import { useTranslation } from 'react-i18next';

import { UiRetryButton } from '../../../ui';
import Illustration from '../../shared/assets/illustration.svg';
import './error-block.css';

export function ErrorBlock({ onRetry }: { onRetry: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="error-block">
      <div className="error-block__content">
        <img
          width="266"
          height="205"
          src={Illustration}
          role="presentation"
          alt=""
        />

        <h1>{t('ai-bots.error-block.title')}</h1>
        <p>{t('ai-bots.error-block.description')}</p>
      </div>

      <UiRetryButton
        className="error-block__retry-button"
        text={t('ai-bots.error-block.retry-button')}
        onRetry={() => onRetry()}
      />
    </div>
  );
}

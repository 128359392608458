import { find, findIndex, last } from 'lodash-es';

import { GuideDetailsType } from '../../entities/guide';

type Props = {
  guide: GuideDetailsType;
  unitId: string;
  lessonId: string;
};

export type ReturnGuideEnd = {
  type: 'guide-end';
};

export type ReturnUnitEnd = {
  type: 'unit-end';
  guideId: string;
  unitId: string;
  lessonId: string;
};

export type ReturnLessonEnd = {
  type: 'lesson-end';
  guideId: string;
  unitId: string;
  lessonId: string;
};

export function calculateNextStep(
  props: Props
): ReturnGuideEnd | ReturnUnitEnd | ReturnLessonEnd {
  const { guide, unitId, lessonId } = props;

  const units = guide.content;
  const currentUnit = find(units, ['id', unitId]);
  const lastUnit = last(units);

  if (!currentUnit || !lastUnit) {
    throw new Error('ERROR_CALCULATE_NEXT_STEP_EMPTY_CURRENT_UNIT');
  }

  const lessons = currentUnit.content;
  const currentLesson = find(lessons, ['id', lessonId]);
  const lastLesson = last(lessons);

  if (!currentLesson || !lastLesson) {
    throw new Error('ERROR_CALCULATE_NEXT_STEP_EMPTY_LESSONS');
  }

  const isLastLesson = currentLesson.id === lastLesson.id;
  const isLastUnit = lastUnit.id === currentUnit.id;

  if (isLastUnit && isLastLesson) {
    return { type: 'guide-end' };
  }

  if (!isLastUnit && isLastLesson) {
    const currentUnitIndex = findIndex(units, ['id', unitId]);
    const nextUnit = units[currentUnitIndex + 1];
    const firstNextUnitLesson = nextUnit.content[0];

    return {
      type: 'unit-end',
      guideId: guide.id,
      unitId: nextUnit.id,
      lessonId: firstNextUnitLesson.id,
    };
  }

  const currentLessonIndex = findIndex(lessons, ['id', lessonId]);

  const nextLesson = lessons[currentLessonIndex + 1];

  return {
    type: 'lesson-end',
    guideId: guide.id,
    unitId: currentUnit.id,
    lessonId: nextLesson.id,
  };
}

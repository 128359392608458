import { useQuery } from '@tanstack/react-query';

import { ChallengesInfoAPI } from './api';

export function useChallengeInfoQuery(id: string) {
  return useQuery({
    queryKey: ['challenge-info-query', id],
    queryFn: () => ChallengesInfoAPI.getInfo(id).then(res => res.data),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

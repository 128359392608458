import { useGrowthBook } from '@growthbook/growthbook-react';

export function useLottieAiExperiment() {
  const gb = useGrowthBook();
  const { arrow } = gb?.getAttributes() ?? {};
  const isArrowAi = arrow === 'ai';

  const value =
    isArrowAi && gb
      ? gb.getFeatureValue('exp_lottie_onboarding_ai', 'default')
      : 'default';

  return value;
}

import { GuideLessonProgress } from '../../../entities/guide-progress/api';

export function LessonStatus({
  status,
}: {
  status: 'selected' | GuideLessonProgress['status'];
}) {
  switch (status) {
    case 'selected':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5" />
          <rect
            width="17"
            height="17"
            x=".5"
            y=".5"
            stroke="#C4C7CC"
            rx="8.5"
          />
          <circle cx="9" cy="9" r="5" fill="#5653FE" />
        </svg>
      );
    case 'completed':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5" />
          <rect
            width="17"
            height="17"
            x=".5"
            y=".5"
            stroke="#19AA32"
            rx="8.5"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m13.2 5.85-5.775 5.775L4.8 9"
          />
        </svg>
      );
    case 'closed':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <rect width="17" height="17" x=".5" y=".5" fill="#C4C7CC" rx="8.5" />
          <rect
            width="17"
            height="17"
            x=".5"
            y=".5"
            stroke="#C4C7CC"
            rx="8.5"
          />
          <path
            fill="#fff"
            d="M9 5c.824 0 1.5.676 1.5 1.5V8h-3V6.5C7.5 5.676 8.176 5 9 5Zm2.5 3V6.5C11.5 5.124 10.376 4 9 4a2.506 2.506 0 0 0-2.5 2.5V8H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-.5ZM6 9h6v4H6V9Z"
          />
          <path fill="#fff" d="M6 13V9h6v4H6Z" />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5" />
          <rect
            width="17"
            height="17"
            x=".5"
            y=".5"
            stroke="#C4C7CC"
            rx="8.5"
          />
        </svg>
      );
  }
}

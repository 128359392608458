import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchParams() {
  const { search } = useLocation();
  const [params, setParams] = useState(new URLSearchParams(search));
  useEffect(() => {
    setParams(new URLSearchParams(search));
  }, [search]);

  return params;
}

import { useTranslation } from 'react-i18next';

import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../entities/guide';
import './assets/guides-list.css';

export function GuidesListLoading() {
  const { t } = useTranslation();

  return (
    <section className="guides-list">
      <h1 className="guides-list__title">{t('guides.recommends.title')}</h1>
      <h1 className="guides-list__subtitle">
        {t('guides.recommends.description')}
      </h1>

      <div className="guides-list__list">
        {Array(8)
          .fill('')
          .map((_, i) => (
            <UiLoadingSkeleton
              key={`guides-list-loading__${i}`}
              style={{
                width: '100%',
                height: '162px',
                borderRadius: '8px',
              }}
            />
          ))}
      </div>
    </section>
  );
}

export function GuidesList({
  guides,
  onGuideClick,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType) => void;
}) {
  const { t } = useTranslation();

  return (
    <section className="guides-list">
      <h1 className="guides-list__title">{t('guides.recommends.title')}</h1>
      <h1 className="guides-list__subtitle">
        {t('guides.recommends.description')}
      </h1>

      <div className="guides-list__list">
        {guides.map(guide => (
          <button
            key={guide.id}
            className="guides-list__item"
            onClick={() => onGuideClick(guide)}
          >
            <img
              className="guides-list__item-image"
              width="64"
              height="64"
              src={guide.image}
              role="presentation"
              alt=""
            />

            <strong className="guides-list__item-title">{guide.name}</strong>
          </button>
        ))}
      </div>
    </section>
  );
}

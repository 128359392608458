import { AnimatePresence, motion } from 'framer-motion';

import './success-toast.css';

export function SuccessToast({
  isVisible = false,
  children,
  events,
}: {
  isVisible: boolean;
  children: string | JSX.Element | JSX.Element[];
  events: {
    onHide: () => void;
  };
}) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="success-toast"
          initial={{ y: -70 }}
          animate={{ y: 0 }}
          exit={{ y: -70, opacity: 0 }}
          className="success-toast"
          onClick={events.onHide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm12.664-3.247a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 0 1 1.494-1.328l1.92 2.159 4.586-5.16a1 1 0 0 1 1.411-.082Z"
              clipRule="evenodd"
            />
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
              clipRule="evenodd"
            />
          </svg>

          <span className="success-toast__content">{children}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

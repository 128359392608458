import { useRecoilState } from 'recoil';

import { GeoState } from '../context/atoms/Geo';
import i18n from '../i18n';
import { LanguageType } from '../types/Language';
import { useGeo } from './geo';

//TODO: refactor this asap
export const useCurrency = () => {
  const { geoFetch } = useGeo();
  const [geoData, setGeoData] = useRecoilState(GeoState);
  const language = i18n.language;
  const dollarLocalSignCountries = [
    'MX',
    'AR',
    'CL',
    'CO',
    'UY',
    'DO',
    'PA',
    'BN',
    'SG',
    'BS',
    'BB',
    'BZ',
    'BM',
    'FJ',
    'JM',
    'SB',
    'TT',
    'BR',
  ];

  const getCurrency = () => {
    return new Promise<string>(async (resolve, reject) => {
      if (language === LanguageType.spanish) {
        resolve('US$');
      } else if (
        geoData &&
        dollarLocalSignCountries.includes(geoData.alpha_2)
      ) {
        resolve('US$');
      }

      const fetchedGeoData = await geoFetch();
      setGeoData(fetchedGeoData);
      if (dollarLocalSignCountries.includes(fetchedGeoData.alpha_2)) {
        resolve('US$');
      } else {
        resolve('$');
      }
    });
  };

  return { getCurrency };
};

import classNames from 'classnames';

import '../../fill-the-gap-modal/ui/fill-the-gap-modal.css';

export function BackspaceButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={classNames('guide-fill-the-gap-modal__backspace-button', {
        'guide-fill-the-gap-modal__backspace-button--disabled': disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.7072 8.29289C18.0977 8.68342 18.0977 9.31658 17.7072 9.70711L15.4143 12L17.7072 14.2929C18.0977 14.6834 18.0977 15.3166 17.7072 15.7071C17.3166 16.0976 16.6835 16.0976 16.293 15.7071L14.0001 13.4142L11.7072 15.7071C11.3166 16.0976 10.6835 16.0976 10.293 15.7071C9.90243 15.3166 9.90243 14.6834 10.293 14.2929L12.5858 12L10.293 9.70711C9.90243 9.31658 9.90243 8.68342 10.293 8.29289C10.6835 7.90237 11.3166 7.90237 11.7072 8.29289L14.0001 10.5858L16.293 8.29289C16.6835 7.90237 17.3166 7.90237 17.7072 8.29289Z"
          fill={disabled ? '#E2E5E9' : '#6B7280'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0001 5C22.0001 4.44772 21.5523 4 21.0001 4H9.46C8.87615 4 8.32145 4.25513 7.94148 4.69842L2.79863 10.6984C2.15664 11.4474 2.15664 12.5526 2.79863 13.3016L7.94148 19.3016C8.32145 19.7449 8.87615 20 9.46 20H21.0001C21.5523 20 22.0001 19.5523 22.0001 19V5ZM20.0001 18H9.46L4.31714 12L9.46 6H20.0001V18Z"
          fill={disabled ? '#E2E5E9' : '#6B7280'}
        />
      </svg>
    </button>
  );
}

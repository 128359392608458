import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import './guide-lesson-screen-loading.css';

export function GuideLessonScreenLoading() {
  return (
    <div className="guide-lesson-screen-loading">
      <div className="guide-lesson-screen-loading__image-container">
        <UiLoadingSkeleton className="guide-lesson-screen-loading__image" />
      </div>

      <div className="guide-lesson-screen-loading__content">
        <UiLoadingSkeleton
          style={{
            width: '75%',
            height: '1.5rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '90%',
            height: '1.5rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '40%',
            height: '1.5rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '2rem',
            width: '80%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '2rem',
            width: '90%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '95%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '75%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '80%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '95%',
            height: '1rem',
          }}
        />

        <UiLoadingSkeleton
          style={{
            marginTop: '.5rem',
            width: '30%',
            height: '1rem',
          }}
        />
      </div>
    </div>
  );
}

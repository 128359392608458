import styled from 'styled-components';

export const PageNotFoundContainer = styled.div`
  min-height: 100vh;
  max-width: 500px;
  text-align: center;
  background-color: white;
  .content {
    height: 100vh;
    padding: 34px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 14px;
  }
  .paragraph {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #8a8a8e;
  }
  .go_back {
    bottom: 0;
    left: 0;
    width: 100%;
  }
  button {
    max-width: 452px;
    width: 100%;
    height: 48px;
    margin: 0 auto;
    color: white;
    background-color: #5653fe;
    border-radius: 8px;
    border: none;

    font-size: 14px;
  }
`;

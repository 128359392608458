import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const OnboardingV2Analytics = {
  personalPlanPageView({
    remote_work,
    influencer,
    pathway_test,
    lottie_test,
  }: {
    remote_work: string;
    influencer: string;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'plan_ready',
      remote_work: remote_work,
      influencer: influencer,
      language: i18next.language,
      exp_pathway_v1: pathway_test,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onIamReady({
    remote_work,
    influencer,
    pathway_test,
    lottie_test,
  }: {
    remote_work: string;
    influencer: string;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'plan_ready',
      action: 'start_onboarding',
      remote_work: remote_work,
      influencer: influencer,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onToolSelectionPageView({ lottie_test }: { lottie_test: string }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'preference_selection',
      language: i18next.language,
      exp_pathway_v1: 'test',
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onToolSelect({ tool, lottie_test }: { tool: string; lottie_test: string }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'preference_selection',
      action: 'select_preference',
      preference: tool,
      language: i18next.language,
      exp_pathway_v1: 'test',
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  startLessonPageView({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'onboarding_lesson_preview',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onStartLesson({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_lesson_preview',
      action: 'start_lesson',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onChunkView({
    chunk_type,
    chunk_index,
    pathway_test,
    lottie_test,
  }: {
    chunk_type: string;
    chunk_index: number;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'onboarding_lesson',
      chunk_id: chunk_index,
      chunk_type,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onQuizAnswer({
    answer,
    chunk_index,
    is_correct,
    pathway_test,
    lottie_test,
  }: {
    answer: string;
    chunk_index: number;
    is_correct: boolean;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_lesson',
      action: 'answer_quiz',
      is_correct,
      chunk_id: chunk_index,
      chunk_type: 'quiz',
      answer,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  taskPageView({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'onboarding_task',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onTaskBubbleClick({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_task',
      action: 'fill_in_blank',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onTaskCheck({
    is_correct,
    pathway_test,
    lottie_test,
  }: {
    is_correct: boolean;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_task',
      action: 'submit_prompt',
      is_correct,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  congratsPageView({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'onboarding_lesson_complete',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onCongratsContinue({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_lesson_complete',
      action: 'finish_onboarding',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  surveyPageView({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_survey_view', {
      exp_onboarding_win_v1: 'test',
      feature: 'onboarding',
      feature_version: 'v3',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onSurveyRate({
    rating,
    pathway_test,
    lottie_test,
  }: {
    rating: number;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      feature: 'onboarding',
      feature_version: 'v3',
      rating,
      action: 'rate',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onSurveyContinue({
    rating,
    pathway_test,
    lottie_test,
  }: {
    rating: number;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      feature: 'onboarding',
      feature_version: 'v3',
      rating,
      action: 'close',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onStartOnboardingPageView({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: 'onboarding_selection_page',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onStartOnboardingReady({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_selection_page',
      action: 'start_lesson',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onSkipForNow({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_selection_page',
      action: 'skip_onboarding',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });

    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_selection_page',
      action: 'finish_onboarding',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
    });
  },
  onSkipLesson({
    chunk_type,
    chunk_index,
    pathway_test,
    lottie_test,
  }: {
    chunk_type: string;
    chunk_index: number;
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_lesson',
      action: 'close_lesson',
      chunk_id: chunk_index,
      chunk_type,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });

    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_lesson',
      action: 'finish_onboarding',
      chunk_id: chunk_index,
      chunk_type,
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
  onSkipTask({
    pathway_test,
    lottie_test,
  }: {
    pathway_test: string;
    lottie_test: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_task',
      action: 'close_lesson',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });

    analyticsService.sendEvent('webapp_onboarding_click', {
      place: 'onboarding_task',
      action: 'finish_onboarding',
      exp_pathway_v1: pathway_test,
      language: i18next.language,
      'exp_lottie-onboarding_v1': lottie_test,
    });
  },
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppFooter } from '../../../../shared';
import { AiBotTool } from '../../../entities/ai-bot/types';
import { useAiBotsCategoriesQuery } from '../../../feature/ai-bots-categories';
import { ViewAllAnalytics } from '../../../shared/analytics/view-all-page';
import { UiNavigation } from '../../../shared/ui';
import { AiBotsListContainer } from '../../../widgets/ai-bots-list';
import { ViewAllLoading } from './ViewAllLoading';
import './ai-bot-view-all.css';

export function AiBotViewAllPage() {
  const { t } = useTranslation();
  const navigation = useHistory();

  function onGoBack() {
    navigation.replace('/ai-bots');
  }

  function onSearchFormClick() {
    ViewAllAnalytics.onSearchBarClick();
  }

  function onSearchFormSubmit() {
    ViewAllAnalytics.onSearchBarSubmit();
  }

  function onCategoryButtonClicked(id: string) {
    ViewAllAnalytics.onCategoryFilterClick({ id });
  }

  function onClickToBot(bot: AiBotTool) {
    ViewAllAnalytics.onBotClick({ botId: bot.id });
  }

  useEffect(() => {
    ViewAllAnalytics.botsListView();
  }, []);

  const allChatsTitle = t('ai-tools.list-title');

  const { data, isLoading } = useAiBotsCategoriesQuery();

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={allChatsTitle}
        onBackButtonClicked={() => onGoBack()}
      />
      <div className="pt-4">
        {!data || isLoading ? (
          <ViewAllLoading />
        ) : (
          <AiBotsListContainer
            data={data}
            withSearchForm
            onCategoryButtonClicked={onCategoryButtonClicked}
            onClickToBot={onClickToBot}
            onSearchFormClick={onSearchFormClick}
            onSearchFormSubmit={onSearchFormSubmit}
          />
        )}
      </div>
    </main>
  );
}

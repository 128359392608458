import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';

import { GuideLessonType } from '../../../../guides/entities/guide';
import { GuideUnitProgress } from '../../../../guides/entities/guide-progress';
import CoastIcon from './GuideCoastIcon';
import './guide-coast.css';

const RippleEffect = ({
  isActive,
  children,
}: {
  isActive: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className="guide-coast__ripple-effect">
      {children}
      {isActive && (
        <AnimatePresence>
          <motion.div
            key="ripple"
            className="guide-coast__ripple-effect__animation"
            initial={{ scale: 1, opacity: 1 }}
            animate={{ scale: 1.3, opacity: 0 }}
            exit={{ scale: 1, opacity: 0 }}
            transition={{
              duration: 2,
              ease: 'easeOut',
              repeat: Infinity,
              repeatType: 'loop',
            }}
          />
        </AnimatePresence>
      )}
    </div>
  );
};
export function GuideCoast({
  lesson,
  isActive,
  onClick,
  progress,
  className,
}: {
  lesson: GuideLessonType;
  isActive: boolean;
  onClick?: (guide: GuideLessonType) => void;
  progress: GuideUnitProgress;
  className?: string;
}) {
  const lessonStatus = useMemo(() => {
    const lessonEntries = Object.entries(progress.lessons);
    const lastLessonEntry = lessonEntries[lessonEntries.length - 1];
    const lastLessonStatus = lastLessonEntry?.[1]?.status;

    const isLastLesson = lesson.id === lastLessonEntry?.[0];

    if (isLastLesson && lastLessonStatus === 'completed') {
      return 'lastCompleted';
    }

    if (isLastLesson) {
      return 'lastLesson';
    }

    return progress.lessons[lesson.id]?.status;
  }, [progress, lesson]);

  function handleClick() {
    if (onClick) {
      onClick(lesson);
    }
  }

  const isCompleted =
    lessonStatus === 'completed' || lessonStatus === 'lastCompleted';

  return (
    <div className={classNames('guide-coast', className)} onClick={handleClick}>
      <RippleEffect isActive={isActive}>
        <div
          className={classNames('guide-coast__coast-outer', {
            'guide-coast__coast-outer--closed': isCompleted,
            'guide-coast__coast-outer--active': isActive,
          })}
        >
          <div className="guide-coast__coast-inner">
            <CoastIcon
              lessonType={lesson.type}
              status={lessonStatus}
              isActive={isActive}
            />
          </div>
          {isCompleted && (
            <>
              <div className="guide-coast__coast-outer__glow-sm" />
              <div className="guide-coast__coast-outer__glow-lg" />
            </>
          )}
        </div>
      </RippleEffect>
    </div>
  );
}

import { find, isEmpty, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { localIsInterestBottomSheetHidden } from '../../../../../context/atoms/AiBots';
import { AiBotCardV2 } from '../../../entities/ai-bot-card-v2';
import { AiBotTool } from '../../../entities/ai-bot/types';
import { AiBotsListType } from '../../../feature/ai-bots-categories/api';
import { EmptyResults } from '../../../feature/empty-results';
import { SearchForm } from '../../../feature/search-form';
import { SelectCategoriesBottomSheet } from '../../select-categories';
import { getUniqCategories } from '../lib';
import { CategoryButton } from './CategoryButton';
import './assets/ai-bots-list-v2.css';

export function AiBotsListContainer({
  data,
  withSearchForm,
  onCategoryButtonClicked,
  onClickToBot,
  onSearchFormClick,
  onSearchFormSubmit,
  isCardsLimited,
}: {
  data: AiBotsListType;
  withSearchForm?: boolean;
  isCardsLimited?: boolean;
  onCategoryButtonClicked?: (id: string) => void;
  onClickToBot?: (bot: AiBotTool) => void;
  onSearchFormClick?: () => void;
  onSearchFormSubmit?: (count: number) => void;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();
  const categories = getUniqCategories(data.items);
  const aiBotsList = data.items;

  const [currentCategoryId, setCurrentCategoryId] = useState(categories[0].id);
  const [searchQuery, setSearchQuery] = useState('');

  const selectedCategory = useMemo(() => {
    return (
      aiBotsList.find(category => category.id === currentCategoryId) || null
    );
  }, [currentCategoryId, categories]);

  const hasUserDismissedBottomSheet = useRecoilValue(
    localIsInterestBottomSheetHidden
  );
  const setHasUserDismissedBottomSheet = useSetRecoilState(
    localIsInterestBottomSheetHidden
  );

  const isSelectCategoriesBottomSheetVisible = useMemo(() => {
    const forYouCategory = find(data?.items, item => item.id === 'for_you');
    return (
      !(forYouCategory?.tools && forYouCategory.tools.length > 0) &&
      !hasUserDismissedBottomSheet
    );
  }, [data, hasUserDismissedBottomSheet]);

  function onSelectCategoryClose() {
    setHasUserDismissedBottomSheet(true);
  }

  const listBySearchQuery = useMemo(() => {
    if (!selectedCategory) return [];

    let filteredList = selectedCategory.tools.filter(
      item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        item.id !== 'preset'
    );

    if (isCardsLimited) {
      filteredList = filteredList.slice(0, 6);
    }

    return filteredList;
  }, [selectedCategory, searchQuery, isCardsLimited]);

  useEffect(() => {
    if (searchQuery) {
      onSearchFormSubmit?.(size(listBySearchQuery));
    }
  }, [searchQuery, listBySearchQuery]);

  function onSearch(query: string) {
    setSearchQuery(query);
  }

  function onSetCurrentCategoryId(id: string) {
    setCurrentCategoryId(id);
    onCategoryButtonClicked?.(id);
  }

  function goToChatPage(aiBot: AiBotTool) {
    onClickToBot?.(aiBot);
    navigation.push(`/ai-bots/new-chat/${aiBot.id}`, {
      from: navigation.location.pathname,
    });
  }

  useEffect(() => {
    setCurrentCategoryId(categories[0].id);
  }, [data.items]);

  const searchPlaceholder = t('ai-bots.search.bots-placeholder');

  return (
    <section className="ai-bots-list-v2__container">
      {withSearchForm && (
        <div className="ai-bots-list-v2__search-form">
          <SearchForm
            placeholder={searchPlaceholder}
            onClick={() => onSearchFormClick?.()}
            onSearch={text => onSearch(text)}
          />
        </div>
      )}
      <div className="ai-bots-list-v2__category-buttons scrollbar-hide no-scrollbar">
        {categories.map(category => (
          <CategoryButton
            key={category.id}
            isActive={category.id === currentCategoryId}
            category={category}
            onClick={onSetCurrentCategoryId}
          />
        ))}
      </div>
      {isEmpty(listBySearchQuery) ? (
        <div className="ai-bots-list-v2-empty">
          <EmptyResults />
        </div>
      ) : (
        <div className="ai-bots-list-v2__content-v2">
          {listBySearchQuery.map(aiBot => (
            <AiBotCardV2
              key={aiBot.id}
              data={aiBot}
              onClick={() => goToChatPage(aiBot)}
            />
          ))}
        </div>
      )}
      <SelectCategoriesBottomSheet
        isVisible={isSelectCategoriesBottomSheetVisible}
        onDismiss={onSelectCategoryClose}
      />
    </section>
  );
}

import { useQuery, useQueryClient } from '@tanstack/react-query';

import i18n from '../i18n';
import { useCurrency } from './currency';

const CURRENCY_QUERY_KEY = 'currency';

export function useCurrencySignQuery() {
  const { getCurrency } = useCurrency();

  return useQuery({
    queryKey: [CURRENCY_QUERY_KEY, i18n.language],
    queryFn: () => getCurrency(),
    staleTime: Infinity,
  });
}

export function usePrefetchCurrencySignQuery() {
  const { getCurrency } = useCurrency();
  const queryClient = useQueryClient();

  function prefetchCurrencyQuery() {
    return queryClient.prefetchQuery({
      queryKey: [CURRENCY_QUERY_KEY, i18n.language],
      queryFn: () => getCurrency(),
    });
  }

  return {
    prefetchCurrencyQuery,
  };
}

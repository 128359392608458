import Bugsnag from '@bugsnag/js';
import i18next from 'i18next';
import qs from 'query-string';

import { NewGeo } from '../types/Geo';
import { SentinelSDK } from './sentinel';

const DEVICE_ID_KEY = 'coursiv:device-id';

const deviceId = localStorage.getItem(DEVICE_ID_KEY);

export function createSentinelSDK({ geo }: { geo: NewGeo }) {
  const urlQueryParams = qs.parse(window.location.search);

  return SentinelSDK({
    source: 'APP',
    analytics: {
      appUrl: window.location.origin,
      urlQueryParams: urlQueryParams,
      userAgent: navigator?.userAgent,
      referrer: document.referrer,
      language: i18next.language, //TODO: add change lang
    },
    firehose: {
      credentials: {
        accessKeyId: process.env.REACT_APP_SENTINEL_ACCESS_KEY as string,
        secretAccessKey: process.env.REACT_APP_SENTINEL_SECRET_KEY as string,
      },
      region: process.env.REACT_APP_SENTINEL_REGION as string,
      deliveryStreamName: process.env
        .REACT_APP_SENTINEL_DELIVERY_STREAM as string,
    },
    geo: {
      groups: geo.groups,
      country: {
        alpha2: geo.country.alpha_2,
        alpha3: geo.country.alpha_3,
        name: geo.country.name,
      },
      ipAddress: geo.ip_address,
      cityName: geo.region.city,
      regionCode: geo.region.code,
      regionName: geo.region.name,
    },
    deviceId,
    events: {
      onDeviceIdUpdated(id) {
        localStorage.setItem(DEVICE_ID_KEY, id);
      },
      onError(cause) {
        Bugsnag.notify(new Error('SENTINEL_SDK_ERROR', { cause }));
      },
      onSendAnalyticsError(cause) {
        Bugsnag.notify(new Error('SENTINEL_SEND_ANALYTICS_ERROR', { cause }));
      },
    },
  });
}

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { GuidesAPI } from '../../shared/api';
import { GuideType } from '../guide';

export function useGuideByIdProgressQuery({ id }: { id: GuideType['id'] }) {
  return useQuery({
    queryKey: ['guides-list-progress', id],
    queryFn: () =>
      GuidesAPI.getGuideProgressById({ id }).then(({ data }) => data),
  });
}

export function usePrefetchGuideByIdProgressQuery() {
  const queryClient = useQueryClient();

  function prefetchGuideByIdProgressQuery({ id }: { id: GuideType['id'] }) {
    return queryClient.prefetchQuery({
      queryKey: ['guides-list-progress', id],
      queryFn: () =>
        GuidesAPI.getGuideProgressById({ id }).then(({ data }) => data),
    });
  }

  return {
    prefetchGuideByIdProgressQuery,
  };
}

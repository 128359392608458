import { find, get } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiNavigation } from '../../../../ai-bots/shared/ui';
import { GoalType } from '../../../../personal-goal/config';
import { AppFooter } from '../../../../shared';
import { GuideType } from '../../../entities/guide';
import { useGuidesListQuery } from '../../../entities/guides-list';
import { SmallGuideCard } from '../../../features/small-guide-card';
import { HomePageAnalytics } from '../../../shared/analytics';
import { partitionGuidesByCategories } from '../../../shared/lib/partition-guides-by-categories';
import './guides-by-category.css';

export function GuidesByCategoryPage() {
  const { t } = useTranslation();
  const { category } = useParams<{ category: string }>();
  const navigation = useHistory();
  const guidesListQuery = useGuidesListQuery();
  const personalProfileQuery = usePersonalProfileQuery();
  const goal = get(personalProfileQuery.data, 'properties.goal[0]');

  const CATEGORIES_CONTENT = t('home.categories', { returnObjects: true });

  const title = get(CATEGORIES_CONTENT, `[${category}].title`, '');

  const guides = useMemo(() => {
    if (guidesListQuery.data && goal) {
      const categories = partitionGuidesByCategories({
        guides: guidesListQuery.data,
        goal: goal as GoalType,
      });

      return find(categories, ['type', category])?.guides ?? [];
    }

    return [];
  }, [guidesListQuery.data, goal]);

  function onGuideClick(guide: GuideType) {
    if (goal) {
      HomePageAnalytics.onGuideClick({
        goal,
        place: 'view_all',
        guideId: guide.id,
        guideName: guide.name,
        category,
      });
    }

    navigation.push(`/guides/${guide.id}`, { from: 'view_all', category });
  }

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={title}
        onBackButtonClicked={() => {
          navigation.replace('/');
        }}
      />

      <section className="ui-base-layout__content guides-by-category-page">
        {guidesListQuery.isError ? (
          <FullPageError onRetry={() => guidesListQuery.refetch()} />
        ) : (
          <div className="guides-by-category-page__content">
            {guides.map(guide => (
              <SmallGuideCard
                key={guide.id}
                guide={guide}
                onClick={() => onGuideClick(guide)}
              />
            ))}
          </div>
        )}
      </section>

      <AppFooter />
    </main>
  );
}

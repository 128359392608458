import { get, trim, uniqueId } from 'lodash-es';

import { GuidesAPI } from '../../shared/api';
import { GuideLessonDetailsTaskType } from '../guide-lesson';
import { GuideMessageType } from './types';

let timeout: ReturnType<typeof setTimeout>;
let abortController = new AbortController();

export function useSendGuideMessage() {
  function getMessageAnswer({
    currentMessage,
    isPractice,
    text,
    task,
  }: {
    currentMessage: {
      completion_id: string;
      status: string;
    };
    isPractice: boolean;
    text: string;
    task: GuideLessonDetailsTaskType;
  }): Promise<GuideMessageType> {
    return new Promise(resolve => {
      const getMessageAnswerPromise = async () => {
        clearTimeout(timeout);

        try {
          const messageAnswer = await GuidesAPI.getMessageAnswer({
            messageId: currentMessage.completion_id,
            signal: abortController.signal,
          }).then(({ data }) => data);

          if (messageAnswer.completion || messageAnswer.feedback) {
            /**
             * @description
             * for practice, we always return isCorrect: true
             * */

            const feedback = () => {
              if (isPractice) {
                return messageAnswer.feedback;
              }

              return messageAnswer.is_correct ? messageAnswer.feedback : '';
            };

            return resolve({
              id: currentMessage.completion_id,
              text,
              taskId: task.id,
              response: messageAnswer.completion,
              feedback: feedback(),
              isCorrect: isPractice ? messageAnswer.is_correct : true,
            });
          }

          startGetAnswerPromisePolling();
        } catch (cause) {
          throw new Error('ERROR_GUIDE_GET_TASK_MESSAGE_ANSWER', { cause });
        }
      };

      function startGetAnswerPromisePolling() {
        timeout = setTimeout(() => {
          getMessageAnswerPromise();
        }, 1000);
      }

      getMessageAnswerPromise();
    });
  }

  function sendGuideMessage({
    text,
    task,
    isPractice,
  }: {
    text: string;
    task: GuideLessonDetailsTaskType;
    isPractice: boolean;
  }) {
    abortController = new AbortController();

    const taskAnswer = task.content[0].answer;

    if (taskAnswer) {
      return new Promise<GuideMessageType>(resolve => {
        setTimeout(() => {
          resolve({
            id: uniqueId('_message-with-local-answer'),
            text,
            taskId: task.id,
            response: taskAnswer as string,
            feedback: '',
            isCorrect: true,
          });
        }, 1000);
      });
    }

    return GuidesAPI.sendMessage({
      message: text,
      /* @description
          use content[0].description
          because the chunks scheme should be the same,
          but task content will always have one element
       */
      context: trim(get(task, 'content[0].description')),
      signal: abortController.signal,
    }).then(({ data }) =>
      getMessageAnswer({
        currentMessage: data,
        isPractice,
        text,
        task,
      })
    );
  }

  function abortSendGuideMessage() {
    abortController.abort();
    clearTimeout(timeout);
  }

  return {
    sendGuideMessage,
    abortSendGuideMessage,
  };
}

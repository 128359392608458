import i18next from 'i18next';

import { analyticsService } from '../../../services/analytics.service';

export const WebToAppAnalytics = {
  pageView({ platform }: { platform: string }) {
    analyticsService.sendEvent('webapp_web2app_view', {
      language: i18next.language,
      platform,
    });
  },
  onClickCopyButton({
    action,
    platform,
  }: {
    action: 'copy_email' | 'copy_password';
    platform: string;
  }) {
    analyticsService.sendEvent('webapp_web2app_click', {
      action,
      language: i18next.language,
      platform,
    });
  },
  onClickDownloadButton({ platform }: { platform: string }) {
    analyticsService.sendEvent('webapp_web2app_click', {
      action: 'download',
      language: i18next.language,
      platform,
    });
  },
};

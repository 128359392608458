import { AxiosResponse } from 'axios';
import { get } from 'lodash-es';

import { GOALS } from '../../../modules/personal-goal';
import { api } from '../../../services/http.service';
import AvatarIcon from '../ui/assets/avatar_icon.svg';

export interface ProfileType {
  photo: string;
  properties: {
    goal: Array<string>;
  };
  personalization_criteria: {
    full_name: string[];
    arrow: string[];
    goal: string[];
    interest: string[];
    required_skills: string[];
  };
  registered_at: string;
}

const PERSONAL_PROFILE = 'coursiv:personal-profile';
export const DEFAULT_PERSONAL_PROFILE = {
  photo: AvatarIcon,
  properties: {
    goal: [GOALS.GROW_WEALTH],
  },
};

export const PersonalProfileStorage = {
  getProfile(userId: number) {
    const data = sessionStorage.getItem(PERSONAL_PROFILE);

    if (data) {
      const profile = JSON.parse(data);

      if (profile) {
        return get(profile, userId) as ProfileType;
      }
    }

    return undefined;
  },
  setProfile({ userId, profile }: { userId: number; profile: ProfileType }) {
    sessionStorage.setItem(
      PERSONAL_PROFILE,
      JSON.stringify({ [userId]: profile })
    );
  },
};

export const PersonalProfileAPI = {
  getProfile(): Promise<AxiosResponse<ProfileType>> {
    return api.get('/user-profile/users');
  },
  updateGoal(userId: number, goal: string): Promise<AxiosResponse<void>> {
    return api
      .patch('user-profile/users/properties', {
        key: 'goal',
        answers: [goal],
      })
      .then(res => {
        const profile = PersonalProfileStorage.getProfile(userId);

        if (profile) {
          PersonalProfileStorage.setProfile({
            userId,
            profile: {
              ...profile,
              properties: {
                goal: [goal],
              },
            },
          });
        }

        return res;
      })
      .catch(err => {
        throw err;
      });
  },
  updateFullName(
    userId: number,
    fullName: string
  ): Promise<AxiosResponse<void>> {
    return api
      .patch('user-profile/users/properties', {
        key: 'full_name',
        answers: [fullName],
      })
      .then(res => {
        const profile = PersonalProfileStorage.getProfile(userId);

        if (profile) {
          PersonalProfileStorage.setProfile({
            userId,
            profile: {
              ...profile,
              personalization_criteria: {
                ...profile.personalization_criteria,
                full_name: [fullName],
              },
            },
          });
        }

        return res;
      })
      .catch(err => {
        throw err;
      });
  },
};

import {
  filter,
  find,
  first,
  isEmpty,
  maxBy,
  some,
  toPairs,
  values,
} from 'lodash-es';

import { GuideType } from '../../../guides/entities/guide';

export function getOngoingGuideByArrow({
  guides,
  progressList,
  arrow = 'ai',
  withFallback = false,
}: {
  guides?: Array<GuideType>;
  progressList?: Record<GuideType['id'], number>;
  arrow?: string;
  withFallback?: boolean;
}) {
  if (!guides && isEmpty(guides) && !progressList) {
    return null;
  }

  const filteredGuides = guides?.filter(guide =>
    guide.tags.some(tag => tag.toLowerCase() === arrow)
  );

  const getFallbackGuide = () => {
    const aiToolId = localStorage.getItem('ai-tool');
    if (aiToolId && filteredGuides) {
      return find(filteredGuides, ['id', aiToolId]) || first(filteredGuides);
    }
    return first(filteredGuides);
  };

  const fallback = {
    progress: 0,
    guide: getFallbackGuide(),
  };

  const hasGuidesProgress = some(values(progressList), v => v > 0);

  if (!hasGuidesProgress) {
    return withFallback ? fallback : null;
  }

  const pairsProgressList = toPairs(progressList);
  const guideIds = filteredGuides?.map(guide => guide.id);

  const filteredPairsProgressList = pairsProgressList.filter(
    ([id]) => guideIds && guideIds.includes(id)
  );

  const filteredProgressList = filter(
    filteredPairsProgressList,
    p => p[1] > 0 && p[1] !== 1
  );

  let maxProgressGuide: [string, number] | undefined;

  if (isEmpty(filteredProgressList) && withFallback) {
    maxProgressGuide = maxBy(filteredPairsProgressList, p => p[1]);
  } else {
    maxProgressGuide = maxBy(filteredProgressList, p => p[1]);
  }
  if (!maxProgressGuide) {
    return withFallback ? fallback : null;
  }

  const [id, progress] = maxProgressGuide;

  const guide = find(filteredGuides, ['id', id]);

  return {
    progress: progress * 100,
    guide,
  };
}

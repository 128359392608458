import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import {
  showCheckMailModal,
  showCongratulationsOnReset,
  showLoadingSpinner,
  showResetPasswordForm,
} from '../../../context/atoms/GlobalPopup';
import { IUserState } from '../../../context/atoms/User';
import signup_congratulations from '../../../images/signup_congratulations.webp';
import { analyticsService } from '../../../services/analytics.service';
import cognitoService from '../../../services/cognito.service';
import { handleImagePreload } from '../../../utils/ImagePreloader/ImagePreloader';
import LanguageNavbar from '../../shared/Navbar/LanguageNavbar';
import LogoNavbar from '../../shared/Navbar/LogoNavbar';
import ResetForm from '../PasswordReset/ResetForm/ResetForm';
import CheckMailModal from './CheckMailModal/CheckMailModal';
import CongratulationsOnReset from './CongratulationsOnReset/CongratulationsOnReset';
import {
  FlexWrapper,
  Heading,
  QuestionContainer,
  Subtitle,
  Wrapper,
} from './PasswordReset.styles';

const ResetPassword: FC = () => {
  const setLoading = useSetRecoilState(showLoadingSpinner);
  const [showCheckEmail, setShowCheckEmail] =
    useRecoilState(showCheckMailModal);
  const [showPasswordResetForm, setShowPasswordResetForm] = useRecoilState(
    showResetPasswordForm
  );
  const [IUser, setIUser] = useRecoilState(IUserState);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t('* Please enter a valid email')!
      )
      .required(t('* This is a required field')!),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    const email = data.email.trim();
    const re_whitespace = /\s/g;

    if (re_whitespace.test(email)) {
      setErrorMessage(t('* Please enter a valid email')!);
      return;
    }

    setLoading(true);
    setErrorMessage('');
    analyticsService.sendEvent('pr_funnel_reset_password_code_enter');
    const newUser = {
      email: email,
      password: IUser.password,
      code: IUser.code,
      username: IUser.username,
      user_id: IUser.user_id,
    };
    setIUser(newUser);
    initiateForgot(email);
  };

  const initiateForgot = async (enteredEmail: string) => {
    analyticsService.sendEvent('pr_funnel_reset_password_initiate', {
      email: enteredEmail,
    });
    await cognitoService
      .forgotPassword(enteredEmail)
      .then((response: any) => {
        setLoading(false);
        const newCheckMailState = {
          open: true,
          errorMessage: '',
        };
        setShowCheckEmail(newCheckMailState);
      })
      .catch((error: any) => {
        switch (error.code) {
          case 'UserNotFoundException':
            setErrorMessage('* Seems like you have not registered yet');
            break;
          case 'InvalidParameterException':
            setErrorMessage("* We couldn't find a user with this email");
            break;
          case 'LimitExceededException':
            setErrorMessage(
              '* Attempt limit exceeded, please try after 5 minutes'
            );
            break;
          default:
            setErrorMessage(t('* Please enter a valid email and password')!);
            break;
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    handleImagePreload(signup_congratulations);
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      {showCheckEmail && <CheckMailModal />}
      {showCongratulationsOnReset && <CongratulationsOnReset />}

      <LogoNavbar isShadow={false} />
      <Wrapper>
        {showPasswordResetForm ? (
          <ResetForm />
        ) : (
          <QuestionContainer>
            <Heading>{t('Forgot password?')}</Heading>
            <Subtitle>
              {t(
                "Don't worry! We'll send You reset instructions. Please enter the email address associated with your account."
              )}
            </Subtitle>

            <FlexWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  {...register('email')}
                  autoFocus
                  sx={{ width: 1 }}
                  id="email"
                  type="text"
                  label={t('Email')}
                  name="email"
                  onChange={() => setErrorMessage('')}
                  error={
                    getValues('email') !== ''
                      ? !!errorMessage || !!errors.email
                      : false
                  }
                  helperText={
                    getValues('email') !== ''
                      ? errors?.email?.message || errorMessage
                      : ''
                  }
                  placeholder={t('example@gmail.com')!}
                  InputLabelProps={{ shrink: true }}
                />
              </form>
            </FlexWrapper>

            <button
              style={{
                backgroundColor: '#5653FE',
                color: '#fff',
                border: 'none',
                borderRadius: '8px',
                padding: '10px 24px',
                width: '100%',
                margin: '24px 0 18px',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '200%',
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {' '}
              {t('Send reset code')}
            </button>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Subtitle
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  lineHeight: '28px',
                  color: 'black',
                }}
              >
                {t('Return to')}{' '}
                <Link to="/auth/signin">
                  <a
                    style={{
                      textDecoration: 'underline',
                      color: '#5653FE',
                    }}
                  >
                    {t('Log In')}
                  </a>
                </Link>
              </Subtitle>
            </div>
          </QuestionContainer>
        )}
      </Wrapper>
    </div>
  );
};

export default ResetPassword;

import { AxiosResponse } from 'axios';

import { api } from '../services/http.service';
import { NewGeo } from '../types/Geo';

export function getGeo(): Promise<AxiosResponse<NewGeo>> {
  return api.request({
    baseURL: process.env.REACT_APP_GEO_API_URL as string,
    method: 'GET',
  });
}

import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  padding: 24px;
  color: #24234c;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #0b0b1d;
  text-align: center;
  max-width: 280px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #636366;
  margin-bottom: 24px;
  text-align: center;
  margin-top: 8px;
`;

export const ImageContainer = styled.div`
  margin-bottom: 24px;
  width: 131px;
  height: auto;
  img {
    width: 100%;
    max-width: 100%;
  }
`;

export const ModalButton = styled.button<{ isMain: boolean }>`
  background: ${({ isMain }) => (isMain ? '#5653FE' : '#FFFFFF')};
  opacity: ${({ isMain }) => (isMain ? '1' : '0.6')};
  border: ${({ isMain }) => (isMain ? '1px solid #5653FE' : 'none')};
  color: ${({ isMain }) => (isMain ? '#FFFFFF' : '#0B0B1D')};

  box-sizing: border-box;
  border-radius: 8px;
  display: block;
  width: 100%;
  height: 48px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 14px;
`;

import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const GuidesAnalytics = {
  mainPageView({
    ab_guides_next_guide,
    ab_guide_first_guide,
    start_register_date,
  }: {
    ab_guides_next_guide: string;
    ab_guide_first_guide: string;
    start_register_date: string;
  }) {
    /**
     * @description
     * The page view event is called click,
     * because for a long time the error was not noticed.
     * Now the data is linked in this way
     * */
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'home_page',
      feature_version: 'v1',
      language: i18next.language,
      ab_guides_next_guide,
      ab_guide_first_guide,
      start_register_date,
    });
  },
  onGuideClick({
    guideId,
    ab_guides_next_guide,
    ab_guide_first_guide,
    start_register_date,
  }: {
    guideId: string;
    ab_guides_next_guide: string;
    ab_guide_first_guide: string;
    start_register_date: string;
  }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place: 'home_page',
      guide_id: guideId,
      is_coming_soon: false,
      language: i18next.language,
      ab_guides_next_guide,
      ab_guide_first_guide,
      start_register_date,
    });
  },
  guidePageView({
    guideId,
    progress,
    ab_home_page,
  }: {
    guideId: string;
    progress: number;
    ab_home_page: string;
  }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place: 'guide_detail_page',
      guide_id: guideId,
      progress,
      feature_version: 'v2',
      language: i18next.language,
      ab_home_page,
    });
  },
  onStartLessonClick({
    guideId,
    unitId,
    lessonId,
    lessonType,
    progress,
    isLocked,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
    lessonType: 'theory' | 'practice';
    progress: number;
    isLocked: boolean;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'guide_detail_page',
      action: 'start_lesson',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      lesson_type: lessonType === 'practice' ? 'practice' : 'lesson',
      lesson_progress: progress,
      locked: isLocked,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  lessonChunkView({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onOpenTask({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'start_task',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onSkipTask({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'skip_task',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onSubmitMessage({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
    isRetrySubmission,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
    isRetrySubmission: boolean;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'submit_prompt',
      is_retry_submission: isRetrySubmission,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onReceivesAnswer({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
    isSuccess,
    isSubmissionCorrect,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
    isSuccess: boolean;
    isSubmissionCorrect: boolean;
  }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'receive_response',
      is_success: isSuccess,
      is_submission_correct: isSubmissionCorrect,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onTriggerToast({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
    isVisible,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
    isVisible: boolean;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: isVisible ? 'show_task_help' : 'close_task_help',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  lessonCompletePageView({
    guideId,
    unitId,
    lessonId,
    aaTestValue,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
    aaTestValue?: string;
  }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place: 'lesson_complete',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature_version: 'v1',
      language: i18next.language,
      ...(aaTestValue && { exp_aa_demo_v1: aaTestValue }),
    });
  },
  onLessonCompleteContinueClick({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'lesson_complete',
      action: 'continue',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature_version: 'v1',
      language: i18next,
    });
  },
  onLessonCompleteNextLessonClick({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'lesson_complete',
      action: 'next_lesson',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature_version: 'v1',
      language: i18next,
    });
  },
  onLessonCompleteCloseClick({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'lesson_complete',
      action: 'close',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature_version: 'v1',
      language: i18next,
    });
  },
  surveyPageView({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_survey_view', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature: 'guides',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onCloseSurveyPage({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature: 'guides',
      feature_version: 'v1',
      action: 'close',
      language: i18next.language,
    });
  },
  onSiteJabberClicked({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  }) {
    analyticsService.sendEvent('webapp_sitejabber_click', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature: 'guides',
      feature_version: 'v1',
      action: 'click',
      language: i18next.language,
    });
  },
  onRateClick({
    guideId,
    unitId,
    lessonId,
    rating,
    isTrustpilotAvailable,
    shouldShowReviewPlatform,
    reviewPlatform,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
    rating: number;
    isTrustpilotAvailable: boolean;
    shouldShowReviewPlatform?: boolean;
    reviewPlatform?: string | null;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature: 'guides',
      feature_version: 'v1',
      rating,
      action: 'rate',
      trustpilot: isTrustpilotAvailable,
      should_show_review_platform: shouldShowReviewPlatform,
      review_platform: reviewPlatform,
      language: i18next.language,
    });
  },
  onSubmitFeedback({
    guideId,
    unitId,
    lessonId,
    rating,
    feedback,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
    rating: number;
    feedback: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      feature: 'guides',
      feature_version: 'v1',
      rating,
      action: 'rate',
      trustpilot: false,
      feedback,
      language: i18next.language,
    });
  },
  onQuizAnswerSelect({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'select_quiz_option',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onQuizSubmit({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
    isCorrect,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
    isCorrect: boolean;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'submit_quiz_option',
      is_correct: isCorrect,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onQuizHintClick({
    place,
    guideId,
    unitId,
    lessonId,
    pageId,
    chunkId,
  }: {
    place: 'theory' | 'practice';
    guideId: string;
    unitId: string;
    lessonId: string;
    pageId: string;
    chunkId: string;
  }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place:
        place === 'practice' ? 'practice_lesson_page' : 'learning_lesson_page',
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
      page_id: pageId,
      chunk_id: chunkId,
      action: 'show_quiz_hint',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
};

import classNames from 'classnames';
import { filter, first, join, split } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userAccessQuery, userQuery } from '../../../../context/atoms/User';
import { analyticsService } from '../../../../services/analytics.service';
import './assets/app-footer.css';
import { FOOTER_TABS, PROFILE_PAGE } from './config';
import { LockIcon } from './icons';

type TabType = (typeof FOOTER_TABS)[0];

export function AppFooter() {
  const history = useHistory();
  const location = useLocation();

  const user = useRecoilValue(userQuery);
  const userId = user?.user_id;
  const userAccess = useRecoilValue(userAccessQuery(userId));

  function isActive(activeRoutes: TabType['activeRoutes']) {
    const pathName = first(filter(split(location.pathname, '/')));
    const path = join(['/', pathName], '');
    return activeRoutes.includes(path);
  }

  function isLocked(type: TabType['type']) {
    return !userAccess.has_access && type !== PROFILE_PAGE;
  }

  function onGoTo({
    title,
    route,
  }: {
    title: TabType['title'];
    route: TabType['route'];
  }) {
    analyticsService.sendEvent('pr_webapp_tapbar_click', {
      type: title,
    });

    history.push(route);
  }

  return (
    <div className="app-footer">
      {FOOTER_TABS.map(
        ({ title, type, route, activeRoutes, IconComponent }) => (
          <button
            key={`footer-tab-${type}`}
            type="button"
            disabled={isLocked(type)}
            className={classNames('app-footer__tab', {
              'app-footer__tab--active': isActive(activeRoutes),
            })}
            onClick={() => onGoTo({ title, route })}
          >
            {isLocked(type) ? <LockIcon /> : <IconComponent />}

            <strong className="app-footer__tab-title">{title}</strong>
          </button>
        )
      )}
    </div>
  );
}

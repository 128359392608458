import { useAutoAnimate } from '@formkit/auto-animate/react';
import { isNil } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GuideLessonDetailsContentType,
  GuideLessonDetailsQuizType,
  GuideLessonDetailsType,
} from '../../../entities/guide-lesson';
import { useGuideLessonQuizStore } from '../../../entities/guide-lesson-quiz';
import { ImageSkeleton } from '../../image-skeleton';
import {
  CheckmarkCorrectIcon,
  CheckmarkIncorrectIcon,
  QuizOptions,
} from './QuizOptions';
import './guide-lesson-quiz.css';

type QuizType = GuideLessonDetailsQuizType['content'][0];
export type AnswerType = GuideLessonDetailsQuizType['content'][0]['options'][0];

function QuizContent({
  type,
  screenId,
  chunkId,
  quiz,
  events,
}: {
  type: GuideLessonDetailsType['type'];
  screenId: GuideLessonDetailsContentType['id'];
  chunkId: GuideLessonDetailsQuizType['id'];
  quiz: QuizType;
  events: {
    onSelectOption?: (chunkId: string) => void;
    onTriggerHint?: (chunkId: string) => void;
    onQuizSubmit?: ({
      chunkId,
      isCorrect,
      selectedAnswer,
    }: {
      chunkId: string;
      isCorrect: boolean;
      selectedAnswer: AnswerType;
    }) => void;
  };
}) {
  const [animatedRef] = useAutoAnimate();
  const { t } = useTranslation();
  const [isHintVisible, setIsHintVisible] = useState(false);
  const { completedQuizIds, addCompletedQuizId } = useGuideLessonQuizStore();

  const isQuizSubmitted = useMemo(
    () => completedQuizIds.includes(chunkId),
    [completedQuizIds]
  );

  const [selectedAnswer, setSelectedAnswer] = useState<AnswerType>();

  function onSelectOption(option: AnswerType) {
    events?.onSelectOption?.(chunkId);

    setSelectedAnswer(option);
  }

  function onTriggerHint() {
    events?.onTriggerHint?.(chunkId);

    setIsHintVisible(v => !v);
  }

  function onSubmit() {
    if (!selectedAnswer) {
      throw new Error('ERROR_SUBMIT_WITHOUT_ANSWER');
    }

    events?.onQuizSubmit?.({
      chunkId,
      isCorrect: selectedAnswer.is_correct,
      selectedAnswer,
    });

    addCompletedQuizId(chunkId);

    setTimeout(() => {
      window.scroll({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 300);
  }

  return (
    <div ref={animatedRef} className="guide-lesson-quiz">
      <h4 className="guide-lesson-quiz__title">{quiz.text}</h4>
      {quiz.text_image && (
        <div>
          <ImageSkeleton
            src={quiz.text_image}
            alt=""
            width="100%"
            height="200px"
            className="guide-lesson-quiz__text-image"
          />
        </div>
      )}

      <QuizOptions
        quiz={quiz}
        isQuizSubmitted={isQuizSubmitted}
        selectedAnswer={selectedAnswer}
        onSelectOption={onSelectOption}
      />

      {isHintVisible && (
        <div className="guide-lesson-quiz__hint">{quiz.hint}</div>
      )}

      {!isQuizSubmitted && (
        <div className="guide-lesson-quiz__actions">
          {quiz.hint && (
            <button
              type="button"
              className="guide-lesson-quiz__action guide-lesson-quiz__hint-button"
              onClick={onTriggerHint}
            >
              {t('guides.hint-button')}
            </button>
          )}

          <button
            disabled={isNil(selectedAnswer)}
            type="button"
            className="guide-lesson-quiz__action guide-lesson-quiz__submit-button"
            onClick={onSubmit}
          >
            {t('guides.submit-button')}
          </button>
        </div>
      )}

      {isQuizSubmitted && selectedAnswer && selectedAnswer.is_correct && (
        <div className="guide-lesson-quiz__answer guide-lesson-quiz__answer--correct">
          <div className="quiz-answer__header">
            <CheckmarkCorrectIcon className="quiz-checkmark-correct" />
            <h5 className="quiz-answer__title">
              {t('guides.quiz.correct-title')}
            </h5>
          </div>

          <p className="quiz-answer__text">{quiz.correct_text}</p>
          {quiz.correct_text_image && (
            <ImageSkeleton
              src={quiz.correct_text_image}
              alt=""
              width="100%"
              height="200px"
              className="guide-lesson-quiz__text-image"
            />
          )}
        </div>
      )}

      {isQuizSubmitted && selectedAnswer && !selectedAnswer.is_correct && (
        <div className="guide-lesson-quiz__answer guide-lesson-quiz__answer--incorrect">
          <div className="quiz-answer__header">
            <CheckmarkIncorrectIcon className="quiz-checkmark-incorrect" />
            <h5 className="quiz-answer__title">
              {t('guides.quiz.incorrect-title')}
            </h5>
          </div>
          <p className="quiz-answer__text">{quiz.wrong_text}</p>
          {quiz.wrong_text_image && (
            <ImageSkeleton
              src={quiz.wrong_text_image}
              alt=""
              width="100%"
              height="200px"
              className="guide-lesson-quiz__text-image"
            />
          )}
        </div>
      )}
    </div>
  );
}

export function GuideLessonQuiz({
  type,
  screenId,
  quiz,
  events,
}: {
  type: GuideLessonDetailsType['type'];
  screenId: GuideLessonDetailsContentType['id'];
  quiz: GuideLessonDetailsQuizType;
  events?: {
    onSelectOption?: (chunkId: string) => void;
    onTriggerHint?: (chunkId: string) => void;
    onQuizSubmit?: ({
      chunkId,
      isCorrect,
      selectedAnswer,
    }: {
      chunkId: string;
      isCorrect: boolean;
      selectedAnswer: AnswerType;
    }) => void;
  };
}) {
  return (
    <div className="guide-lesson-quizes">
      {quiz.content.map(item => (
        <QuizContent
          key={item.id}
          type={type}
          screenId={screenId}
          chunkId={quiz.id}
          quiz={item}
          events={{
            onSelectOption: events?.onSelectOption,
            onTriggerHint: events?.onTriggerHint,
            onQuizSubmit: events?.onQuizSubmit,
          }}
        />
      ))}
    </div>
  );
}

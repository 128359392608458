import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../shared/ui';
import { GoalButton } from '../../features/goal-button';
import { PersonalGoalAnalytics } from '../../shared/analytics';
import './main-screen.css';

export function MainScreen({
  place,
  events,
}: {
  place: 'home_page' | 'profile';
  events: {
    onEdit: () => void;
    onClose: () => void;
  };
}) {
  const { t } = useTranslation();

  function onClick() {
    PersonalGoalAnalytics.onEditButtonClick({
      place,
    });
    events.onEdit();
  }

  return (
    <section className="goal-main-screen">
      <div className="goal-main-screen__hero-image">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="96"
          fill="none"
        >
          <g clipPath="url(#a)">
            <path
              fill="#FF7816"
              d="M87.562 0h-8.107L73.7 5.625h13.862a2.814 2.814 0 0 1 2.813 2.813v4.284c0 5.67-1.95 11.228-5.493 15.656a24.985 24.985 0 0 1-11.643 8.119l-3.966 1.32a2.814 2.814 0 1 0 1.78 5.34l3.966-1.324a30.628 30.628 0 0 0 14.257-9.94A30.816 30.816 0 0 0 96 12.723V8.436C96 3.785 92.215 0 87.562 0Z"
            />
            <path
              fill="#FF9F00"
              d="m26.727 37.818-3.966-1.319a25.011 25.011 0 0 1-11.643-8.121 25.142 25.142 0 0 1-5.493-15.656V8.438a2.814 2.814 0 0 1 2.813-2.813h14.534L16.545 0H8.437C3.785 0 0 3.785 0 8.438v4.284c0 6.94 2.39 13.75 6.724 19.171a30.654 30.654 0 0 0 14.257 9.943l3.966 1.321a2.814 2.814 0 1 0 1.78-5.34Z"
            />
            <path
              fill="#FFD400"
              d="M56.625 54.9v4.163a2.785 2.785 0 0 0 2.813 2.812 8.426 8.426 0 0 1 8.437 8.438v5.624a2.785 2.785 0 0 1-2.813 2.813H30.938a2.785 2.785 0 0 1-2.813-2.813v-5.624a8.426 8.426 0 0 1 8.438-8.438 2.785 2.785 0 0 0 2.812-2.813V54.9c-10.463-3.32-18.112-12.544-19.35-23.513L16.537 0h62.926l-3.488 31.387c-1.238 10.969-8.887 20.194-19.35 23.513Z"
            />
            <path
              fill="#FF9F00"
              d="M65.063 78.75H48V0h31.463l-3.488 31.387c-1.238 10.969-8.887 20.194-19.35 23.513v4.163a2.785 2.785 0 0 0 2.813 2.812 8.426 8.426 0 0 1 8.437 8.438v5.624a2.785 2.785 0 0 1-2.813 2.813Z"
            />
            <path
              fill="#FF9F00"
              d="M61.95 21.206a2.86 2.86 0 0 0-2.306-1.968l-6.3-.9-2.813-5.57c-.506-.956-1.519-1.406-2.53-1.406-1.013 0-2.026.45-2.532 1.406l-2.813 5.57-6.3.9a2.86 2.86 0 0 0-2.306 1.968c-.28 1.013 0 2.137.788 2.869l4.5 4.331-1.069 6.075a2.853 2.853 0 0 0 1.125 2.757c.844.618 2.025.73 2.925.225L48 34.537l5.682 2.926c.955.506 2.08.393 2.925-.225a2.853 2.853 0 0 0 1.124-2.757l-1.069-6.075 4.5-4.331c.788-.731 1.07-1.856.788-2.87Z"
            />
            <path
              fill="#EC5569"
              d="M73.5 75.938v17.025h-51V75.938a2.785 2.785 0 0 1 2.813-2.813h45.374a2.785 2.785 0 0 1 2.813 2.813Z"
            />
            <path
              fill="#FF7816"
              d="M53.681 37.463 48 34.537V11.362c1.012 0 2.025.45 2.531 1.406l2.813 5.57 6.3.9a2.86 2.86 0 0 1 2.306 1.968c.281 1.013 0 2.138-.788 2.869l-4.5 4.331 1.069 6.076a2.852 2.852 0 0 1-1.125 2.756 2.75 2.75 0 0 1-2.925.225Z"
            />
            <path
              fill="#CC2E43"
              d="M73.5 75.938v17.025H48V73.125h22.688a2.785 2.785 0 0 1 2.812 2.813Z"
            />
            <path
              fill="#697C86"
              d="M79.125 93.188A2.785 2.785 0 0 1 76.312 96H19.688a2.785 2.785 0 0 1-2.813-2.813 2.785 2.785 0 0 1 2.813-2.812h56.624a2.785 2.785 0 0 1 2.813 2.813Z"
            />
            <path
              fill="#465A61"
              d="M79.125 93.188A2.785 2.785 0 0 1 76.312 96H48v-5.625h28.313a2.785 2.785 0 0 1 2.812 2.813Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h96v96H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <h1 className="goal-main-screen__title">
        {t('goals.main-screen.title')}
      </h1>
      <p className="goal-main-screen__description">
        {t('goals.main-screen.description')}
      </p>

      <GoalButton
        className="goal-main-screen__edit-button"
        events={{
          onClick,
        }}
      />

      <UiPrimaryButton
        className="goal-main-screen__action-button"
        onClick={events.onClose}
      >
        {t('goals.main-screen.action-button')}
      </UiPrimaryButton>
    </section>
  );
}

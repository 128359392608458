import type { AiBot } from '../../../entities/ai-bot';

import { useState } from 'react';

import { isEmpty, last } from 'lodash-es';

import { type AiBotMessageType, AiBotNewMessage } from '../../../entities/message';
import { ChatForm } from '../../../feature/chat-form';
import { ChatInfo } from '../../../feature/chat-info';
import { ChatList } from '../../../feature/chat-list';
import { ChatPresetPrompts } from '../../../feature/chat-preset-prompts';
import './ai-bot-chat.css';

export const ChatContainer = ({
  chatBotId,
  newMessage,
  isMessagesLoading = false,
  isDeleteSessionButtonVisible = false,
  messages = [],
  onShowInfo,
  onDeleteSession,
  onSubmitMessage,
  onRegeneratePrompt,
  onFormClick,
  onOpenImage,
}: {
  chatBotId: string;
  newMessage: AiBotNewMessage | null;
  isMessagesLoading?: boolean;
  isDeleteSessionButtonVisible?: boolean;
  messages?: Array<AiBotMessageType>;
  onShowInfo?: (info: AiBot) => void;
  onDeleteSession?: () => void;
  onSubmitMessage: (text: string, isPresetPrompt?: boolean) => void;
  onRegeneratePrompt: () => void;
  onFormClick?: () => void;
  onOpenImage?: (url: string) => void;
}) => {
  const hasNewMessage = !isEmpty(newMessage);
  const lastMessage = last(messages);

  const [message, setMessage] = useState('');

  const [isMessageSubmitted, setIsMessageSubmitted] = useState(false);
  const isPromptsDisabled =    messages.length > 0 || isMessageSubmitted || isMessagesLoading;

  const isLastMessageResponseEmpty =    Boolean(lastMessage) && isEmpty(lastMessage?.response);

  const isPendingAnswer = hasNewMessage || isLastMessageResponseEmpty;

  function onSumbitMessageForm(text: string, isPresetPrompt?: boolean) {
    setIsMessageSubmitted(true);
    onSubmitMessage(text, isPresetPrompt);
  }

  return (
    <section className="ai-bot-chat">
      <ChatInfo
        chatBotId={chatBotId}
        isDeleteSessionButtonVisible={isDeleteSessionButtonVisible}
        onShowInfo={chatBotDetails => onShowInfo?.(chatBotDetails)}
        onDeleteSession={() => onDeleteSession?.()}
      />
      <ChatList
        isMessagesLoading={isMessagesLoading}
        newMessage={newMessage}
        messages={messages}
        onOpenImage={onOpenImage}
      />
      <ChatPresetPrompts
        isDisabled={isPromptsDisabled}
        chatBotId={chatBotId}
        onSubmit={(text: string, isPresetPrompt?: boolean) => onSumbitMessageForm(text, isPresetPrompt)}
      />
      <ChatForm
        message={message}
        setMessage={setMessage}
        newMessage={newMessage}
        isPendingAnswer={isPendingAnswer}
        isMessagesLoading={isMessagesLoading}
        onSubmit={text => onSumbitMessageForm(text)}
        onFormClick={onFormClick}
        onRegeneratePrompt={onRegeneratePrompt}
      />
    </section>
  );
};

import './assets/retry-button.css';
import RetryIcon from './assets/retry-icon.svg';

export function UiRetryButton({
  className,
  text,
  onRetry,
}: {
  text: string;
  className?: string;
  onRetry: () => void;
}) {
  return (
    <button
      type="button"
      className={`retry-button ${className}`}
      onClick={() => onRetry()}
    >
      <img width="24" height="24" src={RetryIcon} role="presentation" alt="" />
      {text}
    </button>
  );
}

import {
  BreakBlock,
  BreakTextBlock,
  Chunk,
  ContentBlock,
  FillTheGapBlock,
  HeadingBlock,
  ImageBlock,
  QuizBlock,
  TextBlock,
  TitleBlock,
} from './types';

type BlockType =
  | 'break'
  | 'fillTheGap'
  | 'text'
  | 'title'
  | 'breakText'
  | 'quiz'
  | 'heading'
  | 'image';

type BlockTypeMap = {
  break: BreakBlock;
  fillTheGap: FillTheGapBlock;
  text: TextBlock;
  title: TitleBlock;
  breakText: BreakTextBlock;
  quiz: QuizBlock;
  heading: HeadingBlock;
  image: ImageBlock;
};

export function isBlockOfType<T extends BlockType>(type: T) {
  return (block: ContentBlock): block is BlockTypeMap[T] => block.type === type;
}

export function getBlockOfType<T extends BlockType>(type: T) {
  return (taskContent: ContentBlock[]): BlockTypeMap[T] | undefined => {
    return taskContent.find(isBlockOfType(type)) as BlockTypeMap[T] | undefined;
  };
}

export function smoothScrollToBottom() {
  const pageContainer = document.querySelector('.ui-base-layout');

  if (pageContainer) {
    // @note setTimeout - For deferred execution after rendering
    setTimeout(() => {
      window.scrollTo({
        top: pageContainer.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
}

export function smoothScrollToTop() {
  const pageContainer = document.querySelector('.ui-base-layout');

  if (pageContainer) {
    // @note setTimeout - For deferred execution after rendering
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
}

export function groupIntoChunks(blocks: ContentBlock[]): Chunk[] {
  const chunks: Chunk[] = [];
  let currentChunk: Chunk = [];

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    const nextBlock = i + 1 < blocks.length ? blocks[i + 1] : null;
    const nextNextBlock = i + 2 < blocks.length ? blocks[i + 2] : null;

    if (
      block.type === 'break' ||
      block.type === 'quiz' ||
      block.type === 'fillTheGap' ||
      block.type === 'breakText'
    ) {
      // These types are always alone in chunks
      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
        currentChunk = [];
      }
      chunks.push([block]);
    } else if (block.type === 'heading' || block.type === 'title') {
      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
      }
      currentChunk = [block];

      // Check for [TitleBlock/HeadingBlock, TextBlock, ImageBlock] pattern
      if (
        nextBlock &&
        nextBlock.type === 'info' &&
        nextNextBlock &&
        nextNextBlock.type === 'image'
      ) {
        currentChunk.push(nextBlock, nextNextBlock);
        i += 2;
      } else if (
        nextBlock &&
        (nextBlock.type === 'info' || nextBlock.type === 'image')
      ) {
        currentChunk.push(nextBlock);
        i++;
      }

      chunks.push(currentChunk);
      currentChunk = [];
    } else if (block.type === 'info') {
      if (currentChunk.length === 0) {
        currentChunk = [block];
      } else if (currentChunk[0].type === 'info') {
        chunks.push(currentChunk);
        currentChunk = [block];
      } else {
        currentChunk.push(block);
        chunks.push(currentChunk);
        currentChunk = [];
      }
    } else if (block.type === 'image') {
      if (currentChunk.length > 0) {
        currentChunk.push(block);
        chunks.push(currentChunk);
        currentChunk = [];
      } else if (nextBlock && nextBlock.type === 'info') {
        currentChunk = [block, nextBlock];
        i++;
        chunks.push(currentChunk);
        currentChunk = [];
      } else {
        // Image is alone, which shouldn't happen. We'll group it with the next block if possible.
        if (nextBlock) {
          chunks.push([block, nextBlock]);
          i++;
        } else {
          chunks.push([block]);
        }
      }
    }
  }

  // Push any remaining blocks in the current chunk
  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
}

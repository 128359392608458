import styled from 'styled-components';

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding: 24px;
  width: 100%;
  background: #f5f7f9;
  z-index: 301;
  border-radius: 8px 8px 0 0;

  .navbar {
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #24234c;
  }
  .lang-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
  .lang-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #24234c;
    margin-left: 10px;
  }
  .lang-detail {
    display: flex;
  }
`;

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { UiNavigation } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../../guides/entities/guide';
import { useGuidesListQuery } from '../../../../guides/entities/guides-list';
import { OtherGuidesList } from './OtherGuidesList';

export function OtherGuides() {
  const { t } = useTranslation();
  const navigation = useHistory();

  const guidesListQuery = useGuidesListQuery();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const filteredGuides = useMemo(
    () =>
      guidesListQuery.data?.filter(guide =>
        guide.tags.some(tag => tag.toLowerCase() !== userArrow)
      ),
    [guidesListQuery.data]
  );

  function onGoBack() {
    navigation.replace('/');
  }

  function onGoToGuide(guide: GuideType) {
    navigation.push(`/guides/${guide.id}`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = t('guides-v2.recommended-guides');

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation title={title} onBackButtonClicked={onGoBack} />
      <div>
        {guidesListQuery.data && filteredGuides && (
          <OtherGuidesList guides={filteredGuides} onGuideClick={onGoToGuide} />
        )}
      </div>
    </main>
  );
}

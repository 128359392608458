import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const PersonalGoalAnalytics = {
  view({ place }: { place: 'home_page' | 'profile' }) {
    analyticsService.sendEvent('webapp_goal_view', {
      place,
      feature_version: `v1`,
      language: i18next.language,
    });
  },
  onGoalButtonClick({ place }: { place: 'home_page' | 'profile' }) {
    analyticsService.sendEvent('webapp_goal_click', {
      place,
      feature_version: `v1`,
      language: i18next.language,
    });
  },
  onEditButtonClick({ place }: { place: 'home_page' | 'profile' }) {
    analyticsService.sendEvent('webapp_goal_click', {
      place,
      action: 'edit',
      feature_version: `v1`,
      language: i18next.language,
    });
  },
  onSelectButtonClick({
    place,
    selected_goal,
  }: {
    place: 'home_page' | 'profile';
    selected_goal: string;
  }) {
    analyticsService.sendEvent('webapp_goal_click', {
      place,
      action: 'select',
      selected_goal,
      feature_version: `v1`,
      language: i18next.language,
    });
  },
  onUpdateButtonClick({
    place,
    selected_goal,
    status,
  }: {
    place: 'home_page' | 'profile';
    selected_goal: string;
    status: 'success' | 'error';
  }) {
    analyticsService.sendEvent('webapp_goal_click', {
      place,
      action: 'update',
      selected_goal,
      status,
      feature_version: `v1`,
      language: i18next.language,
    });
  },
};

import i18next from 'i18next';

import { analyticsService } from '@/services/analytics.service';

export type KeepLearningAction = 'keep_learning' | 'end_session';

function onCloseClick({ guideId, lessonId }: {guideId: string; lessonId: string}) {
  analyticsService.sendEvent('webapp_guides_click', {
    place:     'learning_lesson_page',
    action:    'close',
    guide_id:  guideId,
    lesson_id: lessonId,
    language:  i18next.language,
  });
}
function onKeepLearningView({ guideId, lessonId }: {guideId: string; lessonId: string}) {
  analyticsService.sendEvent('webapp_keep_learning_view', {
    feature:   'guides',
    guide_id:  guideId,
    lesson_id: lessonId,
    language:  i18next.language,
  });
}
function onKeepLearningClick({
  guideId,
  lessonId,
  action,
}: {
    guideId: string;
    lessonId: string;
    action: KeepLearningAction;
    }) {
  analyticsService.sendEvent('webapp_keep_learning_click', {
    feature:   'guides',
    action,
    guide_id:  guideId,
    lesson_id: lessonId,
    language:  i18next.language,
  });
}

export { onCloseClick, onKeepLearningView, onKeepLearningClick };

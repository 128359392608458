import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './guide-duration.css';

export function GuideDuration({
  className,
  unitsCount,
  duration,
  size,
}: {
  className: string;
  unitsCount: number;
  duration: string;
  size?: 'md' | 'sm';
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('guide-duration', className, {
        'guide-duration--sm': size === 'sm',
      })}
    >
      <strong>
        {size === 'sm'
          ? t('guides.lesson-card.short-unit', {
              unitsCount,
            })
          : t('guides.lesson-card.unit', {
              unitsCount,
            })}
      </strong>

      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" fill="none">
        <circle cx="2.5" cy="2.5" r="2" fill="#6B7280" />
      </svg>

      <strong>{duration}</strong>
    </div>
  );
}

import { useQuery } from '@tanstack/react-query';

import { GuidesAPI } from '../../shared/api';
import { GuideLessonType, GuideType, GuideUnitType } from '../guide';

export function useGuideLessonsQuery({
  guideId,
  unitId,
  lessonId,
}: {
  guideId: GuideType['id'];
  unitId: GuideUnitType['id'];
  lessonId: GuideLessonType['id'];
}) {
  return useQuery({
    queryKey: ['guide-lessons', guideId, unitId, lessonId],
    queryFn: () =>
      GuidesAPI.getLessonById({
        guideId,
        unitId,
        lessonId,
      }).then(({ data }) => data),
    staleTime: Infinity,
  });
}

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { KeepLearningAction } from '@/modules/guides/shared/lib/analytics/keep-learning-analytics';
import { UiPrimaryButton } from '@/modules/shared/ui';

export const KeepLearningBottomSheet = ({
  isVisible,
  onClose,
  onGoBack,
  events,
}: {
  isVisible: boolean;
  onClose: () => void;
  onGoBack: () => void;
  events: {
    onView: () => void;
    onClick: (action: KeepLearningAction) => void;
  };
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'guides.keep-learning',
  });

  const onContinueClick = () => {
    events.onClick('keep_learning');
    onClose();
  };

  const onEndSessionClick = () => {
    events.onClick('end_session');
    onGoBack();
  };

  useEffect(() => {
    if (isVisible) {
      events.onView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <BottomSheet
      key="new-upsell-chase-bottomsheet"
      open={isVisible}
      onDismiss={onClose}
    >
      <div className="upsell-chase-bottomsheet max-w-[500px] mx-auto">
        <div className="upsell-chase-bottomsheet__container">
          <h1
            className="upsell-chase-bottomsheet__title mt-4 text-xl"
          >
            {t('title')}
          </h1>
          <p
            className="mt-1 text-base"
          >
            {t('message')}
          </p>
          <div className="upsell-chase-bottomsheet__actions">
            <UiPrimaryButton onClick={onContinueClick}>
              {t('buttons.primary.label')}
            </UiPrimaryButton>
            <button
              type="button"
              onClick={onEndSessionClick}
              className="bg-white text-primary mt-3 w-full font-semibold py-3"
            >
              {t('buttons.secondary.label')}
            </button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

import { AxiosResponse } from 'axios';

import { api } from '../../../services/http.service';
import { OnboardingContent } from '../types';

export const OnboardingContentAPI = {
  getOnboardingContent: async (
    lang: string,
    arrow: string
  ): Promise<AxiosResponse<OnboardingContent>> => {
    return api.get(
      `https://d2h7i1o4snhir4.cloudfront.net/onboardings/onboarding_${arrow}_${lang}.json`
    );
  },
};

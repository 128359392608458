import { FirehoseClient, PutRecordCommand } from '@aws-sdk/client-firehose';
import { encode, toUint8Array } from 'js-base64';
import { cloneDeep, extend } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { Attribution } from './attribution';
import { Identity } from './identity';
import { AnalyticsOptions, Properties, SentinelConfig } from './types';
import { User } from './user';

export class Analytics {
  private _metaData: Properties | undefined;

  constructor(
    private config: SentinelConfig,
    private identity: Identity,
    private user: User,
    private attribution?: Attribution
  ) {
    this._metaData = undefined;
    this._client = new FirehoseClient(this.config.firehose);
  }

  private _client: FirehoseClient;

  private _createEvent(eventName: string, properties?: Properties) {
    return {
      analytics_source: this.config.source,
      event_id: uuidv4(),
      timestamp: new Date().getTime() / 1000,
      event_name: eventName,
      event_metadata: properties,
      user_metadata: extend(this.user.userMetaData, this._metaData),
      query_parameters: this.config.analytics.urlQueryParams,
      device_id: this.identity.deviceId,
      user_id: this.identity.userId,
      attribution_id: this.attribution?.attributionId,
      country: this.config.geo.country.name,
      region: this.config.geo.regionCode,
      city: this.config.geo.cityName,
      country_code: this.config.geo.country.alpha2,
      ip: this.config.geo.ipAddress,
      path: this.config.analytics.appUrl,
      user_agent: this.config.analytics.userAgent,
      referrer: this.config.analytics.referrer,
      language: this.config.analytics.language,
    };
  }

  private mergeMetaData(properties?: Properties) {
    this._metaData = extend(cloneDeep(this._metaData), properties);
  }

  private _prepareRecord(eventName: string, properties?: Properties) {
    const event = this._createEvent(eventName, properties);

    return {
      DeliveryStreamName: this.config.firehose.deliveryStreamName,
      Record: {
        Data: toUint8Array(encode(JSON.stringify(event))),
      },
    };
  }

  trackEvent(
    eventName: string,
    properties?: Properties,
    userProperties?: Properties,
    options?: AnalyticsOptions
  ) {
    if (userProperties) {
      this.user.setUserMetaData(userProperties);
    }

    if (this.config.analytics.mergePropertiesInUserMetaData) {
      this.mergeMetaData(properties);
    }

    if (
      this.attribution &&
      this.config.attribution?.sync &&
      (properties || userProperties)
    ) {
      this.attribution.update(
        { ...properties, ...userProperties },
        options?.attribution
      );
    }

    const record = this._prepareRecord(eventName, properties);
    const command = new PutRecordCommand(record);

    return this._client.send(command).catch(cause => {
      this.config.events?.onSendAnalyticsError?.(cause);
    });
  }
}

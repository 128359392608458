import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';

export const SelectCategoriesAnalytics = {
  selectCategoriesView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'bottom_sheet',
      language: i18next.language,
    });
  },
  selectInterestedCategories({ ids }: { ids: string[] }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'bottom_sheet',
      area: ids,
      action: 'continue',
    });
  },
};

import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useTrustpilotLinkQuery } from '../../../../../entities/trustpilot';
import { useTrustPilot } from '../../../../../hooks/trustPilot';
import SurveyImage from '../../../../../images/guides/survey.svg';
import SurveyIcon1 from '../../../../../images/guides/survey/1.png';
import SurveyIcon2 from '../../../../../images/guides/survey/2.png';
import SurveyIcon3 from '../../../../../images/guides/survey/3.png';
import SurveyIcon4 from '../../../../../images/guides/survey/4.png';
import SurveyIcon5 from '../../../../../images/guides/survey/5.png';
import TrustpilotIcon from '../../../../../images/trustpilot_star.svg';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { SiteJabberButton } from '../../../../shared/SiteJabberButton';
import { UiPrimaryButton } from '../../../../shared/ui';
import { GuidesAnalytics } from '../../../shared/analytics';
import useFeedbackFormExperiment from '../../../shared/hooks/use-feedback-form-experiment';
import { useTrustpilotTourQuery } from '../model';
import './guide-survey-page.css';

const ICONS = [SurveyIcon1, SurveyIcon2, SurveyIcon3, SurveyIcon4, SurveyIcon5];
const MAX_CHAR = 150;

const isTrustpilotAvailableForEngagingRate = Math.random() <= 0.8;

function shouldShowReviewPlatform(rateIndex: number) {
  switch (rateIndex) {
    case 4:
      // if user rates 5 stars, always show trustpilot
      return true;

    case 3:
      // if user rates 4 stars, show trustpilot 80% of the time
      return isTrustpilotAvailableForEngagingRate;

    default:
      return false;
  }
}

function chooseReviewPlatform(
  shouldShowReview: boolean,
  isTrustpilotAvailable: boolean,
  isGuideReviewPassed: boolean
): 'trustpilot' | 'sitejabber' | null {
  // returns trustpilot in 80% of the cases

  if (!shouldShowReview || isGuideReviewPassed) {
    return null;
  }

  if (isTrustpilotAvailable) {
    return 'trustpilot';
  }

  return null;
}

export function GuideSurveyPage() {
  const { t } = useTranslation();

  const navigation = useHistory();
  const {
    guideId,
    unitId,
    lessonId,
  }: { guideId: string; unitId: string; lessonId: string } = useParams();
  const [contentRef] = useAutoAnimate();
  const { link: trustDefaultPilotLink } = useTrustPilot();
  const [clickedAnswerIndex, setClickedAnswerIndex] = useState(-1);
  const [comment, setComment] = useState('');
  const {
    userEmail,
    isGuideReviewPassed,
    isGuideTrustpilotTourVisible,
    setGuideTrustpilotTourVisible,
  } = useTrustpilotTourQuery();

  const queryParams = getQueryParamsFromURI();
  const pathway = queryParams?.pathway;

  const { isDefault: isFeedbackFormDisabled } = useFeedbackFormExperiment();

  const redirectUrl =
    pathway === 'test' ? `/guide-pathway/${guideId}` : `/guides/${guideId}`;

  const trustpilotLinkQuery = useTrustpilotLinkQuery();

  const trustPilotLink = useMemo(
    () =>
      trustpilotLinkQuery.data
        ? trustpilotLinkQuery.data.link
        : trustDefaultPilotLink,
    [trustpilotLinkQuery.data, trustDefaultPilotLink]
  );

  const shouldShowReview = useMemo(
    () => shouldShowReviewPlatform(clickedAnswerIndex),
    [clickedAnswerIndex]
  );

  const reviewPlatform = useMemo(
    () =>
      chooseReviewPlatform(
        shouldShowReview,
        isGuideTrustpilotTourVisible,
        isGuideReviewPassed
      ),
    [shouldShowReview, isGuideTrustpilotTourVisible, isGuideReviewPassed]
  );

  const isCommentVisible = clickedAnswerIndex < 3 && clickedAnswerIndex >= 0;

  const commentCharLength = MAX_CHAR - comment.length;

  useEffect(() => {
    GuidesAnalytics.surveyPageView({
      guideId,
      unitId,
      lessonId,
    });
  }, []);

  function onSetComment(e: ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length <= MAX_CHAR) {
      setComment(e.target.value);
    }
  }

  function onRateClick(idx: number) {
    setClickedAnswerIndex(idx);

    const newShouldShowReview = shouldShowReviewPlatform(idx);

    const newReviewPlatform = chooseReviewPlatform(
      newShouldShowReview,
      isGuideTrustpilotTourVisible,
      isGuideReviewPassed
    );

    GuidesAnalytics.onRateClick({
      guideId,
      unitId,
      lessonId,
      isTrustpilotAvailable:
        newShouldShowReview &&
        isGuideTrustpilotTourVisible &&
        newReviewPlatform === 'trustpilot',
      rating: idx + 1,
      shouldShowReviewPlatform: newShouldShowReview,
      reviewPlatform: newReviewPlatform,
    });
  }

  function onOpenTrustpilot() {
    setGuideTrustpilotTourVisible();
    navigation.replace(redirectUrl);
  }

  function onClose() {
    navigation.replace(redirectUrl);

    GuidesAnalytics.onCloseSurveyPage({
      guideId,
      unitId,
      lessonId,
    });
  }

  function onContinue() {
    if (comment) {
      GuidesAnalytics.onSubmitFeedback({
        guideId,
        unitId,
        lessonId,
        rating: clickedAnswerIndex + 1,
        feedback: comment,
      });
    }

    navigation.replace(redirectUrl);
  }

  return (
    <main className="guide-survey-page min-vh-full">
      <div className="guide-survey-page__header">
        <button
          type="button"
          className="guide-survey-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div ref={contentRef} className="guide-survey-page__content">
        <img
          width="168"
          height="168"
          className="guide-survey-page__image"
          src={SurveyImage}
          alt=""
          role="presentation"
        />
        <h1 className="guide-survey-page__title">{t('guides.survey.title')}</h1>
        <div className="guide-survey-page__options">
          <div className="guide-survey-page__options-container">
            {ICONS.map((icon, idx) => (
              <button
                key={idx}
                type="button"
                className={classNames(
                  'guide-survey-page__option',
                  clickedAnswerIndex === idx
                    ? 'guide-survey-page__option--active'
                    : ''
                )}
                onClick={() => onRateClick(idx)}
              >
                <img
                  width="30"
                  height="30"
                  className="guide-survey-page__option-icon"
                  src={icon}
                  alt=""
                />
              </button>
            ))}
          </div>
          <div className="guide-survey-page__options-description">
            <span className="guide-survey-page__options-bad">
              {t('guides.survey.bad')}
            </span>
            <span className="guide-survey-page__options-good">
              {t('guides.survey.good')}
            </span>
          </div>
        </div>

        {isCommentVisible && (
          <div className="guide-survey-page__comment">
            <h4 className="guide-survey-page__comment-title">
              {t('guides.survey.comment.title')}
            </h4>
            <textarea
              maxLength={MAX_CHAR}
              value={comment}
              className="guide-survey-page__comment-area"
              placeholder={t('guides.survey.comment.placeholder') as string}
              onChange={e => onSetComment(e)}
            />
            <h6 className="guide-survey-page__comment-counter">
              {commentCharLength}/{MAX_CHAR}
            </h6>
          </div>
        )}
      </div>

      <div className="guide-survey-page__actions">
        {isFeedbackFormDisabled &&
        isGuideTrustpilotTourVisible &&
        shouldShowReview &&
        reviewPlatform === 'trustpilot' &&
        !isGuideReviewPassed ? (
          <a
            role="button"
            href={trustPilotLink}
            className="guide-survey-page__trustpilot"
            target="_blank"
            rel="noopener noreferrer"
            onClick={onOpenTrustpilot}
          >
            <strong>{t('Review us on')}</strong>
            <img
              width="24"
              height="24"
              src={TrustpilotIcon}
              role="presentation"
              alt=""
            />
            <span>Trustpilot</span>
          </a>
        ) : isFeedbackFormDisabled &&
          shouldShowReview &&
          reviewPlatform === 'sitejabber' &&
          !isGuideReviewPassed ? (
          <SiteJabberButton
            userEmail={userEmail}
            navigation={navigation}
            guideId={guideId}
            unitId={unitId}
            lessonId={lessonId}
            setGuideTrustpilotTourVisible={setGuideTrustpilotTourVisible}
          />
        ) : (
          <UiPrimaryButton onClick={onContinue}>
            {t('guides.continue-button')}
          </UiPrimaryButton>
        )}
      </div>
    </main>
  );
}

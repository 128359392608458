import { useAutoAnimate } from '@formkit/auto-animate/react';
import { motion } from 'framer-motion';
import { extend, filter, isEmpty, reduce } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton, UiNavigation } from '../../../../ai-bots/shared/ui';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import { AppFooter } from '../../../../shared';
import { ChallengeJoinedCard } from '../../../entities/challenge-joined-card';
import { EmptyChallenges } from '../../../entities/empty-challenges';
import {
  ChallengeListItemType,
  useChallengesListQuery,
} from '../../../entities/list';
import {
  type ChallengesListProgressType,
  useChallengesListProgressQuery,
} from '../../../entities/progress';
import { ChallengesAnalytics } from '../../../shared/analytics';
import './challenges-joined-page.css';

function ChallengesListLoading() {
  return (
    <>
      {Array.from({ length: 3 }, (_, i) => i + 1).map(i => (
        <UiLoadingSkeleton
          key={`challenges-joined-list-${i}`}
          style={{
            width: '100%',
            height: '98px',
          }}
        />
      ))}
    </>
  );
}

function ChallengesList(props: {
  list: Array<ChallengeListItemType & ChallengesListProgressType>;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();

  function onCardClick(
    item: ChallengeListItemType & ChallengesListProgressType
  ) {
    ChallengesAnalytics.onCardClick({
      is_completed: item.is_completed,
      challenge_id: item.id,
    });

    if (item.is_joined) {
      navigation.push(`/challenges/${item.id}/tasks`);
    } else {
      navigation.push(`/challenges/${item.id}`);
    }
  }

  return isEmpty(props.list) ? (
    <EmptyChallenges />
  ) : (
    <>
      {props.list.map(item => (
        <ChallengeJoinedCard
          key={item.id}
          item={item}
          events={{
            onClick: () => onCardClick(item),
          }}
        />
      ))}
    </>
  );
}

export function ChallengesJoinedPage() {
  const { t } = useTranslation();
  const [animatedListRef] = useAutoAnimate();
  const navigation = useHistory();

  const listQuery = useChallengesListQuery();
  const listProgressQuery = useChallengesListProgressQuery();

  const isLoading = useMemo(
    () => listQuery.isLoading || listProgressQuery.isLoading,
    [listQuery.isLoading, listProgressQuery.isLoading]
  );

  const isError = useMemo(
    () => listQuery.isError || listProgressQuery.isError,
    [listQuery.isLoading, listProgressQuery.isLoading]
  );

  const challengesList = useMemo(() => {
    if (!listQuery.data?.items || !listProgressQuery.data) {
      return [];
    }

    return reduce(
      listQuery.data.items,
      (r: Array<ChallengeListItemType & ChallengesListProgressType>, v) => {
        const progress = listProgressQuery.data[v.id];

        if (progress) {
          r.push(extend(v, progress));
        }

        return r;
      },
      []
    );
  }, [listQuery.data, listProgressQuery.data]);

  const startedChallenges = useMemo(
    () => filter(challengesList, 'is_joined'),
    [challengesList]
  );

  function onRefetch() {
    listQuery.refetch();
    listProgressQuery.refetch();
  }

  const title = t('challenges.joined.title');

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main className="ui-base-layout min-vh-full challenges-joined-page">
        <UiNavigation
          title={title}
          className="challenges-joined-page__navigation"
          onBackButtonClicked={() => navigation.replace('/challenges')}
        />

        {isError ? (
          <div className="ui-base-layout__content">
            <FullPageError onRetry={onRefetch} />
          </div>
        ) : (
          <>
            <section
              ref={animatedListRef}
              className="ui-base-layout__content challenges-joined-page__content"
            >
              {isLoading ? (
                <ChallengesListLoading />
              ) : (
                <ChallengesList list={startedChallenges} />
              )}
            </section>
          </>
        )}

        <AppFooter />
      </main>
    </motion.section>
  );
}

import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;

  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

export const QuestionContainer = styled.div`
  margin: 22px 24px 48px;

  .disabled {
    opacity: 0.4;
  }
`;

export const Heading = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #95a2af;
  margin: 8px 0 24px;
`;

export const FlexWrapper = styled.span`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;

  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }

  .small {
    color: #e30202;
    margin-top: 9px;
    font-size: 14px;
  }
`;

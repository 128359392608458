import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { AiBotCategoryV2, AiBotTool } from '../../../../entities/ai-bot/types';
import { AiBotCardPreset } from '../../../../entities/ai-bot-card-v2';
import { MainPageAnalytics } from '../../../../shared/analytics';
import { UiLoadingSkeleton } from '../../../../shared/ui';
import './ai-bots-preset-cards.css';

const CARDS_NUMBER = 5;

export const AiBotsPresetCards = ({
  data,
}: {
  data: Array<AiBotCategoryV2> | undefined;
}) => {
  const navigation = useHistory();
  const { t } = useTranslation();
  const presetCards = useMemo(() => {
    if (!data) return undefined;

    return data.find(item => item.id.toLowerCase() === 'preset');
  }, [data]);

  function onClickToBot(bot: AiBotTool) {
    MainPageAnalytics.onBotClick({
      location: 'explore',
      botName:  bot.name,
      botId:    bot.id,
      action:   'create_new_chat',
    });
  }

  function goToChatPage(aiBot: AiBotTool) {
    onClickToBot?.(aiBot);
    navigation.push(`/ai-bots/new-chat/${aiBot.id}`, {
      from: navigation.location.pathname,
    });
  }

  if (!presetCards) {
    return (
      <>
        <div className="ai-bots-list__title">
          <UiLoadingSkeleton
            style={{
              width:  '200px',
              height: '24px',
            }}
            className="m-4"
          />
        </div>
        <div className="ai-bots-preset-cards">
          {Array(CARDS_NUMBER)
            .fill(0)
            .map((_, i) => (
              <UiLoadingSkeleton
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                style={{
                  minWidth:     '182px',
                  height:       '144px',
                  borderRadius: '0.5rem',
                }}
              />
            ))}
        </div>
      </>
    );
  }

  return (
    <>
      <h1 className="ai-bots-preset-cards__title">
        {t('ai-tools.preset-title')}
      </h1>
      <div className="ai-bots-preset-cards">
        {presetCards
          && (presetCards).tools.map(aiBot => (
            <AiBotCardPreset
              key={aiBot.id}
              data={aiBot}
              onClick={() => goToChatPage(aiBot)}
            />
          ))}
      </div>
    </>
  );
};

import { motion } from 'framer-motion';
import { find, isNumber } from 'lodash-es';
import Lottie from 'lottie-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useLocationQuery } from '../../../../../hooks/use-location-query';
import CompleteImage from '../../../../../images/guides/complete.svg';
import { UiPrimaryButton } from '../../../../shared/ui';
import { useChallengeInfoQuery } from '../../../entities/info';
import { ChallengesAnalytics } from '../../../shared/analytics';
import ConfettiLottieAnimation from './assets/confetti-lottie.json';
import './guide-complete-page.css';

export function ChallengeGuideCompletePage() {
  const { t } = useTranslation();

  const SURVEY_PROBABILITY = 0.7;

  const navigation = useHistory();

  const { challengeId, taskId }: { challengeId: string; taskId: string } =
    useParams();

  const locationQuery = useLocationQuery();

  const challengeInfoQuery = useChallengeInfoQuery(challengeId);

  const currentTask = useMemo(
    () => find(challengeInfoQuery.data?.tasks, ['id', taskId]),
    [challengeInfoQuery.data]
  );

  const dayCount = useMemo(() => currentTask?.day, [currentTask]);

  const tasksCount = useMemo(
    () => challengeInfoQuery.data?.tasks_count,
    [challengeInfoQuery.data]
  );

  const isOnboardingComplete = useMemo(
    () => locationQuery.get('guideType') === 'onboarding',
    [locationQuery]
  );

  const isFullComplete = useMemo(
    () => isNumber(tasksCount) && isNumber(dayCount) && tasksCount === dayCount,
    [tasksCount, dayCount]
  );

  const title = useMemo(() => {
    if (isOnboardingComplete) {
      return t('challenges.complete-onboarding-guide-page.title');
    }

    if (isFullComplete) {
      return t('challenges.complete-full-page.title');
    }

    return t('challenges.complete-page.title', {
      count: dayCount,
    });
  }, [isOnboardingComplete, isFullComplete, challengeInfoQuery.data]);

  const description = useMemo(() => {
    const challengeName = challengeInfoQuery.data?.name;

    if (isOnboardingComplete) {
      return t('challenges.complete-onboarding-guide-page.description', {
        name: challengeName,
      });
    }

    if (isFullComplete) {
      return t('challenges.complete-full-page.description', {
        name: challengeName,
      });
    }

    return t('challenges.complete-page.description');
  }, [isOnboardingComplete, challengeInfoQuery.data]);

  const action = useMemo(() => {
    if (isOnboardingComplete) {
      return t('challenges.complete-onboarding-guide-page.action');
    }

    if (isFullComplete) {
      return t('challenges.complete-full-page.action');
    }

    return t('challenges.complete-page.action');
  }, [isOnboardingComplete]);

  function goToTasksPage() {
    const randomNumber = Math.random();

    if (randomNumber > SURVEY_PROBABILITY) {
      navigation.push(`/challenges/${challengeId}/${taskId}/survey`);
    } else {
      navigation.replace(`/challenges/${challengeId}/tasks`);
    }
  }

  useEffect(() => {
    if (isNumber(tasksCount) && isNumber(dayCount)) {
      if (isFullComplete) {
        ChallengesAnalytics.challengeCompletedPageView({
          challenge_id: challengeId,
          task_id: taskId,
        });
      } else {
        ChallengesAnalytics.taskCompletePageView({
          challenge_id: challengeId,
          task_id: taskId,
        });
      }
    }
  }, [isFullComplete, tasksCount, dayCount]);

  function onClose() {
    ChallengesAnalytics.onCompleteActionClick({
      challenge_id: challengeId,
      task_id: taskId,
      action: 'close',
    });

    goToTasksPage();
  }

  function onContinue() {
    ChallengesAnalytics.onCompleteActionClick({
      challenge_id: challengeId,
      task_id: taskId,
      action: 'continue',
    });

    goToTasksPage();
  }

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main className="guide-complete-page min-vh-full">
        <div className="guide-complete-page__header">
          <button
            type="button"
            className="guide-complete-page__close-button"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>
        </div>

        <div className="guide-complete-page__content">
          <img
            width="168"
            height="168"
            className="guide-complete-page__image"
            src={CompleteImage}
            role="presentation"
            alt=""
          />

          <h1 className="guide-complete-page__title">{title}</h1>
          <p className="guide-complete-page__description">{description}</p>
        </div>

        <div className="guide-complete-page__actions">
          <UiPrimaryButton onClick={onContinue}>{action}</UiPrimaryButton>
        </div>

        {isFullComplete && (
          <div className="lottie-background max-vh-full">
            <Lottie animationData={ConfettiLottieAnimation} autoplay={true} />
          </div>
        )}
      </main>
    </motion.section>
  );
}

import classNames from 'classnames';

import TargetIcon from '../ui/assets/target.svg';
import './assets/guide-target.css';

export function GuideTarget({ onClick }: { onClick: () => void }) {
  return (
    <div onClick={onClick} className={classNames('guide-target-outer')}>
      <div className="guide-target-inner">
        <img width={32} height={32} src={TargetIcon} alt="target" />
      </div>
    </div>
  );
}

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { GuideLessonDetailsTaskType } from '../../guide-lesson';
import { GuideMessageType } from '../../guide-message';

type TaskId = GuideLessonDetailsTaskType['id'];

interface GuideLessonTaskStoreStateType {
  skippedTasksIds: Array<TaskId>;
  completedTasksIds: Array<TaskId>;
  currentOpenTask: Array<TaskId>;
  taskMessages: Array<GuideMessageType>;
  addTaskMessage: (task: GuideMessageType) => void;
  clearTaskMessages: () => void;
  addSkippedTask: (id: TaskId) => void;
  addCompletedTask: (id: TaskId) => void;
  removeLastTaskMessage: () => void;
  clear: () => void;
}

export const useGuideLessonTaskStore = create<GuideLessonTaskStoreStateType>()(
  immer(set => ({
    skippedTasksIds: [],
    completedTasksIds: [],
    currentOpenTask: [],
    taskMessages: [],
    addTaskMessage: (task: GuideMessageType) =>
      set(state => {
        state.taskMessages.push(task);
      }),
    clearTaskMessages: () =>
      set(state => {
        state.taskMessages = [];
      }),
    addSkippedTask: (id: TaskId) =>
      set(state => {
        state.skippedTasksIds.push(id);
      }),
    addCompletedTask: (id: TaskId) =>
      set(state => {
        state.completedTasksIds.push(id);
      }),
    removeLastTaskMessage: () =>
      set(state => {
        state.taskMessages.pop();
      }),
    clear: () =>
      set(state => {
        state.taskMessages = [];
        state.skippedTasksIds = [];
        state.completedTasksIds = [];
      }),
  }))
);

import './assets/satisfaction-survey-toggle-button.css';

export function ToggleButton({
  onClick,
  children,
  selected,
}: {
  onClick: () => void;
  children?: string | JSX.Element | JSX.Element[];
  selected?: boolean;
}) {
  return (
    <button
      type="button"
      className={`satisfaction-survey-toggle-button ${
        selected ? 'active' : ''
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { GuideLessonDetailsQuizType } from '../guide-lesson';

type QuizId = GuideLessonDetailsQuizType['id'];

interface GuideLessonQuizStoreStateType {
  completedQuizIds: Array<QuizId>;
  addCompletedQuizId: (id: QuizId) => void;
  clear: () => void;
}

export const useGuideLessonQuizStore = create<GuideLessonQuizStoreStateType>()(
  immer(set => ({
    completedQuizIds: [],
    addCompletedQuizId: (id: QuizId) =>
      set(state => {
        state.completedQuizIds.push(id);
      }),
    clear: () =>
      set(state => {
        state.completedQuizIds = [];
      }),
  }))
);

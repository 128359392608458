import http from '../services/http.service';
import { IHasAccess, IPaymentMethod, ISubscription } from '../types/Profile';

export const usersApi = {
  getSubscriptions: async (user_id: any) =>
    await http
      .get<ISubscription[]>(`/subscriptions/?user_id=${user_id}`)
      .then(response => response.data)
      .then(data => {
        return data;
      })
      .catch(() => {
        return [] as ISubscription[];
      }),

  checkAccess: async (user_id: any) =>
    await http
      .get<IHasAccess>(`/subscriptions/users/${user_id}/`)
      .then(response => response.data)
      .then(data => {
        return data;
      })
      .catch(() => {
        return {} as IHasAccess;
      }),

  getPaymentMethod: async (user_id: any) =>
    await http
      .get<IPaymentMethod>(`/payments/solid/cards/users/${user_id}/`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        return data;
      })
      .catch(() => {
        return null;
      }),
};

import { useHistory } from 'react-router-dom';

import LogoIcon from '../../../../../images/logo.svg';
import { MainPageAnalytics } from '../../../shared/analytics';
import HistoryIcon from './assets/history.svg';

export function AiBotsHeader() {
  const navigation = useHistory();
  const onHistoryClick = () => {
    MainPageAnalytics.onHistoryClick();
    navigation.replace('/ai-bots/sessions');
  };
  return (
    <>
      <header className="ui-header flex !justify-between pr-[6px]">
        <img width="147" height="48" src={LogoIcon} alt="logo" />
        <button className="p-[10px]" onClick={onHistoryClick}>
          <img width="24" height="24" src={HistoryIcon} alt="history" />
        </button>
      </header>
    </>
  );
}

import {
  filter,
  find,
  first,
  isEmpty,
  maxBy,
  some,
  toPairs,
  values,
} from 'lodash-es';

import { GuideType } from '../../entities/guide';

export function getOngoingGuide({
  guides,
  progressList,
  withFallback = false,
}: {
  guides?: Array<GuideType>;
  progressList?: Record<GuideType['id'], number>;
  withFallback?: boolean;
}) {
  if (!guides && isEmpty(guides) && !progressList) {
    return null;
  }

  const fallback = {
    progress: 0,
    guide: first(guides),
  };

  const hasGuidesProgress = some(values(progressList), v => v > 0);

  if (!hasGuidesProgress) {
    return withFallback ? fallback : null;
  }

  const pairsProgressList = toPairs(progressList);

  const filteredProgressList = filter(
    pairsProgressList,
    p => p[1] > 0 && p[1] !== 1
  );

  let maxProgressGuide: [string, number] | undefined;

  if (isEmpty(filteredProgressList) && withFallback) {
    maxProgressGuide = maxBy(pairsProgressList, p => p[1]);
  } else {
    maxProgressGuide = maxBy(filteredProgressList, p => p[1]);
  }

  if (!maxProgressGuide) {
    return withFallback ? fallback : null;
  }

  const [id, progress] = maxProgressGuide;

  const guide = find(guides, ['id', id]);

  return {
    progress: progress * 100,
    guide,
  };
}

import { UiLoadingSkeleton } from '../../../shared/ui';

export function ChatInfoLoading() {
  return (
    <div className="chat-info">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UiLoadingSkeleton
          style={{
            minWidth: '64px',
            height: '64px',
          }}
        />

        <UiLoadingSkeleton
          style={{ marginLeft: '16px', width: '100%', height: '56px' }}
        />
      </div>

      <UiLoadingSkeleton
        style={{ marginTop: '12px', width: '70%', height: '16px' }}
      />
      <UiLoadingSkeleton
        style={{ marginTop: '12px', width: '30%', height: '16px' }}
      />

      <UiLoadingSkeleton
        style={{
          marginTop: '12px',
          width: '100px',
          height: '34px',
          borderRadius: '64px',
        }}
      />
    </div>
  );
}

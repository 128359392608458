import type { AxiosResponse } from 'axios';

import { api } from '../../../../services/http.service';
import type { AiBot } from '../ai-bot';

export const ChatDetailsAPI = {
  getChatBotDetails(chatBotId: string): Promise<AxiosResponse<AiBot>> {
    return api.get(`/ai-chatbots/${chatBotId}`);
  },
};

export type UserAnswer = {
  answer: string;
  placeholder: {
    text: string;
    placeholder: string | undefined;
    id?: number;
  };
};

export type PlaceHolder = {
  text: string;
  placeholder: string | undefined;
  id?: number;
};

export type FillInState = {
  fillInParts: {
    text: string;
    placeholder: string | undefined;
    id?: number;
  }[];
  answers: UserAnswer[];
  activePlaceholder: {
    text: string;
    placeholder: string | undefined;
    id?: number;
  } | null;
};

export enum FillInActionType {
  ADD_USER_ANSWER = 'ADD_USER_ANSWER',
  DESELECT_USER_ANSWER = 'DESELECT_USER_ANSWER',
  DESELECT_LAST_ANSWER = 'DESELECT_LAST_ANSWER',
  RESET = 'RESET',
}

export type FillInAction =
  | { type: FillInActionType.ADD_USER_ANSWER; payload: string }
  | { type: FillInActionType.DESELECT_USER_ANSWER; payload: PlaceHolder }
  | { type: FillInActionType.DESELECT_LAST_ANSWER }
  | { type: FillInActionType.RESET };

export type TaskTool = {
  name: string;
  icon: string;
};

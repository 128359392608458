import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { showCongratulationsOnReset } from '../../../../context/atoms/GlobalPopup';
import signup_congratulations from '../../../../images/signup_congratulations.webp';
import {
  ImageContainer,
  ModalButton,
  ModalContainer,
  Subtitle,
  Title,
} from './CongratulationsOnReset.styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '75%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 4,
  p: 0,
  borderRadius: '8px',
  maxWidth: '360px',
};

export default function CongratulationsOnRegister() {
  const { t } = useTranslation();

  const [showCongratsOnReset, setShowCongratsOnReset] = useRecoilState(
    showCongratulationsOnReset
  );
  const history = useHistory();
  const handleClose = () => {
    setShowCongratsOnReset(false);
    history.push('/');
  };

  return (
    <>
      <Modal
        open={showCongratsOnReset}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalContainer>
            <Title>{t('Congratulations!')}</Title>
            <Subtitle>
              {t('Your password has been successfully reset!')}
            </Subtitle>
            <ImageContainer>
              <img src={signup_congratulations} alt="" />
            </ImageContainer>

            <ModalButton isMain={true} onClick={handleClose}>
              {t('Login now')}
            </ModalButton>
          </ModalContainer>
        </Box>
      </Modal>
    </>
  );
}

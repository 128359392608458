import { useFeatureValue } from '@growthbook/growthbook-react';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import qs from 'query-string';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../../../../../api/userApi';
import {
  localOnboardState,
  userQuery,
} from '../../../../../context/atoms/User';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingAnalytics } from '../../../shared/analytics';
import {
  LEVEL_ADVANCED,
  LEVEL_BEGINNER,
  LEVEL_INTERMEDIATE,
} from '../../../shared/config';
import LevelAdvancedImageES from './assets/level-advanced-image-es.png';
import LevelAdvancedImageEN from './assets/level-advanced-image.png';
import LevelBeginnerImageES from './assets/level-beginner-image-es.png';
import LevelBeginnerImageEN from './assets/level-beginner-image.png';
import './assets/level-info-page.css';
import LevelIntermediateImageES from './assets/level-intermediate-image-es.png';
import LevelIntermediateImageEN from './assets/level-intermediate-image.png';

type Level =
  | typeof LEVEL_BEGINNER
  | typeof LEVEL_INTERMEDIATE
  | typeof LEVEL_ADVANCED;

export function OnboardingLevelInfoPage() {
  const navigation = useHistory();
  const { t } = useTranslation();
  const query = qs.parse(navigation.location.search);
  const user = useRecoilValue(userQuery);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  const setLocalOnboard = useSetRecoilState(localOnboardState);
  const isESLocale = i18next.language === 'es';

  const INFO_CONTENT = {
    [LEVEL_BEGINNER]: {
      title: t('onboarding.level-info-page.levels.beginner.title'),
      subtitle: t('onboarding.level-info-page.levels.beginner.subtitle'),
      image: isESLocale ? LevelBeginnerImageES : LevelBeginnerImageEN,
      quote: t('onboarding.level-info-page.levels.beginner.quote'),
    },
    [LEVEL_INTERMEDIATE]: {
      title: t('onboarding.level-info-page.levels.intermediate.title'),
      subtitle: t('onboarding.level-info-page.levels.intermediate.subtitle'),
      image: isESLocale ? LevelIntermediateImageES : LevelIntermediateImageEN,
      quote: t('onboarding.level-info-page.levels.intermediate.quote'),
    },
    [LEVEL_ADVANCED]: {
      title: t('onboarding.level-info-page.levels.advanced.title'),
      subtitle: t('onboarding.level-info-page.levels.advanced.subtitle'),
      image: isESLocale ? LevelAdvancedImageES : LevelAdvancedImageEN,
      quote: t('onboarding.level-info-page.levels.advanced.quote'),
    },
  };

  function getContentInfo({ level }: { level?: Level }) {
    if (level) {
      return INFO_CONTENT[level];
    }

    return INFO_CONTENT[LEVEL_BEGINNER];
  }

  const { title, subtitle, image, quote } = getContentInfo({
    level: query.level as Level,
  });

  useEffect(() => {
    OnboardingAnalytics.levelInfoPageView({
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
  }, []);

  function onNext() {
    OnboardingAnalytics.onContinueClickInLevelInfoPage({
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.push('/', { from: 'onboarding' });
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="level-picker-page min-vh-full"
    >
      <div className="level-info-page__header">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>

      <div className="level-info-page__content">
        <div className="level-info-page__image-container">
          <img src={image} role="presentation" alt="" />
        </div>

        <div className="level-info-page__quote">{quote}</div>
      </div>

      <div className="level-info-page__actions">
        <UiPrimaryButton onClick={onNext}>
          {t('onboarding.level-info-page.action')}
        </UiPrimaryButton>
      </div>
    </motion.section>
  );
}

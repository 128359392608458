import { useFeatureValue } from '@growthbook/growthbook-react';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingAnalytics } from '../../../../../shared/analytics';
import { QUIZ_SCREEN_INTEREST } from '../../../../../shared/config';
import { QuizButton } from '../../../../../shared/ui';

const CONTENT = [
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.become_freelancer'
      );
    },
    slug: 'become_freelancer',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.new_career_skills'
      );
    },
    slug: 'new_career_skills',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.new_profession'
      );
    },
    slug: 'new_profession',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.build_habits'
      );
    },
    slug: 'build_habits',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.work_from_home'
      );
    },
    slug: 'work_from_home',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.optimize_work_ai'
      );
    },
    slug: 'optimize_work_ai',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.catchup_ai'
      );
    },
    slug: 'catchup_ai',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.interest-screen.content.become_entrepreneur'
      );
    },
    slug: 'become_entrepreneur',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.interest-screen.content.other');
    },
    slug: 'other',
  },
];

export function QuizInterestScreen({
  events,
}: {
  events: { onNext: () => void };
}) {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<string>();
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  function onSelect(slug: string) {
    setSelectedContent(slug);
    OnboardingAnalytics.onQuizAnswerSubmit({
      question: QUIZ_SCREEN_INTEREST,
      answer: slug,
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
    events.onNext();
  }

  return (
    <div className="quiz-interest-screen quiz-page__screen">
      <h2 className="quiz-interest-screen__title quiz-page__title">
        {t('onboarding.quiz-page.interest-screen.title')}
      </h2>
      <p className="quiz-interest-screen__description quiz-page__description">
        {t('onboarding.quiz-page.interest-screen.description')}
      </p>

      <div className="quiz-interest-screen__content quiz-page__screen-content quiz-page__screen-content--with-spacing">
        {CONTENT.map((item, i) => (
          <QuizButton
            key={`quiz-interest-screen-content-${i}`}
            isActive={selectedContent === item.slug}
            events={{
              onClick: () => onSelect(item.slug),
            }}
          >
            {item.text}
          </QuizButton>
        ))}
      </div>
    </div>
  );
}

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { userQuery } from '../../context/atoms/User';
import { TrustpilotAPI } from './api';

function useUserData() {
  const userData = useRecoilValue(userQuery);

  const username = userData?.username as string;
  const email = userData?.email as string;

  return {
    username,
    email,
  };
}

export function useTrustpilotLinkQuery() {
  const { username, email } = useUserData();

  return useQuery({
    queryKey: ['trustpilot-link', username, email],
    queryFn: () =>
      TrustpilotAPI.getTrustpilotLink({ username, email })
        .then(({ data }) => data)
        .catch(cause => {
          throw new Error('ERROR_GET_TRUSTPILOT_LINK', { cause });
        }),
    staleTime: Infinity,
  });
}

export function usePrefetchTrustpilotLinkQuery() {
  const queryClient = useQueryClient();
  const { username, email } = useUserData();

  function prefetchTrustpilotLink() {
    return queryClient.prefetchQuery({
      queryKey: ['trustpilot-link', username, email],
      queryFn: () =>
        TrustpilotAPI.getTrustpilotLink({ username, email }).then(
          ({ data }) => data
        ),
      staleTime: Infinity,
    });
  }

  return {
    prefetchTrustpilotLink,
  };
}

import eng_lang from '../images/language/eng_lang.svg';
import fra_lang from '../images/language/fra_lang.svg';
import ger_lang from '../images/language/ger_lang.svg';
import ita_lang from '../images/language/ita_lang.svg';
import por_lang from '../images/language/por_lang.svg';
import spa_lang from '../images/language/spa_lang.svg';

export const languages: ILanguage[] = [
  {
    code2: 'en',
    language: 'English',
    img: eng_lang,
  },
  {
    code2: 'es',
    language: 'Español',
    img: spa_lang,
  },
  {
    code2: 'fr',
    language: 'Français',
    img: fra_lang,
  },
  {
    code2: 'de',
    language: 'Deutsch',
    img: ger_lang,
  },
  {
    code2: 'it',
    language: 'Italiano',
    img: ita_lang,
  },
  {
    code2: 'pt',
    language: 'Português',
    img: por_lang,
  },
];

export interface ILanguage {
  code2: string;
  language: string;
  img: string;
}

export enum LanguageType {
  spanish = 'es',
  portuguese = 'pt',
  deutsch = 'de',
  english = 'en',
  french = 'fr',
  italian = 'it',
}

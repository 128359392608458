export const allowedArrows = ['influencer', 'remote_work'];

enum StreamType {
  REMOTE_WORK = 'remote_work',
  INFLUENCER = 'influencer',
}

export function getAnalyticsState({
  query,
  remoteWorExperiment,
  influencerExperiment,
  fromQuery,
}: {
  query: string | undefined;
  remoteWorExperiment: string;
  influencerExperiment: string;
  fromQuery: string;
}) {
  const isGigaUpsell = !allowedArrows.includes(query ?? '');
  const isRemoteWorkTest =
    query === StreamType.REMOTE_WORK && remoteWorExperiment === 'test';
  const isInfluencerTest =
    query === StreamType.INFLUENCER && influencerExperiment === 'test';

  const influencerState =
    isGigaUpsell && fromQuery === StreamType.INFLUENCER ? 'clean' : 'default';
  const remoteWorkState =
    isGigaUpsell && fromQuery === StreamType.REMOTE_WORK ? 'clean' : 'default';

  const remoteWorkAnalyticsState = isRemoteWorkTest
    ? 'test'
    : query === StreamType.REMOTE_WORK && remoteWorExperiment === 'clean'
      ? 'clean'
      : 'default';

  const influencerAnalyticsState = isInfluencerTest
    ? 'test'
    : query === StreamType.INFLUENCER && influencerExperiment === 'clean'
      ? 'clean'
      : 'default';

  const remoteWorkAnalyticsValue = isGigaUpsell
    ? remoteWorkState
    : remoteWorkAnalyticsState;
  const influencerAnalyticsValue = isGigaUpsell
    ? influencerState
    : influencerAnalyticsState;

  return {
    remoteWorkAnalyticsValue,
    influencerAnalyticsValue,
  };
}

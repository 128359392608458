import classNames from 'classnames';
import { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { BackspaceButton } from '../../backspace-button';
import { FillInOptionButton } from '../../fill-in-option-button';
import { FillInState } from '../lib/types';
import { shuffleArray } from '../utils';

export function FillTheGapModalActions({
  taskGaps,
  fillInState,
  isChecked,
  onTaskCheck,
  onOptionClick,
  onBackspaceClick,
  buttonRef,
  task,
}: {
  taskGaps: string[];
  fillInState: FillInState;
  isChecked: boolean;
  onTaskCheck: () => void;
  onOptionClick: (answer: string) => void;
  onBackspaceClick: () => void;
  buttonRef?: RefObject<HTMLButtonElement>;
  task?: GuideLessonDetailsFillTheGapType[];
}) {
  const { t } = useTranslation();

  const firstCorrectAnswer = task ? task[0].content[0].gaps[0] : null;

  const shuffledGaps = useMemo(() => shuffleArray(taskGaps), []);

  if (isChecked) {
    return null;
  }

  return (
    <div className="guide-fill-the-gap-modal__actions">
      <div>
        <div className="guide-fill-the-gap-modal__actions__options">
          {shuffledGaps
            ?.filter(
              item => !fillInState.answers.some(({ answer }) => answer === item)
            )
            .map(
              gap =>
                gap && (
                  <FillInOptionButton
                    key={gap}
                    option={gap}
                    ref={gap === firstCorrectAnswer ? buttonRef : null}
                    id={
                      gap === firstCorrectAnswer ? 'first_correct_answer' : ''
                    }
                    onClick={() => {
                      onOptionClick(gap);
                    }}
                  />
                )
            )}
        </div>
        <div className="guide-fill-the-gap-modal__actions__buttons">
          <button
            className={classNames('guide-fill-the-gap-modal__check-button', {
              'guide-fill-the-gap-modal__check-button--disabled':
                fillInState.answers.length !== taskGaps.length,
            })}
            disabled={fillInState.answers.length !== taskGaps.length}
            onClick={onTaskCheck}
          >
            {t('Check')}
          </button>
          <BackspaceButton
            onClick={onBackspaceClick}
            disabled={fillInState.answers.length === 0}
          />
        </div>
      </div>
    </div>
  );
}

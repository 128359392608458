import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { GeoQueryState, GeoState } from '../context/atoms/Geo';
import { IGeo } from '../types/Geo';

export function useGeo() {
  const [geoData, setGeoData] = useRecoilState(GeoState);
  const geoLoadable = useRecoilValueLoadable(GeoQueryState);

  function geoFetch() {
    return new Promise<IGeo>((resolve, reject) => {
      if (geoData && geoData.id) {
        resolve(geoData);
      } else {
        geoLoadable
          .toPromise()
          .then(geo => {
            setGeoData(geo);
            resolve(geo);
          })
          .catch(() => {
            reject({} as IGeo);
          });
      }
    });
  }
  return { geoFetch };
}

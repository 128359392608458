import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../../../entities/personal-profile';
import { GuideUnitType } from '../../../../../guides/entities/guide';
import { Toast } from '../../../../pages/learning-pathway/ui/LearningPathway';

interface PathwayNavigationToastProps {
  toastValue: Toast | undefined;
  toastRef: React.RefObject<HTMLDivElement>;
  isToolsOpen: boolean;
  onToastClick: (unit: GuideUnitType | undefined) => void;
}

export function PathwayNavigationToast({
  toastValue,
  toastRef,
  isToolsOpen,
  onToastClick,
}: PathwayNavigationToastProps) {
  const { t } = useTranslation();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const localizedTitle = arrow
    ? `guides-v2.${arrow}-arrow`
    : 'guides-v2.ai-arrow';

  return (
    <div
      className={classNames('pathway-navigation-toast-container', {
        'pathway-navigation-toast-container__active': isToolsOpen,
      })}
    >
      <div
        className="pathway-navigation-toast"
        ref={toastRef}
        onClick={() => onToastClick(toastValue?.unit)}
      >
        <div className="pathway-navigation-toast__details">
          <p>{t(localizedTitle)}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            fill="none"
          >
            <circle cx="2.5" cy="2.5" r="2" fill="#FFFFFF" />
          </svg>
          <p>
            {toastValue?.guideName}: {t('guides-v2.level')}{' '}
            {toastValue?.unit.ordering}
          </p>
        </div>
        <h4 className={'pathway-navigation-toast__heading'}>
          {toastValue?.unit.name}
        </h4>
      </div>
    </div>
  );
}

import { forwardRef } from 'react';

import './fill-in-option-button.css';

interface FillInOptionButtonProps {
  id?: string;
  option: string;
  onClick: () => void;
}

export const FillInOptionButton = forwardRef<
  HTMLButtonElement,
  FillInOptionButtonProps
>(({ id, option, onClick }, ref) => {
  return (
    <button
      id={id}
      className="fill-in-option-button"
      onClick={onClick}
      ref={ref}
    >
      {option}
    </button>
  );
});

import { UiLoadingSkeleton } from '../../../shared/ui';

export function AiBotCardLoadingV2() {
  return (
    <UiLoadingSkeleton
      style={{
        height: '120px',
      }}
    />
  );
}

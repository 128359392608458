import { useFeatureValue } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import { isEmpty, map, toNumber } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingAnalytics } from '../../../shared/analytics';
import './assets/info-page.css';
import InfoSlide1ES from './assets/info-slide-1-es.png';
import InfoSlide1EN from './assets/info-slide-1.png';
import InfoSlide2 from './assets/info-slide-2.png';
import InfoSlide3 from './assets/info-slide-2.png';

let slides: NodeListOf<HTMLDivElement>;
let autoplayInterval: ReturnType<typeof setTimeout>;
const AUTOPLAY_SECONDS = 3000;

export function OnboardingInfoPage() {
  const navigation = useHistory();
  const { t } = useTranslation();
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const isESLocale = i18next.language === 'es';

  const SLIDES_LOCALIZED_CONTENT = t('onboarding.info-page.slides', {
    returnObjects: true,
  }) as Array<{ title: string; description: string }>;

  const SLIDES_IMAGES = [
    isESLocale ? InfoSlide1ES : InfoSlide1EN,
    InfoSlide2,
    InfoSlide3,
  ];

  const SLIDES_CONTENT = useMemo(() => {
    return map(SLIDES_LOCALIZED_CONTENT, (v, i) => ({
      ...v,
      image: SLIDES_IMAGES[i],
    }));
  }, [isESLocale]);

  useEffect(() => {
    OnboardingAnalytics.infoPageView({
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
    slides = document.querySelectorAll('.info-page__slide');
    if (slides) {
      initSliderAutoplay(0);
      OnboardingAnalytics.infoPageSlideView({
        index: 0,
        abOnboardingQuizValue: growthbookAbOnboardingQuiz,
      });
    }

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);

  useEffect(() => {
    OnboardingAnalytics.infoPageSlideView({
      index: currentSlideIndex,
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
  }, [currentSlideIndex]);

  useEffect(initIntersectionObserver, []);

  function initIntersectionObserver() {
    const intersectionObserver = new IntersectionObserver(
      intersectionObserverCallback,
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (!isEmpty(slides)) {
      slides.forEach(slide => {
        intersectionObserver.observe(slide);
      });
    }

    return () => {
      if (!isEmpty(slides)) {
        slides.forEach(slide => {
          intersectionObserver.unobserve(slide);
        });
      }
    };
  }

  function intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const slideIndex = entry.target.getAttribute('data-slide-index');
        setCurrentSlideIndex(toNumber(slideIndex));
        clearInterval(autoplayInterval);
        initSliderAutoplay(toNumber(slideIndex));
      }
    });
  }

  function initSliderAutoplay(i: number) {
    autoplayInterval = setInterval(() => {
      if (i === slides.length - 1) {
        goToSlide(0);
      } else {
        goToSlide(i + 1);
      }
    }, AUTOPLAY_SECONDS);
  }

  function goToSlide(i: number) {
    if (!isEmpty(slides)) {
      setCurrentSlideIndex(i);
      slides[i].scrollIntoView({
        behavior: 'smooth',
      });

      clearInterval(autoplayInterval);
      initSliderAutoplay(i);
    }
  }

  function onNext() {
    if (growthbookAbOnboardingQuiz === 'test') {
      return navigation.push('/onboarding/quiz');
    }

    return navigation.push('/onboarding/level-picker');
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="info-page vh-full"
    >
      <div className="info-page__slide-container">
        {SLIDES_CONTENT.map((slide, i) => (
          <div
            key={`info-page-slide_${i}`}
            className="info-page__slide"
            data-slide-index={i}
          >
            <div className="info-page__image-container">
              <img
                className="info-page__image"
                src={slide.image}
                role="presentation"
                alt=""
              />
            </div>

            <div className="info-page__content">
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="info-page__actions">
        <div className="info-page__slide-controls">
          {SLIDES_CONTENT.map((_, i) => (
            <button
              key={`info-page-slide-bullet_${i}`}
              type="button"
              className={classNames('info-page__slide-bullet', {
                'info-page__slide-bullet--active': currentSlideIndex === i,
              })}
              onClick={() => goToSlide(i)}
            />
          ))}
        </div>

        <UiPrimaryButton onClick={onNext}>
          {t('onboarding.info-page.action')}
        </UiPrimaryButton>
      </div>
    </motion.section>
  );
}

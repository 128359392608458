import classNames from 'classnames';

import './fill-in-placeholder.css';

export function FillInPlaceholder({
  placeholder,
  isActive,
  value,
  onClick,
}: {
  placeholder: string;
  value: string | null;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <span
      className={classNames('fill-in-placeholder', {
        'fill-in-placeholder--active': value || isActive,
        'fill-in-selected': isActive,
      })}
      onClick={onClick}
    >
      {isActive && !value && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="22"
          viewBox="0 0 3 22"
          fill="none"
          className="fill-in-placeholder__cursor"
        >
          <path
            d="M1.5 1V21"
            stroke="#5653FE"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
      <span>{value || `${placeholder}`}</span>
    </span>
  );
}

import classNames from 'classnames';

import { MappedUpsellCascadeOffer } from '../lib';
import { UpsellOffer } from './UpsellOffer';
import { UpsellOffersLoading } from './UpsellOffersLoading';

export function UpsellOffers({
  className,
  isLoading,
  offers,
  selectedOfferId,
  onOfferSelected,
  badgeText,
  pricingIDWithBadge,
}: {
  className?: string;
  isLoading: boolean;
  offers: Array<MappedUpsellCascadeOffer>;
  selectedOfferId?: string;
  onOfferSelected: (offer: MappedUpsellCascadeOffer) => void;
  badgeText?: string;
  pricingIDWithBadge?: string | null;
}) {
  return isLoading ? (
    <UpsellOffersLoading className={className} />
  ) : (
    <div className={classNames('upsell-offers', className)}>
      {offers.map(offer => {
        if (pricingIDWithBadge === offer.pricingId && badgeText) {
          return (
            <UpsellOffer
              key={offer.id}
              image={offer.image}
              title={offer.title}
              fullPrice={offer.fullPrice}
              discountPriceText={offer.discountPriceText}
              discountPercent={offer.discountPercent}
              isActive={offer.id === selectedOfferId}
              onSelect={() => onOfferSelected(offer)}
              badgeText={badgeText}
            />
          );
        } else {
          return (
            <UpsellOffer
              key={offer.id}
              image={offer.image}
              title={offer.title}
              fullPrice={offer.fullPrice}
              discountPriceText={offer.discountPriceText}
              discountPercent={offer.discountPercent}
              isActive={offer.id === selectedOfferId}
              onSelect={() => onOfferSelected(offer)}
            />
          );
        }
      })}
    </div>
  );
}

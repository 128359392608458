import { AiBotCardLoadingV2 } from '../../../entities/ai-bot-card-v2';
import { UiLoadingSkeleton } from '../../../shared/ui';
import './ai-bot-view-all.css';

export function ViewAllLoading() {
  return (
    <div className="ai-bots_list">
      <div className="ai-bot-view-search">
        <UiLoadingSkeleton
          style={{
            width: '100%',
            height: '45px',
            marginBottom: '16px',
          }}
        />
      </div>

      <div className="ai-bot-view-all__category-buttons ai-bot-view-all__category-buttons-loading">
        {Array(5)
          .fill(0)
          .map((_, i) => (
            <UiLoadingSkeleton
              key={i}
              style={{
                minWidth: '92px',
                height: '37px',
                borderRadius: '64px',
              }}
            />
          ))}
      </div>

      <div className="ai-bot-view-all__conten grid grid-cols-2 gap-[9px]">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <AiBotCardLoadingV2 key={i} />
          ))}
      </div>
    </div>
  );
}

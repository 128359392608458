import i18next from 'i18next';
import { filter, isEmpty, partition, size, slice, uniqueId } from 'lodash-es';

import { GOALS } from '../../../personal-goal';
import { GoalType } from '../../../personal-goal/config';
import { GuideType } from '../../entities/guide';

//TODO: MOVE to remote (growthbook)
const SUBCATEGORIES_BY_GOAL: Record<GoalType, Array<GoalType>> = {
  [GOALS.BE_MY_OWN_BOSS]: [GOALS.GROW_WEALTH, GOALS.PROFESSIONAL_GROWTH],
  [GOALS.GROW_WEALTH]: [GOALS.BE_MY_OWN_BOSS, GOALS.PROFESSIONAL_GROWTH],
  [GOALS.TRAVEL_WORLD]: [GOALS.WORK_LIFE_BALANCE, GOALS.GROW_WEALTH],
  [GOALS.FINANCIAL_FREEDOM]: [GOALS.BE_MY_OWN_BOSS, GOALS.PROFESSIONAL_GROWTH],
  [GOALS.PROFESSIONAL_GROWTH]: [GOALS.WORK_LIFE_BALANCE, GOALS.GROW_WEALTH],
  [GOALS.WORK_LIFE_BALANCE]: [GOALS.GROW_WEALTH, GOALS.TRAVEL_WORLD],
  [GOALS.SOMETHING_ELSE]: [GOALS.GROW_WEALTH, GOALS.PROFESSIONAL_GROWTH],
  [GOALS.AI]: [GOALS.GROW_WEALTH, GOALS.PROFESSIONAL_GROWTH],
};

const CATEGORIES_TEXTS = {
  get [GOALS.GROW_WEALTH]() {
    return {
      title: i18next.t('home.categories.grow_wealth.title'),
      subtitle: i18next.t('home.categories.grow_wealth.subtitle'),
    };
  },
  get [GOALS.BE_MY_OWN_BOSS]() {
    return {
      title: i18next.t('home.categories.be_my_own_boss.title'),
      subtitle: i18next.t('home.categories.be_my_own_boss.subtitle'),
    };
  },
  get [GOALS.TRAVEL_WORLD]() {
    return {
      title: i18next.t('home.categories.travel_world.title'),
      subtitle: i18next.t('home.categories.travel_world.subtitle'),
    };
  },
  get [GOALS.FINANCIAL_FREEDOM]() {
    return {
      title: i18next.t('home.categories.financial_freedom.title'),
      subtitle: i18next.t('home.categories.financial_freedom.subtitle'),
    };
  },
  get [GOALS.PROFESSIONAL_GROWTH]() {
    return {
      title: i18next.t('home.categories.professional_growth.title'),
      subtitle: i18next.t('home.categories.professional_growth.subtitle'),
    };
  },
  get [GOALS.WORK_LIFE_BALANCE]() {
    return {
      title: i18next.t('home.categories.work_life_balance.title'),
      subtitle: i18next.t('home.categories.work_life_balance.subtitle'),
    };
  },
  get [GOALS.SOMETHING_ELSE]() {
    return {
      title: i18next.t('home.categories.something_else.title'),
      subtitle: i18next.t('home.categories.something_else.subtitle'),
    };
  },
};

const RECOMMENDED = 'recommended';
const GOAL = 'goal';
const OTHERS = 'others';

export const CATEGORIES_TYPES = {
  RECOMMENDED,
  GOAL,
  OTHERS,
};

export function getSortedGuidesByGoal({
  guides,
  goal,
}: {
  guides: Array<GuideType>;
  goal: GoalType;
}) {
  return guides.sort((a, b) => {
    let indexA = a.tags.indexOf(goal);
    let indexB = b.tags.indexOf(goal);

    if (indexA < 0 && indexB < 0) {
      return 0;
    }

    if (indexA < 0) {
      return 1;
    }

    if (indexB < 0) {
      return -1;
    }

    return indexA - indexB;
  });
}

function getRecommendedGuidesByGoal({ guides }: { guides: Array<GuideType> }) {
  if (size(guides) > 6) {
    return slice(guides, 0, 6);
  }

  return guides;
}

interface CategoryType {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  viewAllLink?: string;
  guides: Array<GuideType>;
}

export function partitionGuidesByCategories({
  guides,
  goal,
}: {
  guides: Array<GuideType>;
  goal: GoalType;
}) {
  const subcategories = SUBCATEGORIES_BY_GOAL[goal] ?? [];
  const [includedGuides, otherGuides] = partition(guides, guide =>
    [...subcategories, goal].some(v => guide.tags.includes(v))
  );

  const sortedGuidesByGoal = getSortedGuidesByGoal({
    guides: includedGuides,
    goal,
  });

  const categories: Array<CategoryType> = [
    {
      id: uniqueId('_guide-category'),
      type: RECOMMENDED,
      title: i18next.t('home.categories.recommended.title'),
      subtitle: i18next.t('home.categories.recommended.subtitle'),
      guides: getRecommendedGuidesByGoal({ guides: sortedGuidesByGoal }),
    },
  ];

  for (const category of subcategories) {
    let guides: Array<GuideType> = [];

    if (goal === category) {
      guides = sortedGuidesByGoal;
    } else {
      const filteredGuides = filter(includedGuides, v =>
        v.tags.includes(category)
      );
      guides = getSortedGuidesByGoal({
        guides: filteredGuides,
        goal: category,
      });
    }

    categories.push({
      id: uniqueId('_guide-category'),
      type: category,
      ...CATEGORIES_TEXTS[category as keyof typeof CATEGORIES_TEXTS],
      viewAllLink: size(guides) > 2 ? `/guides/category/${category}` : '',
      guides,
    });
  }

  if (!isEmpty(otherGuides)) {
    categories.push({
      id: uniqueId('_guide-category'),
      type: OTHERS,
      title: i18next.t('home.categories.others.title'),
      subtitle: i18next.t('home.categories.others.subtitle'),
      viewAllLink: size(otherGuides) > 2 ? '/guides/category/others' : '',
      guides: otherGuides,
    });
  }

  return categories;
}

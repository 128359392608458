import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import surfer_cosmo from '../../../../images/surfer_cosmo.webp';
import {
  ImageContainer,
  ModalButton,
  ModalContainer,
  Subtitle,
  Title,
} from './CongratulationsModal.styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '75%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 4,
  p: 0,
  borderRadius: '8px',
  maxWidth: '360px',
};

export default function CongratulationsModal() {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={open}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalContainer>
            <Title>{t('Congratulations!')}</Title>

            <Subtitle>{t('You have successfully purchased the plan')}</Subtitle>

            <ImageContainer>
              <img src={surfer_cosmo} alt="" />
            </ImageContainer>

            <ModalButton isMain={true} onClick={handleClose}>
              {t('Get started')}
            </ModalButton>
          </ModalContainer>
        </Box>
      </Modal>
    </div>
  );
}

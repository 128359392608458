import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import AiAvatarIcon from '../../../../ai-bots/entities/message/ui/assets/ai-avatar.svg';
import { smoothScrollToBottom } from '../../fill-the-gap-modal/utils';
import './typing-ai-message.css';

type TypingAnimationProps = {
  text: string;
  onHeightChange?: () => void;
  speed?: number;
};

export function TypingAIMessage({
  text,
  speed = 5,
  onHeightChange,
}: TypingAnimationProps) {
  const [displayedText, setDisplayedText] = useState('');
  const [wasAnimated, setWasAnimated] = useState(false);
  useEffect(() => {
    setDisplayedText('');
    setWasAnimated(false);
  }, [text]);
  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setDisplayedText(prev => prev + text.charAt(index));
      index++;
      if (index >= text.length) {
        clearInterval(timer);
        setWasAnimated(true);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed]);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    const observeTarget = containerRef.current;

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, []);

  useEffect(() => {
    smoothScrollToBottom();
    onHeightChange?.();
  }, [height]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="typing-ai-message typing-ai-message_ai-message"
    >
      <img
        width="24"
        height="24"
        src={AiAvatarIcon}
        role="presentation"
        alt=""
      />
      <p ref={containerRef}>
        {displayedText.replace(/<br\s*\/?>/gi, '\n')}
        {!wasAnimated && (
          <span style={{ position: 'relative', left: '4px' }}>
            <span
              style={{ position: 'absolute', top: '4px' }}
              className="task-page__cursor"
            />
          </span>
        )}
      </p>
    </motion.div>
  );
}

import { AxiosPromise } from 'axios';

import { api } from '../../../../services/http.service';

type ChallengeLessonsProgressType = Record<
  string,
  {
    id: string;
    status: string;
  }
>;

export const ChallengeLessonsProgressAPI = {
  getProgress(challengeId: string): AxiosPromise<ChallengeLessonsProgressType> {
    return api.get(`/daily-challenges/${challengeId}/tasks/progress`);
  },
  saveProgress(challengeId: string, taskId: string) {
    return api.post(
      `/daily-challenges/${challengeId}/tasks/${taskId}/progress`
    );
  },
  forceOpen(challengeId: string) {
    return api.post(`/daily-challenges/${challengeId}/force-open`);
  },
};

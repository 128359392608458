import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../shared/ui';
import './guide-feedback.css';

export function GuideSuccessFeedback({
  feedback,
  onContinue,
}: {
  feedback?: string;
  onContinue: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="guide-feedback guide-success-feedback">
      <div className="guide-feedback__header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <rect width="19" height="19" x=".5" y=".5" fill="#19AA32" rx="9.5" />
          <rect
            width="19"
            height="19"
            x=".5"
            y=".5"
            stroke="#19AA32"
            rx="9.5"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14.666 6.5 8.25 12.917 5.333 10"
          />
        </svg>

        <h4 className="guide-feedback__title">
          {t('guides.lesson-chat.feedback.success.title')}
        </h4>
      </div>

      {feedback && <p className="guide-feedback__feedback">{feedback}</p>}

      <UiPrimaryButton
        className="guide-feedback__button guide-success-feedback__button"
        onClick={onContinue}
      >
        {t('guides.continue-button')}
      </UiPrimaryButton>
    </div>
  );
}

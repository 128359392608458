import { FC } from 'react';

import logo from '../../../images/logo.svg';
import { navbarType } from '../../../types/Types';
import { LogoNavbarContainer } from './Navbar.style';

const LogoNavbar: FC<navbarType> = ({ isShadow = true }) => {
  return (
    <LogoNavbarContainer isShadow={isShadow}>
      <img src={logo} alt="back arrow" />
    </LogoNavbarContainer>
  );
};

export default LogoNavbar;

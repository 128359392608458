import Bugsnag from '@bugsnag/js';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userQuery } from '../../../../context/atoms/User';
import { ApplePayButton, useApplePay } from '../../../../entities/apple-pay';
import { PAYMENT_METHODS } from '../../../../entities/payment-method';
import { ReviewCard } from '../../../../entities/review-card';
import { Loader } from '../../../shared';
import { UiPrimaryButton } from '../../../shared/ui';
import { UpsellAnalytics } from '../../shared/analytics';
import { useWebToAppExperiment } from '../../shared/hooks/use-web-to-app-experiment';
import { getWebToAppUrl } from '../../shared/lib';
import { UiHeader } from '../../shared/ui';
import { UpsellCascadeAPI } from '../api';
import { UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG } from '../config';
import type { MappedUpsellCascadeOffer } from '../lib';
import { useUpsellCascadeOffers } from '../model';
import { ChoiceToast } from './ChoiceToast';
import { UpsellOffers } from './UpsellOffersRedesign';
import './assets/upsell-cascade.css';

function iOSVersion() {
  const match = navigator?.userAgent?.match(/OS (\d+)_?(\d+)?/) ?? null;
  return match !== null ? parseInt(match[1], 10) : 0;
}

function isIOS16OrLater() {
  return iOSVersion() >= 16;
}

function getHighestDiscountPricingId(offers: Array<MappedUpsellCascadeOffer>) {
  let highestDiscountPricingId = null;
  let highestDiscountPercent = 0;

  offers.forEach(offer => {
    const discountPercentNum = parseFloat(offer.discountPercent);
    if (discountPercentNum > highestDiscountPercent) {
      highestDiscountPercent = discountPercentNum;
      highestDiscountPricingId = offer.pricingId;
    }
  });

  return highestDiscountPricingId;
}

export function UpsellCascadeRedesignPage() {
  //TODO: refactor user
  const user = useRecoilValue(userQuery);
  const userId = user?.user_id as number;

  const { t } = useTranslation();
  const navigation = useHistory();
  const [parent] = useAutoAnimate();
  const { isLoading, isError, offers, hasVat } = useUpsellCascadeOffers(
    UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
    true
  );
  const [selectedOffer, setSelectedOffer] =
    useState<MappedUpsellCascadeOffer>();
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const webToAppExperiment = useWebToAppExperiment();

  const growthBookUpsellGigaValue = useFeatureValue(
    'exp_upsell_megaupsell',
    'default'
  );

  const isRedesignedUpsellCascadeGroup = useFeatureValue(
    'exp_upsell_upsell_cascade_redesign',
    false
  );

  const pageQuery = useMemo(
    () => qs.parse(navigation.location.search),
    [navigation.location.search]
  );

  const isChoiceToastInitialVisible = useMemo(
    () => pageQuery.isReportOfferAdded === 'true',
    [pageQuery]
  );

  const REDESIGN = {
    TITLE: t('upsell-cascade.redesign.title'),
    SUBTITLE: t('upsell-cascade.redesign.subtitle'),
    TESTIMONIAL: {
      TITLE: t('upsell-cascade.redesign.testimonial.title'),
      REVIEWS: t('upsell-cascade.redesign.testimonial.reviews', {
        returnObjects: true,
      }) as Array<{ reviewer: string; text: string }>,
    },
    FEATURES_LIST: t('upsell-cascade.redesign.features-list', {
      returnObjects: true,
    }) as Array<string>,
  };

  const isHasUpsells = !isEmpty(offers);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay]
  );

  useEffect(() => {
    UpsellAnalytics.pageView({
      place: 'signup_multioffer_upsell',
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
      is_oneclick: isOneClick,
    });
  }, []);

  useEffect(() => {
    if (offers && isHasUpsells) {
      setSelectedOffer(offers[0]);
    }
  }, [offers, isHasUpsells]);

  useEffect(() => {
    if ((!isLoading && !isHasUpsells) || isError) {
      Bugsnag.notify(new Error('ERROR_UPSELL_CASCADE_EMPTY_OFFERS'));
      UpsellAnalytics.pageViewWithError({
        place: 'signup_multioffer_upsell',
        paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
        is_oneclick: isOneClick,
        remote_work: 'default',
        influencer: 'default',
        assistant: 'default',
        business: 'default',
      });
      goToNextPage();
    }
  }, [isLoading, isHasUpsells, isError]);
  function goToNextPage() {
    if (isIOS16OrLater() && isWebToAppScreenShow) {
      navigation.push(getWebToAppUrl(webToAppExperiment));
    } else {
      navigation.replace('/onboarding/v2');
    }
  }

  function onOfferSelected(offer: MappedUpsellCascadeOffer) {
    UpsellAnalytics.onChooseOffer({
      offerId: offer.id,
      pricingId: offer.pricingId,
      price: offer.rawDiscountPrice,
      currency: offer.currency,
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
      is_oneclick: isOneClick,
      remote_work: 'default',
      influencer: 'default',
      assistant: 'default',
      business: 'default',
    });
    setSelectedOffer(offer);
  }

  function onSkip({ position }: { position: 'top' | 'bottom' }) {
    UpsellAnalytics.onSkip({
      position,
      place: 'signup_multioffer_upsell',
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
      is_oneclick: isOneClick,
    });
    goToNextPage();
  }

  async function purchaseUpsell() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    if (!userId) {
      throw new Error('EMPTY_USER');
    }

    UpsellAnalytics.onTryToPay({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
      is_oneclick: isOneClick,
    });

    return await UpsellCascadeAPI.buyOffer({
      userId,
      pricingId: selectedOffer.pricingId,
      offerId: selectedOffer.id,
      paywallConfigId: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
    });
  }

  function onPurchase() {
    purchaseUpsell().catch(cause => {
      Bugsnag.notify(new Error('ERROR_UPSELL_CASCADE_PURCHASE', { cause }));
    });

    goToNextPage();
  }

  function onApplePaySuccess() {
    goToNextPage();
  }

  function onApplePayTryToPay() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    UpsellAnalytics.onTryToPay({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      paymentMethod: PAYMENT_METHODS.APPLE_PAY,
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      exp_upsell_upsell_cascade_redesign: isRedesignedUpsellCascadeGroup,
      is_oneclick: isOneClick,
    });
  }

  function onApplePayLoaderClick() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    UpsellAnalytics.onApplePayLoaderClick({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG,
      is_oneclick: isOneClick,
    });
  }

  /**
   * @note
   * Trans - тяжелый компонент для рендера, в profile больше всех занимает.
   * TODO: Нужно перенести setSelectedOffer в какой то globalState и не перерисовывать страницу
   * */

  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      //TODO: send analytics event?
      navigation.replace('/onboarding/v2');
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  if (isLoading || lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  const badgeText = t('upsell-cascade.redesign.badge-text');

  const pricingIDWithBadge = getHighestDiscountPricingId(offers);

  return (
    <main className="upsell-cascade">
      <UiHeader
        events={{
          onSkip: () => onSkip({ position: 'top' }),
        }}
      />

      <div className="upsell-cascade__container" ref={parent}>
        <ChoiceToast isInitialVisible={isChoiceToastInitialVisible} />

        <div className="upsell-cascade__content">
          <h1 className="upsell-cascade__title">{REDESIGN.TITLE}</h1>
          <h3 className="upsell-cascade__subtitle">{REDESIGN.SUBTITLE}</h3>
          <UpsellOffers
            isLoading={isLoading}
            offers={offers}
            className="upsell-cascade__offers"
            selectedOfferId={selectedOffer?.id}
            onOfferSelected={onOfferSelected}
            badgeText={badgeText}
            pricingIDWithBadge={pricingIDWithBadge}
          />

          <ul className="upsell-cascade__features-list">
            {REDESIGN.FEATURES_LIST.map((feature, i) => (
              <li
                key={`upsell-cascade-feature-${i}`}
                className="upsell-cascade__redesign_feature"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect width="20" height="20" fill="#5653FE" rx="10" />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14.667 6.5 8.25 12.917 5.333 10"
                  />
                </svg>
                <p>{feature}</p>
              </li>
            ))}
          </ul>

          <div className="upsell-cascade__reviews">
            <h3 className="upsell-cascade__reviews-title">
              {REDESIGN.TESTIMONIAL.TITLE}
            </h3>
            <div className="upsell__reviews-list">
              {REDESIGN.TESTIMONIAL.REVIEWS.map((review, i) => (
                <ReviewCard
                  key={`upsell-review__${i}`}
                  name={review.reviewer}
                  text={review.text}
                />
              ))}
            </div>
          </div>

          <div className="upsell-cascade__billing-description">
            <Trans
              i18nKey={
                hasVat
                  ? t('upsell-cascade.billing-description-with-vat')
                  : t('upsell-cascade.billing-description')
              }
              values={{ discountPrice: selectedOffer?.discountPrice }}
              components={{ b: <b /> }}
            />
          </div>

          {isHasUpsells && (
            <>
              <div className="upsell-cascade__purchase-container">
                {isApplePay && selectedOffer && (
                  <ApplePayButton
                    offerId={selectedOffer.id}
                    pricingId={selectedOffer.pricingId}
                    paywallConfigId={UPSELL_CASCADE_REDESIGN_PAYWALL_CONFIG}
                    events={{
                      onTryToPay: onApplePayTryToPay,
                      onLoaderClick: onApplePayLoaderClick,
                      onSuccess: onApplePaySuccess,
                    }}
                  />
                )}

                {!isApplePay && (
                  <UiPrimaryButton
                    className="upsell-cascade__purchase-button"
                    onClick={onPurchase}
                  >
                    {t('upsell-cascade.purchase-button')}
                  </UiPrimaryButton>
                )}
              </div>

              <div className="upsell-cascade__skip-action-container">
                <button
                  type="button"
                  className="upsell-cascade__skip-action-button"
                  onClick={() => onSkip({ position: 'bottom' })}
                >
                  {t('upsell-cascade.skip-action-button')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

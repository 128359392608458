import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 680px;
  margin: 0 auto;

  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

export const Container = styled.div`
  margin: 22px 24px 24px 24px;
  form {
    label {
      position: absolute;
      pointer-events: none;
      transition-property: all;
      transition-timing-function: ease;
      line-height: 1;
    }
  }
`;

export const ContainerWithReviews = styled.div`
  margin: 0px 24px 24px 24px;
  form {
    label {
      position: absolute;
      pointer-events: none;
      transition-property: all;
      transition-timing-function: ease;
      line-height: 1;
    }
  }
`;

export const PositionedPageContainer = styled.div`
  position: relative;
`;

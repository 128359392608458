import { useState } from 'react';

import { useGrowthBook } from '@growthbook/growthbook-react';

import { useWhatsNew } from '@/entities/whats-new';
import { GuidesMainPage } from '@/modules/guides';
import { GuidesMainPageV2 } from '@/modules/guides-v2';
import useAiPathwayExperiment from '@/shared/lib/hooks/use-ai-pathway-experiment';
import useAiToolsExperiment from '@/shared/lib/hooks/use-ai-tools-experiment';
import { AiToolsUpdatesBottomSheet } from '@/shared/ui/ai-tools-updates-bottom-sheet';

export const MainPage = () => {
  const growthbook = useGrowthBook();
  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });
  const {
    isWhatsNewVisible: isAiToolsWhatsNewVisible,
    setWhatsNewHidden: setAiToolsWhatsNewViewed,
  } = useWhatsNew('ai-tools');
  const { isTest: isAiToolsTest } = useAiToolsExperiment();
  const [isAiToolsWhatsNewClosed, setIsAiToolsWhatsNewClosed] = useState(false);

  const onAiToolsWhatsNewClose = () => {
    setIsAiToolsWhatsNewClosed(true);
    setAiToolsWhatsNewViewed();
  };

  return (
    <>
      {aiPathwayExperiment === 'test' ? (
        <GuidesMainPageV2 />
      ) : (
        <GuidesMainPage />
      )}
      <AiToolsUpdatesBottomSheet
        isVisible={Boolean(!isAiToolsWhatsNewClosed && isAiToolsWhatsNewVisible && !isAiToolsTest && growthbook?.ready)}
        onClose={onAiToolsWhatsNewClose}
      />
    </>
  );
};

export default MainPage;

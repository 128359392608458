import { useMutation, useQuery } from '@tanstack/react-query';

import { GuidesAPI } from '../../shared/api';
import { GuideLessonType, GuideType, GuideUnitType } from '../guide';
import { usePrefetchGuideByIdProgressQuery } from '../guide-progress';

export function useLessonProgressByUnitId({
  unitId,
}: {
  unitId: GuideUnitType['id'];
}) {
  return useQuery({
    queryKey: ['lesson-progress-by-unit-id', unitId],
    queryFn: () =>
      GuidesAPI.getLessonProgressByUnitId({ unitId }).then(({ data }) => data),
  });
}

export function useSaveLessonProgressMutation() {
  const { prefetchGuideByIdProgressQuery } =
    usePrefetchGuideByIdProgressQuery();

  return useMutation({
    mutationKey: ['save-lesson-progress'],
    mutationFn: ({
      guideId,
      unitId,
      lessonId,
    }: {
      guideId: GuideType['id'];
      unitId: GuideUnitType['id'];
      lessonId: GuideLessonType['id'];
    }) =>
      GuidesAPI.saveLessonProgress({ guideId, unitId, lessonId }).then(() => {
        return prefetchGuideByIdProgressQuery({ id: guideId });
      }),
  });
}

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { getTool } from '../utils';

export function FillTheGapModalHeader({
  task,
  onClose,
  disableNavigation,
  isOnboardingArrow,
}: {
  task: GuideLessonDetailsFillTheGapType;
  disableNavigation?: boolean;
  onClose: () => void;
  isOnboardingArrow?: boolean;
}) {
  const { t } = useTranslation();

  const tool = task.content[0].tool;

  const { name: iconName, icon: iconSrc } = getTool(
    isOnboardingArrow,
    t('onboarding-redesign.fill-in-blanks'),
    tool
  );

  return (
    <>
      {!disableNavigation && (
        <nav className="guide-fill-the-gap-modal__navigation">
          <button
            type="button"
            className="guide-lesson-widget__close-button"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>
        </nav>
      )}

      <div className="guide-fill-the-gap-modal__header">
        {task.content.map(item => (
          <Fragment key={item.title}>
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </Fragment>
        ))}
      </div>
      <div className="guide-fill-the-gap-modal__chatgpt">
        <img
          width="24"
          height="24"
          src={iconSrc}
          role="presentation"
          alt=""
          id={'total_return'}
        />
        <p>{iconName}</p>
      </div>
    </>
  );
}

import classNames from 'classnames';

import { GuideLessonDetailsQuizType } from '../../../entities/guide-lesson';
import { ImageSkeleton } from '../../image-skeleton';
import { AnswerType } from './GuideLessonQuiz';

export function CheckmarkCorrectIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
    >
      <rect width="19" height="19" x=".5" y="1" fill="currentColor" rx="9.5" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.667 7 8.25 13.417 5.333 10.5"
      />
    </svg>
  );
}

export function CheckmarkIncorrectIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
    >
      <rect width="19" height="19" x=".5" y=".5" fill="currentColor" rx="9.5" />
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M6.088 6.088a.583.583 0 0 1 .824 0L10 9.175l3.088-3.087a.583.583 0 0 1 .825.824L10.825 10l3.088 3.088a.583.583 0 0 1-.825.825L10 10.825l-3.088 3.088a.583.583 0 1 1-.824-.825L9.175 10 6.088 6.912a.583.583 0 0 1 0-.824Z"
      />
    </svg>
  );
}

function OptionCheckmark({
  isQuizSubmitted,
  isSelected,
  isSelectedAnswerCorrect,
  isOptionCorrect,
}: {
  isQuizSubmitted: boolean;
  isSelected: boolean;
  isSelectedAnswerCorrect: boolean;
  isOptionCorrect: boolean;
}) {
  const isCorrect = isSelectedAnswerCorrect && isSelected;
  const isIncorrect = !isSelectedAnswerCorrect && isSelected;

  if (!isQuizSubmitted) {
    return (
      <span
        className={classNames('quiz-option__checkmark', {
          'quiz-option__checkmark--checked': isSelected,
        })}
      ></span>
    );
  }

  switch (true) {
    case isCorrect:
      return <CheckmarkCorrectIcon className="quiz-checkmark-correct" />;
    case !isSelected && isOptionCorrect:
      return <CheckmarkCorrectIcon className="quiz-checkmark-disabled" />;
    case isIncorrect:
      return <CheckmarkIncorrectIcon className="quiz-checkmark-incorrect" />;
    default:
      return <span className="quiz-option__checkmark"></span>;
  }
}

export function QuizOptions({
  quiz,
  isQuizSubmitted,
  selectedAnswer,
  onSelectOption,
}: {
  quiz: GuideLessonDetailsQuizType['content'][0];
  isQuizSubmitted: boolean;
  selectedAnswer: AnswerType | undefined;
  onSelectOption: (option: AnswerType) => void;
}) {
  const isOptionsImage = !!quiz.options[0].image;

  if (isOptionsImage) {
    return (
      <div className="guide-lesson-quiz__options--grid">
        {quiz.options.map(option => (
          <div key={option.id} className="quiz-option">
            <label className="quiz-option__label__image">
              <input
                type="radio"
                name={`quiz-option-radio__${quiz.id}`}
                disabled={isQuizSubmitted && Boolean(selectedAnswer)}
                className="quiz-option__radio"
                checked={selectedAnswer?.id === option.id}
                onChange={() => onSelectOption(option)}
              />

              <OptionCheckmark
                isQuizSubmitted={isQuizSubmitted}
                isOptionCorrect={option.is_correct}
                isSelected={selectedAnswer?.id === option.id}
                isSelectedAnswerCorrect={Boolean(selectedAnswer?.is_correct)}
              />

              <ImageSkeleton
                src={option.image as string}
                alt=""
                className="quiz-option__image"
                placeholderAspectRatio="1/1"
              />
            </label>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <ul className="guide-lesson-quiz__options">
        {quiz.options.map(option => (
          <li key={option.id} className="quiz-option">
            <label className="quiz-option__label">
              <input
                type="radio"
                name={`quiz-option-radio__${quiz.id}`}
                disabled={isQuizSubmitted && Boolean(selectedAnswer)}
                className="quiz-option__radio"
                checked={selectedAnswer?.id === option.id}
                onChange={() => onSelectOption(option)}
              />

              <OptionCheckmark
                isQuizSubmitted={isQuizSubmitted}
                isOptionCorrect={option.is_correct}
                isSelected={selectedAnswer?.id === option.id}
                isSelectedAnswerCorrect={Boolean(selectedAnswer?.is_correct)}
              />

              <span className="quiz-option__text">{option.text}</span>
            </label>
          </li>
        ))}
      </ul>
    </>
  );
}

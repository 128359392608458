import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { languageState } from '../../../context/atoms/Language';
import back_arrow from '../../../images/back_arrow.svg';
import logo from '../../../images/logo.svg';
import { languages } from '../../../types/Language';
import { navbarType } from '../../../types/Types';
import LanguageBottomsheet from '../LanguageBottomsheet/LanguageBottomsheet';
import { StyledNavbar } from './Navbar.style';

const LanguageNavbar: FC<navbarType> = ({
  isShadow = true,
  isSticky = true,
  isLogoRedirects = true,
}) => {
  const [languageCode, setLanguageCode] = useRecoilState(languageState);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  return (
    <>
      <StyledNavbar
        alignLogoCenter={false}
        isShadow={isShadow}
        isSticky={isSticky}
      >
        <div className="logo">
          <img
            src={logo}
            onClick={() => isLogoRedirects && history.push('/')}
            alt="prosperi logo"
          />
        </div>
        <div
          className="lang-options"
          onClick={() => {
            setShowLanguageModal(!showLanguageModal);
          }}
        >
          {!!languageCode && (
            <img
              src={
                languages.filter(language => language.code2 === languageCode)[
                  languages.filter(language => language.code2 === languageCode)
                    .length - 1
                ].img || languages.filter(lang => lang.code2 === 'en')[0].img
              }
              alt=""
              className="flag"
            />
          )}
          <img src={back_arrow} alt="" className="rotated" />
        </div>
      </StyledNavbar>
      {showLanguageModal && (
        <AnimatePresence>
          <LanguageBottomsheet handleClose={handleClose} />
        </AnimatePresence>
      )}
    </>
  );
};
export default LanguageNavbar;

import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../shared/ui';
import type {
  GuideLessonType,
  GuideType,
  GuideUnitType,
} from '../../../entities/guide';
import type { GuideUnitProgress } from '../../../entities/guide-progress';
import { useGuidesOnboarding } from '../../../entities/guides-onboarding';
import { GuidesAnalytics } from '../../../shared/analytics';
import type { ReturnLessonEnd, ReturnUnitEnd } from '../../../shared/lib';
import { LessonStatus } from './LessonStatus';
import './guide-unit-lesson.css';

export function GuideUnitLesson({
  guideId,
  unitId,
  isSelected,
  lesson,
  progress,
  nextLesson,
  events,
}: {
  guideId: GuideType['id'];
  unitId: GuideUnitType['id'];
  nextLesson: ReturnLessonEnd | ReturnUnitEnd | null;
  isSelected: boolean;
  lesson: GuideLessonType;
  progress: GuideUnitProgress;
  events: {
    onSelect: (lesson: GuideLessonType) => void;
    onGoToLesson: (lesson: GuideLessonType) => void;
  };
}) {
  const { t } = useTranslation();
  const lessonRef = useRef<HTMLDivElement>(null);

  const { isGuidesOnboardingVisible, setGuidesOnboardingVisible } =
    useGuidesOnboarding();

  const lessonStatus = useMemo(() => {
    const status = progress.lessons[lesson.id].status;

    if (isSelected && status === 'not_started') {
      return 'selected';
    }

    return progress.lessons[lesson.id].status;
  }, [isSelected, progress, lesson]);

  useEffect(() => {
    if (lessonRef.current && nextLesson?.lessonId === lesson.id) {
      const position = lessonRef.current.getBoundingClientRect().top;

      setTimeout(() => {
        window.scrollTo({
          top: position - 80,
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [nextLesson]);

  function onSelectLesson() {
    if (lessonStatus !== 'closed') {
      events.onSelect(lesson);
    }
    GuidesAnalytics.onStartLessonClick({
      guideId,
      unitId,
      lessonId: lesson.id,
      lessonType: lesson.type,
      isLocked: lessonStatus === 'closed',
      progress: progress.progress,
    });
  }

  function onGoToLesson(lesson: GuideLessonType) {
    setGuidesOnboardingVisible();
    events.onGoToLesson(lesson);
  }

  return (
    <div
      ref={lessonRef}
      className={classNames('guide-unit-lesson', {
        'guide-unit-lesson--closed': lessonStatus === 'closed',
        'guide-unit-lesson--active': isSelected,
      })}
      role="button"
      onClick={onSelectLesson}
    >
      <div className="guide-unit-lesson__container">
        <LessonStatus status={lessonStatus} />
        <div className="guide-unit-lesson__content">
          <img
            className="guide-unit-lesson__image"
            width="72"
            height="72"
            src={lesson.image}
            role="presentation"
            alt=""
          />

          <div className="guide-unit-lesson__text">
            <h3 className="guide-unit-lesson__title">{lesson.name}</h3>
            <h4 className="guide-unit-lesson__subtitle">
              {lesson.type === 'theory'
                ? t('guides.lesson-card.lesson-subtitle')
                : t('guides.lesson-card.practice-subtitle')}
            </h4>
          </div>
        </div>
      </div>
      {isSelected && (
        <UiPrimaryButton
          className="guide-unit-lesson__action"
          withPulseAnimation={isGuidesOnboardingVisible}
          onClick={() => onGoToLesson(lesson)}
        >
          {lesson.type === 'theory'
            ? t('guides.start-lesson-button')
            : t('guides.start-practice-button')}
        </UiPrimaryButton>
      )}
    </div>
  );
}

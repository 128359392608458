import { useTranslation } from 'react-i18next';

import type { AiBot } from '../../../entities/ai-bot';
import { useChatBotDetailsQuery } from '../../../entities/chat-details';
import { ChatTag } from '../../../entities/chat-tag';
import { DeleteSessionButton } from '../../../entities/delete-session-button';
import { UiSecondaryButton } from '../../../shared/ui/secondary-button';
import { ChatInfoLoading } from './ChatInfoLoading';
import './assets/chat-info.css';

export function ChatInfo({
  chatBotId,
  isDeleteSessionButtonVisible,
  onShowInfo,
  onDeleteSession,
}: {
  chatBotId: string;
  isDeleteSessionButtonVisible: boolean;
  onShowInfo?: (info: AiBot) => void;
  onDeleteSession?: () => void;
}) {
  const { t } = useTranslation();
  const { isLoading, data } = useChatBotDetailsQuery({ chatBotId: chatBotId });

  if (isLoading) {
    return <ChatInfoLoading />;
  }

  if (!data) {
    throw new Error('ERR_AI_BOTS_CHAT_INFO_RENDER_WITHOUT_DATA');
  }

  return (
    <div className="chat-info">
      <div className="chat-info__container">
        <img
          className="chat-info__image"
          width="64"
          height="64"
          src={data.image ?? ''} //@note For custom bots add default image (or make image required)
          role="presentation"
          alt=""
        />
        <div className="chat-info__content">
          <h2>{data.name}</h2>
          <div className="chat-info__tags">
            {data.tags.map(t => (
              <ChatTag key={t.id} name={t.name} />
            ))}
          </div>
        </div>
      </div>

      <p className="chat-info__description">{data.description}</p>

      <div className="chat-info__actions">
        <UiSecondaryButton onClick={() => onShowInfo?.(data)}>
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                fill="#24234C"
                d="M8 2.667a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 8 2.667ZM1.333 8a6.667 6.667 0 1 1 13.334 0A6.667 6.667 0 0 1 1.333 8Z"
              />
              <path
                fill="#24234C"
                d="M8 6.667c.368 0 .667.298.667.666v4a.667.667 0 1 1-1.334 0v-4c0-.368.299-.666.667-.666ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
              />
            </svg>
            {t('ai-bots.chat.info-button')}
          </>
        </UiSecondaryButton>

        {isDeleteSessionButtonVisible && (
          <DeleteSessionButton onClick={() => onDeleteSession?.()} />
        )}
      </div>
    </div>
  );
}

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CoursivLogo from '../../../../../images/coursiv-logo.svg';
import './ui-header.css';

export function UiHeader({
  events,
  className,
}: {
  events: {
    onSkip: () => void;
  };
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <header className={classNames('upsell-ui-header', className)}>
      <img width="112" height="36" src={CoursivLogo} alt="coursiv" />
      <button
        type="button"
        className="upsell-ui-header__skip-button"
        onClick={events.onSkip}
      >
        {t('upsell-cascade.skip-button')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          fill="none"
        >
          <path
            fill="#40444D"
            d="M.293 13.707a1 1 0 0 1 0-1.414L5.586 7 .293 1.707A1 1 0 0 1 1.707.293l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"
          />
        </svg>
      </button>
    </header>
  );
}

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { SatisfactionSurveyAnalytics } from '../analytics';
import { CloseButton } from './CloseButton';
import { SatisfactionSurveyBody } from './SatisfactionSurveyBody';
import { SatisfactionSurveySuccess } from './SatisfactionSurveySuccess';
import './assets/satisfaction-survey.css';
import FilledStarIcon from './assets/star-filled.svg';
import StarIcon from './assets/star.svg';

const LEVEL_TYPES = {
  NOT_SATISFIED: 'not_satisfied',
  SLIGHTLY_SATISFIED: 'slightly_satisfied',
  SOMEWHAT_SATISFIED: 'somewhat_satisfied',
  SATISFIED: 'satisfied',
  VERY_SATISFIED: 'very_satisfied',
} as const;

type LevelType = (typeof LEVEL_TYPES)[keyof typeof LEVEL_TYPES];

export function SatisfactionSurveyBottomSheet({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();

  const LEVELS = [
    LEVEL_TYPES.NOT_SATISFIED,
    LEVEL_TYPES.SLIGHTLY_SATISFIED,
    LEVEL_TYPES.SOMEWHAT_SATISFIED,
    LEVEL_TYPES.SATISFIED,
    LEVEL_TYPES.VERY_SATISFIED,
  ];

  const [selectedLevel, setSelectedLevel] = useState<LevelType>();
  const [isFeedbackSend, setIsFeedbackSend] = useState(false);

  useEffect(() => {
    if (isVisible) {
      SatisfactionSurveyAnalytics.view();
    }
  }, [isVisible]);

  const getStarIcon = (level: LevelType) => {
    if (!selectedLevel) {
      return StarIcon;
    }
    return LEVELS.indexOf(selectedLevel) >= LEVELS.indexOf(level)
      ? FilledStarIcon
      : StarIcon;
  };

  function onSelectLevel(type: LevelType) {
    SatisfactionSurveyAnalytics.onLevelButtonClick({ levelType: type });
    setSelectedLevel(type);
  }

  function onSendFeedback(feedback: string, reason: string) {
    SatisfactionSurveyAnalytics.onSubmitFeedback({
      levelType: selectedLevel!,
      feedback,
      reason,
    });
    setIsFeedbackSend(true);
  }

  function onCloseBottomSheet() {
    if (!isFeedbackSend) {
      SatisfactionSurveyAnalytics.onDismiss();
    }

    onDismiss();
  }

  return (
    <BottomSheet
      key="satisfaction-survey-bottomsheet"
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={onCloseBottomSheet}
    >
      <section className="satisfaction-survey">
        <div className="satisfaction-survey__title-container">
          <h1 className="satisfaction-survey__title">
            {t('satisfaction-survey.title')}
          </h1>

          <CloseButton onClick={onCloseBottomSheet} />
        </div>

        <div className="satisfaction-survey__levels_container">
          <ul className="satisfaction-survey__levels">
            {LEVELS.map(level => (
              <li key={level}>
                <button
                  className={classNames('satisfaction-survey__level', {
                    'satisfaction-survey__level--active':
                      selectedLevel === level,
                  })}
                  onClick={() => onSelectLevel(level)}
                >
                  <span>
                    <img
                      width="56"
                      height="56"
                      src={getStarIcon(level)}
                      role="presentation"
                      alt=""
                    />
                  </span>
                </button>
              </li>
            ))}
          </ul>
          <div className="satisfaction-survey__level-title">
            <p>{t('satisfaction-survey.levels.not_satisfied')}</p>
            <p>{t('satisfaction-survey.levels.very_satisfied')}</p>
          </div>
        </div>

        {selectedLevel && (
          <>
            <SatisfactionSurveyBody
              isPositive={LEVELS.indexOf(selectedLevel) > 2}
              onSendFeedback={onSendFeedback}
            />
          </>
        )}
        {isFeedbackSend && (
          <SatisfactionSurveySuccess onDismiss={onCloseBottomSheet} />
        )}
      </section>
    </BottomSheet>
  );
}

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ApplePayButton, useApplePay } from '../../../../entities/apple-pay';
import { PAYMENT_METHODS } from '../../../../entities/payment-method';
import { ReviewCard } from '../../../../entities/review-card';
import { useCurrencySignQuery } from '../../../../hooks/use-currency-sign-query';
import UpsellImageEN from '../../../../images/upsell_with_reviews_en.png';
import UpsellImageES from '../../../../images/upsell_with_reviews_es.png';
import { isIOS16OrLater } from '../../../../shared/lib';
import { formatPrice } from '../../../../utils/price';
import { Loader } from '../../../shared';
import CustomSpinner from '../../../shared/CustomSpinner/CustomSpinner';
import { UiPrimaryButton } from '../../../shared/ui';
import { UpsellAnalytics } from '../../shared/analytics';
import { useWebToAppExperiment } from '../../shared/hooks/use-web-to-app-experiment';
import { getWebToAppUrl } from '../../shared/lib';
import { UiHeader } from '../../shared/ui';
import { useBuyUpsellOfferMutation, useUpsellSingleQuery } from '../model';
import { ChaseBottomsheet } from './ChaseBottomsheet';
import FireIcon from './assets/fire-icon.png';
import UpsellReviewsUsers from './assets/upsell-reviews-users.png';
import './assets/upsell.css';

function StarRating() {
  return (
    <div className="upsell__review-rating">
      {Array(5)
        .fill('')
        .map((_, j) => (
          <svg
            key={`upsell_ration_${j}`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            fill="none"
          >
            <path
              fill="#FFAD33"
              d="M8.103.817a1 1 0 0 1 1.794 0l1.81 3.666a1 1 0 0 0 .752.548l4.046.587a1 1 0 0 1 .555 1.706l-2.928 2.854a1 1 0 0 0-.288.885l.691 4.03a1 1 0 0 1-1.45 1.054l-3.62-1.902a1 1 0 0 0-.93 0l-3.62 1.902a1 1 0 0 1-1.45-1.054l.69-4.03a1 1 0 0 0-.287-.885L.94 7.324a1 1 0 0 1 .555-1.706l4.046-.587a1 1 0 0 0 .753-.548L8.104.817Z"
            />
          </svg>
        ))}
    </div>
  );
}

export function UpsellSinglePage() {
  const { t } = useTranslation();
  const navigation = useHistory();
  const isESLocale = i18next.language === 'es';
  const params = useParams<{ paywallConfigId: string }>();

  const growthBookUpsellGigaValue = useFeatureValue(
    'exp_upsell_megaupsell',
    'default'
  );

  const { paywallConfigId } = params;
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const webToAppExperiment = useWebToAppExperiment();

  const featuresList = t('upsell-single.features-list', {
    returnObjects: true,
  }) as Array<string>;

  const reviewsList = t('upsell-single.reviews.reviews', {
    returnObjects: true,
  }) as Array<{ reviewerName: string; reviewText: string }>;

  const [isUpsellBottomSheetVisible, setIsUpsellBottomSheetVisible] =
    useState(false);
  const [isDiscountUpsell, setIsDiscountUpsell] = useState(false);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const upsellOfferSingleQuery = useUpsellSingleQuery({
    paywallConfigId,
  });

  const currencySignQuery = useCurrencySignQuery();

  const pricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return isDiscountUpsell
        ? upsellOfferSingleQuery.data.discountedPrice
        : upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data, isDiscountUpsell]);

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay]
  );
  const upsellOfferSingleMutation = useBuyUpsellOfferMutation();

  const fullPrice = useMemo(
    () =>
      pricing && currencySignQuery.data
        ? `${currencySignQuery.data}${formatPrice(pricing.full_price)}`
        : '',
    [pricing, currencySignQuery.data]
  );

  const discountedPrice = useMemo(
    () =>
      pricing && currencySignQuery.data
        ? `${currencySignQuery.data}${formatPrice(pricing.discount_price)}`
        : '',
    [pricing, currencySignQuery.data]
  );

  const currencyCode = useMemo(
    () =>
      upsellOfferSingleQuery.data ? upsellOfferSingleQuery.data.currency : '',
    [upsellOfferSingleQuery.data]
  );

  const hasVat = useMemo(() => Boolean(pricing && pricing.vat), [pricing]);

  const discountedPercent = useMemo(
    () => pricing && pricing.discount,
    [pricing]
  );

  const isOfferPaywallConfig = paywallConfigId.includes('_single');

  const discountPercentOffText = t('upsell-single.discount-percent-off', {
    discountPercent: discountedPercent,
  });

  function goToNextPage(isPurchased = false) {
    window.scroll(0, 0);
    const path = isPurchased
      ? '/upsell-cascade?isReportOfferAdded=true'
      : '/upsell-cascade';

    return navigation.push(path);
  }

  function onSkip({ position }: { position: 'top' | 'bottom' }) {
    UpsellAnalytics.onSkip({
      position,
      place: 'signup_singleoffer_upsell',
      paywall_config_id: isOfferPaywallConfig
        ? 'offer_single'
        : 'upsells_notion_report',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
      upsellName: paywallConfigId,
    });

    if (isDiscountUpsell) {
      return goToNextPage();
    }

    setIsUpsellBottomSheetVisible(true);
  }

  function onShowDiscountedUpsell() {
    setIsUpsellBottomSheetVisible(false);
    setIsDiscountUpsell(true);
    window.scroll(0, 0);
  }

  function onPurchaseNewUpsell() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_singleoffer_upsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        upsellName: 'default',
      });

      upsellOfferSingleMutation
        .mutateAsync({
          offerId: upsellOfferSingleQuery.data.id,
          paywallConfigId,
          pricingId: pricing.id,
        })
        .catch(cause => {
          throw new Error('ERROR_PURCHASE_NEW_UPSELL', { cause });
        })
        .finally(() => {
          setTimeout(() => {
            goToNextPage(true);
          }, 500);
        });
    }
  }

  function onApplePaySuccess() {
    setTimeout(() => {
      goToNextPage(true);
    }, 500);
  }

  function onApplePayTryToPay() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_singleoffer_upsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: PAYMENT_METHODS.APPLE_PAY,
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });
    }
  }

  function onApplePayLoaderClick() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onApplePayLoaderClick({
        place: 'signup_singleoffer_upsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        is_oneclick: isOneClick,
      });
    }
  }

  useEffect(() => {
    if (
      upsellOfferSingleQuery.data &&
      pricing &&
      !lastPaymentMethodQuery.isLoading &&
      isUpsellAvailable
    ) {
      UpsellAnalytics.pageView({
        place: 'signup_singleoffer_upsell',
        offerId: upsellOfferSingleQuery.data.id,
        pricingId: pricing.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });

      UpsellAnalytics.legacyPageView({
        price: pricing.discount_price,
        currency: currencyCode,
        isDiscountUpsell,
        isOneClick,
      });
    }
  }, [
    upsellOfferSingleQuery.data,
    pricing,
    lastPaymentMethodQuery.isLoading,
    isUpsellAvailable,
  ]);
  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      UpsellAnalytics.onAllUpsellSkip({
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });
      if (isIOS16OrLater() && isWebToAppScreenShow) {
        navigation.replace(getWebToAppUrl(webToAppExperiment));
      } else {
        navigation.replace('/onboarding/v2');
      }
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  if (lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  return (
    <>
      {upsellOfferSingleMutation.isLoading && <CustomSpinner />}

      <main className="upsell vh-full">
        <UiHeader
          events={{
            onSkip: () => onSkip({ position: 'top' }),
          }}
        />
        <div className="upsell__header">
          <div className="upsell__hero-image-container">
            <img
              src={isESLocale ? UpsellImageES : UpsellImageEN}
              role="presentation"
              alt=""
            />
          </div>
          <div className="upsell__hero-image-overlay"></div>
          <div className="upsell__price-card">
            <div className="upsell__discount">{discountPercentOffText}</div>
            <div className="upsell__price-amount">
              <h1>{discountedPrice}</h1>
              <h2>{fullPrice}</h2>
            </div>
            <div className="upsell__price-description">
              {t('upsell-single.price-description')}
            </div>
          </div>
        </div>

        <div className="upsell__content">
          <h1 className="upsell__title">{t('upsell-single.title')}</h1>

          <div className="upsell__recommended-block">
            {t('upsell-single.recommended-block')}
          </div>

          <div className="upsell__features">
            {isDiscountUpsell && (
              <div className="upsell__discount-flag">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="78"
                  fill="none"
                >
                  <path fill="#5653FE" d="M0 0h22.5v78H0l17.5-39L0 0Z" />
                </svg>
                {discountPercentOffText.split(' ').join('\n')}
              </div>
            )}

            <ul className="upsell__features-list">
              {featuresList.map((item, i) => (
                <li key={`upsell_feature_${i}`} className="upsell__feature">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                  >
                    <rect width="20" height="20" fill="#5653FE" rx="10" />
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14.666 6.5 8.25 12.917 5.333 10"
                    />
                  </svg>

                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="upsell__save-time-block">
            <div className="upsell__save-time-block-title">
              <img
                width="30"
                height="30"
                src={FireIcon}
                role="presentation"
                alt=""
              />
              <h2>{t('upsell-single.save-time-block.title')}</h2>
            </div>

            <p>{t('upsell-single.save-time-block.description')}</p>
          </div>

          <div className="upsell__reviews">
            <h3 className="upsell__reviews-title">
              {t('upsell-single.reviews.title')}
            </h3>

            <div className="upsell__reviews-count">
              <div className="upsell__reviews-rating">
                <StarRating />
                <h4>{t('upsell-single.reviews.count')}</h4>
              </div>
              <img
                width="90"
                height="30"
                src={UpsellReviewsUsers}
                role="presentation"
                alt=""
              />
            </div>

            <div className="upsell__reviews-list">
              {reviewsList.map((item, i) => (
                <ReviewCard
                  key={`upsell-review__${i}`}
                  name={item.reviewerName}
                  text={item.reviewText}
                />
              ))}
            </div>
          </div>
          <div className="upsell__billing-description">
            <Trans
              i18nKey={
                hasVat
                  ? t('upsell-single.billing-description-with-vat')
                  : t('upsell-single.billing-description')
              }
              values={{ discountPrice: discountedPrice }}
              components={{ b: <b /> }}
            />
          </div>
        </div>

        {upsellOfferSingleQuery.data && (
          <div className="upsell_purchase-button">
            {isApplePay && pricing && (
              <ApplePayButton
                offerId={upsellOfferSingleQuery.data.id}
                pricingId={pricing.id}
                paywallConfigId={paywallConfigId}
                events={{
                  onTryToPay: onApplePayTryToPay,
                  onLoaderClick: onApplePayLoaderClick,
                  onSuccess: onApplePaySuccess,
                }}
              />
            )}

            {!isApplePay && (
              <UiPrimaryButton
                className="upsell__add-button"
                onClick={onPurchaseNewUpsell}
              >
                {t('upsell-single.purchase-button')}
              </UiPrimaryButton>
            )}
          </div>
        )}

        {upsellOfferSingleQuery.data && (
          <div className="upsell__skip">
            <button
              type="button"
              className="upsell__skip-button"
              onClick={() => onSkip({ position: 'bottom' })}
            >
              {t('upsell-single.skip-button')}
            </button>
          </div>
        )}
      </main>

      {/* <ChaseBottomsheet
        isVisible={isUpsellBottomSheetVisible}
        events={{
          onClose: onShowDiscountedUpsell,
        }}
        upsellType="giga_upsell"
      /> */}
    </>
  );
}

import { useOnboardingContent } from '@/entities/onboarding-content/model';
import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { Loader } from '@/modules/shared';
import { getAllowedArrow } from '@/modules/upsell/upsell-cascade/lib';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../../../../../api/userApi';
import {
  localOnboardState,
  userQuery,
} from '../../../../../context/atoms/User';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import './assets/congrats-page.css';

export const OnboardingV2CongratsPage = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userQuery);
  const setLocalOnboard = useSetRecoilState(localOnboardState);

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0] || 'ai';

  const userArrow = getAllowedArrow(arrow, 'ai');

  const { data: onboardingContent } = useOnboardingContent(userArrow);

  const pageContent = onboardingContent?.['finish-page'];

  const pageBlocks = useMemo(() => {
    const modulesBlock = pageContent?.find(
      item => item.type === 'prompts-block'
    )?.value;

    return {
      title:
        (pageContent?.find(item => item.type === 'title')?.value as string) ||
        '',
      description:
        (pageContent?.find(item => item.type === 'description')
          ?.value as string) || '',
      image:
        (pageContent?.find(item => item.type === 'image')?.value as string) ||
        '',
      modules:
        modulesBlock && typeof modulesBlock !== 'string'
          ? {
              title:
                (modulesBlock?.find(item => item.type === 'title')
                  ?.value as string) || '',
              topic:
                (modulesBlock?.find(item => item.type === 'topic')
                  ?.value as string) || '',
              icon:
                (modulesBlock?.find(item => item.type === 'icon')
                  ?.value as string) || '',
              tool:
                (modulesBlock?.find(item => item.type === 'tool')
                  ?.value as string) || '',
              prompt:
                (modulesBlock?.find(item => item.type === 'prompt')
                  ?.value as string) || '',
              gaps:
                (modulesBlock?.find(item => item.type === 'gaps')
                  ?.value as Array<string>) || '',
            }
          : {},
    };
  }, [pageContent]);

  const navigation = useHistory();

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });
  const lottieOnboardingExperiment = useLottieAiExperiment();

  function onNext() {
    OnboardingV2Analytics.onCongratsContinue({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    userApi.setOnboarded(user?.user_id as number).catch(() => {});
    setLocalOnboard(true);
    navigation.replace('/', { from: 'onboarding' });
  }

  function onClose() {
    userApi.setOnboarded(user?.user_id as number).catch(() => {});
    setLocalOnboard(true);
    navigation.replace('/', { from: 'onboarding' });
  }

  useEffect(() => {
    OnboardingV2Analytics.congratsPageView({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiPathwayExperiment]);

  if (!onboardingContent) {
    return <Loader />;
  }

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="congrats-page min-vh-full"
    >
      <div className="congrats-page__header">
        <button
          type="button"
          className="congrats-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              // eslint-disable-next-line max-len
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div className="congrats-page__content">
        <img
          width="112"
          height="112"
          className="congrats-page__image"
          src={pageBlocks.image}
          alt=""
          role="presentation"
        />

        <h1 className="congrats-page__title">{pageBlocks.title}</h1>
        <p className="congrats-page__description">{pageBlocks.description}</p>
      </div>
      {!isEmpty(pageBlocks.modules) && (
        <Carousel
          reviews={[
            {
              tags: pageBlocks.modules.gaps as Array<string>,
              title: pageBlocks.modules.topic as string,
              description: pageBlocks.modules.prompt as string,
              ai: pageBlocks.modules.tool as string,
              icon: pageBlocks.modules.icon as string,
            },
          ]}
          title={pageBlocks.modules.title as string}
        />
      )}

      <div className="congrats-page__actions">
        <UiPrimaryButton onClick={() => onNext()}>
          {t('Continue')}
        </UiPrimaryButton>
      </div>
    </motion.main>
  );
};

interface Card {
  title: string;
  description: string;
  ai: string;
  tags: string[];
  icon: string;
}

const Carousel = ({ reviews, title }: { reviews: Card[]; title: string }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const currentReview = reviews[currentReviewIndex];

  useEffect(() => {
    if (isAnimating) {
      const timeoutId = setTimeout(() => {
        setCurrentReviewIndex(prevIndex => (prevIndex + 1) % reviews.length);
        setIsAnimating(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimating]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setIsAnimating(true);
    }, 3000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const variants = {
    initial: { x: '-100%', z: 0, opacity: 0 },
    animate: { x: 0, z: 0, opacity: 1 },
    exit: { x: '100%', z: 0, opacity: 0 },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '220px' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <h3 className="congrats-page__cards__title">{title} </h3>
        <AnimatePresence custom={1}>
          <motion.div
            key={currentReview.title}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            <div className="congrats-page__card">
              <div>
                <h3>{currentReview.title}</h3>
                <p>{currentReview.description}</p>
              </div>
              <div className="congrats-page_line" />
              <div className="congrats-page__ai_avatar">
                <img
                  width="24"
                  height="24"
                  src={currentReview.icon}
                  role="presentation"
                  alt=""
                />
                <p>{currentReview.ai}</p>
              </div>
              <div style={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
                {currentReview.tags.map(tag => (
                  <div key={tag} className="congrats-page__tag">
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {/* </motion.div>
      </AnimatePresence> */}
    </div>
  );
};

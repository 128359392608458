import { useFeatureValue } from '@growthbook/growthbook-react';
import { AnimatePresence, motion } from 'framer-motion';
import { findIndex, get, map } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  usePatchGoalMutation,
  usePersonalProfileQuery,
} from '../../../../../../../entities/personal-profile';
import { UiLoadingSkeleton } from '../../../../../../ai-bots/shared/ui';
import { GOALS, GOALS_CONTENT } from '../../../../../../personal-goal';
import { UiPrimaryButton } from '../../../../../../shared/ui';
import { OnboardingAnalytics } from '../../../../../shared/analytics';
import { QUIZ_SCREEN_GOAL } from '../../../../../shared/config';
import { QuizButton } from '../../../../../shared/ui';
import './quiz-goal-screen.css';

function getSortedMappedGoalsContent(goal: string) {
  const mappedGoalsContent = map(GOALS_CONTENT, (value, key) => ({
    ...value,
    slug: key,
  }));

  const index = findIndex(mappedGoalsContent, ['slug', goal]);

  if (index >= 0) {
    const element = mappedGoalsContent[index];

    mappedGoalsContent.splice(index, 1);
    mappedGoalsContent.unshift(element);
  }

  return mappedGoalsContent;
}

export function QuizGoalScreen({ events }: { events: { onNext: () => void } }) {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<string>(
    GOALS.GROW_WEALTH
  );
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  const patchGoalMutation = usePatchGoalMutation();
  const personalProfileQuery = usePersonalProfileQuery();
  const goal = useMemo(
    () => get(personalProfileQuery.data, 'properties.goal[0]'),
    [personalProfileQuery.data]
  );

  useEffect(() => {
    if (goal) {
      setSelectedContent(goal);
    }
  }, [goal]);

  const sortedMappedGoalsContent = useMemo(
    () => (goal ? getSortedMappedGoalsContent(goal) : []),
    [goal]
  );

  function showErrorToast() {
    setIsErrorToastVisible(true);

    setTimeout(() => {
      setIsErrorToastVisible(false);
    }, 2000);
  }

  function onSelect(slug: string) {
    OnboardingAnalytics.onQuizAnswerSubmit({
      question: QUIZ_SCREEN_GOAL,
      answer: slug,
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
    setSelectedContent(slug);
  }

  function onSubmit() {
    if (goal !== selectedContent) {
      patchGoalMutation
        .mutateAsync(selectedContent)
        .then(() => {
          events.onNext();
        })
        .catch(cause => {
          showErrorToast();

          throw new Error('ERROR_ONBOARDING_UPDATE_GOAL', { cause });
        });
    } else {
      events.onNext();
    }
  }

  return (
    <div className="quiz-goal-screen quiz-page__screen">
      <h2 className="quiz-goal-screen__title quiz-page__title">
        {t('onboarding.quiz-page.goal-screen.title')}
      </h2>
      <p className="quiz-goal-screen__description quiz-page__description">
        {t('onboarding.quiz-page.goal-screen.description')}
      </p>

      <div className="quiz-goal-screen__content quiz-page__screen-content quiz-page__screen-content--with-spacing">
        {personalProfileQuery.isLoading
          ? map(GOALS_CONTENT, (_, key) => (
              <UiLoadingSkeleton
                key={key}
                style={{ width: '100%', height: '58px' }}
              />
            ))
          : sortedMappedGoalsContent.map(item => (
              <QuizButton
                key={`quiz-goal-screen-content-${item.slug}`}
                isActive={selectedContent === item.slug}
                isDisabled={patchGoalMutation.isLoading}
                events={{
                  onClick: () => onSelect(item.slug),
                }}
              >
                <span className="quiz-goal-screen__quiz-button-content">
                  {item.icon && (
                    <img
                      width="24"
                      height="24"
                      src={item.icon}
                      alt=""
                      role="presentation"
                    />
                  )}
                  {item.title}
                </span>
              </QuizButton>
            ))}
      </div>

      <div className="quiz-page__actions">
        <AnimatePresence>
          {isErrorToastVisible && (
            <motion.div
              key="quiz-goal-screen-error-toast"
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              exit={{ y: -20, opacity: 0 }}
              className="quiz-page__error"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
              >
                <path
                  fill="#D43333"
                  d="M8 2.667a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 8 2.667ZM1.333 8a6.667 6.667 0 1 1 13.334 0A6.667 6.667 0 0 1 1.334 8Zm3.862-2.805c.26-.26.683-.26.943 0L8 7.057l1.862-1.862a.667.667 0 1 1 .943.943L8.943 8l1.862 1.862a.667.667 0 1 1-.943.943L8 8.943l-1.862 1.862a.667.667 0 0 1-.943-.943L7.057 8 5.195 6.138a.667.667 0 0 1 0-.943Z"
                />
              </svg>
              <strong>{t('ai-bots.default-error')}</strong>
            </motion.div>
          )}
        </AnimatePresence>

        {!personalProfileQuery.isLoading && (
          <UiPrimaryButton
            disabled={patchGoalMutation.isLoading}
            onClick={onSubmit}
          >
            {patchGoalMutation.isLoading
              ? t('guides.loading')
              : t('onboarding.quiz-page.goal-screen.action-button')}
          </UiPrimaryButton>
        )}
      </div>
    </div>
  );
}

import { useQuery } from '@tanstack/react-query';
import { extend } from 'lodash-es';

import { ChallengeLessonAPI } from './api';

export function useChallengeLessonQuery(props: {
  challengeId: string;
  taskId: string;
}) {
  return useQuery({
    queryKey: ['challenge-lesson-query', props.challengeId, props.taskId],
    queryFn: () =>
      ChallengeLessonAPI.getLesson(props.challengeId, props.taskId).then(
        res => res.data
      ),
    select(data) {
      // TODO: add type to api
      return extend(data, {
        type: 'theory',
      });
    },
    staleTime: Infinity,
  });
}

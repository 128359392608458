import './ui-secondary-button.css';

export function UiSecondaryButton({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: string | JSX.Element | JSX.Element[];
}) {
  return (
    <button
      type="button"
      className="ui-secondary-button"
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
}

import { isEmpty, trim } from 'lodash-es';
import { FormEvent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiLoadingSkeleton, UiRetryButton } from '../../../shared/ui';
import { UiSpinner } from '../../../shared/ui/spinner';
import { scrollToBottom } from '../../../shared/utils';
import './chat-form.css';

export function ChatForm({
  newMessage,
  isPendingAnswer,
  isMessagesLoading,
  onSubmit,
  onFormClick,
  onRegeneratePrompt,
}: {
  newMessage: { text: string; errorMessage: string } | null;
  isPendingAnswer: boolean;
  isMessagesLoading?: boolean;
  onSubmit: (text: string) => void;
  onFormClick?: () => void;
  onRegeneratePrompt: () => void;
}) {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const textField = useRef<HTMLParagraphElement>(null);

  const isFormFieldActive = !isEmpty(message);
  const isMessageError = Boolean(newMessage?.errorMessage);

  function clearTextField() {
    if (textField && textField.current) {
      textField.current.innerText = '';
    }
  }

  function handleDeleteKey(e: KeyboardEvent<HTMLParagraphElement>) {
    const target = e.target as HTMLParagraphElement;
    if (trim(target.innerText) === '') {
      clearTextField();
    }
  }

  function onKeyUp(e: KeyboardEvent<HTMLParagraphElement>) {
    switch (e.code) {
      case 'Backspace':
        handleDeleteKey(e);
        return;
    }
  }

  function onTextFieldInput(e: FormEvent<HTMLParagraphElement>) {
    const target = e.target as HTMLParagraphElement;
    setMessage(trim(target.innerText));
  }

  function focusToTextField() {
    onFormClick?.();
    textField.current?.focus?.();
  }

  function blurTextField() {
    textField.current?.blur?.();
  }

  function submitMessage() {
    clearTextField();
    blurTextField();

    onSubmit(message);
    scrollToBottom();
    setMessage('');
  }

  if (isMessagesLoading) {
    return (
      <div className="chat-form">
        <UiLoadingSkeleton
          style={{
            height: '49px',
            width: '100%',
            borderRadius: '1rem',
          }}
        />
      </div>
    );
  }

  if (isMessageError) {
    return (
      <div className="chat-form">
        <UiRetryButton
          text={t('ai-bots.chat.regenerate-button')}
          onRetry={() => onRegeneratePrompt()}
        />
      </div>
    );
  }

  return (
    <div className="chat-form">
      <div className="chat-form__input-container" onClick={focusToTextField}>
        <p
          ref={textField}
          contentEditable={!isPendingAnswer}
          className="chat-form__input"
          data-placeholder={
            isPendingAnswer
              ? t('ai-bots.chat.loading')
              : t('ai-bots.chat.input-placeholder')
          }
          onKeyUp={e => onKeyUp(e)}
          onInput={e => onTextFieldInput(e)}
        ></p>

        {isPendingAnswer && <UiSpinner className="chat-form__spinner" />}

        <button
          type="button"
          disabled={!isFormFieldActive}
          className="chat-form__send-button"
          style={{ opacity: isFormFieldActive ? '1' : '0' }}
          onClick={submitMessage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="currentColor"
              d="M4.827 4.06a.571.571 0 0 0-.816.623l1.604 5.543a.572.572 0 0 0 .453.405l6.503 1.089c.307.06.307.5 0 .56l-6.503 1.09a.572.572 0 0 0-.453.404L4.01 19.317a.571.571 0 0 0 .816.623l14.858-7.43a.572.572 0 0 0 0-1.02L4.827 4.06Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

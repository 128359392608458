import { MessageError } from './MessageError';
import { MessageLoading } from './MessageLoading';
import './assets/ai-bot-message.css';
import CustomerAvatarIcon from './assets/customer-avatar.svg';

export function NewMessage({
  newMessage,
}: {
  newMessage: { text: string; errorMessage: string } | null;
}) {
  return (
    newMessage && (
      <div className="ai-bot-new-message">
        <div className="ai-bot-message">
          <img
            width="24"
            height="24"
            src={CustomerAvatarIcon}
            role="presentation"
            alt=""
          />
          <p>{newMessage.text}</p>
        </div>
        {newMessage.errorMessage ? (
          <MessageError text={newMessage.errorMessage} />
        ) : (
          <MessageLoading />
        )}
      </div>
    )
  );
}

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DialogContainer, Wrapper } from './WarningModal.styles';

export default function WarningModal({
  warningModalState,
  closeWarningModal,
}: any) {
  const { t } = useTranslation();
  const navigation = useHistory();

  function handleClose() {
    closeWarningModal();

    navigation.push('/auth/signin');
  }

  return (
    <Modal
      open={warningModalState}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <DialogContainer>
          <p className="title">
            {t('Looks like you have already registered 👀')}
          </p>
          <button type="button" className="btn-confirm" onClick={handleClose}>
            {t('Sign In')}
          </button>
        </DialogContainer>
      </Wrapper>
    </Modal>
  );
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ChallengeLessonsProgressAPI } from './api';

export function useChallengeLessonsProgress(props: { challengeId: string }) {
  return useQuery({
    queryKey: ['challenge-lessons-progress', props.challengeId],
    queryFn: () =>
      ChallengeLessonsProgressAPI.getProgress(props.challengeId).then(
        res => res.data
      ),
    staleTime: Infinity,
  });
}

export function useChallengeLessonSaveProgressMutation(props: {
  challengeId: string;
  taskId: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      'challenge-lessons-save-progress',
      props.challengeId,
      props.taskId,
    ],
    mutationFn: () =>
      ChallengeLessonsProgressAPI.saveProgress(
        props.challengeId,
        props.taskId
      ).then(res => res.data),
    onSuccess() {
      return queryClient.invalidateQueries([
        'challenge-lessons-progress',
        props.challengeId,
      ]);
    },
  });
}

export function useForceOpenChallengeMutation(props: { challengeId: string }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['challenge-force-open', props.challengeId],
    mutationFn: () =>
      ChallengeLessonsProgressAPI.forceOpen(props.challengeId).then(
        res => res.data
      ),
    onSuccess() {
      return queryClient.invalidateQueries([
        'challenge-lessons-progress',
        props.challengeId,
      ]);
    },
  });
}

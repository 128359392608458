import { fill, isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GuideLessonDetailsContentType,
  GuideLessonDetailsType,
} from '../../../entities/guide-lesson';
import { useGuideLessonFillTheGapStore } from '../../../entities/guide-lesson-fill-the-gap';
import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { GuideErrorFeedback, GuideSuccessFeedback } from '../../guide-feedback';
import { useFillInState } from '../hooks/use-fillin-state';
import { FillInActionType, PlaceHolder } from '../lib/types';
import { getFillInParts } from '../utils';
import { FillTheGapModalActions } from './FillTheGapModalActions';
import { FillTheGapModalContent } from './FillTheGapModalContent';
import { FillTheGapModalHeader } from './FillTheGapModalHeader';
import './fill-the-gap-modal.css';

export function FillTheGapModal({
  guideType,
  task,
  events,
}: {
  pageId: GuideLessonDetailsContentType['id'];
  type: GuideLessonDetailsType['type'];
  guideType: GuideLessonDetailsType['type'];
  task: GuideLessonDetailsFillTheGapType;
  events: {
    onClose: () => void;
    onExerciseToastTrigger?: (isVisible: boolean) => void;
    onSubmitMessage?: (isRetrySubmission: boolean) => void;
    onReceivesAnswer?: (props: {
      isSuccess: boolean;
      isSubmissionCorrect: boolean;
    }) => void;
  };
}) {
  const chatListRef = useRef<HTMLDivElement>(null);

  const [isChecked, setIsChecked] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const taskGaps = task.content[0].gaps;

  const { t } = useTranslation();

  const initFillInParts = getFillInParts(task);

  const { addCompletedFillTheGap } = useGuideLessonFillTheGapStore();

  const { fillInState, dispatch } = useFillInState({
    fillInParts: initFillInParts,
    answers: [],
    activePlaceholder: initFillInParts?.find(item => item.placeholder) || null,
  });

  const isPracticeGuide = guideType === 'practice';

  const onTaskCheck = () => {
    const sortedAnswers = [...fillInState.answers].sort((a, b) => {
      const aId = a?.placeholder?.id ?? 0;
      const bId = b?.placeholder?.id ?? 0;
      return aId - bId;
    });

    const fillInAnswers = sortedAnswers.map(item => item.answer);

    const isCorrect = isEqual(fillInAnswers, taskGaps);
    setIsCorrectAnswer(isCorrect);
    if (isCorrect) {
      addCompletedFillTheGap(task.id);
    }
    setIsChecked(true);
  };
  const onOptionClick = (answer: string) => {
    dispatch({ type: FillInActionType.ADD_USER_ANSWER, payload: answer });
  };

  const onBackspaceClick = () => {
    dispatch({
      type: FillInActionType.DESELECT_LAST_ANSWER,
    });
  };

  const onPlaceholderClick = (placeholder: PlaceHolder) => {
    dispatch({
      type: FillInActionType.DESELECT_USER_ANSWER,
      payload: placeholder || '',
    });
  };
  const scrollToBottom = () => {
    if (chatListRef.current) {
      setTimeout(() => {
        chatListRef.current!.scrollTo({
          top: chatListRef.current!.scrollHeight,
          behavior: 'smooth',
        });
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [fillInState]);

  const onRetry = () => {
    dispatch({
      type: FillInActionType.RESET,
    });
    setIsChecked(false);
  };

  const onTaskCompleted = () => {
    events.onClose();
    addCompletedFillTheGap(task.id);
  };

  useEffect(() => {
    return () => {
      if (!isPracticeGuide) {
        dispatch({
          type: FillInActionType.RESET,
        });
      }
    };
  }, []);

  return (
    <main className="vh-full guide-fill-the-gap-modal">
      <section className="guide-fill-the-gap-modal__content">
        <div
          ref={chatListRef}
          className="guide-fill-the-gap-modal__scrollable-content"
        >
          <FillTheGapModalHeader task={task} onClose={events.onClose} />
          <FillTheGapModalContent
            task={task}
            isChecked={isChecked}
            isCorrectAnswer={isCorrectAnswer}
            onPlaceholderClick={onPlaceholderClick}
            fillInState={fillInState}
            scrollToBottom={scrollToBottom}
          />
        </div>
        <FillTheGapModalActions
          taskGaps={taskGaps}
          fillInState={fillInState}
          isChecked={isChecked}
          onTaskCheck={onTaskCheck}
          onOptionClick={onOptionClick}
          onBackspaceClick={onBackspaceClick}
        />
        {!isCorrectAnswer && isChecked && (
          <GuideErrorFeedback
            feedback={`${t('fill-the-gap.incorrect-answer')} ${taskGaps.join(
              ', '
            )}`}
            onRetry={onRetry}
          />
        )}
        {isCorrectAnswer && isChecked && (
          <GuideSuccessFeedback
            feedback={t('fill-the-gap.correct-answer') as string}
            onContinue={onTaskCompleted}
          />
        )}
      </section>
    </main>
  );
}

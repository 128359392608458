export function ChallengesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
      <path
        fill="currentColor"
        stroke="currentColor"
        d="m15.054 8.804.146-.147v-1.35l2.6-2.6V6.7h1.993l-2.6 2.6h-1.35l-.147.146-2.295 2.295-.218.219.098.293c.015.046.019.1.019.247a1.3 1.3 0 1 1-1.3-1.3c.146 0 .2.004.247.02l.293.097.219-.218 2.295-2.295ZM8.747 4.647A8.5 8.5 0 0 1 12 4c.715 0 1.427.094 2.12.273l-.67.67A7.463 7.463 0 0 0 12 4.8a7.7 7.7 0 1 0 7.7 7.7c0-.486-.047-.971-.142-1.45l.665-.666c.183.688.277 1.4.277 2.116A8.5 8.5 0 1 1 8.747 4.647Zm-.212 4.388a4.9 4.9 0 0 1 2.965-1.41v.806A4.1 4.1 0 1 0 16.07 13h.804a4.9 4.9 0 1 1-8.339-3.965Z"
      />
    </svg>
  );
}

import { useQuery, useQueryClient } from '@tanstack/react-query';

import i18n from '../../../i18n';
import { OnboardingContentAPI } from '../api';

export function useOnboardingContent(arrow: string) {
  return useQuery({
    queryKey: ['onboarding-content', i18n.language, arrow],
    queryFn: () =>
      OnboardingContentAPI.getOnboardingContent(i18n.language, arrow).then(
        res => res.data
      ),
    staleTime: Infinity,
  });
}

export function usePrefetchOnboardingContent(arrow: string) {
  const queryClient = useQueryClient();

  function prefetchOnboardingContent() {
    return queryClient.prefetchQuery({
      queryKey: ['onboarding-content', i18n.language, arrow],
      queryFn: () =>
        OnboardingContentAPI.getOnboardingContent(i18n.language, arrow).then(
          res => res.data
        ),
    });
  }

  return {
    prefetchOnboardingContent,
  };
}

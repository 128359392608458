import { useQuery } from '@tanstack/react-query';

import { UpsellCascadeAPI } from '../api';
import { UPSELL_CASCADE_QUERY_KEY } from '../config';

export function useUpsellCascadeQuery(paywallConfigId?: string) {
  return useQuery({
    queryKey: UPSELL_CASCADE_QUERY_KEY,
    queryFn: () =>
      UpsellCascadeAPI.getOffers(paywallConfigId).then(({ data }) => data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

import ThumbsUpActive from './assets/thumbs-up-active.svg';
import ThumbsUp from './assets/thumbs-up.svg';

function RatingAnswer({
  isNegative,
  isActive,
  onClick,
}: {
  isNegative?: boolean;
  isActive: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={`
        transition-all
        hover:opacity-80
        bg-[#E2E5E9]
        rounded-full 
        p-2 
        flex 
        justify-center 
        items-center
        ${isActive ? 'bg-[#EEF]' : 'bg-[#E2E5E9]'}
        ${isNegative ? 'rotate-180' : ''}
      `}
    >
      <img src={isActive ? ThumbsUpActive : ThumbsUp} alt="" />
    </button>
  );
}

export default RatingAnswer;

import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import SurveyImage from '../../../../../images/guides/survey.svg';
import SurveyIcon1 from '../../../../../images/guides/survey/1.png';
import SurveyIcon2 from '../../../../../images/guides/survey/2.png';
import SurveyIcon3 from '../../../../../images/guides/survey/3.png';
import SurveyIcon4 from '../../../../../images/guides/survey/4.png';
import SurveyIcon5 from '../../../../../images/guides/survey/5.png';
import { UiPrimaryButton } from '../../../../shared/ui';
import { ChallengesAnalytics } from '../../../shared/analytics';
import './survey-page.css';

const ICONS = [SurveyIcon1, SurveyIcon2, SurveyIcon3, SurveyIcon4, SurveyIcon5];
const MAX_CHAR = 150;

export function ChallengeSurveyPage() {
  const { t } = useTranslation();

  const navigation = useHistory();

  const { challengeId, taskId }: { challengeId: string; taskId: string } =
    useParams();

  const [contentRef] = useAutoAnimate();

  const [clickedAnswerIndex, setClickedAnswerIndex] = useState(-1);

  const [comment, setComment] = useState('');

  const isCommentVisible = clickedAnswerIndex < 3 && clickedAnswerIndex >= 0;

  const commentCharLength = MAX_CHAR - comment.length;

  useEffect(() => {
    ChallengesAnalytics.onSurveyPageView({
      challengeId,
      taskId,
    });
  }, []);

  function onSetComment(e: ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length <= MAX_CHAR) {
      setComment(e.target.value);
    }
  }

  function onRateClick(idx: number) {
    setClickedAnswerIndex(idx);

    ChallengesAnalytics.onRateClick({
      challengeId,
      taskId,
      rating: idx + 1,
    });
  }

  function onClose() {
    navigation.replace(`/challenges/${challengeId}/tasks`);

    ChallengesAnalytics.onCloseSurveyPage({
      challengeId,
      taskId,
    });
  }

  function onContinue() {
    if (comment) {
      ChallengesAnalytics.onSubmitFeedback({
        challengeId,
        taskId,
        rating: clickedAnswerIndex + 1,
        feedback: comment,
      });
    }

    navigation.replace(`/challenges/${challengeId}/tasks`);
  }

  return (
    <main className="survey-page min-vh-full">
      <div className="survey-page__header">
        <button
          type="button"
          className="survey-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div ref={contentRef} className="survey-page__content">
        <img
          width="168"
          height="168"
          className="survey-page__image"
          src={SurveyImage}
          alt=""
          role="presentation"
        />
        <h1 className="survey-page__title">{t('guides.survey.title')}</h1>
        <div className="survey-page__options">
          <div className="survey-page__options-container">
            {ICONS.map((icon, idx) => (
              <button
                key={idx}
                type="button"
                className={classNames(
                  'survey-page__option',
                  clickedAnswerIndex === idx
                    ? 'survey-page__option--active'
                    : ''
                )}
                onClick={() => onRateClick(idx)}
              >
                <img
                  width="30"
                  height="30"
                  className="survey-page__option-icon"
                  src={icon}
                  alt=""
                />
              </button>
            ))}
          </div>
          <div className="survey-page__options-description">
            <span className="survey-page__options-bad">
              {t('guides.survey.bad')}
            </span>
            <span className="survey-page__options-good">
              {t('guides.survey.good')}
            </span>
          </div>
        </div>

        {isCommentVisible && (
          <div className="survey-page__comment">
            <h4 className="survey-page__comment-title">
              {t('guides.survey.comment.title')}
            </h4>
            <textarea
              maxLength={MAX_CHAR}
              value={comment}
              className="survey-page__comment-area"
              placeholder={t('guides.survey.comment.placeholder') as string}
              onChange={e => onSetComment(e)}
            />
            <h6 className="survey-page__comment-counter">
              {commentCharLength}/{MAX_CHAR}
            </h6>
          </div>
        )}
      </div>

      <div className="survey-page__actions">
        <UiPrimaryButton onClick={onContinue}>
          {t('guides.continue-button')}
        </UiPrimaryButton>
      </div>
    </main>
  );
}

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import error_cosmo from '../../../images/error_cosmo.svg';
import { ErrorFallbackContainer, StyledButton } from './ErrorFallback.styles';

// import { analyticsService } from "../../../services/analytics.service";
// import { useLocation } from "react-router-dom";

const ErrorFallback: FC = () => {
  const { t } = useTranslation();
  // const location = useLocation();

  const handleClick = () => {
    if (typeof window === 'undefined' || typeof window.location === 'undefined')
      return;

    window.location.reload();
  };

  // useEffect(() => {
  //   analyticsService.sendEvent("pr_webapp_oops_page", {
  //     path: location.pathname
  //   });
  // }, []);

  return (
    <ErrorFallbackContainer>
      <img src={error_cosmo} alt="" />
      <p className="title">{t('Ooops! Something went wrong')}</p>
      <p className="subtitle">
        {t('We are working to resolve the problem as quickly as we could')}
      </p>
      <StyledButton>
        <button onClick={handleClick}>{t('Refresh page')}</button>
      </StyledButton>
    </ErrorFallbackContainer>
  );
};

export default ErrorFallback;

import { motion } from 'framer-motion';
import { get, map } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ReviewCard } from '../../../../../entities/review-card';
import { SuccessToast } from '../../../../../entities/success-toast';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton, UiNavigation } from '../../../../ai-bots/shared/ui';
import { AppFooter } from '../../../../shared';
import { UiPrimaryButton } from '../../../../shared/ui';
import { useChallengeInfoQuery } from '../../../entities/info';
import { useChallengesListProgressQuery } from '../../../entities/progress';
import { ChallengesAnalytics } from '../../../shared/analytics';
import './challenges-info-page.css';

let successToastTimeout: ReturnType<typeof setTimeout>;

export function ChallengeInfoPage() {
  const navigation = useHistory();
  const { t } = useTranslation();
  const { challengeId } = useParams<{ challengeId: string }>();
  const challengeInfoQuery = useChallengeInfoQuery(challengeId);
  const challengeListProgressQuery = useChallengesListProgressQuery();

  const [isJoinWaitlistToastVisible, setIsJoinWaitlistToastVisible] =
    useState(false);

  const isJoinedToChallengeWaitlist = useMemo(() => {
    return challengeListProgressQuery.data && challengeInfoQuery.data
      ? Boolean(
          get(challengeListProgressQuery.data, challengeInfoQuery.data.id)
            ?.is_joined
        )
      : false;
  }, [challengeListProgressQuery.data, challengeInfoQuery.data]);

  const isReleased = useMemo(
    () => Boolean(challengeInfoQuery.data?.is_released),
    [challengeInfoQuery.data]
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (challengeInfoQuery.data && challengeListProgressQuery.data) {
      ChallengesAnalytics.infoPageView({
        challenge_id: challengeId,
      });
    }
  }, [challengeInfoQuery.data, challengeListProgressQuery.data]);

  function hideJoinWaitlistToast() {
    clearTimeout(successToastTimeout);
    setIsJoinWaitlistToastVisible(false);
  }

  function showJoinWaitlistToast() {
    clearTimeout(successToastTimeout);

    setIsJoinWaitlistToastVisible(true);

    successToastTimeout = setTimeout(() => {
      setIsJoinWaitlistToastVisible(false);
    }, 2000);
  }

  function onClickToJoinButton() {
    ChallengesAnalytics.onJoinButtonClick({
      challenge_id: challengeId,
      is_waitlist_joined: isJoinWaitlistToastVisible,
      is_released: isReleased,
    });

    if (!isReleased) {
      return showJoinWaitlistToast();
    }

    if (isJoinedToChallengeWaitlist) {
      navigation.push(`/challenges/${challengeId}/tasks`);
    } else {
      const taskId = get(challengeInfoQuery.data, 'tasks[0].id');
      navigation.push(
        `/challenges/${challengeId}/${taskId}?guideType=onboarding`
      );
    }
  }

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main className="ui-base-layout min-vh-full challenges-info-page">
        <UiNavigation
          title={
            challengeInfoQuery.isLoading ? (
              <UiLoadingSkeleton
                style={{
                  height: '24px',
                  width: '150px',
                }}
              />
            ) : (
              challengeInfoQuery.data?.name ?? ''
            )
          }
          className="challenges-info-page__navigation"
          onBackButtonClicked={() => navigation.replace('/challenges')}
        />

        {challengeInfoQuery.isError ? (
          <div className="ui-base-layout__content">
            <FullPageError onRetry={() => challengeInfoQuery.refetch()} />
          </div>
        ) : (
          <>
            <header className="challenges-info-page__header">
              <div className="challenges-info-page__image">
                {challengeInfoQuery.isLoading ? (
                  <UiLoadingSkeleton
                    style={{
                      borderRadius: 'none',
                      width: '100%',
                      height: '200px',
                    }}
                  />
                ) : (
                  <img
                    width="100%"
                    height="200"
                    src={challengeInfoQuery.data?.image_v2}
                    role="presentation"
                    alt=""
                  />
                )}
              </div>

              <div className="challenges-info-page__header-content">
                {challengeInfoQuery.isLoading ? (
                  <>
                    <UiLoadingSkeleton
                      style={{
                        height: '28px',
                        width: '240px',
                      }}
                    />
                    <UiLoadingSkeleton
                      style={{
                        marginTop: '8px',
                        height: '24px',
                        width: '150px',
                      }}
                    />
                  </>
                ) : (
                  <>
                    {!isReleased && (
                      <div className="challenges-info-page__coming-soon">
                        {t('challenges.coming-soon')}
                      </div>
                    )}
                    <h2 className="challenges-info-page__title">
                      {challengeInfoQuery.data?.name}
                    </h2>
                    <p className="challenges-info-page__description">
                      {challengeInfoQuery.data?.description}
                    </p>

                    <UiPrimaryButton
                      className="challenges-info-page__join-button"
                      onClick={onClickToJoinButton}
                    >
                      {!isReleased
                        ? t('challenges.join-waitlist-button')
                        : t('challenges.join-button')}
                    </UiPrimaryButton>
                  </>
                )}
              </div>
            </header>

            <section className="ui-base-layout__content challenges-info-page__content">
              {challengeInfoQuery.isLoading ? (
                <UiLoadingSkeleton
                  style={{
                    height: '28px',
                    width: '200px',
                    marginBottom: '16px',
                  }}
                />
              ) : (
                <h3 className="challenges-info-page__subtitle">
                  {t('challenges.info-page.title')}
                </h3>
              )}

              <div className="challenges-info-page__info-cards">
                {challengeInfoQuery.isLoading ? (
                  <>
                    <UiLoadingSkeleton
                      style={{
                        borderRadius: '8px',
                        height: '72px',
                        width: '100%',
                      }}
                    />
                    <UiLoadingSkeleton
                      style={{
                        borderRadius: '8px',
                        height: '72px',
                        width: '100%',
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div className="challenges-info-page__card">
                      <h5>{t('challenges.info-page.duration')}</h5>
                      <div className="challenges-info-page__card-content">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                        >
                          <path
                            fill="#5653FE"
                            d="M7.999 2.666a5.333 5.333 0 1 0 0 10.667 5.333 5.333 0 0 0 0-10.667ZM1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8Zm6.667-4c.368 0 .666.298.666.666v3.058l1.805 1.804a.667.667 0 1 1-.943.943l-2-2A.667.667 0 0 1 7.332 8V4.666c0-.368.299-.666.667-.666Z"
                          />
                        </svg>

                        <strong>{challengeInfoQuery.data?.duration}</strong>
                      </div>
                    </div>

                    <div className="challenges-info-page__card">
                      <h5>{t('challenges.info-page.level')}</h5>
                      <div className="challenges-info-page__card-content">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                        >
                          <rect
                            width="3.333"
                            height="5.333"
                            x="1.332"
                            y="9.333"
                            fill="#5653FE"
                            rx="1.667"
                          />
                          <rect
                            width="3.333"
                            height="8"
                            x="6.668"
                            y="6.667"
                            fill="#E5E7EB"
                            rx="1.667"
                          />
                          <rect
                            width="3.333"
                            height="12.667"
                            x="12"
                            y="2"
                            fill="#E5E7EB"
                            rx="1.667"
                          />
                        </svg>

                        <strong>{challengeInfoQuery.data?.level}</strong>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {challengeInfoQuery.isLoading ? (
                <>
                  <UiLoadingSkeleton
                    style={{
                      height: '28px',
                      width: '220px',
                    }}
                  />
                  <UiLoadingSkeleton
                    style={{
                      marginTop: '16px',
                      height: '144px',
                      width: '100%',
                    }}
                  />
                </>
              ) : (
                <>
                  <h3 className="challenges-info-page__subtitle">
                    {t('challenges.info-page.how-it-works-title')}
                  </h3>

                  <p className="challenges-info-page__description">
                    {challengeInfoQuery.data?.detail}
                  </p>
                </>
              )}

              <div className="challenges-info-page__reviews">
                {challengeInfoQuery.isLoading ? (
                  <>
                    <UiLoadingSkeleton
                      style={{
                        height: '28px',
                        width: '240px',
                      }}
                    />

                    <div className="challenges-info-page__reviews-list">
                      {map(Array(3).fill(''), (_, i) => (
                        <UiLoadingSkeleton
                          key={`challenge-review-${i}`}
                          style={{
                            height: '167px',
                            width: '312px',
                          }}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="challenges-info-page__title">
                      {t('challenges.info-page.reviews.title')}
                    </h2>

                    <div className="challenges-info-page__reviews-list">
                      {map(challengeInfoQuery.data?.reviews, (review, i) => (
                        <ReviewCard
                          key={`challenge-review-${i}`}
                          name={review.name}
                          text={review.review}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </section>
          </>
        )}

        <SuccessToast
          isVisible={isJoinWaitlistToastVisible}
          events={{ onHide: hideJoinWaitlistToast }}
        >
          <>{t('challenges.join-waitlist-success-description')}</>
        </SuccessToast>

        <AppFooter />
      </main>
    </motion.section>
  );
}

import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  showCheckMailModal,
  showCongratulationsOnReset,
  showLoadingSpinner,
  showResetPasswordForm,
} from '../../../../context/atoms/GlobalPopup';
import { IUserState } from '../../../../context/atoms/User';
import x_mark from '../../../../images/x_mark.svg';
import { analyticsService } from '../../../../services/analytics.service';
import cognitoService from '../../../../services/cognito.service';
import {
  BtnContainer,
  DialogContainer,
  Header,
  HeaderIcon,
  ResendCodeButton,
  SmallError,
  Title,
  Wrapper,
} from './CheckMailModal.styles';

export default function CheckMailModal() {
  const { t } = useTranslation();

  const [IUser, setIUser] = useRecoilState(IUserState);
  const [code, setCode] = useState('');
  const [resendMessage, setResendMessage] = useState('');
  const [showCheckMail, setShowCheckMail] = useRecoilState(showCheckMailModal);
  const setLoading = useSetRecoilState(showLoadingSpinner);
  const setShowPasswordResetForm = useSetRecoilState(showResetPasswordForm);
  const setShowCongratsOnReset = useSetRecoilState(showCongratulationsOnReset);
  let errorMsg = '';

  const handleCodeVerify = () => {
    analyticsService.sendEvent('pr_funnel_reset_password_code_enter');

    setLoading(true);
    const newCheckMailState = {
      open: showCheckMail.open,
      errorMessage: '',
    };

    setShowCheckMail(newCheckMailState);
    setResendMessage(t('Resend confirmation code')!);

    const newUser = {
      email: IUser.email,
      password: IUser.password,
      code: code,
      username: IUser.username,
      user_id: IUser.user_id,
    };
    setIUser(newUser);

    if (!!IUser.password) {
      submitForgot();
      setCode('');
    } else {
      setCode('');
      setShowPasswordResetForm(true);
      const newCheckMailState = {
        open: false,
        errorMessage: '',
      };
      setShowCheckMail(newCheckMailState);
      setLoading(false);
    }
  };

  const submitForgot = () => {
    cognitoService
      .forgotPasswordSubmit(IUser.email, code, IUser.password)
      .then(() => {
        analyticsService.sendEvent('pr_funnel_reset_password_finish', {
          email: IUser.email,
          success: true,
        });
        signIn();
      })
      .catch((error: any) => {
        analyticsService.sendEvent('pr_funnel_reset_password_finish', {
          email: IUser.email,
          success: false,
          error: error.message,
        });

        const newCheckMailState = {
          open: true,
          errorMessage: error.message,
        };
        setShowCheckMail(newCheckMailState);
        setLoading(false);
      });
  };

  const signIn = () => {
    cognitoService.cleanUpCognitoCookies();
    cognitoService
      .signIn(IUser.email, IUser.password)
      .then(() => {
        cognitoService.getSession().then(() => {
          const newCheckMailState = {
            open: false,
            errorMessage: '',
          };
          setShowCheckMail(newCheckMailState);
          setLoading(false);
          setShowCongratsOnReset(true);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    const newCheckMailState = {
      open: false,
      errorMessage: '',
    };
    setShowCheckMail(newCheckMailState);
  };

  const resendConfirmation = () => {
    setLoading(true);
    const newCheckMailState = {
      open: true,
      errorMessage: t('A new verification code was sent to your email'),
    };
    setShowCheckMail(newCheckMailState);

    initiateForgot();
  };

  const initiateForgot = () => {
    analyticsService.sendEvent('pr_funnel_reset_password_initiate', {
      email: IUser.email,
    });
    setLoading(true);

    cognitoService
      .forgotPassword(IUser.email)
      .then(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        switch (error.code) {
          case 'UserNotFoundException':
          case 'InvalidParameterException':
            errorMsg = t("We couldn't find a user with this email");
            break;
          default:
            errorMsg = error.message;
            break;
        }

        const newCheckMailState = {
          open: true,
          errorMessage: errorMsg,
        };
        setShowCheckMail(newCheckMailState);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={showCheckMail.open}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Header>
          <span></span>
          <HeaderIcon onClick={handleClose}>
            <img src={x_mark} alt="" width={'24px'} height={'24px'} />
          </HeaderIcon>
        </Header>

        <DialogContainer>
          <div className="dialog-container">
            <Title>{t('Check your mail')}</Title>

            <p className="subtitle">{t("We've sent a confirmation code")}</p>

            <VerificationInput
              validChars="0-9"
              placeholder=""
              value={code}
              onChange={(e: any) => setCode(e)}
              classNames={{
                container: 'code-container',
                character: 'code-character',
                characterInactive: 'code-character--inactive',
                characterSelected: 'code-character--selected',
              }}
            />

            {!!showCheckMail.errorMessage && (
              <SmallError>{showCheckMail.errorMessage}</SmallError>
            )}

            <BtnContainer>
              <button
                type="button"
                className="btn-confirm"
                onClick={handleCodeVerify}
                disabled={code.length < 6}
              >
                {t('Verify')}
              </button>

              <ResendCodeButton onClick={resendConfirmation}>
                {resendMessage}
              </ResendCodeButton>
            </BtnContainer>
          </div>
        </DialogContainer>
      </Wrapper>
    </Modal>
  );
}

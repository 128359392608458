import type { AiBot } from '../../../entities/ai-bot';
import type { AiBotNewMessage } from '../../../entities/message';

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep, size } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
  prefetchSessionMessages,
  useCreateMessageMutation,
  useCreateSessionMutation,
} from '../../../entities/session';
import { setInitialSessionDetailsData } from '../../../entities/session/model';
import { useChatPageAnalytics } from '../../../shared/analytics';

import { ChatContainer } from './ChatContainer';

export const AiBotNewChat = ({
  chatBotId,
  onShowInfo,
}: {
  chatBotId: string;
  onShowInfo?: (info: AiBot) => void;
}) => {
  const { t } = useTranslation();
  const navigation = useHistory();
  const queryClient = useQueryClient();
  const [newMessage, setNewMessage] = useState<AiBotNewMessage | null>(null);
  const createSessionMutation = useCreateSessionMutation({ chatBotId });
  const createMessageMutation = useCreateMessageMutation();
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId });

  useEffect(() => {
    if (
      newMessage
      && (createMessageMutation.isError || createSessionMutation.isError)
    ) {
      setNewMessage({
        ...newMessage,
        errorMessage: t('ai-bots.default-error'),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMessageMutation.isError, createSessionMutation.isError]);

  function onPrefetchSessionMessages(sessionId: string) {
    return prefetchSessionMessages({ queryClient, sessionId }).catch((cause) => {
      // TODO: send event to bug tracker and add chatbot_id, sessionId tag, message id
      throw new Error('ERROR_PREFETCH_SESSION_MESSAGES', { cause });
    });
  }

  function submitSessionMessage(text: string, sessionId: string) {
    return createMessageMutation
      .mutateAsync({ text, sessionId })
      .then(() => onPrefetchSessionMessages(sessionId))
      .then(() => navigation.replace(`/ai-bots/session/${chatBotId}/${sessionId}`))
      .catch((cause) => {
        // TODO: send event to bug tracker and add chatbot_id, sessionId tag
        throw new Error('ERROR_CREATE_NEW_MESSAGE', { cause });
      });
  }

  function createSessionAndSubmitMessage(text: string, isPresetPrompt: boolean) {
    createSessionMutation
      .mutateAsync()
      .then((data) => {
        setInitialSessionDetailsData({ queryClient, data });

        if (isPresetPrompt) {
          ChatPageAnalytics.onClickBubble({ bubbleText: text, promptLength: text.length, chatId: data.id });
        }

        return submitSessionMessage(text, data.id).catch(() => {});
      })
      .catch((cause) => {
        // TODO: handle error newMessage.isError = true
        const error = new Error('ERROR_CREATE_NEW_SESSION', { cause });

        ChatPageAnalytics.onReceivesAnswer({
          status:       'error',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          errorMessage: `${error.message} -> ${cause.message}`,
        });
        // TODO: send event to bug tracker and add chatbot_id tag
        throw error;
      });
  }

  function onSubmitMessage({
    text,
    isResend = false,
    isPresetPrompt = false,
  }: {
    text: string;
    isResend?: boolean;
    isPresetPrompt?: boolean;
  }) {
    setNewMessage({ sessionId: null, text, errorMessage: '' });

    createSessionAndSubmitMessage(text, isPresetPrompt);

    if (!isResend) {
      ChatPageAnalytics.onFormSubmit({
        promptLength: size(text),
      });
    }
  }

  function onRegeneratePrompt() {
    if (newMessage) {
      const message = cloneDeep(newMessage);

      setNewMessage(null);
      onSubmitMessage({ text: message.text, isResend: true });

      ChatPageAnalytics.onFormSubmit({
        resend:       true,
        promptLength: size(message.text),
      });
    }
  }

  function onFormClick() {
    ChatPageAnalytics.onFormClick();
  }

  return (
    <ChatContainer
      chatBotId={chatBotId}
      newMessage={newMessage}
      messages={[]}
      onShowInfo={chatBotDetails => onShowInfo?.(chatBotDetails)}
      onSubmitMessage={(text: string, isPresetPrompt?: boolean) => onSubmitMessage({ text, isPresetPrompt })}
      onRegeneratePrompt={() => onRegeneratePrompt()}
      onFormClick={() => onFormClick()}
    />
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { GuideCertificateAPI } from '../../../../../entities/certificates';
import { GuideType } from '../../../../guides/entities/guide';

export function useCertificateQuery({ id }: { id: GuideType['id'] }) {
  return useQuery({
    queryKey: ['guide-certificate', id],
    queryFn: () =>
      GuideCertificateAPI.getCertificate(id).then(({ data }) => data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useCreateCertificate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-certification-mutation'],
    mutationFn: ({ id }: { id: GuideType['id'] }) =>
      GuideCertificateAPI.createCertificate(id).then(({ data }) => data),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: ['guide-certificate', variables.id],
      }),
  });
}

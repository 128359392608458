import { AiBotTool } from '../../ai-bot/types';
import './ai-bot-card-preset.css';

export function AiBotCardPreset({
  data,
  onClick,
}: {
  data: AiBotTool;
  onClick?: () => void;
}) {
  return (
    <div className="ai-bot-card-preset" onClick={onClick}>
      <div className="">
        <img
          className="ai-bot-card-preset__image w-full object-fit"
          src={data.image || ''} //@note For custom bots add default image (or make image required)
          role="presentation"
          alt=""
        />
      </div>
      <div className="ai-bot-card-preset__content px-3 pb-3 pt-2">
        <h2>{data.name}</h2>
        <p className="ai-bot-card-preset__description">{data.description}</p>
      </div>
    </div>
  );
}

import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import back_arrow from '../../../images/back_arrow.svg';
import cross_icon from '../../../images/cross_icon.svg';
import icon_settings from '../../../images/icon_settings.svg';
import logo from '../../../images/p_main_logo.svg';
import { NavbarIconType } from '../../../types/NavbarType';
import { navbarType } from '../../../types/Types';
import { StyledNavbar } from './Navbar.style';

const Navbar: FC<navbarType> = ({
  routeName,
  backRoute,
  iconType,
  alignLogoCenter,
  callback,
  settings,
  assetLogo,
  isShadow = true,
  isSticky = true,
  isLogoRedirects = true,
}) => {
  const history = useHistory();
  return (
    <StyledNavbar
      alignLogoCenter={alignLogoCenter}
      isShadow={isShadow}
      isSticky={isSticky}
    >
      {iconType && (
        <div
          className="back-closep-icon"
          onClick={() => {
            if (callback) callback();
            backRoute ? history.push(backRoute) : history.goBack();
          }}
        >
          <img
            src={iconType === NavbarIconType.arrow ? back_arrow : cross_icon}
            alt="back arrow"
          />
        </div>
      )}
      {!iconType && !routeName && (
        <div className="logo">
          <img
            src={logo}
            onClick={() => isLogoRedirects && history.push('/')}
            alt="prosperi logo"
          />
        </div>
      )}
      {routeName && (
        <div className="route_name">
          <div className="route-content">
            {assetLogo && <img src={assetLogo} alt="" width={24} height={24} />}
            <p className="route_header">{routeName}</p>
          </div>
        </div>
      )}
      {settings && (
        <div
          className="settings-container"
          onClick={() => {
            history.push('/profile/settings');
          }}
        >
          <img src={icon_settings} alt="" />
        </div>
      )}
      <div className="settings_icon"></div>
    </StyledNavbar>
  );
};
export default Navbar;

import dayjs from 'dayjs';

const UPSELL_MEDIA_URL = process.env.REACT_APP_UPSELL_MEDIA_URL;

export function iOSVersion() {
  const match = navigator?.userAgent?.match(/OS (\d+)_?(\d+)?/) ?? null;
  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isIOS16OrLater() {
  return iOSVersion() >= 16;
}

export function androidVersion() {
  const match = navigator?.userAgent?.match(/Android (\d+)\.?(\d+)?/) ?? null;
  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isAndroid() {
  return androidVersion() >= 8;
}

export function getPlatform(
  fallback: 'ios' | 'android'
): 'ios' | 'android' | 'default' {
  if (isIOS16OrLater()) {
    return 'ios';
  }
  if (isAndroid()) {
    return 'android';
  }
  return fallback;
}

export function isAfterDate(register_date?: string, expStartDate?: string) {
  if (!register_date || !expStartDate) {
    return false;
  }

  const registrationDate = dayjs(register_date);
  const experimentStartDate = dayjs(expStartDate);

  return (
    registrationDate.isAfter(experimentStartDate) ||
    registrationDate.isSame(experimentStartDate)
  );
}

export function getUpsellImageUrl(image: string) {
  return `${UPSELL_MEDIA_URL}/${image}`;
}

export function interpolateString(str: string, word: string) {
  return str.replace(/\{\{(\w+)\}\}/g, (_, key) => word || '');
}

export const globalColors = {
  purple: '#5653FE',
  purpleHover: '#6F6DFE',
  purpleDisabled: '#CCCBFF',
  white: '#ffffff',
  grayText: '#8A8A8E',
  grayText2: '#636366',
  grayDark: '#48484A',
  grayShadow: '#0000000d',
  grayLabel: '#636366',
  grayInput: '#C8CED7',
  grayBlockShadow: '#0000005b',
  primaryBlack: '#0B0B1D',
  halfWidthButton: '#5653FE',
  primaryBlue: '#5653FE',
  primaryDarkBlue: '#24234C',
  primaryGreen: '#34C759',
  accentRed: '#FF453A',
  accentOrange: '#FF9900',
  greenProgress: '#19AA32',
  grayProgress: '#E5E7EB',
  grayBackground: '#F5F7F9',
  blueBackgroundGradient: 'linear-gradient(180deg, #28264C 0%, #1B1C32 100%)',
  lightBlueInactive: '#DDDDFF',
};

import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { userApi } from '@/api/userApi';

const QUERY_KEY = 'whats-new';

export function useWhatsNewQuery(value: string, userId: number | undefined) {
  const queryClient = useQueryClient();

  const whatsNewQuery = useQuery({
    queryKey: [QUERY_KEY, value],
    queryFn:  () => userApi
      .getTourPassed(userId, value)
      .then(data => data.passed),
    staleTime: Infinity,
    enabled:   Boolean(userId),
  });

  const whatsNewMutation = useMutation({
    mutationKey: [QUERY_KEY, value],
    mutationFn:  () => userApi.postTourPassed(userId, value),
    onSuccess() {
      return queryClient.setQueryData(
        [QUERY_KEY, value],
        true,
      );
    },
  });

  return {
    whatsNewQuery,
    whatsNewMutation,
  };
}

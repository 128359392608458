import classNames from 'classnames';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { getGoalTitle } from '../../../shared/lib';
import './goal-button.css';

export function GoalButton({
  className,
  isDisabled = false,
  title,
  events,
}: {
  className?: string;
  isDisabled?: boolean;
  title?: string;
  events?: { onClick: () => void };
}) {
  const { t } = useTranslation();
  const personalProfileQuery = usePersonalProfileQuery();
  const goal = get(personalProfileQuery.data, 'properties.goal[0]');
  const goalTitle = title || getGoalTitle(goal);

  return personalProfileQuery.isLoading ? (
    <UiLoadingSkeleton
      style={{
        width: '100%',
        height: '56px',
      }}
    />
  ) : (
    <button
      type="button"
      disabled={isDisabled}
      className={classNames('goal-button', className)}
      onClick={events?.onClick}
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <path
            fill="#5653FE"
            d="M10 2.5a7.5 7.5 0 1 0 7.5 7.5 7.33 7.33 0 0 0-.457-2.543l-1.2 1.2C15.946 9.1 16 9.55 16 10a6 6 0 1 1-6-6c.45 0 .9.053 1.342.157L12.55 2.95A7.577 7.577 0 0 0 10 2.5Zm5.25 0-3 3v1.125l-1.912 1.912C10.225 8.5 10.113 8.5 10 8.5a1.5 1.5 0 1 0 1.5 1.5c0-.113 0-.225-.037-.338l1.912-1.912H14.5l3-3h-2.25V2.5ZM10 5.5a4.5 4.5 0 1 0 4.5 4.5H13a3 3 0 1 1-3-3V5.5Z"
          />
        </svg>
        <strong>
          {t('goals.goal-info', {
            goal: goalTitle,
          })}
        </strong>
      </span>

      {!isDisabled && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <path
            fill="#6B7280"
            d="M13.577 1.91a.833.833 0 0 1 1.179 0l3.333 3.334a.833.833 0 0 1 0 1.179L7.256 17.256a.833.833 0 0 1-.59.244H3.334a.833.833 0 0 1-.833-.833v-3.334c0-.22.088-.433.244-.589l8.333-8.333 2.5-2.5Zm-1.91 4.268-7.5 7.5v2.155H6.32l7.5-7.5-2.154-2.155ZM15 7.155l1.322-1.322-2.155-2.155L12.845 5 15 7.155Z"
          />
        </svg>
      )}
    </button>
  );
}

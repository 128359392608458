import { useFeatureValue } from '@growthbook/growthbook-react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingAnalytics } from '../../../shared/analytics';
import {
  LEVEL_ADVANCED,
  LEVEL_BEGINNER,
  LEVEL_INTERMEDIATE,
} from '../../../shared/config';
import { LevelPickerButton } from './LevelPickerButton';
import LevelAdvancedIcon from './assets/level-advanced-icon.svg';
import LevelBeginnerIcon from './assets/level-beginner-icon.svg';
import LevelIntermediateIcon from './assets/level-intermediate-icon.svg';
import './assets/level-picker-page.css';

type CurrentLevel =
  | typeof LEVEL_BEGINNER
  | typeof LEVEL_INTERMEDIATE
  | typeof LEVEL_ADVANCED;

export function OnboardingLevelPickerPage() {
  const navigation = useHistory();
  const { t } = useTranslation();
  const [currentLevel, setCurrentLevel] = useState<CurrentLevel>();
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );

  useEffect(() => {
    OnboardingAnalytics.levelPickerPageView({
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
  }, []);

  useEffect(() => {
    if (currentLevel) {
      OnboardingAnalytics.onSelectLevel({
        level: currentLevel,
        abOnboardingQuizValue: growthbookAbOnboardingQuiz,
      });
    }
  }, [currentLevel]);

  function onNext() {
    navigation.push(`/onboarding/level-info?level=${currentLevel}`);
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="level-picker-page min-vh-full"
    >
      <div className="level-picker-page__header">
        <h1>{t('onboarding.level-picker-page.title')}</h1>
        <p>{t('onboarding.level-picker-page.description')}</p>
      </div>

      <div className="level-picker-page__content">
        <LevelPickerButton
          title={t('onboarding.level-picker-page.levels.beginner.title')}
          description={t(
            'onboarding.level-picker-page.levels.beginner.description'
          )}
          isActive={currentLevel === LEVEL_BEGINNER}
          icon={LevelBeginnerIcon}
          onClick={() => setCurrentLevel(LEVEL_BEGINNER)}
        />

        <LevelPickerButton
          title={t('onboarding.level-picker-page.levels.intermediate.title')}
          description={t(
            'onboarding.level-picker-page.levels.intermediate.description'
          )}
          isActive={currentLevel === LEVEL_INTERMEDIATE}
          icon={LevelIntermediateIcon}
          onClick={() => setCurrentLevel(LEVEL_INTERMEDIATE)}
        />

        <LevelPickerButton
          title={t('onboarding.level-picker-page.levels.advanced.title')}
          description={t(
            'onboarding.level-picker-page.levels.advanced.description'
          )}
          isActive={currentLevel === LEVEL_ADVANCED}
          icon={LevelAdvancedIcon}
          onClick={() => setCurrentLevel(LEVEL_ADVANCED)}
        />
      </div>

      {currentLevel && (
        <div className="level-picker-page__actions">
          <UiPrimaryButton onClick={onNext}>
            {t('onboarding.level-picker-page.action')}
          </UiPrimaryButton>
        </div>
      )}
    </motion.section>
  );
}

export const getCohortParams = () => {
  const today = new Date();
  const weekDay = ((today.getUTCDay() + 6) % 7) + 1;

  return {
    webapp_cohort_day: getDayOfYear(today),
    webapp_cohort_week: getWeekOfYear(today),
    webapp_cohort_weekday: weekDay,
    webapp_cohort_month: today.getUTCMonth() + 1,
    webapp_cohort_year: today.getUTCFullYear(),
  };
};

const getDayOfYear = (date: Date) => {
  let start = new Date(date.getUTCFullYear(), 0, 0);
  return Math.floor((date.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
};

const getWeekOfYear = (date: Date) => {
  let start = new Date(date.getUTCFullYear(), 0, 0);
  return Math.floor(
    (date.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 7)
  );
};

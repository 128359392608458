import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { languageState } from '../context/atoms/Language';

export const trustPilotLinks: any = {
  de: 'https://de.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
  en: 'https://www.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
  es: 'https://es.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
  fr: 'https://fr.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
  pt: 'https://br.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
  tr: 'https://www.trustpilot.com/evaluate/coursiv.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
};

export const useTrustPilot = () => {
  const [link, setLink] = useState('');
  const [languageCode, setLanguageCode] = useRecoilState(languageState);

  useEffect(() => {
    setLink(trustPilotLinks[languageCode]);
  }, [languageCode]);

  return { link };
};

import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const LazyProfile = lazy(() => import('./Profile/Profile'));
const LazyUnsubscribePlansSummary = lazy(
  () => import('./Unsubscribe/UnsubscribePlansSummary')
);
const LazySubscriptionBlock = lazy(
  () => import('./SubscriptionBlock/SubscriptionBlock')
);
const LazyUnsubscribeQuestions = lazy(
  () => import('./Unsubscribe/UnsubscribeQuestions')
);
const LazySettings = lazy(() => import('./Settings/Settings'));
const LazyReports = lazy(() => import('./Reports/Reports'));
const LazyLanguage = lazy(() => import('./Language/Language'));
const LazyPaymentHistory = lazy(
  () => import('./payment-history/ui/PaymentHistory')
);

const ProfileRoutes = () => {
  return (
    <Switch>
      <Route exact path="/profile" component={LazyProfile} />
      {/* settings routes start*/}
      <Route
        exact
        path="/profile/plan-subscription/:subscriptionId/unsubscribe/plan"
        component={LazyUnsubscribePlansSummary}
      />
      <Route
        exact
        path="/profile/plan-subscription/:subscriptionId/unsubscribe"
        component={LazyUnsubscribeQuestions}
      />
      <Route
        path="/profile/plan-subscription"
        component={LazySubscriptionBlock}
      />
      <Route path="/profile/settings/language" component={LazyLanguage} />
      <Route path="/profile/settings" component={LazySettings} />
      {/* settings routes end*/}
      <Route path="/profile/reports" component={LazyReports} />
      <Route path="/profile/payment-history" component={LazyPaymentHistory} />
    </Switch>
  );
};

export default ProfileRoutes;

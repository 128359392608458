export function PillButton({
  onClick,
  children,
  selected,
}: {
  onClick: () => void;
  children?: string | JSX.Element | JSX.Element[];
  selected?: boolean;
}) {
  return (
    <button
      type="button"
      className={`border outline-none transition duration-200 p-2 px-4 rounded-full flex justify-center items-center gap-2.5 text-[#24234c] text-sm ${
        selected
          ? 'scale-95 bg-[#EEF] border-[#5653FE]'
          : 'bg-[#FFF] bornder-trasparent'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import './next-task-confirmation-toast.css';

export function NextTaskConfirmationToast({
  isVisible = false,
  events,
}: {
  isVisible: boolean;
  events: {
    onHide: () => void;
    onConfirm: () => void;
  };
}) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="next-task-confirmation-toast"
          initial={{ y: -70 }}
          animate={{ y: 0 }}
          exit={{ y: -70, opacity: 0 }}
          className="next-task-confirmation-toast"
        >
          <div className="next-task-confirmation-toast__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="#fff"
                d="M12 4c1.648 0 3 1.352 3 3v3H9V7c0-1.648 1.352-3 3-3Zm5 6V7c0-2.752-2.248-5-5-5S7 4.248 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1ZM6 12h12v8H6v-8Z"
              />
              <path fill="#fff" d="M6 20v-8h12v8H6Z" />
            </svg>

            <div className="next-task-confirmation-toast__content">
              <h5>{t('challenges.next-task-confirmation-toast.title')}</h5>

              <button type="button" onClick={events.onConfirm}>
                {t('challenges.next-task-confirmation-toast.action')}
              </button>
            </div>
          </div>

          <button
            type="button"
            className="next-task-confirmation-toast__close-button"
            onClick={events.onHide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="#F9FAFB"
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

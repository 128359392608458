import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { trim } from 'lodash-es';
import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChangeChatBotNameMutation } from '../../../entities/session';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { UiSpinner } from '../../../shared/ui/spinner';
import './chat-details-name.css';

export function ChatDetailsName({
  name,
  chatBotId,
  sessionId,
}: {
  name: string;
  chatBotId: string;
  sessionId?: string;
}) {
  return sessionId ? (
    <ChatDetailsEditableName
      name={name}
      chatBotId={chatBotId}
      sessionId={sessionId}
    />
  ) : (
    <h2 className="chat-details-name__name">{name}</h2>
  );
}

const ERROR_VISIBLE_DELAY = 2000;

function ChatDetailsEditableName({
  name,
  chatBotId,
  sessionId,
}: {
  name: string;
  chatBotId: string;
  sessionId: string;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [localName, setLocalName] = useState(name);
  const nameInput = useRef<HTMLInputElement>(null);
  const changeNameMutation = useChangeChatBotNameMutation({
    queryClient,
    sessionId,
  });
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId, sessionId });

  function onSetEditable() {
    setIsEditable(true);

    setTimeout(() => nameInput.current?.focus());

    ChatPageAnalytics.onEditChatButtonClick();
  }

  function changeName() {
    const trimmedLocalName = trim(localName);

    setErrorMessage('');
    if (trimmedLocalName && trimmedLocalName !== name) {
      changeNameMutation
        .mutateAsync(trimmedLocalName)
        .then(() => ChatPageAnalytics.onSaveNameChanges())
        .catch(cause => {
          setLocalName(name);
          const defaultErrorMessage = t('ai-bots.default-error');
          setErrorMessage(defaultErrorMessage);
          setTimeout(() => setErrorMessage(''), ERROR_VISIBLE_DELAY);
          throw new Error('ERROR_CHANGE_NAME', { cause });
        })
        .finally(() => setIsEditable(false));
    } else {
      setLocalName(name);
      setIsEditable(false);
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    nameInput.current?.blur();

    changeName();
  }

  return (
    <div className="chat-details-name">
      {isEditable ? (
        <form className="chat-details-name__form" onSubmit={onSubmit}>
          <input
            readOnly={changeNameMutation.isLoading}
            ref={nameInput}
            className={classNames('chat-details-name__input', {
              'chat-details-name__input--loading': changeNameMutation.isLoading,
            })}
            type="text"
            value={localName}
            onBlur={changeName}
            onChange={e => setLocalName(e.target.value)}
          />
          {changeNameMutation.isLoading && (
            <UiSpinner className="chat-details-name__spinner" />
          )}
        </form>
      ) : (
        <div className="chat-details-name__container">
          <h2 className="chat-details-name__name">{name}</h2>
          <button
            type="button"
            className="chat-details-name__edit-button"
            onClick={onSetEditable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
            >
              <path
                fill="#5653FE"
                d="M13.577 1.91a.833.833 0 0 1 1.179 0l3.333 3.334a.833.833 0 0 1 0 1.179L7.256 17.256a.833.833 0 0 1-.59.244H3.334a.833.833 0 0 1-.833-.833v-3.334c0-.22.088-.433.244-.589l8.333-8.333 2.5-2.5Zm-1.91 4.269-7.5 7.5v2.154H6.32l7.5-7.5-2.154-2.154ZM15 7.155l1.322-1.322-2.155-2.154L12.845 5 15 7.155Z"
              />
            </svg>
          </button>
        </div>
      )}
      {errorMessage && (
        <div className="chat-details-name__error-message">{errorMessage}</div>
      )}
    </div>
  );
}

import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.scss';

export const MessageLoading = () => (
  <div className="ai-bot-message-v2 ai-bot-message_ai-message-v2">
    <img
      width="24"
      height="24"
      src={AiAvatarIcon}
      role="presentation"
      alt=""
    />
    <div className="ai-bot-message__loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        fill="none"
      >
        <rect
          className="ai-bot-message__loading__dot-1"
          width="8"
          height="8"
          rx="4"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        fill="none"
      >
        <rect
          className="ai-bot-message__loading__dot-2"
          width="8"
          height="8"
          rx="4"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="8"
        fill="none"
      >
        <rect
          className="ai-bot-message__loading__dot-3"
          width="7"
          height="7"
          y=".5"
          rx="3.5"
        />
      </svg>
    </div>
  </div>
);

import { shuffle } from 'lodash-es';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../shared/ui';
import { ToggleButton } from './ToggleButton';
import './assets/satisfaction-survey-comment.css';

export function SatisfactionSurveyBody({
  onSendFeedback,
  isPositive,
}: {
  onSendFeedback: (comment: string, pills: string) => void;
  isPositive: boolean;
}) {
  const MAX_CHAR = 150;
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);

  const feedback = useMemo(() => {
    return {
      positive: shuffle(
        t('satisfaction-survey.reason.positive', {
          returnObjects: true,
        }) as string[]
      ),
      negative: shuffle(
        t('satisfaction-survey.reason.negative', {
          returnObjects: true,
        }) as string[]
      ),
    };
  }, [t]);

  const feedbackPills = isPositive ? feedback.positive : feedback.negative;

  const commentCharLength = MAX_CHAR - comment.length;

  function onSetComment(e: ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length <= MAX_CHAR) {
      setComment(e.target.value);
    }
  }

  function toggleFeedback(label: string) {
    setSelectedFeedback(prevSelected => {
      if (prevSelected.includes(label)) {
        return prevSelected.filter(item => item !== label);
      }
      return [...prevSelected, label];
    });
  }

  function sendFeedback() {
    const formattedFeedbackPills = selectedFeedback.join(', ');
    onSendFeedback(comment, formattedFeedbackPills);
    setSelectedFeedback([]);
    setComment('');
  }

  useEffect(() => {
    setSelectedFeedback([]);
  }, [isPositive]);

  return (
    <>
      <div className="satisfaction-survey-comment">
        <h2 className="satisfaction-survey-comment__title">
          {t('satisfaction-survey.reason.title')}
        </h2>

        <div className="satisfaction-survey-rating-container">
          {feedbackPills.map(label => (
            <ToggleButton
              key={label}
              onClick={() => toggleFeedback(label)}
              selected={selectedFeedback.includes(label)}
            >
              {label}
            </ToggleButton>
          ))}
        </div>

        <h2 className="satisfaction-survey-comment__title">
          {t('satisfaction-survey.comment.title')}
        </h2>

        <textarea
          maxLength={MAX_CHAR}
          value={comment}
          className="satisfaction-survey-comment__area"
          placeholder={t('satisfaction-survey.comment.placeholder') as string}
          onChange={e => onSetComment(e)}
        />

        <h4 className="satisfaction-survey-comment__counter">
          {commentCharLength}/{MAX_CHAR}
        </h4>
      </div>

      <UiPrimaryButton
        className="satisfaction-survey-comment__action"
        onClick={sendFeedback}
      >
        {t('satisfaction-survey.comment.action')}
      </UiPrimaryButton>
    </>
  );
}

import { AxiosResponse } from 'axios';

import { api } from '../../../../../../services/http.service';
import { GigaUpsell } from '../types';

export const UpsellContentApi = {
  getUpsellContent: async (
    lang: string,
    name: string
  ): Promise<AxiosResponse<GigaUpsell>> => {
    return api.get(
      `${process.env.REACT_APP_UPSELL_HOST_URL}/${name}/${lang}.json`
    );
  },
};

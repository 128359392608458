import axios from 'axios';
import Cookies from 'js-cookie';

import cognito from './cognito.service';

export const api = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
});

api.interceptors.request.use(config => {
  //TODO: wtf? includes('auth');

  if (typeof window !== 'undefined' && window.location.href.includes('auth')) {
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_FUNNEL_TOKEN}`;
    return config;
  }

  return cognito.getToken().then(token => {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['accept-language'] = Cookies.get('sys_lang') || 'en';

    return config;
  });
});

const get = async <T = any>(endpoint: string, baseURL: string = '') => {
  const requestConfigs: any = {};

  if (baseURL) {
    requestConfigs.baseURL = baseURL;
  }

  return await api.get<T>(endpoint, requestConfigs);
};

const post = async <T = any, D = any>(
  endpoint: string,
  data: D,
  baseURL: string = ''
) => {
  const requestConfigs: any = {};

  if (baseURL) {
    requestConfigs.baseURL = baseURL;
  }

  return await api.post<T>(endpoint, data, requestConfigs);
};

const put = async <T = any, D = any>(
  endpoint: string,
  data: D,
  baseURL: string = ''
) => {
  const requestConfigs: any = {};

  if (baseURL) {
    requestConfigs.baseURL = baseURL;
  }

  return await api.put<T>(endpoint, data, requestConfigs);
};

const patch = async <T = any, D = any>(
  endpoint: string,
  data: D,
  baseURL: string = ''
) => {
  const requestConfigs: any = {};

  if (baseURL) {
    requestConfigs.baseURL = baseURL;
  }

  return await api.patch<T>(endpoint, data, requestConfigs);
};

export default { api, get, post, put, patch };

import { useFeatureValue } from '@growthbook/growthbook-react';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../../../context/atoms/User';
import { analyticsService } from '../../../../../services/analytics.service';
import { getCohortParams } from '../../../../../utils/GetCohortParams/GetCohortParams';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingAnalytics } from '../../../shared/analytics';
import CoursivIcon from './assets/coursiv-icon.svg';
import WelcomeImage from './assets/welcome-image.png';
import './assets/welcome-page.css';

export function OnboardingWelcomePage() {
  const navigation = useHistory();
  const { t } = useTranslation();
  const userData = useRecoilValue(userDataQuery);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );

  useEffect(() => {
    OnboardingAnalytics.welcomePageView({
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
  }, []);

  useEffect(() => {
    //TODO: перенести куда то в отдельное место. Нужно для того,
    // чтоб понимать когда пользователь зашел в приложение первый раз. И к какой когорте относится
    // в текущих реалиях в аналитике придется смотреть только самый первый ивент.
    // Но в целом нужно унифицировать это и сделать так, чтоб отправлялось разово
    // Можно использовать /tours

    if (userData && !userData.is_onboarded) {
      analyticsService.setAmplitudeUserProperties(getCohortParams());
      analyticsService.sendEvent('pr_webapp_launch_first_time');
    }
  }, [userData]);

  function onNext() {
    navigation.push('/onboarding/info');
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="welcome-page"
    >
      <div className="welcome-page__container min-vh-full">
        <div className="welcome-page__image-container">
          <img
            className="welcome-page__image"
            src={WelcomeImage}
            role="presentation"
            alt=""
          />
        </div>

        <div className="welcome-page__content">
          <img
            width="92"
            height="32"
            src={CoursivIcon}
            role="presentation"
            alt=""
          />

          <h1>{t('onboarding.welcome-page.title')}</h1>

          <p>{t('onboarding.welcome-page.description')}</p>
        </div>

        <div className="welcome-page__actions">
          <UiPrimaryButton onClick={onNext}>
            {t('onboarding.welcome-page.action')}
          </UiPrimaryButton>
        </div>
      </div>
    </motion.section>
  );
}

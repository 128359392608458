import { Step } from 'react-joyride';

export const joyrideStyles = {
  buttonNext: {
    display: 'none',
  },
  buttonBack: {
    display: 'none',
  },
  spotlight: {
    borderRadius: 9,
  },
  tooltipFooter: {
    padding: 0,
    display: 'none',
  },
  tooltip: {
    top: '8px !important',
    padding: 0,
    animation: 'fadeIn 2s',
  },
  tooltipContent: {
    padding: '8px 14px',
  },
  options: {
    primaryColor: '#5653FE',
    width: 'auto',
    maxWidth: 'calc(100% - 60px)',
    animation: 'fadeIn 2s',
  },
  floaterStyles: {
    arrow: {},
  },
};

import { useGrowthBook } from '@growthbook/growthbook-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TestpilotApi } from '../api';

export function useTestPilot(name: string) {
  const growthbook = useGrowthBook();
  const { id } = growthbook?.getAttributes() ?? {};

  return useQuery({
    queryKey: ['testpilot', name],
    queryFn: () =>
      TestpilotApi.getExperiment({ experiment: name, uniqueId: id }).then(
        ({ data }) => data
      ),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';

import { isAfterDate } from '..';

export default function useCertificatesExperiment() {
  const gb = useGrowthBook();
  const expDate = useFeatureValue('exp_ai_certificates_date', null);
  const { registered_at, arrow } = gb?.getAttributes() ?? {};

  const isExperimentActive =
    expDate && isAfterDate(registered_at, expDate) && arrow === 'ai';

  const value = gb?.getFeatureValue('exp_certificates', 'default');

  return {
    value: isExperimentActive ? value : 'default',
    isTest: isExperimentActive ? value === 'test' : false,
  };
}

import i18next from 'i18next';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { LanguageType } from '../../types/Language';
import { GuidesAnalytics } from '../guides/shared/analytics';

interface SiteJabberButtonProps {
  navigation: any;
  guideId: string;
  unitId: string;
  lessonId: string;
  userEmail?: string;
  setGuideTrustpilotTourVisible: any;
}

export const SiteJabberButton: FC<SiteJabberButtonProps> = ({
  navigation,
  guideId,
  unitId,
  lessonId,
  userEmail,
  setGuideTrustpilotTourVisible,
}) => {
  useEffect(() => {
    const setupObserver = () => {
      const observerCallback: MutationCallback = mutations => {
        mutations.forEach(mutation => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'style'
          ) {
            const target = mutation.target as HTMLElement;

            if (
              target.classList.contains('stjr-instant-feedback') &&
              target.classList.contains(
                'guide-survey-page__sitejabber__feedback'
              ) &&
              target.style.display === 'none' &&
              target.style.opacity === '-0.05'
            ) {
              setTimeout(() => {
                setGuideTrustpilotTourVisible();

                navigation.push(`/guides/${guideId}`);
              }, 150);
            }
          }
        });
      };

      const observer = new MutationObserver(observerCallback);
      const config: MutationObserverInit = {
        attributes: true,
        attributeFilter: ['style'],
        subtree: true,
        childList: true,
      };

      observer.observe(document.body, config);

      return () => {
        observer.disconnect();
      };
    };

    // Wait for the Sitejabber script to load before setting up the observer
    const waitForSitejabber = setInterval(() => {
      if (window.STJRV2) {
        clearInterval(waitForSitejabber);
        const cleanup = setupObserver();
        return () => {
          cleanup();
        };
      }
    }, 100);

    return () => {
      clearInterval(waitForSitejabber);
    };
  }, []);

  const sitejabberSupportedLanguages: string[] = [
    LanguageType.english,
    LanguageType.deutsch,
    LanguageType.french,
    LanguageType.spanish,
    LanguageType.italian,
  ];

  const sitejabberLanguageCode = sitejabberSupportedLanguages.includes(
    i18next.language
  )
    ? i18next.language
    : LanguageType.english;

  const [isSiteJabberClicked, setIsSiteJabberClicked] = useState(false);

  function handleSiteJabberClick() {
    if (isSiteJabberClicked) return;

    GuidesAnalytics.onSiteJabberClicked({
      guideId,
      unitId,
      lessonId,
    });

    setIsSiteJabberClicked(true);
  }

  return (
    <>
      <Helmet>
        <script>
          {`document.querySelector("body").addEventListener("STJRV2.instantFeedbackWidgetLoaded",function(){STJRV2.setConfig({language:'${sitejabberLanguageCode}',user:{email: '${userEmail}'}})});`}
        </script>
        <script>
          {`
        (function(d, s, id, c) {
            var js, rC = [], uRC = [], r = 0;
            Array.from(document.querySelectorAll('[class*="stjr-"]')).forEach(r => {
              rC = [...rC, ...Array.from(r.classList).filter((cl) => {
                return /^stjr-/.test(cl);
              })];
            });
            uRC = [...new Set(rC)];
            var t = d.getElementsByTagName(s)[0];
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://www.sitejabber.com/js/v2/653b661568203/widgets.js' + 
                    (uRC.length ? '?widget-classes=' + uRC.join("|") : '?widget-classes=stjr-base');
            js.onload = js.onreadystatechange = function() {
              if (!r && (!this.readyState || this.readyState[0] == 'c')) {
                r = 1;
                c();
              }
            };
            t.parentNode.insertBefore(js, t);
          })(document, 'script', 'sj-widget', function() {});
        `}
        </script>
      </Helmet>
      <div
        className="guide-survey-page__sitejabber"
        id="guide-survey-review-block"
      >
        <div
          className="stjr-instant-feedback guide-survey-page__sitejabber__feedback"
          onClick={handleSiteJabberClick}
        ></div>
      </div>
    </>
  );
};

import { motion } from 'framer-motion';
import { FC } from 'react';

import { BaseBottomsheetContainer } from './BaseBottomsheet.styles';

interface Props {
  children: JSX.Element | JSX.Element[];
  onClose?: () => void;
}

const BaseBottomsheet: FC<Props> = ({ children, onClose }) => (
  <BaseBottomsheetContainer>
    <motion.div
      className="overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.6 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      onClick={onClose}
    />
    <div className="wrapper">
      <motion.div
        className=" rounded-t-lg overflow-y-auto max-h-[90vh]"
        initial={{ y: 500 }}
        animate={{ y: 0 }}
        exit={{ y: 500 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </div>
  </BaseBottomsheetContainer>
);

export default BaseBottomsheet;

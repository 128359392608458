import { useEffect, useState } from 'react';

import { authApi } from '../../../api/authApi';

export const useAppToWeb = () => {
  const [token, setToken] = useState('');

  const fetchToken = async () => {
    const data = await authApi.getAutoLoginToken();
    setToken(data.token);
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return {
    token,
  };
};

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './review-card.css';

function StarRating({ count }: { count: number }) {
  return (
    <div className="review-card__rating">
      {Array(count)
        .fill('')
        .map((_, j) => (
          <svg
            key={`review-card-rating__${j}`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            fill="none"
          >
            <path
              fill="#FFAD33"
              d="M8.103.817a1 1 0 0 1 1.794 0l1.81 3.666a1 1 0 0 0 .752.548l4.046.587a1 1 0 0 1 .555 1.706l-2.928 2.854a1 1 0 0 0-.288.885l.691 4.03a1 1 0 0 1-1.45 1.054l-3.62-1.902a1 1 0 0 0-.93 0l-3.62 1.902a1 1 0 0 1-1.45-1.054l.69-4.03a1 1 0 0 0-.287-.885L.94 7.324a1 1 0 0 1 .555-1.706l4.046-.587a1 1 0 0 0 .753-.548L8.104.817Z"
            />
          </svg>
        ))}
    </div>
  );
}

export function ReviewCard({
  name,
  text,
  starCount = 5,
  className,
}: {
  name: string;
  text: string;
  className?: string;
  starCount?: number;
}) {
  const { t } = useTranslation();

  return (
    <div className={classNames('review-card', className)}>
      <div className="review-card__header">
        <h5 className="review-card__name">{name}</h5>
        <h6 className="review-card__verified">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="none"
          >
            <rect width="13" height="13" fill="#19AA32" rx="6.5" />
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m9.533 4.225-4.17 4.17L3.467 6.5"
            />
          </svg>
          <span>{t('review-card.verified-tip')}</span>
        </h6>
      </div>
      <p className="review-card__description">{text}</p>

      <StarRating count={starCount} />
    </div>
  );
}

import { AxiosPromise } from 'axios';

import { api } from '../../../../services/http.service';
import { GuideLessonDetailsType } from '../../../guides/entities/guide-lesson';

export const ChallengeLessonAPI = {
  getLesson(
    challengeId: string,
    taskId: string
  ): AxiosPromise<GuideLessonDetailsType> {
    return api.get(`daily-challenges/${challengeId}/tasks/${taskId}/`);
  },
};

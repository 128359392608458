type arrow =
  | 'ai'
  | 'influencer'
  | 'remote_work'
  | 'freelance'
  | 'dropship'
  | 'entrepreneur'
  | 'online_money';

const CONFIG_ID_ARROW_MAPPING: {
  [key: string]: arrow;
} = {
  '0': 'ai',
  '1': 'ai',
  '2': 'ai',
  '3': 'ai',
  '4': 'ai',
  '5': 'ai',
  '7': 'ai',
  '8': 'ai',
  '9': 'ai',
  '10': 'ai',
  '11': 'ai',
  '12': 'ai',
  '13': 'ai',
  '14': 'ai',
  '15': 'ai',
  '16': 'ai',
  '17': 'ai',
  '18': 'freelance',
  '19': 'ai',
  '20': 'dropship',
  '21': 'dropship',
  '22': 'influencer',
  '23': 'influencer',
  '24': 'remote_work',
  '25': 'ai',
  '26': 'online_money',
  '27': 'ai',
  '28': 'entrepreneur',
  '29': 'entrepreneur',
  '30': 'influencer',
  '31': 'remote_work',
  '32': 'influencer',
  '33': 'remote_work',
  '34': 'ai',
  '35': 'ai',
  '36': 'influencer',
  '37': 'ai',
  '1017': 'ai',
  '1018': 'ai',
  '3006': 'influencer',
  '7005': 'remote_work',
};

export function getArrowByPrcId(prcId: string): arrow {
  if (prcId in CONFIG_ID_ARROW_MAPPING) {
    return CONFIG_ID_ARROW_MAPPING[prcId];
  }

  const configId = parseInt(prcId, 10);

  if (isNaN(configId)) {
    return 'ai';
  }

  if (configId >= 1000 && configId < 2000) return 'ai';
  if (configId >= 2000 && configId < 3000) return 'freelance';
  if (configId >= 3000 && configId < 4000) return 'influencer';
  if (configId >= 4000 && configId < 5000) return 'online_money';
  if (configId >= 5000 && configId < 6000) return 'entrepreneur';
  if (configId >= 6000 && configId < 7000) return 'dropship';
  if (configId >= 7000 && configId < 8000) return 'remote_work';

  return 'ai';
}

export function getConfigIdByPrcId(prcId: string): string {
  const mapping = getArrowByPrcId(prcId);
  return mapping === 'ai' ? 'giga_upsell' : `${mapping}_upsell`;
}

import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { UiSpinner } from '../../../../ai-bots/shared/ui/spinner';
import { UiPrimaryButton } from '../../../../shared/ui';
import { AiBotCategory } from '../../../entities/ai-bot';
import { useAiBotsCategoriesQuery } from '../../../feature/ai-bots-categories';
import { useSaveUserInterests } from '../../../feature/ai-bots-categories/model';
import { SelectCategoriesAnalytics } from '../../../shared/analytics/select-categories';
import { ErrorBlock } from '../../../shared/errors/error-block';
import { getUniqCategories } from '../../ai-bots-list/lib';
import './select-categories.css';
import { SelectCategoryButton } from './select-category-button';

function CategoriesList({
  isLoading,
  data,
  selectedCategories,
  onSelectCategory,
}: {
  isLoading: boolean;
  data?: Array<AiBotCategory>;
  selectedCategories: string[];
  onSelectCategory: (category: AiBotCategory) => void;
}) {
  if (!data || isLoading || isEmpty(data)) {
    return <></>;
  }

  return (
    <div className="pt-4 flex flex-wrap gap-2">
      {data
        .filter(item => item.id !== 'for_you')
        .map(category => (
          <SelectCategoryButton
            key={category.id}
            isActive={selectedCategories.includes(category.id)}
            category={category}
            onClick={() => onSelectCategory(category)}
          />
        ))}
    </div>
  );
}

export function SelectCategoriesBottomSheet({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { data, isError, refetch, isLoading } = useAiBotsCategoriesQuery();
  const saveUserInterests = useSaveUserInterests();

  const categories = data?.items ? getUniqCategories(data.items) : [];

  useEffect(() => {
    if (isVisible && !isEmpty(data?.items)) {
      SelectCategoriesAnalytics.selectCategoriesView();
    }
  }, [isVisible, data]);

  const onSelectCategory = (category: AiBotCategory) => {
    setSelectedCategories(prev => {
      if (prev.includes(category.id)) {
        return prev.filter(id => id !== category.id);
      } else if (prev.length < 3) {
        return [...prev, category.id];
      }
      return [...prev.slice(1), category.id];
    });
  };

  const onContinue = () => {
    saveUserInterests.mutate(
      { categoryIds: selectedCategories },
      { onSuccess: onDismiss }
    );
    SelectCategoriesAnalytics.selectInterestedCategories({
      ids: selectedCategories,
    });
  };

  const loadingButton = t('guides.loading');
  const continueButton = t('Continue');

  return (
    <BottomSheet
      key="select-categories-bottomsheet"
      style={{ height: '100%' }}
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={onDismiss}
    >
      <div className="select-categories px-4 pt-[10px] flex flex-col h-full">
        <div className="select-categories__heading">
          <div>
            <h1 className="select-categories__title text-[24px]">
              {t('ai-tools.bottomsheet-title')}
            </h1>
            <p className="mt-2">{t('ai-tools.bottomsheet-description')}</p>
          </div>
        </div>
        <div className="flex-grow overflow-auto">
          {isError ? (
            <ErrorBlock onRetry={() => refetch()} />
          ) : (
            <CategoriesList
              isLoading={isLoading}
              data={categories}
              selectedCategories={selectedCategories}
              onSelectCategory={onSelectCategory}
            />
          )}
        </div>
        <div className="mt-[32px] mb-[42px]">
          <UiPrimaryButton
            className="w-full"
            onClick={onContinue}
            disabled={selectedCategories.length === 0}
          >
            {saveUserInterests.isLoading ? loadingButton : continueButton}
          </UiPrimaryButton>
        </div>
      </div>
    </BottomSheet>
  );
}

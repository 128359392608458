import { AxiosResponse } from 'axios';
import i18next from 'i18next';

import { api } from '../../../../services/http.service';
import { AiBotMessageType } from '../message';
import { AiBotSessionType } from './types';

type MessagesType = { items: Array<AiBotMessageType> };
type SessionsListType = { items: Array<AiBotSessionType> };

export const SessionAPI = {
  createSession({
    chatBotId,
  }: {
    chatBotId: string;
  }): Promise<AxiosResponse<AiBotSessionType>> {
    return api.post('ai-chatbots/sessions', {
      name: i18next.t('ai-bots.chat.new-title'),
      chatbot_id: chatBotId,
    });
  },
  getAllSessions(): Promise<AxiosResponse<SessionsListType>> {
    return api.get('ai-chatbots/sessions');
  },
  getSessionDetailsById({
    sessionId,
  }: {
    sessionId: string;
  }): Promise<AxiosResponse<AiBotSessionType>> {
    return api.get(`ai-chatbots/sessions/${sessionId}`);
  },
  createNewMessage({
    text,
    sessionId,
  }: {
    text: string;
    sessionId: string;
  }): Promise<AxiosResponse<AiBotMessageType>> {
    return api.post(`ai-chatbots/sessions/${sessionId}/queries`, {
      text,
    });
  },
  getMessagesBySessionId({
    sessionId,
  }: {
    sessionId: string;
  }): Promise<AxiosResponse<MessagesType>> {
    return api.get(`ai-chatbots/sessions/${sessionId}/queries`);
  },
  deleteSessionById({
    sessionId,
  }: {
    sessionId: string;
  }): Promise<AxiosResponse<void>> {
    return api.delete('ai-chatbots/sessions', {
      data: {
        id: sessionId,
      },
    });
  },
  changeName({ sessionId, newName }: { sessionId: string; newName: string }) {
    return api.patch(`ai-chatbots/sessions/${sessionId}`, {
      name: newName,
    });
  },
};

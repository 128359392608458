import LogoIcon from '../../../../../../images/logo.svg';
import './ui-header.css';

export function UiHeader() {
  return (
    <header className="ui-header">
      {/*TODO: поправить размеры картинок (в дизайне картинка 112/36), но в этой большие внутренние отступы */}
      <img width="147" height="48" src={LogoIcon} alt="logo" />
    </header>
  );
}

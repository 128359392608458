import dayjs, { ManipulateType } from 'dayjs';
import { toNumber } from 'lodash-es';

import { checkDelayTime } from './check-delay-time';

export function parseDelayTime(value: string) {
  return value.split('_');
}

export function calculateDelayTime({
  initialDate,
  delayTime,
  defaultDelayTime,
}: {
  initialDate: string;
  delayTime: string;
  defaultDelayTime: string;
}) {
  const delayTimeValue = checkDelayTime(delayTime)
    ? delayTime
    : defaultDelayTime;

  const [count, unit] = parseDelayTime(delayTimeValue);

  return dayjs(initialDate).add(toNumber(count), unit as ManipulateType);
}

import { atom, selector } from 'recoil';

import { getGeo } from '../../api/new-geo';
import { IGeo } from '../../types/Geo';

export const GeoState = atom<IGeo | null>({
  key: 'GeoState',
  default: null,
});

export const GeoQueryState = selector({
  key: 'GeoQueryState',
  get: () => {
    return new Promise<IGeo>((resolve, reject) => {
      getGeo()
        .then(({ data }) => {
          resolve({
            id: data.country.alpha_3,
            groups: data.groups,
            country_name: data.country.name,
            alpha_2: data.country.alpha_2,
            numeric_repr: data.country.code,
            region_code: data.region.code,
            region_name: data.region.city,
          });
        })
        .catch(err => {
          reject({} as IGeo);
        });
    });
  },
});

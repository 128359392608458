import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

import './image-lightbox.css';

export function ImageLightbox({
  url,
  alt,
  onClose,
}: {
  url: string;
  alt?: string;
  onClose: () => void;
}) {
  const imageLightBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lock([imageLightBoxRef.current!], {
      overflowType: 'clip',
    });
    return () => {
      unlock([imageLightBoxRef.current!]);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="image-lightbox"
    >
      <div className="image-lightbox__overlay"></div>
      <div className="image-lightbox__actions">
        <button
          type="button"
          className="image-lightbox__action"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#fff"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>

        <a
          href={url}
          download
          target="_blank"
          rel="noreferrer noopener"
          className="image-lightbox__action"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#fff"
              d="M16 6a1 1 0 0 1 1 1v10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L15 17.586V7a1 1 0 0 1 1-1ZM9 21a1 1 0 0 1 1 1v2h12v-2a1 1 0 1 1 2 0v2a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-2a1 1 0 0 1 1-1Z"
            />
          </svg>
        </a>
      </div>
      <div ref={imageLightBoxRef} className="image-lightbox__content">
        <img
          className="image-lightbox__image"
          src={url}
          role={alt ? '' : 'presentation'}
          alt={alt}
        />
      </div>
    </motion.div>
  );
}

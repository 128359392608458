import { useFeatureValue } from '@growthbook/growthbook-react';
import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18next';
import { filter, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../../../shared/ui';
import { OnboardingAnalytics } from '../../../../../shared/analytics';
import { QUIZ_SCREEN_FORMAT } from '../../../../../shared/config';
import { QuizButton } from '../../../../../shared/ui';

const CONTENT = [
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.format-screen.content.interactive_courses'
      );
    },
    slug: 'interactive_courses',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.format-screen.content.audiobooks');
    },
    slug: 'audiobooks',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.format-screen.content.podcasts');
    },
    slug: 'podcasts',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.format-screen.content.blogs_forums'
      );
    },
    slug: 'blogs_forums',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.format-screen.content.articles');
    },
    slug: 'articles',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.format-screen.content.videos');
    },
    slug: 'videos',
  },
];

export function QuizFormatScreen({
  events,
}: {
  events: { onNext: () => void };
}) {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<Array<string>>([]);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  function onSelect(slug: string) {
    if (selectedContent.includes(slug)) {
      return setSelectedContent(content => filter(content, v => v !== slug));
    }

    return setSelectedContent(content => [...content, slug]);
  }

  function onNext() {
    OnboardingAnalytics.onQuizAnswerSubmit({
      question: QUIZ_SCREEN_FORMAT,
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
      // @note - send array of slugs
      answer: selectedContent.toString(),
    });

    events.onNext();
  }

  return (
    <div className="quiz-format-screen quiz-page__screen">
      <h2 className="quiz-format-screen__title quiz-page__title">
        {t('onboarding.quiz-page.format-screen.title')}
      </h2>
      <p className="quiz-format-screen__description quiz-page__description">
        {t('onboarding.quiz-page.format-screen.description')}
      </p>

      <div className="quiz-format-screen__content quiz-page__screen-content quiz-page__screen-content--with-spacing">
        {CONTENT.map((item, i) => (
          <QuizButton
            key={`quiz-format-screen-content-${i}`}
            isActive={selectedContent.includes(item.slug)}
            events={{
              onClick: () => onSelect(item.slug),
            }}
          >
            {item.text}
          </QuizButton>
        ))}
      </div>

      <AnimatePresence>
        {!isEmpty(selectedContent) && (
          <motion.div
            key="quiz-skills-screen-action"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            exit={{ y: 'calc(100% + 2rem)' }}
            className="quiz-page__actions"
          >
            <UiPrimaryButton onClick={onNext}>
              {t('onboarding.quiz-page.format-screen.action-button')}
            </UiPrimaryButton>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

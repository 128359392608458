import { useReducer } from 'react';

import { FillInAction, FillInActionType, FillInState } from '../lib/types';

function fillInReducer(state: FillInState, action: FillInAction): FillInState {
  let result = state;
  switch (action.type) {
    case FillInActionType.ADD_USER_ANSWER:
      result = {
        ...state,
        answers: [
          ...state.answers,
          {
            answer: action.payload,
            placeholder: state.activePlaceholder!,
          },
        ],
      };
      break;
    case FillInActionType.DESELECT_USER_ANSWER:
      result = {
        ...state,
        answers: state.answers.filter(({ placeholder }) => {
          return placeholder.id !== action.payload.id;
        }),
      };
      break;
    case FillInActionType.DESELECT_LAST_ANSWER: {
      if (!state.answers.length) return state;

      const sortedAnswers = [...state.answers].sort((a, b) => {
        const aId = a?.placeholder?.id ?? 0;
        const bId = b?.placeholder?.id ?? 0;
        return aId - bId;
      });

      result = {
        ...state,
        answers: sortedAnswers.slice(0, -1),
      };
      break;
    }
    case FillInActionType.RESET:
      result = {
        ...state,
        answers: [],
        activePlaceholder:
          state.fillInParts?.find(item => item.placeholder) || null,
      };
      break;
    default:
      break;
  }
  return {
    ...result,
    activePlaceholder: findNextActivePlaceholder(result),
  };
}

export function findNextActivePlaceholder({
  fillInParts,
  answers,
  activePlaceholder,
}: FillInState): {
  text: string;
  placeholder: string | undefined;
  id?: number;
} | null {
  if (!answers.length) {
    return fillInParts.find(item => item.placeholder) || null;
  }

  const answeredIds = answers.map(answer => answer.placeholder.id);

  return (
    fillInParts.find(
      item => item.placeholder && !answeredIds.includes(item.id)
    ) || null
  );
}
export function useFillInState(initialValues: FillInState) {
  const [fillInState, dispatch] = useReducer<
    (prev: FillInState, action: FillInAction) => FillInState
  >(fillInReducer, initialValues);
  return { fillInState, dispatch };
}

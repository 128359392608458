import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { GuideType } from '../../../../../guides/entities/guide';
import { GuidesV2Analytics } from '../../../analytics';

interface PathwayNavigationToolsProps {
  guides: GuideType[] | undefined;
  selectedGuide: GuideType | undefined;
  isToolsOpen: boolean;
  setIsToolsOpen: (isOpen: boolean) => void;
  getProgress: (guide: GuideType) => number;
}

export function PathwayNavigationTools({
  guides,
  selectedGuide,
  isToolsOpen,
  setIsToolsOpen,
  getProgress,
}: PathwayNavigationToolsProps) {
  const navigation = useHistory();

  function onToolClick(guide: GuideType) {
    setIsToolsOpen(false);
    GuidesV2Analytics.onToolClick({
      current_guide_id: selectedGuide?.id || '',
      guide_id: guide.id,
    });
    navigation.push(`/guide-pathway/${guide.id}`);
  }

  return (
    <div
      className={classNames('pathway-navigation__tools', {
        'pathway-navigation__tools__active': isToolsOpen,
      })}
    >
      <div className="pathway-navigation__tools__list">
        {guides?.map(guide => (
          <div
            className="pathway-navigation__tools__container"
            onClick={() => onToolClick(guide)}
            key={guide.id}
          >
            <div
              className={classNames(
                'pathway-navigatin__tools__container__image-container',
                {
                  'pathway-navigatin__tools__container__image-container__active':
                    selectedGuide?.id === guide.id,
                }
              )}
            >
              <img
                className="pathway-navigatin__tools__container__container__image"
                src={guide.image}
                alt={guide.name}
              />
            </div>
            <h2 className="pathway-navigation__tools__container__title">
              {guide.name}
            </h2>
            <p
              className={classNames(
                'pathway-navigation__tools__container__progress',
                {
                  'pathway-navigation__tools__container__progress__active':
                    selectedGuide?.id === guide.id,
                }
              )}
            >
              {getProgress(guide)}%
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;

  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }
  /* on focus */
`;

export const QuestionContainer = styled.div`
  margin: 22px 24px 24px 24px;
`;

export const QuestionText = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;

  @media screen and (min-width: 600px) {
    margin-bottom: 32px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .small {
    color: #e30202;
    margin-top: 9px;
    font-size: 14px;
  }

  .autocomplete-container {
    margin-top: 8px;
  }
`;

export const StickyButton = styled.div`
  position: relative;
  bottom: 0;
  padding: 16px 24px;
  background: #ffffff;
  width: 100%;
  border: none;

  button:disabled {
    opacity: 0.5;
  }

  button {
    width: 100%;
    height: 48px;
    background: #5653fe;
    border: 1px solid #5653fe;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 200%;
    color: #ffffff;
  }
`;

export const StyledSpan = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  button {
    margin-top: 18px;
    margin-left: 16px;
    margin-left: auto;
    background: none;
    border: none;
    color: #5653fe;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 28px;
  margin-top: 18px;
  font-weight: 400;
  text-align: center;
`;

import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
  find,
  findIndex,
  reduce,
} from 'lodash-es';

import { FullPageError } from '@/modules/ai-bots/shared/errors';
import { UiLoadingSkeleton } from '@/modules/ai-bots/shared/ui';
import {
  GuideChunk,
  GuideLessonDetailsContentType,
  GuideLessonDetailsTaskType,
  GuideLessonDetailsType,
} from '@/modules/guides/entities/guide-lesson';
import { GuideLessonDetailsFillTheGapType } from '@/modules/guides/entities/guide-lesson/types';
import { useGuideLessonQuizStore } from '@/modules/guides/entities/guide-lesson-quiz';
import { useGuideLessonTaskStore } from '@/modules/guides/entities/guide-lesson-task';
import { FillTheGapModal } from '@/modules/guides/features/fill-the-gap-modal/ui/FillTheGapModal';
import { GuideLessonScreen, GuideLessonScreenLoading } from '@/modules/guides/features/guide-lesson-screen';
import { KeepLearningBottomSheet } from '@/modules/guides/features/keep-learning-bottom-sheet';
import { GuideTaskModal } from '@/modules/guides/features/task-modal';
import {
  KeepLearningAction,
  onCloseClick,
  onKeepLearningClick,
  onKeepLearningView,
} from '@/modules/guides/shared/lib/analytics/keep-learning-analytics';

import '@/modules/ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import './guide-lesson-widget.css';

const GUIDE_COMPLETED_PERCENTAGE = 100;

export const GuideLessonWidget = (props: {
  guide?: GuideLessonDetailsType;
  isError: boolean;
  isLoading: boolean;
  isFinishLessonLoading: boolean;
  lessonProgress?: string;
  events: {
    onRefetch: () => void;
    onGoBack: () => void;
    onFinishLesson: () => void;
    onOpenTask?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideLessonDetailsTaskType['id'];
    }) => void;
    onLessonChunkView?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onSkipPractice?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onSelectQuizOption?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onTriggerQuizHint?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onQuizSubmit?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isCorrect: boolean;
    }) => void;
    onTaskExerciseToastTrigger?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isVisible: boolean;
    }) => void;
    onTaskSubmitMessage?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isRetrySubmission: boolean;
    }) => void;
    onTaskReceivesAnswer?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isSuccess: boolean;
      isSubmissionCorrect: boolean;
    }) => void;
  };
}) => {
  const {
    guideId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  } = useParams();
  const {
    events, guide, lessonProgress, isLoading, isError, isFinishLessonLoading,
  } = props;
  const [parent] = useAutoAnimate();

  const clearGuideLessonTaskStore = useGuideLessonTaskStore(
    state => state.clear,
  );
  const clearGuideLessonQuizStore = useGuideLessonQuizStore(
    state => state.clear,
  );

  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const [currentOpenTask, setCurrentOpenTask] =    useState<GuideLessonDetailsTaskType>();

  const [currentFillTheGap, setCurrentFillTheGap] =    useState<GuideLessonDetailsFillTheGapType>();
  const [isFillTheGapModalVisible, setIsFillTheGapModalVisible] =    useState<boolean>(false);

  const [currentGuideProgressPercent, setCurrentGuideProgressPercent] =    useState(0);

  const [currentVisibleScreenId, setCurrentVisibleScreenId] =    useState<GuideLessonDetailsContentType['id']>();

  const [isKeepLearningOpen, setIsKeepLearningOpen] = useState(false);

  const onGoBackClick = () => {
    onCloseClick({ guideId, lessonId });
    setIsKeepLearningOpen(true);
  };

  const onKeepLearningClose = () => {
    setIsKeepLearningOpen(false);
  };

  const onKeepLearningSheetView = () => {
    onKeepLearningView({ guideId, lessonId });
  };

  const onKeepLearningSheetClick = (action: KeepLearningAction) => {
    onKeepLearningClick({ guideId, lessonId, action });
  };

  useEffect(() => {
    window.scroll(0, 0);

    return () => {
      clearGuideLessonTaskStore();
      clearGuideLessonQuizStore();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guide && !currentVisibleScreenId) {
      setCurrentVisibleScreenId(guide.content[0].id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guide]);

  useEffect(() => {
    setCurrentGuideProgressPercent(
      lessonProgress === 'completed' ? GUIDE_COMPLETED_PERCENTAGE : 0,
    );
  }, [lessonProgress]);

  const chunkProgressWeight = useMemo(() => {
    if (!guide) {
      return 0;
    }

    const chunksCount = reduce(
      guide.content,
      (sum, item) => sum + item.content.length,
      0,
    );

    /*
     * @description
     * When the last chunk is shown,
     * the lesson is considered completed.
     * Therefore, you need to subtract 1 for a correct calculation
     * */
    return GUIDE_COMPLETED_PERCENTAGE / (chunksCount - 1);
  }, [guide]);

  function onSaveProgress() {
    if (currentGuideProgressPercent < GUIDE_COMPLETED_PERCENTAGE) {
      setCurrentGuideProgressPercent(v => v + chunkProgressWeight);
    }
  }

  const currentVisibleScreen = useMemo(
    () => (guide
      ? find(guide.content, ['id', currentVisibleScreenId])
      : null),
    [currentVisibleScreenId, guide],
  );

  const nextVisibleScreenId = useMemo(() => {
    if (!guide) {
      return null;
    }

    const currentVisibleScreenIdIndex = findIndex(guide.content, [
      'id',
      currentVisibleScreenId,
    ]);

    if (currentVisibleScreenIdIndex === guide.content.length - 1) {
      return null;
    }

    return guide.content[currentVisibleScreenIdIndex + 1].id;
  }, [currentVisibleScreenId, guide]);

  function onGoToNextScreen() {
    if (nextVisibleScreenId) {
      setCurrentVisibleScreenId(nextVisibleScreenId);
    }
  }

  function onOpenFillTheGap(task: GuideLessonDetailsFillTheGapType) {
    setCurrentFillTheGap(task);
    setIsFillTheGapModalVisible(true);

    if (guide && currentVisibleScreen) {
      // analytics in there
    }
  }

  function onOpenTask(task: GuideLessonDetailsTaskType) {
    setCurrentOpenTask(task);
    setIsTaskModalVisible(true);

    if (guide && currentVisibleScreen) {
      events.onOpenTask?.({
        place:   guide.type,
        pageId:  currentVisibleScreen.id,
        chunkId: task.id,
      });
    }
  }

  function onCloseTaskModal() {
    setIsTaskModalVisible(false);
    setCurrentOpenTask(undefined);
  }

  function onCloseFillTheGapModal() {
    setIsFillTheGapModalVisible(false);
    setCurrentFillTheGap(undefined);
  }

  function onLessonChunkView(chunkId: GuideChunk['id']) {
    if (guide && currentVisibleScreen) {
      events.onLessonChunkView?.({
        place:  guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onSkipPractice(chunkId: GuideChunk['id']) {
    if (guide && currentVisibleScreen) {
      events.onSkipPractice?.({
        place:  guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onSelectQuizOption(chunkId: GuideChunk['id']) {
    if (guide && currentVisibleScreen) {
      events.onSelectQuizOption?.({
        place:  guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onTriggerQuizHint(chunkId: string) {
    if (guide && currentVisibleScreen) {
      events.onTriggerQuizHint?.({
        place:  guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onQuizSubmit({
    chunkId,
    isCorrect,
  }: {
    chunkId: string;
    isCorrect: boolean;
  }) {
    if (guide && currentVisibleScreen) {
      events.onQuizSubmit?.({
        place:  guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
        isCorrect,
      });
    }
  }

  function onTaskExerciseToastTrigger(isVisible: boolean) {
    if (guide && currentOpenTask && currentVisibleScreen) {
      events.onTaskExerciseToastTrigger?.({
        place:   guide.type,
        pageId:  currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isVisible,
      });
    }
  }

  function onTaskSubmitMessage(isRetrySubmission: boolean) {
    if (guide && currentOpenTask && currentVisibleScreen) {
      events.onTaskSubmitMessage?.({
        place:   guide.type,
        pageId:  currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isRetrySubmission,
      });
    }
  }

  function onTaskReceivesAnswer({
    isSuccess,
    isSubmissionCorrect,
  }: {
    isSuccess: boolean;
    isSubmissionCorrect: boolean;
  }) {
    if (guide && currentOpenTask && currentVisibleScreen) {
      events.onTaskReceivesAnswer?.({
        place:   guide.type,
        pageId:  currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isSuccess,
        isSubmissionCorrect,
      });
    }
  }

  return (
    <>
      <main className="ui-base-layout min-vh-full guide-lesson-widget">
        <nav className="guide-lesson-widget__navigation">
          <button
            type="button"
            className="guide-lesson-widget__close-button"
            onClick={onGoBackClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                // eslint-disable-next-line max-len
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>

          {isLoading ? (
            <UiLoadingSkeleton className="guide-lesson-widget__progress" />
          ) : (
            <div className="guide-lesson-widget__progress">
              <div
                className="guide-lesson-widget__progress-line"
                style={{ width: `${currentGuideProgressPercent}%` }}
              />
            </div>
          )}
        </nav>

        <section
          ref={parent}
          className="guide-lesson-widget__content"
        >
          {isError && (
            <FullPageError
              withFooter={false}
              onRetry={events.onRefetch}
            />
          )}
          {!isError && isLoading && <GuideLessonScreenLoading />}
          {!isError && !isLoading && guide && currentVisibleScreen && (
            <GuideLessonScreen
              key={currentVisibleScreen.id}
              screen={currentVisibleScreen}
              isFinishLoading={isFinishLessonLoading}
              type={guide.type}
              events={{
                onGoToNextScreen,
                onSaveProgress,
                onOpenTask,
                onOpenFillTheGap,
                onFinishLesson: events.onFinishLesson,
                onLessonChunkView,
                onSkipPractice,
                onSelectQuizOption,
                onTriggerQuizHint,
                onQuizSubmit,
              }}
            />
          )}
        </section>
      </main>

      {guide
        && isTaskModalVisible
        && currentOpenTask
        && currentVisibleScreen && (
        <GuideTaskModal
          guideType={guide.type}
          pageId={currentVisibleScreen.id}
          type={guide.type}
          task={currentOpenTask}
          events={{
            onClose:                onCloseTaskModal,
            onExerciseToastTrigger: onTaskExerciseToastTrigger,
            onSubmitMessage:        onTaskSubmitMessage,
            onReceivesAnswer:       onTaskReceivesAnswer,
          }}
        />
      )}

      {guide
        && isFillTheGapModalVisible
        && currentFillTheGap
        && currentVisibleScreen && (
        <FillTheGapModal
          guideType={guide.type}
          pageId={currentVisibleScreen.id}
          type={guide.type}
          task={currentFillTheGap}
          events={{
            onClose:                onCloseFillTheGapModal,
            onExerciseToastTrigger: onTaskExerciseToastTrigger,
            onSubmitMessage:        onTaskSubmitMessage,
            onReceivesAnswer:       onTaskReceivesAnswer,
          }}
        />
      )}
      <KeepLearningBottomSheet
        isVisible={isKeepLearningOpen}
        onClose={onKeepLearningClose}
        onGoBack={events.onGoBack}
        events={{
          onView:  onKeepLearningSheetView,
          onClick: onKeepLearningSheetClick,
        }}
      />

    </>
  );
};

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface PersonalGoalStore {
  currentGoal: string;
  setCurrentGoal: (goal: string) => void;
  clear: () => void;
}

export const usePersonalGoalStore = create<PersonalGoalStore>()(
  immer(set => ({
    currentGoal: '',
    setCurrentGoal: (goal: string) =>
      set(state => {
        state.currentGoal = goal;
      }),
    clear: () =>
      set(state => {
        state.currentGoal = '';
      }),
  }))
);

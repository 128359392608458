import { CustomerMessage } from '../../../../ai-bots/entities/message';
import { AiMessage } from '../../../../ai-bots/entities/message/ui/AiMessage';
import { MessageLoading } from '../../../../ai-bots/entities/message/ui/MessageLoading';

export function GuideMessage({
  message,
}: {
  message: {
    id: string;
    text: string;
    response: string;
  };
}) {
  return (
    <div>
      <CustomerMessage
        key={`customer_message_${message.id}`}
        text={message.text}
      />
      {message.response ? (
        <AiMessage key={`ai_message_${message.id}`} text={message.response} />
      ) : (
        <MessageLoading key={`message_loading_${message.id}`} />
      )}
    </div>
  );
}

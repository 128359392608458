import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ChallengesListAPI } from './api';

const LIST_QUERY_KEY = ['challenges-list-query'];

export function useChallengesListQuery() {
  return useQuery({
    queryKey: LIST_QUERY_KEY,
    queryFn: () => ChallengesListAPI.getChallengesList().then(res => res.data),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function usePrefetchChallengesListQuery() {
  const queryClient = useQueryClient();

  function prefetchChallengesListQuery() {
    return queryClient.prefetchQuery({
      queryKey: LIST_QUERY_KEY,
      queryFn: () =>
        ChallengesListAPI.getChallengesList().then(res => res.data),
    });
  }

  return {
    prefetchChallengesListQuery,
  };
}

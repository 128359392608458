import { atom } from 'recoil';

export const dailyGiftPopupState = atom({
  key: 'dailyGiftPopupQuery',
  default: false,
});

export const showLoadingSpinner = atom({
  key: 'showLoadingSpinner',
  default: false,
});

export const showCheckMailModal = atom({
  key: 'showCheckMailModal',
  default: { open: false, errorMessage: '' },
});

export const showResetPasswordForm = atom({
  key: 'showResetPasswordForm',
  default: false,
});

export const showCongratulationsOnReset = atom({
  key: 'showCongratulationsOnReset',
  default: false,
});

export const showMentorChatPopupState = atom({
  key: 'showMentorChatPopupState',
  default: false,
});

export const showChatGemShopState = atom({
  key: 'showChatGemShopState',
  default: false,
});

import styled from 'styled-components';

interface ProgressStepProps {
  success: boolean;
  active: boolean;
}

interface ProgressBarProps {
  activeStep: number;
}

export const ProgressContainer = styled.div`
  margin: 60px 24px 0 24px;
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 3rem 0 0 0;
  max-width: 604px;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
    z-index: -1;
    background: linear-gradient(
      to right,
      ${props => (props.activeStep > 1 ? '#5653FE' : '#dee2e6')} 0% 33%,
      ${props => (props.activeStep > 2 ? '#5653FE' : '#dee2e6')} 33% 66%,
      ${props => (props.activeStep > 3 ? '#5653FE' : '#dee2e6')} 66% 100%
    );

    @media screen and (min-width: 768px) {
      height: 3px;
    }
  }
`;

export const ProgressStep = styled.div<ProgressStepProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;

    svg {
      width: 70%;
      height: 70%;
    }
  }

  ::before {
    content: attr(data-title);
    max-width: 54px;
    position: absolute;
    top: -2.5rem;

    text-align: center;
    font-weight: ${({ active }) => (active ? 500 : 400)};
    font-size: 10px;
    line-height: 12px;
    color: ${({ active }) => (active ? '#0B0B1D' : '#8A8A8E')};

    font-size: 16px;

    @media screen and (max-width: 320px) {
      max-width: 54px;
    }

    @media screen and (max-width: 360px) {
      top: -1.5rem;
      transform: translate(0%, -50%);
    }

    @media screen and (max-width: 768px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      max-width: 72px;
    }

    @media screen and (min-width: 768px) {
      min-width: 120px;
    }
  }

  border: 2px solid;

  @media screen and (min-width: 768px) {
    border-width: 3px;
  }

  ${props => {
    if (props.success) {
      return `
            background-color: #5653FE;
            border: #5653FE;
        `;
    } else if (props.active) {
      return `
            border-color: #5653FE;
            background-color: #FFFFFF;
        `;
    } else {
      return `
            border-color: #C8CED7;
            background-color: #FFFFFF;
        `;
    }
  }}
`;

import Cookies from 'js-cookie';
import { lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { languageState } from '../../context/atoms/Language';
import i18n from '../../i18n';
import cognitoService from '../../services/cognito.service';
import {
  getAppLanguageCode,
  getExistedAppLanguage,
  setAppLanguage,
} from '../../utils/Language/Language';
import { getQueryParamsFromURI } from '../../utils/QueryParams/QueryParams';
import PasswordReset from './PasswordReset/PasswordReset';
import SignIn from './Signin/Signin';
import { SignUpPage } from './Signup/SignUpPage';

const LazyHelpWidget = lazy(() => import('../../modules/shared/HelpWidget'));

const AuthRoutes = () => {
  const [lang, setLanguage] = useRecoilState(languageState);
  const [isInitial, setIsInitial] = useState(true);
  const queryParams = getQueryParamsFromURI();
  const [languageCode, setLanguageCode] = useRecoilState(languageState);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleCheckSubscription = () => {
    cognitoService
      .getCurrentUser()
      .then(res => {
        if (!res) return;
        setIsLoggedIn(true);
      })
      .catch(() => {});
  };

  useEffect(() => {
    handleCheckSubscription();
  }, []);

  useEffect(() => {
    if (!i18n.language || !isInitial) return;

    setIsInitial(false);
    setLanguage(getAppLanguageCode());
  }, [i18n.language]);

  useEffect(() => {
    if (
      !queryParams ||
      !queryParams.lang ||
      Cookies.get('set_lang') === queryParams.lang
    )
      return;
    const lang = getExistedAppLanguage(queryParams.lang);

    Cookies.set('set_lang', lang);

    setLanguageCode(lang);
    setAppLanguage(lang, null, true);
  }, [queryParams.lang]);

  return (
    <Switch>
      <Route
        path="/auth/help-widget/:languageCode"
        component={LazyHelpWidget}
      />
      <Route exact path="/auth/signup" component={SignUpPage} />
      <Route exact path="/auth/reset-password" component={PasswordReset} />
      {isLoggedIn && <Redirect to="/" />}
      <Route exact path="/auth/signin" component={SignIn} />
    </Switch>
  );
};

export default AuthRoutes;

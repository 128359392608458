import { TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import circle_information from '../../../../images/circle_information.svg';
import x_mark from '../../../../images/x_mark.svg';
import {
  BtnContainer,
  DialogContainer,
  Header,
  HeaderIcon,
  Wrapper,
} from './VerificationModal.styles';

export default function VerificationModal({
  emailValidation,
  email,
  onchange,
  modalState,
  closeAction,
  submitAction,
}: any) {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={modalState}
        onClose={closeAction}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper>
          <Header>
            <span></span>
            <HeaderIcon onClick={closeAction}>
              <img src={x_mark} alt="" />
            </HeaderIcon>
          </Header>
          <DialogContainer>
            <p className="title">{t('Almost finished')}</p>
            <p className="subtitle">{t('Check your email one more time 👀')}</p>

            <TextField
              {...emailValidation}
              sx={{ width: 1 }}
              id="email"
              type="text"
              label={t('Email')}
              name="email"
              margin="normal"
              defaultValue={email && `${email}`}
              onChange={e => {
                onchange(e.target.value);
              }}
              placeholder={t('example@gmail.com')}
              InputLabelProps={{ shrink: true }}
            />

            <div className="email-warning">
              <img src={circle_information} alt="" />
              <p>
                <strong>{t('Access')}</strong>{' '}
                {t('to investment know-how will be linked to this address')}
              </p>
            </div>

            <BtnContainer>
              <button
                type="button"
                className="btn-confirm"
                onClick={submitAction}
              >
                {t('Confirm')}
              </button>
            </BtnContainer>
          </DialogContainer>
        </Wrapper>
      </Modal>
    </div>
  );
}

import { AxiosResponse } from 'axios';

import { api } from '../../../../services/http.service';

export interface ChallengeListItemType {
  id: string;
  name: string;
  description: string;
  image: string;
  duration: string;
  level: string;
  is_released: boolean;
  social_proof_image: string;
  tasks_count: number;
  ordering: number;
}

export interface ChallengesListType {
  items: Array<ChallengeListItemType>;
}

export const ChallengesListAPI = {
  getChallengesList(): Promise<AxiosResponse<ChallengesListType>> {
    return api.get('daily-challenges/');
  },
};

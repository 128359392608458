import Cookies from 'js-cookie';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { languageState } from '../../../context/atoms/Language';
import i18n from '../../../i18n';
import cross from '../../../images/cross_icon.svg';
import lang_check from '../../../images/language/lang_check.svg';
import { analyticsService } from '../../../services/analytics.service';
import { languages } from '../../../types/Language';
import BaseBottomsheet from '../BaseBottomsheet/BaseBottomsheet';
import { LanguageContainer } from './LanguageBottomsheet.styles';

interface IProps {
  handleClose: () => void;
}

const LanguageBottomsheet: FC<IProps> = ({ handleClose }) => {
  const [languageCode, setLanguageCode] = useRecoilState(languageState);
  const { t } = useTranslation();

  const handleChangeLanguage = (langCode: string) => {
    setLanguageCode(langCode);
    i18n.changeLanguage(langCode);
    Cookies.set('sys_lang', langCode);
    Cookies.set('set_lang', langCode);
    analyticsService.sendEvent('pr_webapp_language_set', {
      set_language: langCode,
      place: 'auth',
    });
    handleClose();
  };

  return (
    <BaseBottomsheet onClose={handleClose}>
      <LanguageContainer>
        <div className="navbar">
          <div></div>
          <p className="title">{t('Language')}</p>
          <div onClick={handleClose}>
            <img src={cross} alt="" />
          </div>
        </div>
        <div>
          {languages.map(language => (
            <div
              className="lang-item"
              key={language.code2}
              onClick={() => {
                handleChangeLanguage(language.code2);
              }}
            >
              <div className="lang-detail">
                <img src={language.img} alt="" />

                <p className="lang-title">{language.language}</p>
              </div>
              {language.code2 === languageCode && (
                <img src={lang_check} alt="arrow-icon" />
              )}
            </div>
          ))}
        </div>
      </LanguageContainer>
    </BaseBottomsheet>
  );
};

export default LanguageBottomsheet;

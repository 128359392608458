/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useState } from 'react';

import classNames from 'classnames';

import { ProgressIndicator } from '../../../../../../entities/progress-indicator';
import BackArrow from '../../../../../../images/back_arrow.svg';
import useCertificatesExperiment from '../../../../../../shared/lib/hooks/use-certificates-experiment';
import { GuideType, GuideUnitType } from '../../../../../guides/entities/guide';
import { Toast } from '../../../../pages/learning-pathway/ui/LearningPathway';
import { GuidesV2Analytics } from '../../../analytics';
import { useOnClickOutside } from '../../../lib';

import { PathwayNavigationToast } from './PathwayNavigationToast';
import { PathwayNavigationTools } from './PathwayNavigationTools';
import './assets/pathway-navigation.css';

interface PathwayNavigationProps {
  title: string | JSX.Element | JSX.Element[];
  className?: string;
  onBackButtonClicked: () => void;
  toastValue: Toast | undefined;
  toastRef: React.RefObject<HTMLDivElement>;
  guides: GuideType[] | undefined;
  progress: Record<string, number> | undefined;
  selectedGuide: GuideType | undefined;
}

export const PathwayNavigation = ({
  title,
  className,
  onBackButtonClicked,
  toastValue,
  toastRef,
  guides,
  progress,
  selectedGuide,
}: PathwayNavigationProps) => {
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const toolsRef = useRef<HTMLDivElement>(null);

  const { isTest: isCertificateEnabled } = useCertificatesExperiment();

  useOnClickOutside(toolsRef, () => {
    setTimeout(() => {
      if (isToolsOpen) {
        setIsToolsOpen(false);
      }
    }, 200);
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    GuidesV2Analytics.onDropdownClick({
      guide_id: selectedGuide?.id || '',
    });
    setIsToolsOpen(prevState => !prevState);
  };

  function getProgress(guide: GuideType) {
    return progress ? Math.round(progress[guide.id] * 100) : 0;
  }

  const onToastClick = (unit: GuideUnitType | undefined) => {
    if (!unit || unit.ordering === 1) {
      return window.scroll({ top: 0, behavior: 'smooth' });
    }

    const element = document.querySelector(`.separator_${unit.id}`);

    if (element instanceof HTMLElement) {
      const middle =        element.getBoundingClientRect().top
        + window.scrollY
        - window.innerHeight / 2;

      window.scrollTo({ top: middle, behavior: 'smooth' });
    }

    return undefined;
  };

  const onProgressClick = () => {
    GuidesV2Analytics.onCertificateProgressClick();
    const element = document.querySelector('.guide-certificate__container');

    if (element instanceof HTMLElement) {
      const middle = element.getBoundingClientRect().bottom;

      window.scrollTo({ top: middle, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div ref={toolsRef}>
        <nav
          className={classNames('pathway-navigation', className, {
            'pathway-navigation__active': isToolsOpen,
          })}
        >
          <div className="z-10">
            <button
              type="button"
              className="pathway-navigation__back-button"
              onClick={() => onBackButtonClicked()}
            >
              <img
                width="24"
                height="24"
                src={BackArrow}
                alt="go back"
              />
            </button>
          </div>
          <div className="pathway-navigation__title">
            <div
              className="flex items-center"
              onClick={handleClick}
            >
              {typeof title === 'string' ? <h1>{title}</h1> : title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className={classNames('pathway-navigation__title-path', {
                  'pathway-navigation__title-path__active': isToolsOpen,
                })}
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  // eslint-disable-next-line max-len
                  d="M4.41058 8.24408C4.73602 7.91864 5.26366 7.91864 5.58909 8.24408L9.99984 12.6548L14.4106 8.24408C14.736 7.91864 15.2637 7.91864 15.5891 8.24408C15.9145 8.56951 15.9145 9.09715 15.5891 9.42259L10.5891 14.4226C10.2637 14.748 9.73602 14.748 9.41058 14.4226L4.41058 9.42259C4.08514 9.09715 4.08514 8.56951 4.41058 8.24408Z"
                  fill="#24234C"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex items-center z-10"
          >
            {selectedGuide && isCertificateEnabled && (
              <ProgressIndicator
                onClick={onProgressClick}
                progress={getProgress(selectedGuide)}
              />
            )}
          </div>
        </nav>
        <PathwayNavigationTools
          guides={guides}
          selectedGuide={selectedGuide}
          isToolsOpen={isToolsOpen}
          setIsToolsOpen={setIsToolsOpen}
          // eslint-disable-next-line react/jsx-no-bind
          getProgress={getProgress}
        />
      </div>
      {toastValue && (
        <PathwayNavigationToast
          toastValue={toastValue}
          toastRef={toastRef}
          isToolsOpen={isToolsOpen}
          onToastClick={onToastClick}
        />
      )}
    </div>
  );
};

import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import type {
  GuideLessonType,
  GuideType,
  GuideUnitType,
} from '../../../entities/guide';
import type { GuideUnitProgress } from '../../../entities/guide-progress';
import { type ReturnLessonEnd, type ReturnUnitEnd } from '../../../shared/lib';
import { GuideUnitLesson } from './GuideUnitLesson';
import './guide-unit.css';

export function GuideUnit({
  guideId,
  nextLesson,
  unit,
  progress,
  isInitialVisible = false,
  events,
}: {
  guideId: GuideType['id'];
  nextLesson: ReturnLessonEnd | ReturnUnitEnd | null;
  unit: GuideUnitType;
  progress: GuideUnitProgress;
  isInitialVisible?: boolean;
  events: {
    onGoToLesson: (lesson: GuideLessonType) => void;
  };
}) {
  const [parent] = useAutoAnimate();
  const [isVisible, setIsVisible] = useState(isInitialVisible);
  const [selectedLessonId, setSelectedLessonId] = useState('');

  useEffect(() => {
    if (nextLesson) {
      return setSelectedLessonId(nextLesson.lessonId);
    }

    setSelectedLessonId(unit.content[0].id);
  }, [nextLesson]);

  function onSelectLesson(lesson: GuideLessonType) {
    setSelectedLessonId(lesson.id);
  }

  return (
    <div ref={parent} className="guide-unit">
      <button
        type="button"
        className={classNames('guide-unit__trigger', {
          'guide-unit__trigger--active': isVisible,
        })}
        onClick={() => setIsVisible(isVisible => !isVisible)}
      >
        <strong>{unit.name}</strong>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
        >
          <path
            fill="#565B66"
            d="M9.293 13.293a1 1 0 0 1 1.414 0L16 18.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z"
          />
        </svg>
      </button>

      {isVisible && (
        <div className="guide-unit__content">
          {unit.content.map(lesson => (
            <GuideUnitLesson
              key={lesson.id}
              guideId={guideId}
              unitId={unit.id}
              nextLesson={nextLesson}
              lesson={lesson}
              progress={progress}
              isSelected={lesson.id === selectedLessonId}
              events={{
                onSelect: onSelectLesson,
                onGoToLesson: events.onGoToLesson,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

import { Analytics } from './analytics';
import { Attribution } from './attribution';
import { Identity } from './identity';
import type { SentinelConfig } from './types';
import { User } from './user';

export function SentinelSDK(config: SentinelConfig) {
  const identity = new Identity(config);
  const attribution = new Attribution(config, identity);
  const user = new User();
  const analytics = new Analytics(config, identity, user, attribution);

  return {
    identity,
    analytics,
    attribution,
    user,
  };
}

import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.scss';

export const MessageError = ({ text }: { text: string }) => (
  <div className="ai-bot-message-v2 ai-bot-message_ai-message-v2">
    <img
      width="24"
      height="24"
      src={AiAvatarIcon}
      role="presentation"
      alt="Message contains error"
    />
    <div className="ai-bot-message__error-block">
      <div className="ai-bot-message__error-block-content">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // eslint-disable-next-line max-len
            d="M8.00016 2.66671C5.05464 2.66671 2.66683 5.05452 2.66683 8.00004C2.66683 10.9456 5.05464 13.3334 8.00016 13.3334C10.9457 13.3334 13.3335 10.9456 13.3335 8.00004C13.3335 5.05452 10.9457 2.66671 8.00016 2.66671ZM1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004ZM5.19543 5.1953C5.45577 4.93495 5.87788 4.93495 6.13823 5.1953L8.00016 7.05723L9.86209 5.1953C10.1224 4.93495 10.5446 4.93495 10.8049 5.1953C11.0653 5.45565 11.0653 5.87776 10.8049 6.13811L8.94297 8.00004L10.8049 9.86197C11.0653 10.1223 11.0653 10.5444 10.8049 10.8048C10.5446 11.0651 10.1224 11.0651 9.86209 10.8048L8.00016 8.94285L6.13823 10.8048C5.87788 11.0651 5.45577 11.0651 5.19543 10.8048C4.93508 10.5444 4.93508 10.1223 5.19543 9.86197L7.05735 8.00004L5.19543 6.13811C4.93508 5.87776 4.93508 5.45565 5.19543 5.1953Z"
            fill="#D43333"
          />
        </svg>

        {text}
      </div>
    </div>
  </div>
);

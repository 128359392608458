import classNames from 'classnames';

import './assets/level-picker-button.css';

export function LevelPickerButton({
  title,
  isActive,
  description,
  icon,
  onClick,
}: {
  title: string;
  isActive: boolean;
  description: string;
  icon: string;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className={classNames('level-picker-button', {
        'level-picker-button--active': isActive,
      })}
      onClick={onClick}
    >
      <span className="level-picker-button__content">
        <strong className="level-picker-button__title">{title}</strong>
        <span className="level-picker-button__description">{description}</span>
      </span>
      <img
        width="40"
        height="40"
        className="level-picker-button__icon"
        src={icon}
        role="presentation"
        alt=""
      />
    </button>
  );
}

import { size } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { ChallengeJoinedCard } from '../../../entities/challenge-joined-card';
import { ChallengeListItemType } from '../../../entities/list';
import type { ChallengesListProgressType } from '../../../entities/progress';
import { ChallengesAnalytics } from '../../../shared/analytics';
import './challenges-slider.css';

export function ChallengesSlider(props: {
  list: Array<ChallengeListItemType & ChallengesListProgressType>;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();

  const isViewAllLinkVisible = useMemo(
    () => size(props.list) > 3,
    [props.list]
  );

  function onSlideCardClick(
    item: ChallengeListItemType & ChallengesListProgressType
  ) {
    ChallengesAnalytics.onCardClick({
      is_completed: item.is_completed,
      challenge_id: item.id,
    });

    if (item.is_joined) {
      navigation.push(`/challenges/${item.id}/tasks`);
    } else {
      navigation.push(`/challenges/${item.id}`);
    }
  }

  return (
    <div className="challenges-slider">
      <div className="challenges-slider__headline">
        <h4>{t('challenges.joined.title')}</h4>
        {isViewAllLinkVisible && (
          <Link to={`/challenges/joined`}>
            {t('challenges.joined.view-all-button')}
          </Link>
        )}
      </div>

      <ul className="challenges-slider__container">
        {props.list.map(item => (
          <li
            key={`challenges-slider-${item}`}
            className="challenges-slider__item"
          >
            <ChallengeJoinedCard
              item={item}
              events={{ onClick: () => onSlideCardClick(item) }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

import ChallengesListEmptyImage from './assets/empty-challenges.svg';

export function EmptyChallenges() {
  const { t } = useTranslation();

  return (
    <div className="challenges-list-empty">
      <img
        width="312"
        height="240"
        src={ChallengesListEmptyImage}
        role="presentation"
        alt=""
      />

      <h4>{t('challenges.list-empty.title')}</h4>
      <p>{t('challenges.list-empty.description')}</p>
    </div>
  );
}

import { AiBotTool } from '../../ai-bot/types';
import ChevronIcon from '../ui/assets/chevron_right.svg';
import './ai-bot-card.css';

export function AiBotCardV2({
  data,
  onClick,
}: {
  data: AiBotTool;
  onClick?: () => void;
}) {
  return (
    <div className="ai-bot-card-v2" onClick={onClick}>
      <div className="flex justify-between items-start mb-[9px]">
        <div className="p-[3px]">
          <img
            className="ai-bot-card-v2__image"
            width="26"
            height="26"
            src={data.image || ''} //@note For custom bots add default image (or make image required)
            role="presentation"
            alt=""
          />
        </div>
        <img src={ChevronIcon} alt="chevron-right" height={16} width={16} />
      </div>
      <div className="ai-bot-card-v2__content">
        <h2>{data.name}</h2>
        <p className="ai-bot-card-v2__description">{data.description}</p>
      </div>
    </div>
  );
}

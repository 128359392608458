import { AxiosResponse } from 'axios';

import { api } from '../../services/http.service';

export const TrustpilotAPI = {
  getTrustpilotLink({
    username,
    email,
  }: {
    username: string;
    email: string;
  }): Promise<AxiosResponse<{ link: string }>> {
    return api.post('feedback/trustpilot/invitation-link/', {
      name: username,
      email,
    });
  },
};

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';

const StartTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#24234C',
    padding: '6px 14px',
    border: '1.5px #E2E5E9 solid',
    borderRadius: '8px',
    lineHeight: '28px',
    fontSize: '24px',
    fontWeight: 'bold',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.03)',
    zIndex: 99999,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFFFFF',
    fontSize: '16px',
    '&:before': {
      border: '1px solid #E2E5E9',
    },
  },
}));

export default StartTooltip;

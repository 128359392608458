import { useTranslation } from 'react-i18next';

import { CloseButton } from './CloseButton';
import './assets/satisfaction-survey-success.css';
import SuccessImage from './assets/success-image.svg';

export function SatisfactionSurveySuccess({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="satisfaction-survey-success">
      <div className="satisfaction-survey-success__header">
        <CloseButton onClick={onDismiss} />
      </div>
      <div className="satisfaction-survey-success__content">
        <img
          width="200"
          height="200"
          src={SuccessImage}
          role="presentation"
          alt=""
        />
        <h1 className="satisfaction-survey-success__title">
          {t('satisfaction-survey.success.title')}
        </h1>
        <p className="satisfaction-survey-success__description">
          {t('satisfaction-survey.success.description')}
        </p>
      </div>
    </div>
  );
}

import { v4 as uuidv4 } from 'uuid';

import { SentinelConfig } from './types';

export class Identity {
  private readonly _deviceId: string;

  private _userId: number | null;

  constructor(private config: SentinelConfig) {
    this._userId = this.config.userId ?? null;
    this._deviceId = this._getOrGenerateDeviceId();
  }

  get userId(): number | null {
    return this._userId;
  }

  setUserId(id: number) {
    this._userId = id;
    this.config.events?.onUserIdUpdated?.(id);
  }

  get deviceId() {
    return this._deviceId;
  }

  private _getOrGenerateDeviceId() {
    if (this.config.deviceId) {
      return this.config.deviceId;
    }

    const newDeviceId = uuidv4();
    this.config.events?.onDeviceIdUpdated?.(newDeviceId);

    return newDeviceId;
  }
}

export function LockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#A6AAB3"
        d="M12 4c1.648 0 3 1.352 3 3v3H9V7c0-1.648 1.352-3 3-3Zm5 6V7c0-2.752-2.248-5-5-5S7 4.248 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1ZM6 12h12v8H6v-8Z"
      />
    </svg>
  );
}

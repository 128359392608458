import {
  useExperiment,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';

import { isAfterDate } from '..';

export default function useAiPathwayExperiment({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const growthbook = useGrowthBook();
  const { arrow, registered_at: registeredAt } =    growthbook?.getAttributes() ?? {};

  const aiExpStartDate = useFeatureValue('exp_ai_pathway_date', null);
  const aiExpEndDate = useFeatureValue('exp_ai_pathway_end_date', null);

  const expStartDate = useFeatureValue('exp_pathway_date', null);
  const entrepreneurStartDate = useFeatureValue(
    'exp_entrepreneur_pathway_date',
    null,
  );

  const isExperimentActive = (startDate: string | null): boolean => {
    if (
      isLoading
      || !registeredAt
      || !startDate
      || typeof registeredAt !== 'string'
    ) return false;

    return isAfterDate(registeredAt, startDate);
  };

  const experimentConfig = {
    ai: {
      key:      'ab_pathway_ai',
      isActive: isExperimentActive(aiExpStartDate) && arrow === 'ai',
    },
    remote_work: {
      key:      'ab_pathway_remote_work',
      isActive: false,
    },
    influencer: {
      key:      'ab_pathway_influencer',
      isActive: isExperimentActive(expStartDate) && arrow === 'influencer',
    },
    entrepreneur: {
      key: 'ab_pathway_entrepreneur',
      isActive:
        isExperimentActive(entrepreneurStartDate) && arrow === 'entrepreneur',
    },
  };

  const experimentKey =    experimentConfig[arrow as keyof typeof experimentConfig]?.key ?? '';
  const isActive =    experimentConfig[arrow as keyof typeof experimentConfig]?.isActive ?? false;

  const { value } = useExperiment<'clean' | 'test'>({
    key:        experimentKey,
    variations: ['test', 'clean'],
    active:     isActive,
  });

  const shouldForceTestVariation = (expKey: string): boolean => {
    if (!registeredAt || typeof registeredAt !== 'string') {
      return false;
    }

    const isRelevantExperiment = expKey === 'ab_pathway_ai' || expKey === 'ab_pathway_entrepreneur';

    if (!isRelevantExperiment || !aiExpEndDate || !entrepreneurStartDate) {
      return false;
    }

    const relevantDate = expKey === 'ab_pathway_ai' ? aiExpEndDate : entrepreneurStartDate;

    return isAfterDate(registeredAt, relevantDate);
  };

  if (shouldForceTestVariation(experimentKey)) {
    return { value: 'test' };
  }

  return { value: isActive ? value ?? 'default' : 'default' };
}

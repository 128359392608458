import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GuideLessonType } from '../../../../../guides/entities/guide';
import { UiPrimaryButton } from '../../../../../shared/ui';
import { useOnClickOutside } from '../../../lib';
import HtmlTooltip from './HtmlTooltip';
import './assets/lesson-tooltip.css';

interface LessonTooltipProps {
  children: ReactElement;
  tooltipValue: GuideLessonType | undefined;
  onClose: () => void;
  isLeftAligned?: boolean;
  onStartLesson: () => void;
}

export function LessonTooltip({
  children,
  isLeftAligned,
  tooltipValue,
  onClose,
  onStartLesson,
}: LessonTooltipProps) {
  const { t } = useTranslation();

  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, e => handleClose(e));

  const handleClose = useCallback(e => {
    e.stopPropagation();
    setIsToolTipOpen(false);
    onClose();
  }, []);

  const handleOpen = useCallback(() => {
    setIsToolTipOpen(true);
  }, []);

  const tooltipYOffset = useMemo(
    () => (isLeftAligned ? 95 : -95),
    [isLeftAligned]
  );

  const tooltipContent = useMemo(
    () => (
      <div className="lesson-tooltip">
        <h2 className="lesson-tooltip__title">{tooltipValue?.name}</h2>
        <p className="lesson-tooltip__description">
          {tooltipValue?.description}
        </p>
        <UiPrimaryButton
          className="lesson-tooltip__button"
          onClick={onStartLesson}
        >
          {t('guides-v2.start-lesson')}
        </UiPrimaryButton>
      </div>
    ),
    [tooltipValue]
  );

  return (
    <div>
      {isToolTipOpen && <div className="lesson-tooltip__overlay" />}
      <div ref={isToolTipOpen ? ref : null}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [tooltipYOffset, -50],
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom'],
                },
              },
            ],
          }}
          arrow
          open={isToolTipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipContent}
        >
          <div onClick={handleOpen}>{children}</div>
        </HtmlTooltip>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

import { UiSecondaryButton } from '../../../shared/ui/secondary-button';

export function DeleteSessionButton({ onClick }: { onClick?: () => void }) {
  const { t } = useTranslation();

  return (
    <UiSecondaryButton onClick={() => onClick?.()}>
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
        >
          <path
            fill="#24234C"
            d="M4.667 2.667c0-.737.597-1.334 1.333-1.334h4c.736 0 1.333.597 1.333 1.334V4h1.327a.64.64 0 0 1 .012 0H14a.667.667 0 1 1 0 1.333h-.713l-.578 8.095a1.333 1.333 0 0 1-1.33 1.239H4.621c-.7 0-1.28-.541-1.33-1.239l-.578-8.095H2A.667.667 0 1 1 2 4h2.667V2.667ZM6 4h4V2.667H6V4ZM4.05 5.333l.57 8h6.76l.57-8h-7.9Zm2.617 1.334c.368 0 .666.298.666.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.667-.666Zm2.666 0c.369 0 .667.298.667.666v4a.667.667 0 0 1-1.333 0v-4c0-.368.298-.666.666-.666Z"
          />
        </svg>
        {t('ai-bots.chat.delete-button')}
      </>
    </UiSecondaryButton>
  );
}

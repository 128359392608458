import Lottie from 'lottie-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import ConfettiLottieAnimation from '../../../../challenges/pages/complete/ui/assets/confetti-lottie.json';
import { useCertificateQuery } from '../../../../guides-v2/entites/guide-certificate';
import { ALLOWED_ARROWS } from '../../../../guides-v2/shared/lib/consts';
import { UiPrimaryButton } from '../../../../shared/ui';
import { useGuideByIdQuery } from '../../guide-by-id-page/model';
import { GuidesCertificateCompleteAnalytics } from '../analytics';
import {
  GuideCertificateCompleteSurvey,
  LevelType,
} from './GuideCertificateCompleteSurvey';
import './guide-certificate-complete-page.css';

export const GuideCertificateCompletePage = () => {
  const { t } = useTranslation();

  const navigation = useHistory();
  const { guideId }: { guideId: string } = useParams();

  const guideByIdQuery = useGuideByIdQuery({ id: guideId });

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });
  const { data: certificateData } = useCertificateQuery({ id: guideId });

  const [selectedLevel, setSelectedLevel] = useState<LevelType>();

  const isPathway = useMemo(
    () =>
      aiPathwayExperiment === 'test' &&
      guideByIdQuery.data?.tags?.some(tag => ALLOWED_ARROWS.includes(tag)),
    [aiPathwayExperiment, guideByIdQuery.data?.tags]
  );

  const redirectUrl = useMemo(
    () => (isPathway ? `/guide-pathway/${guideId}` : `/guides/${guideId}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aiPathwayExperiment, guideByIdQuery.data?.tags, guideId]
  );

  function onGoNextPage() {
    navigation.push(redirectUrl);
  }
  function onContinue() {
    if (selectedLevel) {
      GuidesCertificateCompleteAnalytics.onRatingSubmit({
        guideId,
        rating: selectedLevel,
      });
    }

    GuidesCertificateCompleteAnalytics.onContinue({
      guideId,
    });

    onGoNextPage();
  }
  function onClose() {
    if (selectedLevel) {
      GuidesCertificateCompleteAnalytics.onRatingSubmit({
        guideId,
        rating: selectedLevel,
      });
    }

    GuidesCertificateCompleteAnalytics.onCloseClick({
      guideId,
    });
    onGoNextPage();
  }
  function onSelectLevel(type: LevelType) {
    setSelectedLevel(type);
  }

  useEffect(() => {
    GuidesCertificateCompleteAnalytics.onView({
      guideId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="guide-certificate-complete-page min-vh-full">
        <div className="guide-certificate-complete-page__header">
          <button
            type="button"
            className="guide-certificate-complete-page__close-button"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                // eslint-disable-next-line max-len
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>
        </div>

        <div className="guide-certificate-complete-page__content">
          <img
            width="168"
            height="168"
            className="guide-certificate-complete-page__image -z-10"
            src={certificateData?.success_image}
            alt=""
            role="presentation"
          />

          <h1 className="guide-certificate-complete-page__title -z-10">
            {t('Congratulations!')}
          </h1>
          <p className="guide-certificate-complete-page__description -z-10">
            {t('guides-v2.complete-page.description')}
          </p>
        </div>

        <div className="guide-certificate-complete-page__actions flex-col">
          <GuideCertificateCompleteSurvey
            selectedLevel={selectedLevel}
            onSelectLevel={() => onSelectLevel}
          />

          <UiPrimaryButton onClick={() => onContinue} className="mt-6">
            {t('guides.continue-button')}
          </UiPrimaryButton>
        </div>
      </main>
      <div className="lottie-background max-vh-full">
        <Lottie animationData={ConfettiLottieAnimation} loop={false} />
      </div>
    </>
  );
};

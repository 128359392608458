import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { extend, filter, isEmpty, reduce } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LogoIcon from '../../../../../images/logo.svg';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import { AppFooter } from '../../../../shared';
import { ChallengeCard } from '../../../entities/challenge-card';
import {
  ChallengeListItemType,
  useChallengesListQuery,
} from '../../../entities/list';
import {
  type ChallengesListProgressType,
  useChallengesListProgressQuery,
} from '../../../entities/progress';
import { ChallengesSlider } from '../../../feature/challenges-slider';
import { ChallengesAnalytics } from '../../../shared/analytics';
import {
  CHALLENGES_TABS,
  type ChallengesTab,
  useChallengesMainPageStore,
} from '../model';
import ChallengesListEmptyImage from './assets/empty-challenges.svg';
import './challenges-main-page.css';

function ChallengesListLoading() {
  return (
    <div className="challenges-list">
      {Array.from({ length: 3 }, (_, i) => i + 1).map(i => (
        <UiLoadingSkeleton
          key={`challenges-list-${i}`}
          style={{
            width: '100%',
            height: '200px',
          }}
        />
      ))}
    </div>
  );
}

function ChallengesList(props: {
  list: Array<ChallengeListItemType & ChallengesListProgressType>;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();

  function onCardClick(
    item: ChallengeListItemType & ChallengesListProgressType
  ) {
    ChallengesAnalytics.onCardClick({
      is_completed: item.is_completed,
      challenge_id: item.id,
    });

    if (item.is_joined) {
      navigation.push(`/challenges/${item.id}/tasks`);
    } else {
      navigation.push(`/challenges/${item.id}`);
    }
  }

  return isEmpty(props.list) ? (
    <div className="challenges-list-empty">
      <img
        width="312"
        height="240"
        src={ChallengesListEmptyImage}
        role="presentation"
        alt=""
      />

      <h4>{t('challenges.list-empty.title')}</h4>
      <p>{t('challenges.list-empty.description')}</p>
    </div>
  ) : (
    <>
      {props.list.map(item => (
        <ChallengeCard
          key={item.id}
          title={item.name}
          level={item.level}
          duration={item.duration}
          imageUrl={item.image}
          events={{
            onClick: () => onCardClick(item),
          }}
        />
      ))}
    </>
  );
}

export function ChallengesMainPage() {
  const { t } = useTranslation();
  const [animatedListRef] = useAutoAnimate();
  const { selectedTab, setSelectedTab } = useChallengesMainPageStore();

  const listQuery = useChallengesListQuery();
  const listProgressQuery = useChallengesListProgressQuery();

  const isLoading = useMemo(
    () => listQuery.isLoading || listProgressQuery.isLoading,
    [listQuery.isLoading, listProgressQuery.isLoading]
  );

  const isError = useMemo(
    () => listQuery.isError || listProgressQuery.isError,
    [listQuery.isLoading, listProgressQuery.isLoading]
  );

  const challengesList = useMemo(() => {
    if (!listQuery.data?.items || !listProgressQuery.data) {
      return [];
    }

    return reduce(
      listQuery.data.items,
      (r: Array<ChallengeListItemType & ChallengesListProgressType>, v) => {
        const progress = listProgressQuery.data[v.id];

        if (progress) {
          r.push(extend(v, progress));
        }

        return r;
      },
      []
    );
  }, [listQuery.data, listProgressQuery.data]);

  const startedChallenges = useMemo(
    () => filter(challengesList, 'is_joined'),
    [challengesList]
  );

  const challengesListByTab = useMemo(
    () =>
      selectedTab === CHALLENGES_TABS.ALL
        ? challengesList
        : filter(challengesList, item => item.is_completed),
    [challengesList, selectedTab]
  );

  useEffect(() => {
    if (listQuery.data && listProgressQuery.data) {
      ChallengesAnalytics.homePageView();
    }
  }, [listQuery.data, listProgressQuery.data]);

  function onRefetch() {
    listQuery.refetch();
    listProgressQuery.refetch();
  }

  function onSetSelectedTab(tab: ChallengesTab) {
    setSelectedTab(tab);
  }

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main className="ui-base-layout min-vh-full challenges-main-page">
        <div className="challenges-main-page__logo">
          <img width="147" height="48" src={LogoIcon} alt="logo" />
        </div>

        {isError ? (
          <div className="ui-base-layout__content">
            <FullPageError onRetry={onRefetch} />
          </div>
        ) : (
          <>
            <div className="challenges-main-page__tabs">
              <button
                type="button"
                className={classNames('challenges-main-page__tab', {
                  '--active': selectedTab === CHALLENGES_TABS.ALL,
                })}
                onClick={() => onSetSelectedTab(CHALLENGES_TABS.ALL)}
              >
                {t('challenges.main-page.tabs.all')}
              </button>

              <button
                type="button"
                className={classNames('challenges-main-page__tab', {
                  '--active': selectedTab === CHALLENGES_TABS.COMPLETED,
                })}
                onClick={() => onSetSelectedTab(CHALLENGES_TABS.COMPLETED)}
              >
                {t('challenges.main-page.tabs.completed')}
              </button>

              <hr
                className={classNames('challenges-main-page__tabs-selector', {
                  '--completed': selectedTab === CHALLENGES_TABS.COMPLETED,
                })}
              />
            </div>

            <section
              ref={animatedListRef}
              className="challenges-main-page__content"
            >
              {isLoading ? (
                <ChallengesListLoading />
              ) : (
                <>
                  {!isEmpty(startedChallenges) &&
                    selectedTab !== CHALLENGES_TABS.COMPLETED && (
                      <ChallengesSlider list={startedChallenges} />
                    )}
                  <div className="challenges-list">
                    {!isEmpty(startedChallenges) &&
                      selectedTab !== CHALLENGES_TABS.COMPLETED && (
                        <h4 className="challenges-list__title">
                          {t('challenges.title')}
                        </h4>
                      )}

                    <ChallengesList list={challengesListByTab} />
                  </div>
                </>
              )}
            </section>
          </>
        )}

        <AppFooter />
      </main>
    </motion.section>
  );
}

import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../../context/atoms/User';
import { UpsellSingleAPI } from '../api';

const BUY_UPSELL_OFFER_MUTATION_KEY = ['upsell-single-mutation'];

export function useBuyUpsellOfferMutation() {
  const userData = useRecoilValue(userDataQuery);
  const userId = userData?.id as number;

  return useMutation({
    mutationKey: BUY_UPSELL_OFFER_MUTATION_KEY,
    mutationFn: ({
      offerId,
      pricingId,
      paywallConfigId,
    }: {
      offerId: string;
      pricingId: string;
      paywallConfigId: string;
    }) =>
      UpsellSingleAPI.buyOffer({
        userId,
        offerId,
        pricingId,
        paywallConfigId,
      }),
  });
}

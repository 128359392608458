import { MessageError } from './MessageError';
import { MessageLoading } from './MessageLoading';
import './assets/ai-bot-message.scss';

export const NewMessage = ({
  newMessage,
}: {
  newMessage: { text: string; errorMessage: string } | null;
}) => (
  newMessage && (
    <div className="ai-bot-new-message">
      <div className="flex justify-end px-4 mb-4">
        <div className="ai-bot-message-content">
          <p>{newMessage.text}</p>
        </div>
      </div>
      {newMessage.errorMessage ? (
        <MessageError text={newMessage.errorMessage} />
      ) : (
        <MessageLoading />
      )}
    </div>
  )
);

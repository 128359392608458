import { filter, map, pick, some } from 'lodash-es';

import type { AiBot, AiBotCategory } from '../../../entities/ai-bot';
import { AiBotCategoryV2 } from '../../../entities/ai-bot/types';

export function getUniqCategories(
  list: Array<AiBotCategoryV2>
): Array<AiBotCategory> {
  const filteredList = list.filter(
    item => item.tools.length > 0 && item.id !== 'preset'
  );
  return map(filteredList, (category: AiBotCategoryV2) => ({
    ...pick(category, ['id', 'name']),
    ...(category.icon ? { icon: category.icon } : {}),
  }));
}

export function getSortedListByCategoryId({
  list,
  id,
}: {
  list: Array<AiBot>;
  id: AiBotCategory['id'];
}) {
  return filter(list, item => some(item.categories, c => c.id === id));
}

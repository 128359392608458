import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty, last } from 'lodash-es';

import { SessionAPI } from './api';
import { AiBotSessionType } from './types';

const MINUTE_1 = 1000 * 60;
const SECONDS_2 = 2000;

export function useCreateSessionMutation({ chatBotId }: { chatBotId: string }) {
  return useMutation({
    mutationKey: ['create-session', chatBotId],
    mutationFn: () =>
      SessionAPI.createSession({ chatBotId }).then(({ data }) => data),
  });
}

export function useSessionDetailsQuery({ sessionId }: { sessionId: string }) {
  return useQuery({
    queryKey: ['session-details', sessionId],
    queryFn: () =>
      SessionAPI.getSessionDetailsById({ sessionId }).then(({ data }) => data),
    staleTime: MINUTE_1,
    refetchOnWindowFocus: false,
  });
}

export function prefetchSessionDetailsQuery({
  queryClient,
  sessionId,
}: {
  queryClient: QueryClient;
  sessionId: string;
}) {
  return queryClient.prefetchQuery({
    queryKey: ['session-details', sessionId],
    queryFn: () =>
      SessionAPI.getSessionDetailsById({ sessionId }).then(({ data }) => data),
  });
}

export function useSessionsListQuery() {
  return useQuery({
    queryKey: ['sessions-list'],
    queryFn: () => SessionAPI.getAllSessions().then(({ data }) => data),
    refetchOnWindowFocus: false,
    select: data => data.items,
  });
}

export function prefetchSessionsListQuery({
  queryClient,
}: {
  queryClient: QueryClient;
}) {
  return queryClient.prefetchQuery({
    queryKey: ['sessions-list'],
    queryFn: () => SessionAPI.getAllSessions().then(({ data }) => data),
  });
}

export function setInitialSessionDetailsData({
  queryClient,
  data,
}: {
  queryClient: QueryClient;
  data: AiBotSessionType;
}) {
  queryClient.setQueryData(['session-details', data.id], data);
}

export function useCreateMessageMutation() {
  return useMutation({
    mutationKey: ['create-message'],
    mutationFn: ({ sessionId, text }: { sessionId: string; text: string }) =>
      SessionAPI.createNewMessage({
        text,
        sessionId,
      }),
  });
}

export function useSessionMessagesQuery({
  sessionId,
  refetchOnInterval = false,
}: {
  sessionId: string;
  refetchOnInterval?: boolean;
}) {
  return useQuery({
    queryKey: ['session-messages', sessionId],
    queryFn: () =>
      SessionAPI.getMessagesBySessionId({ sessionId }).then(({ data }) => data),
    select: data => data.items,
    refetchOnWindowFocus: false,
    refetchInterval: data => {
      if (
        refetchOnInterval &&
        !isEmpty(data) &&
        isEmpty(last(data)?.response)
      ) {
        return SECONDS_2;
      }

      return false;
    },
  });
}

export function prefetchSessionMessages({
  queryClient,
  sessionId,
}: {
  queryClient: QueryClient;
  sessionId: string;
}) {
  return queryClient.prefetchQuery({
    queryKey: ['session-messages', sessionId],
    queryFn: () =>
      SessionAPI.getMessagesBySessionId({ sessionId }).then(({ data }) => data),
  });
}

export function useDeleteSessionMutation({
  queryClient,
  sessionId,
}: {
  queryClient: QueryClient;
  sessionId: string;
}) {
  return useMutation({
    mutationKey: ['delete-session', sessionId],
    mutationFn: () =>
      SessionAPI.deleteSessionById({ sessionId }).then(({ data }) => data),
    onSuccess() {
      const prefetchSessionsList = () =>
        prefetchSessionsListQuery({
          queryClient,
        }).catch(cause => {
          throw new Error('ERROR_PREFETCH_LIST_QUERY', { cause });
        });

      return prefetchSessionsList().catch(() =>
        queryClient.invalidateQueries(['sessions-list'])
      );
    },
  });
}

export function useChangeChatBotNameMutation({
  queryClient,
  sessionId,
}: {
  queryClient: QueryClient;
  sessionId: string;
}) {
  return useMutation({
    mutationKey: ['change-session-name'],
    mutationFn: (newName: string) =>
      SessionAPI.changeName({ sessionId, newName }).then(({ data }) => data),
    onSuccess() {
      return queryClient.invalidateQueries(['sessions-list']).then(() =>
        prefetchSessionDetailsQuery({
          queryClient,
          sessionId,
        }).catch(cause => {
          throw new Error('ERROR_PREFETCH_SESSION_DETAILS', { cause });
        })
      );
    },
  });
}

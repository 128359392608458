import { useFeatureValue } from '@growthbook/growthbook-react';
import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18next';
import { filter, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../../../shared/ui';
import { OnboardingAnalytics } from '../../../../../shared/analytics';
import { QUIZ_SCREEN_SKILLS } from '../../../../../shared/config';
import { QuizButton } from '../../../../../shared/ui';
import './quiz-skills-screen.css';

const CONTENT = [
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.skill-screen.content.digital_marketing'
      );
    },
    slug: 'digital_marketing',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.skill-screen.content.content_creation'
      );
    },
    slug: 'content_creation',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.blogging');
    },
    slug: 'blogging',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.branding');
    },
    slug: 'branding',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.prompting');
    },
    slug: 'prompting',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.smm');
    },
    slug: 'smm',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.skill-screen.content.dropshipping'
      );
    },
    slug: 'dropshipping',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.ecom');
    },
    slug: 'ecom',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.skill-screen.content.website_creation'
      );
    },
    slug: 'website_creation',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.copywriting');
    },
    slug: 'copywriting',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.networking');
    },
    slug: 'networking',
  },
  {
    get text() {
      return i18next.t(
        'onboarding.quiz-page.skill-screen.content.communication'
      );
    },
    slug: 'communication',
  },
  {
    get text() {
      return i18next.t('onboarding.quiz-page.skill-screen.content.other');
    },
    slug: 'other',
  },
];

const MAX_SELECTED_CONTENT = 3;

export function QuizSkillsScreen({
  events,
}: {
  events: { onNext: () => void };
}) {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<Array<string>>([]);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default'
  );
  function onSelect(slug: string) {
    if (slug === 'other' && selectedContent.includes(slug)) {
      return setSelectedContent([]);
    }

    if (slug === 'other' && !selectedContent.includes('other')) {
      return setSelectedContent([slug]);
    }

    if (slug !== 'other' && selectedContent.includes('other')) {
      return setSelectedContent([slug]);
    }

    if (selectedContent.includes(slug)) {
      return setSelectedContent(content => filter(content, v => v !== slug));
    }

    if (selectedContent.length >= MAX_SELECTED_CONTENT) {
      return;
    }

    return setSelectedContent(content => [...content, slug]);
  }

  function onNext() {
    OnboardingAnalytics.onQuizAnswerSubmit({
      question: QUIZ_SCREEN_SKILLS,
      // @note - send array of slugs
      answer: selectedContent.toString(),
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
    events.onNext();
  }

  return (
    <div className="quiz-skills-screen quiz-page__screen">
      <h2 className="quiz-skill-screen__title quiz-page__title">
        {t('onboarding.quiz-page.skill-screen.title')}
      </h2>
      <p className="quiz-skill-screen__description quiz-page__description">
        {t('onboarding.quiz-page.skill-screen.description')}
      </p>

      <div className="quiz-skill-screen__content">
        <div className="quiz-skill-screen__selector">
          {CONTENT.map((item, i) => (
            <QuizButton
              key={`quiz-skill-screen-content-${i}`}
              isActive={selectedContent.includes(item.slug)}
              size="sm"
              className="quiz-skill-screen__quiz-button"
              withCheckmark={false}
              flexWidth
              events={{
                onClick: () => onSelect(item.slug),
              }}
            >
              {item.text}
            </QuizButton>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {!isEmpty(selectedContent) && (
          <motion.div
            key="quiz-skills-screen-action"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            exit={{ y: 'calc(100% + 2rem)' }}
            className="quiz-page__actions"
          >
            <UiPrimaryButton onClick={onNext}>
              {t('onboarding.quiz-page.skill-screen.action-button')}
            </UiPrimaryButton>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

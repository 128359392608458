import React from 'react';

import Markdown from 'markdown-to-jsx';

import AiAvatarIcon from './assets/ai-avatar.svg';
import CopyIcon from './assets/copy.svg';
import './assets/ai-bot-message.scss';

export const AiMessage = ({ text, onCopy }: { text: string | null; onCopy: (text: string) => void }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onCopy(text as string);
    }
  };

  return (
    <div className="ai-bot-message-answer ai-bot-message_ai-message-v2">
      <img
        width="24"
        height="24"
        src={AiAvatarIcon}
        role="presentation"
        alt=""
      />
      <div className="ai-bot-message_ai-message-v2__content">
        {text && (
          <Markdown>
            {text}
          </Markdown>
        )}
        <div className="mt-2">
          <button
            type="button"
            className="cursor-pointer select-none"
            onClick={() => onCopy(text as string)}
            onKeyDown={handleKeyDown}
            aria-label="Copy message text"
            title="Copy message text"

          >
            <img
              width={20}
              height={20}
              alt=""
              src={CopyIcon}
              role="presentation"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  position: relative;
  box-shadow:
    0 11px 15px -7px #0003,
    0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
  border: 0;
  max-width: 315px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  padding: 24px;
  color: #24234c;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  border-radius: 8px;

  background: #fff;
  color: #495057;

  .img {
    margin-bottom: 24px;
    width: 131px;
    height: auto;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: $dark-0;
    text-align: center;
    max-width: 280px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #636366;
    margin-bottom: 24px;
  }

  .email-warning {
    margin-top: 12px;
    display: flex;
    justify-self: flex-end;
    padding: 16px;
    border-radius: 5px;
    background-color: #f5f7f9;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #24234c;
    }
  }

  .btn-confirm {
    background: #5653fe;
    border: 1px solid #5653fe;
    box-sizing: border-box;
    border-radius: 8px;

    display: block;
    width: 100%;
    height: 48px;
    margin: 0 auto;

    color: #ffffff;
    font-weight: 600;
    font-size: 14px;

    margin-top: 12px;
  }

  .code-form {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;

    input.code-number {
      display: flex;
      width: 32px;
      height: 32px;
    }
  }

  #resend-confirmation {
    text-decoration: underline;
  }
`;

import { useFeatureValue } from '@growthbook/growthbook-react';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userAccessQuery, userQuery } from '../../../../../context/atoms/User';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiBaseLayout, UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../entities/guide';
import { useGuidesListQuery } from '../../../entities/guides-list';
import { useGuidesListProgressQuery } from '../../../entities/guides-list-progress';
import { OngoingGuide } from '../../../features/ongoing-guide';
import { GuidesAnalytics } from '../../../shared/analytics';
import { getOngoingGuide } from '../../../shared/lib';
import { GuidesList, GuidesListLoading } from './GuidesList';
import './assets/guides-main-page.css';

export function GuidesMainPage() {
  const { t } = useTranslation();
  const navigation = useHistory();
  const location = useLocation<{ from?: string }>();
  const isCameFromOnboarding = location.state?.from?.includes('onboarding');

  const user = useRecoilValue(userQuery);
  const userAccess = useRecoilValue(userAccessQuery(user?.user_id));

  const growthbookStartRegisterDate = useFeatureValue(
    'start_register_date_of_new_users_to_ab_guide_first_guide_test',
    ''
  );

  const growthbookNextGuideFeature = useFeatureValue(
    'ab_guides_next_guide',
    'default'
  );

  const growthbookFirstGuideFeature = useFeatureValue(
    'ab_guide_first_guide',
    'default'
  );

  useEffect(() => {
    GuidesAnalytics.mainPageView({
      ab_guides_next_guide: growthbookNextGuideFeature,
      ab_guide_first_guide: growthbookFirstGuideFeature,
      start_register_date: growthbookStartRegisterDate,
    });
  }, []);

  const guidesListProgressQuery = useGuidesListProgressQuery();

  const guidesListQuery = useGuidesListQuery({
    onSuccess() {
      if (isCameFromOnboarding) {
        setTimeout(() => {
          setIsGuidesOnboardingVisible(true);
        }, 1000);
      }
    },
  });

  const [isGuidesOnboardingVisible, setIsGuidesOnboardingVisible] =
    useState(false);

  const ongoingGuide = useMemo(
    () =>
      getOngoingGuide({
        guides: guidesListQuery.data,
        progressList: guidesListProgressQuery.data,
        withFallback: true,
      }),
    [guidesListProgressQuery.data, guidesListQuery.data]
  );

  function onGoToGuide(guide: GuideType) {
    GuidesAnalytics.onGuideClick({
      guideId: guide.id,
      ab_guides_next_guide: growthbookNextGuideFeature,
      ab_guide_first_guide: growthbookFirstGuideFeature,
      start_register_date: growthbookStartRegisterDate,
    });
    navigation.push(`/guides/${guide.id}`);
  }

  function hideGuidesOnboarding() {
    setIsGuidesOnboardingVisible(false);
  }

  useEffect(() => {
    if (!isNil(userAccess.has_access) && !userAccess.has_access) {
      navigation.push('/profile');
    }
  }, [userAccess]);

  return (
    <>
      <UiBaseLayout>
        {guidesListQuery.isError ? (
          <FullPageError onRetry={() => guidesListQuery.refetch()} />
        ) : (
          <section className="guides-main-page">
            <header className="guides-main-page__header">
              <h1 className="guides-main-page__title">{t('guides.title')}</h1>
              <h2 className="guides-main-page__subtitle">
                {t('guides.description')}
              </h2>
            </header>

            <div className="guides-main-page__ongoing-guide">
              {guidesListQuery.isLoading ? (
                <UiLoadingSkeleton
                  style={{
                    width: '100%',
                    height: '340px',
                    borderRadius: '8px',
                  }}
                />
              ) : (
                ongoingGuide &&
                ongoingGuide.guide && (
                  <OngoingGuide
                    guide={ongoingGuide.guide}
                    progress={ongoingGuide.progress}
                    onContinue={onGoToGuide}
                  />
                )
              )}
            </div>

            <div className="guides-main-page__guides-list">
              {guidesListQuery.isLoading ? (
                <GuidesListLoading />
              ) : (
                guidesListQuery.data && (
                  <GuidesList
                    guides={guidesListQuery.data}
                    onGuideClick={onGoToGuide}
                  />
                )
              )}
            </div>
          </section>
        )}
      </UiBaseLayout>
    </>
  );
}

import { useFeatureValue } from '@growthbook/growthbook-react';

export default function useFeedbackFormExperiment() {
  const value = useFeatureValue('exp_feedback_form', 'default');
  return {
    value,
    isTest: value === 'test',
    isClean: value === 'clean',
    isDefault: value === 'default',
  };
}

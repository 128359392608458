import './assets/satisfaction-survey-close-button.css';

export function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      className="satisfaction-survey-close"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="#565B66"
          d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
        />
      </svg>
    </button>
  );
}

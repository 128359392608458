import { useQuery } from '@tanstack/react-query';

import { ChatDetailsAPI } from './api';

export function useChatBotDetailsQuery({ chatBotId }: { chatBotId: string }) {
  return useQuery({
    queryKey: ['chat-bot-details', chatBotId],
    queryFn: () =>
      ChatDetailsAPI.getChatBotDetails(chatBotId).then(({ data }) => data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

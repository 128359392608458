import './ui-loading-skeleton.css';

export function UiLoadingSkeleton({
  className = '',
  style = {},
}: {
  className?: string;
  style?: Record<string, string>;
}) {
  return (
    <div
      className={`ai-bots-loading-skeleton ${className}`}
      style={style}
    ></div>
  );
}

import { filter, isEmpty, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppFooter } from '../../../../shared';
import { UiPrimaryButton } from '../../../../shared/ui';
import {
  AiBotSessionType,
  useSessionsListQuery,
} from '../../../entities/session';
import { SessionListItem } from '../../../entities/session-list-item';
import { SearchForm } from '../../../feature/search-form';
import { SessionsPageAnalytics } from '../../../shared/analytics';
import { FullPageError } from '../../../shared/errors';
import { UiNavigation } from '../../../shared/ui';
import { SessionsLoading } from './SessionsLoading';
import './ai-bot-sessions.css';
import SessionsEmpty from './assets/sessions-empty.png';

function ExploreBotsButton({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick?: () => void;
}) {
  function onButtonClick() {
    SessionsPageAnalytics.onClickToCreateChat();
    onClick?.();
  }

  return isLoading ? (
    <></>
  ) : (
    <button type="button" onClick={onButtonClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="#565B66"
          d="m11 21.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L11 16.583v4.43ZM22.045 8.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM13 17.417l6.03-5.973 1.586 1.586L14.587 19 13 19.007v-1.59Z"
        />
        <path
          fill="#565B66"
          d="M9 25h14c1.103 0 2-.897 2-2v-8.668l-2 2V23H12.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H9V9h6.847l2-2H9c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"
        />
      </svg>
    </button>
  );
}

export function AiBotSessionsPageV2() {
  const { t } = useTranslation();
  const navigation = useHistory();
  const sessionsListQuery = useSessionsListQuery();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (!isEmpty(sessionsListQuery.data)) {
      SessionsPageAnalytics.botsListView();
    }
  }, [sessionsListQuery.data]);

  const filteredSessionsBySearchQuery = useMemo(
    () =>
      filter(
        sessionsListQuery.data,
        session =>
          session.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          session.chatbot.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [sessionsListQuery.data, searchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      SessionsPageAnalytics.onSearchFormSubmit({
        place: 'sessions_page',
        resultCount: size(filteredSessionsBySearchQuery),
      });
    }
  }, [searchQuery, filteredSessionsBySearchQuery]);

  function onGoBack() {
    navigation.replace('/ai-bots');
  }

  function goToSessionPage(session: AiBotSessionType) {
    SessionsPageAnalytics.onSessionClick({
      sessionId: session.id,
      botId: session.chatbot.id,
      botName: session.chatbot.name,
    });

    navigation.push(`/ai-bots/session/${session.chatbot.id}/${session.id}`, {
      from: navigation.location.pathname,
    });
  }

  function onSearchFormClick() {
    SessionsPageAnalytics.onSearchFormClick({ place: 'sessions_page' });
  }

  function onSearch(query: string) {
    if (query !== searchQuery) {
      setSearchQuery(query);
    }
  }

  function onStartNewChat() {
    SessionsPageAnalytics.onStartChatClick();
    navigation.replace('/ai-bots');
  }

  const allChatsTitle = t('ai-tools.history');
  const searchPlaceholder = t('ai-bots.search.chats-placeholder');

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={allChatsTitle}
        onBackButtonClicked={() => onGoBack()}
      />
      {sessionsListQuery.isError ? (
        <FullPageError onRetry={() => sessionsListQuery.refetch()} />
      ) : (
        <section className="ui-base-layout__content">
          {sessionsListQuery.isLoading ? (
            <SessionsLoading />
          ) : (
            <div className="ai-bot-sessions">
              {isEmpty(filteredSessionsBySearchQuery) ? (
                <div className="ai-bot-sessions__empty px-[16px] flex-col">
                  <img
                    className="mt-[122px]"
                    src={SessionsEmpty}
                    alt="Sessions Empty"
                  />
                  <h3 className="text-[20px] font-bold text-center mt-4 mb-2">
                    {t('ai-tools.history-empty-title')}
                  </h3>
                  <p className="text-center text-[#565B66] text-[16px] mb-4">
                    {t('ai-tools.history-empty-description')}
                  </p>

                  <UiPrimaryButton
                    className="w-fit mx-auto"
                    onClick={onStartNewChat}
                  >
                    {t('ai-tools.start-chat')}
                  </UiPrimaryButton>
                </div>
              ) : (
                <>
                  <div className="ai-bot-sessions__search-form">
                    <SearchForm
                      placeholder={searchPlaceholder}
                      onClick={onSearchFormClick}
                      onSearch={query => onSearch(query)}
                    />
                  </div>
                  <div className="ai-bot-sessions__list">
                    {filteredSessionsBySearchQuery.map(session => (
                      <SessionListItem
                        key={session.id}
                        data={session}
                        onClick={() => goToSessionPage(session)}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </section>
      )}
    </main>
  );
}

import { useTranslation } from 'react-i18next';

import {
  ProgressBar,
  ProgressContainer,
  ProgressStep,
} from './ProgressComponent.styles';

interface ProgressStepSignUp {
  title: string;
}

interface ProgressComponentProps {
  steps: ProgressStepSignUp[];
  currentStep: number;
}

const ProgressComponent = ({ steps, currentStep }: ProgressComponentProps) => {
  const { t } = useTranslation();

  return (
    <ProgressContainer>
      <ProgressBar activeStep={currentStep}>
        {steps.map((step, i) => {
          return (
            <ProgressStep
              key={`progress-step-${i}`}
              data-title={t(step.title)}
              success={i + 1 < currentStep}
              active={i + 1 === currentStep}
            >
              {i + 1 < currentStep ? (
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33219 0.626301C9.53858 0.80976 9.55717 1.1258 9.37371 1.33219L4.04038 7.33219C3.94549 7.43893 3.80949 7.50001 3.66667 7.50001C3.52385 7.50001 3.38785 7.43893 3.29297 7.33219L0.626301 4.33219C0.442842 4.1258 0.461432 3.80976 0.667823 3.6263C0.874215 3.44284 1.19025 3.46143 1.37371 3.66782L3.66667 6.24741L8.6263 0.667823C8.80976 0.461432 9.1258 0.442842 9.33219 0.626301Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {i + 1 === currentStep ? (
                    <circle cx="4" cy="4" r="4" fill="#5653FE" />
                  ) : (
                    <></>
                  )}
                </svg>
              )}
            </ProgressStep>
          );
        })}
      </ProgressBar>
    </ProgressContainer>
  );
};

export default ProgressComponent;

import styled from 'styled-components';

export const ErrorFallbackContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 24px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #636366;
  }

  img {
    margin-bottom: 40px;
  }
`;

export const StyledButton = styled.div`
  width: (100% - 48px);
  max-width: 500px;
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0 auto;

  bottom: 24px;
  left: 24px;
  right: 24px;
  z-index: 100;
  background-color: #ffff;
  border: none;
  button {
    width: 100%;
    padding: 8px 0px;
    color: #ffffff;
    background-color: #5653fe;
    border-radius: 8px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 200%;
    border: none;
  }
`;

import { AxiosResponse } from 'axios';

import { api } from '../../services/http.service';
import { CreateFormPropsType, FormResponseType } from './type';

export const SolidPaymentsFormAPI = {
  createForm(
    props: CreateFormPropsType & {
      signal: AbortSignal;
    }
  ): Promise<AxiosResponse<FormResponseType>> {
    return api.post(
      '/payments/solid/forms',
      {
        country_code: props.countryCode,
        email: props.email,
        order_type: 'ONECLICK',
        /*
         * @description - lazy params
         * it is needed for asynchronous execution and work using a long polling.
         * If false, it will work synchronously and send the data to the response.
         * But there may be a timeout error.
         * */
        lazy: true,
        offer_id: props.offerId,
        pricing_id: props.pricingId,
        user_id: props.userId,
      },
      {
        signal: props.signal,
        headers: {
          'Paywall-Config-Id': props.paywallConfigId,
        },
      }
    );
  },
  getFormData(props: {
    orderId: string;
    userId: number;
    signal: AbortSignal;
  }): Promise<AxiosResponse<FormResponseType>> {
    return api.get(
      `payments/solid/forms/${props.orderId}/status/?user_id=${props.userId}`,
      {
        signal: props.signal,
      }
    );
  },
};

import { useExperiment } from '@growthbook/growthbook-react';

export default function useAssistantUpsellExperiment({
  arrow,
}: {
  arrow: string;
}) {
  const isArrowAi = arrow === 'ai';
  const isArrowRemoteWork = arrow === 'remote_work';

  const { value: aiTestValue } = useExperiment<'clean' | 'test'>({
    key: 'ab_assistant_upsell_ai',
    variations: ['clean', 'test'],
    active: isArrowAi,
  });

  const { value: remoteWorkTestValue } = useExperiment<'clean' | 'test'>({
    key: 'ab_assistant_upsell_remote_work',
    variations: ['clean', 'test'],
    active: isArrowRemoteWork,
  });

  // @TODO turned off all smoke tests. Must be removed
  const experimentValue = 'default';

  return { value: experimentValue };
}

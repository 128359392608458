import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.css';

export function AiMessage({ text }: { text: string | null }) {
  return (
    <div className="ai-bot-message ai-bot-message_ai-message">
      <img
        width="24"
        height="24"
        src={AiAvatarIcon}
        role="presentation"
        alt=""
      />
      <p>{text}</p>
    </div>
  );
}

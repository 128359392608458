import { useAutoAnimate } from '@formkit/auto-animate/react';
import { filter, findIndex, last } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '../../../../shared/ui';
import {
  GuideChunk,
  GuideLessonDetailsContentType,
  GuideLessonDetailsTaskType,
  GuideLessonDetailsType,
} from '../../../entities/guide-lesson';
import { useGuideLessonFillTheGapStore } from '../../../entities/guide-lesson-fill-the-gap';
import { useGuideLessonQuizStore } from '../../../entities/guide-lesson-quiz';
import { useGuideLessonTaskStore } from '../../../entities/guide-lesson-task';
import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { GuideLessonChunk } from '../../guide-lesson-chunk';
import { GuideLessonFillTheGap } from '../../guide-lesson-fill-the-gap/ui/GuideLessonFillTheGap';
import { GuideLessonQuiz } from '../../guide-lesson-quiz';
import { GuideLessonTask } from '../../guide-lesson-task';
import { ChunkType } from '../types';
import './guide-lesson-screen.css';

function ActionButton({
  screenOrdering,
  lessonType,
  isLastChunk,
  isFinishLoading,
  isActiveChunk,
  chunkType,
  onStartLesson,
  onFinishLesson,
  onSkipPractice,
  onContinue,
}: {
  screenOrdering: GuideLessonDetailsContentType['ordering'];
  lessonType: GuideLessonDetailsType['type'];
  isLastChunk: boolean;
  isFinishLoading: boolean;
  isActiveChunk: boolean;
  chunkType: GuideChunk['type'];
  onStartLesson: () => void;
  onFinishLesson: () => void;
  onSkipPractice: () => void;
  onContinue: () => void;
}) {
  const { t } = useTranslation();

  const finishLessonButton = t('guides.finish-lesson-button');
  const skipPracticeButton = t('guides.lesson-card.skip-button');
  const startLessonButton = t('guides.start-lesson-button');
  const continueButton = t('guides.continue-button');
  const loadingButton = t('guides.loading');

  if (screenOrdering === 1 && isLastChunk) {
    return (
      <UiPrimaryButton onClick={onStartLesson}>
        {startLessonButton}
      </UiPrimaryButton>
    );
  }

  if (
    lessonType === 'theory' &&
    (chunkType === 'task' || chunkType === 'fill_the_gap') &&
    isActiveChunk
  ) {
    return (
      <button
        type="button"
        className="skip-practice-button"
        onClick={onSkipPractice}
      >
        <span>{skipPracticeButton}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            fill="#40444D"
            d="M9.293 18.707a1 1 0 0 1 0-1.414L14.586 12 9.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"
          />
        </svg>
      </button>
    );
  }

  if (
    lessonType === 'practice' &&
    (chunkType === 'task' || chunkType === 'fill_the_gap') &&
    isActiveChunk
  ) {
    return null;
  }

  if (chunkType === 'quiz' && isActiveChunk) {
    return null;
  }

  if (isLastChunk) {
    return (
      <UiPrimaryButton disabled={isFinishLoading} onClick={onFinishLesson}>
        {isFinishLoading ? loadingButton : finishLessonButton}
      </UiPrimaryButton>
    );
  }

  return (
    <UiPrimaryButton onClick={onContinue}>{continueButton}</UiPrimaryButton>
  );
}

type ChunkId = GuideChunk['id'];

export function GuideLessonScreen({
  type,
  screen,
  events,
  isFinishLoading,
}: {
  type: GuideLessonDetailsType['type'];
  screen: GuideLessonDetailsContentType;
  isFinishLoading: boolean;
  events: {
    onGoToNextScreen: () => void;
    onSaveProgress: () => void;
    onFinishLesson: () => void;
    onOpenTask: (task: GuideLessonDetailsTaskType) => void;
    onOpenFillTheGap: (task: GuideLessonDetailsFillTheGapType) => void;
    onLessonChunkView?: (chunkId: string) => void;
    onSkipPractice?: (chunkId: string) => void;
    onSelectQuizOption?: (chunkId: string) => void;
    onTriggerQuizHint?: (chunkId: string) => void;
    onQuizSubmit?: ({
      chunkId,
      isCorrect,
    }: {
      chunkId: string;
      isCorrect: boolean;
    }) => void;
  };
}) {
  const chunks = screen.content;
  const [contentRef] = useAutoAnimate();

  const addSkippedTask = useGuideLessonTaskStore(state => state.addSkippedTask);
  const completedTasksIds = useGuideLessonTaskStore(
    state => state.completedTasksIds
  );

  const skippedTasksIds = useGuideLessonTaskStore(
    state => state.skippedTasksIds
  );
  const completedQuizIds = useGuideLessonQuizStore(
    state => state.completedQuizIds
  );

  const addSkippedFillTheGap = useGuideLessonFillTheGapStore(
    state => state.addSkippedFillTheGap
  );
  const completedFillTheGapIds = useGuideLessonFillTheGapStore(
    state => state.completedFillTheGapIds
  );
  const skippedFillTheGapIds = useGuideLessonFillTheGapStore(
    state => state.skippedFillTheGapIds
  );

  const [visibleChunksIds, setVisibleChunksIds] = useState<Array<ChunkId>>([
    chunks[0].id,
  ]);

  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);

  const visibleFilteredChunks = useMemo(
    () => filter(chunks, chunk => visibleChunksIds.includes(chunk.id)),
    [visibleChunksIds]
  );

  const lastVisibleChunkId = visibleChunksIds[visibleChunksIds.length - 1];
  const lastVisibleChunk =
    visibleFilteredChunks[visibleFilteredChunks.length - 1];

  useEffect(() => {
    if (lastVisibleChunkId) {
      events.onLessonChunkView?.(lastVisibleChunkId);
    }
  }, [lastVisibleChunkId]);

  const isActiveChunk = useMemo(
    () =>
      !completedTasksIds.includes(lastVisibleChunk.id) &&
      !completedFillTheGapIds.includes(lastVisibleChunk.id) &&
      !skippedTasksIds.includes(lastVisibleChunk.id) &&
      !skippedFillTheGapIds.includes(lastVisibleChunk.id) &&
      !completedQuizIds.includes(lastVisibleChunk.id),
    [
      skippedFillTheGapIds,
      completedFillTheGapIds,
      completedTasksIds,
      skippedTasksIds,
      lastVisibleChunk,
      completedQuizIds,
    ]
  );

  const nextChunkId = useMemo(() => {
    const currentChunkIdIndex = findIndex(chunks, ['id', lastVisibleChunkId]);

    if (currentChunkIdIndex < 0) {
      return null;
    }

    if (currentChunkIdIndex === chunks.length - 1) {
      return null;
    }

    return chunks[currentChunkIdIndex + 1].id;
  }, [visibleChunksIds, chunks]);

  const isLastVisibleChunk =
    chunks[chunks.length - 1].id === lastVisibleChunkId;

  useEffect(() => {
    if (
      lastVisibleChunk.id === last(completedTasksIds) ||
      lastVisibleChunk.id === last(completedFillTheGapIds)
    ) {
      onContinue();
    }
  }, [completedTasksIds, completedFillTheGapIds]);

  function onFinishLesson() {
    events.onSaveProgress();
    events.onFinishLesson();
  }

  function onStartLesson() {
    events.onGoToNextScreen();
    events.onSaveProgress();
  }

  function onSkipPractice() {
    if (!isActionButtonDisabled) {
      if (lastVisibleChunk.type === 'fill_the_gap') {
        addSkippedFillTheGap(lastVisibleChunk.id);
      } else {
        addSkippedTask(lastVisibleChunk.id);
      }

      onContinue();

      events.onSkipPractice?.(lastVisibleChunk.id);
    }
  }

  function onOpenTask(task: GuideLessonDetailsTaskType) {
    events.onOpenTask(task);
  }

  function onOpenFillTheGap(task: GuideLessonDetailsFillTheGapType) {
    events.onOpenFillTheGap(task);
  }

  function onContinue() {
    if (nextChunkId && !isActionButtonDisabled) {
      setVisibleChunksIds([...visibleChunksIds, nextChunkId]);
      events.onSaveProgress();
    }
  }

  useEffect(() => {
    const chunkElement = document.querySelector(
      `[data-chunk-id='${lastVisibleChunkId}']`
    );

    let timeout: ReturnType<typeof setTimeout>;

    if (chunkElement) {
      setIsActionButtonDisabled(true);
      chunkElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      timeout = setTimeout(() => {
        setIsActionButtonDisabled(false);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visibleChunksIds]);

  return (
    <div className="guide-lesson-screen">
      <div ref={contentRef} className="guide-lesson-screen__content">
        {visibleFilteredChunks.map(chunk => (
          <div
            key={chunk.id}
            data-chunk-id={chunk.id}
            className="guide-lesson-screen__chunk"
          >
            {chunk.type === ChunkType.CHUNK && (
              <GuideLessonChunk chunk={chunk} />
            )}

            {chunk.type === ChunkType.TASK && (
              <GuideLessonTask
                task={chunk}
                onOpenTask={task => onOpenTask(task)}
              />
            )}

            {chunk.type === ChunkType.FILL_THE_GAP && (
              <GuideLessonFillTheGap
                task={chunk}
                onOpenTask={task => onOpenFillTheGap(task)}
              />
            )}

            {chunk.type === ChunkType.QUIZ && (
              <GuideLessonQuiz
                type={type}
                screenId={screen.id}
                quiz={chunk}
                events={{
                  onSelectOption: events.onSelectQuizOption,
                  onTriggerHint: events.onTriggerQuizHint,
                  onQuizSubmit: events.onQuizSubmit,
                }}
              />
            )}
          </div>
        ))}
      </div>

      <div className="guide-lesson-action">
        <ActionButton
          lessonType={type}
          chunkType={lastVisibleChunk.type}
          isActiveChunk={isActiveChunk}
          isFinishLoading={isFinishLoading}
          isLastChunk={isLastVisibleChunk}
          screenOrdering={screen.ordering}
          onStartLesson={onStartLesson}
          onFinishLesson={onFinishLesson}
          onSkipPractice={onSkipPractice}
          onContinue={onContinue}
        />
      </div>
    </div>
  );
}

import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
  type AiBotMessageType,
  type AiBotNewMessage,
  ChatMessage,
  NewMessage,
} from '@/modules/ai-bots-v2/entities/message';
import { CopyMessageToast } from '@/modules/ai-bots-v2/feature/copy-message';
import { UiSpinner } from '@/modules/ai-bots-v2/shared/ui/spinner';

import IconArrowDown from './assets/svg/arrow_down.svg';

const EmptyChatList = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 items-center justify-center p-4 text-gray-500 text-sm">
      {t('ai-bots.chat.empty-results')}
    </div>
  );
};

const LoadingChatList = () => (
  <div className="flex flex-1 items-center justify-center p-4 text-gray-500 text-sm">
    <UiSpinner />
  </div>
);

export const ChatList = ({
  isMessagesLoading = false,
  newMessage,
  messages = [],
  onOpenImage,
}: {
  isMessagesLoading?: boolean;
  newMessage: AiBotNewMessage | null;
  messages?: Array<AiBotMessageType>;
  onOpenImage?: (url: string) => void;
}) => {
  const [isCopyToastVisible, setIsCopyToastVisible] = useState(false);
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const chatListRef = useRef<HTMLDivElement>(null);

  const handleCopy = (text: string) => {
    if (!text) {
      return;
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopyToastVisible(true);
      })
      .catch(() => {});
  };

  const handleScroll = () => {
    const isAtBottom = window.scrollY + window.innerHeight !== document.body.scrollHeight;

    if (isAtBottom) {
      return setHasReachedBottom(true);
    }

    return setHasReachedBottom(false);
  };

  const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;

    if (chatListRef.current) {
      try {
        window.scrollTo({ top: chatListRef.current.scrollHeight, behavior: 'smooth' });
      } catch (error) {
      // Fallback for browsers without smooth scroll support
        window.scrollTo(0, scrollHeight);
      }
      setHasReachedBottom(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (isMessagesLoading) {
    return <LoadingChatList />;
  }

  if (!newMessage && isEmpty(messages)) {
    return <EmptyChatList />;
  }

  return (
    <div
      className="ai-bot-chat-v2__list"
      ref={chatListRef}
    >
      {messages.map(message => (
        <ChatMessage
          key={message.id}
          message={message}
          onOpenImage={onOpenImage}
          onCopyText={handleCopy}
        />
      ))}
      {newMessage && <NewMessage newMessage={newMessage} />}
      <CopyMessageToast
        isVisible={isCopyToastVisible}
        onClose={() => setIsCopyToastVisible(false)}
      />
      {hasReachedBottom && (
        <button
          type="button"
          onClick={scrollToBottom}
          className="ai-bot-chat-v2__list__arrow"
        >
          <img
            width={22}
            height={22}
            src={IconArrowDown}
            alt="target"
          />
        </button>
      )}
    </div>
  );
};

import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';

import { AppFooter } from '../../../../shared';
import { AiBotTool } from '../../../entities/ai-bot/types';
import type { AiBotSessionType } from '../../../entities/session';
import { useSessionsListQuery } from '../../../entities/session';
import { useAiBotsCategoriesQuery } from '../../../feature/ai-bots-categories';
import { AiBotsListType } from '../../../feature/ai-bots-categories/api';
import { AiBotsHeader } from '../../../feature/ai-bots-header';
import { MainPageAnalytics } from '../../../shared/analytics';
import { FullPageError } from '../../../shared/errors';
import { AiBotsList, AiBotsListLoading } from '../../../widgets/ai-bots-list';
import { AiBotsPresetCards } from './ai-bots-preset-cards';

function isSessionsError({
  isError,
  data,
}: {
  isError: boolean;
  data?: Array<AiBotSessionType>;
}) {
  return isError || isEmpty(data);
}

function isListError({
  isError,
  data,
}: {
  isError: boolean;
  data?: AiBotsListType;
}) {
  return isError || isEmpty(data && data.items);
}

function AiBotsListContainer({ isLoading }: { isLoading: boolean }) {
  function onCategoryButtonClicked(id: string) {
    MainPageAnalytics.onCategoryFilterClick({ id });
  }

  function onClickToBot(bot: AiBotTool) {
    MainPageAnalytics.onBotClick({
      location: 'explore',
      botName: bot.name,
      botId: bot.id,
      action: 'create_new_chat',
    });
  }

  return isLoading ? (
    <AiBotsListLoading />
  ) : (
    <AiBotsList
      onCategoryButtonClicked={onCategoryButtonClicked}
      onClickToBot={onClickToBot}
    />
  );
}

export function AiBotsMainPageV2() {
  const aiBotsListQuery = useAiBotsCategoriesQuery();
  const aiBotsSessionsQuery = useSessionsListQuery();

  useEffect(() => {
    if (!isEmpty(aiBotsListQuery.data)) {
      MainPageAnalytics.botsListView();
    }
  }, [aiBotsListQuery.data]);

  const isAiBotsListError = isListError({
    isError: aiBotsListQuery.isError,
    data: aiBotsListQuery.data,
  });

  const isAiSessionsError = isSessionsError({
    isError: aiBotsSessionsQuery.isError,
    data: aiBotsSessionsQuery.data,
  });

  const isFullPageErrorVisible =
    !aiBotsListQuery.isLoading &&
    !aiBotsSessionsQuery.isLoading &&
    isAiBotsListError &&
    isAiSessionsError;

  function onRetry() {
    aiBotsListQuery.refetch();
    aiBotsSessionsQuery.refetch();
  }

  return (
    <main className="ui-base-layout min-vh-full">
      <AiBotsHeader />
      {isFullPageErrorVisible ? (
        <FullPageError onRetry={onRetry} />
      ) : (
        <>
          <AiBotsPresetCards data={aiBotsListQuery.data?.items} />

          <AiBotsListContainer isLoading={aiBotsListQuery.isLoading} />
        </>
      )}
      <AppFooter />
    </main>
  );
}

import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { usePatchFullName } from '../../../../../entities/personal-profile/model';
import { GuideType } from '../../../../guides/entities/guide';
import { UiPrimaryButton } from '../../../../shared/ui';
import { useCreateCertificate } from '../../../entites/guide-certificate/model';
import './select-categories.css';

export function PersonalizeCertificateBottomSheet({
  isVisible,
  onDismiss,
  guideId,
  onSubmit,
  onClose,
  onView,
}: {
  isVisible: boolean;
  onDismiss: () => void;
  guideId: GuideType['id'];
  onView: () => void;
  onSubmit: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [fullName, setFullName] = useState<string>();

  const updateFullName = usePatchFullName();
  const createCertificate = useCreateCertificate();

  const onBottomSheetDismiss = () => {
    onClose();
    onDismiss();
  };

  const onNameSubmit = () => {
    if (!fullName) return;

    onSubmit();

    updateFullName.mutate(fullName, {
      onSuccess: () => {
        createCertificate.mutate(
          { id: guideId },
          {
            onSuccess: certificateResponse => {
              window.open(certificateResponse.pdf_url, '_blank');
              onDismiss();
            },
          }
        );
      },
    });
  };
  const loadingButton = t('guides.loading');
  const submitButton = t('Submit');

  const inputPlaceholder = t('guides-v2.bottom-sheet.placeholder');

  useEffect(() => {
    if (isVisible) return;
    onView();
  }, [isVisible]);

  return (
    <BottomSheet
      key="select-categories-bottomsheet"
      style={{
        height: '100%',
        zIndex: 1000000,
        position: 'relative',
      }}
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={onBottomSheetDismiss}
    >
      <div className="select-categories px-4 pt-[10px] flex flex-col h-full">
        <div className="select-categories__heading">
          <div>
            <h1 className="select-categories__title text-[24px] mb-2">
              {t('guides-v2.bottom-sheet.title')}
            </h1>
            <p>{t('guides-v2.bottom-sheet.description')}</p>
          </div>
        </div>
        <div className="">
          <TextField
            sx={{
              width: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
            id="name"
            type="text"
            name="name"
            autoComplete="on"
            margin="normal"
            onChange={e => {
              setFullName(e.target.value);
            }}
            placeholder={inputPlaceholder}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="mt-[32px] mb-[42px]">
          <UiPrimaryButton
            className="w-full"
            onClick={onNameSubmit}
            disabled={!fullName}
          >
            {updateFullName.isLoading ? loadingButton : submitButton}
          </UiPrimaryButton>
        </div>
      </div>
    </BottomSheet>
  );
}

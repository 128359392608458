import { AxiosResponse } from 'axios';

import { api } from '../../../../services/http.service';
import type {
  GuideDetailsType,
  GuideLessonType,
  GuideType,
  GuideUnitType,
} from '../../entities/guide';
import type { GuideLessonDetailsType } from '../../entities/guide-lesson';
import {
  GuideProgressByIdType,
  GuideUnitProgress,
} from '../../entities/guide-progress';

interface GuidesListType {
  status: string;
  items: Array<GuideType>;
}

export const GuidesAPI = {
  getGuidesList(): Promise<AxiosResponse<GuidesListType>> {
    return api.get('guides/v2?version=2024-08-14');
  },
  getGuidesListProgress(): Promise<
    AxiosResponse<Record<GuideType['id'], number>>
  > {
    return api.get('guides/progress');
  },
  getGuideDetailsById({
    id,
  }: {
    id: GuideType['id'];
  }): Promise<AxiosResponse<GuideDetailsType>> {
    return api.get(`guides/v2/${id}`);
  },
  getGuideProgressById({
    id,
  }: {
    id: GuideType['id'];
  }): Promise<AxiosResponse<GuideProgressByIdType>> {
    return api.get(`guides/progress?guide_id=${id}`);
  },
  getLessonById({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: GuideType['id'];
    unitId: GuideUnitType['id'];
    lessonId: GuideLessonType['id'];
  }): Promise<AxiosResponse<GuideLessonDetailsType>> {
    return api.get(`guides/v2/${guideId}/units/${unitId}/lessons/${lessonId}`);
  },
  getLessonProgressByUnitId({
    unitId,
  }: {
    unitId: GuideUnitType['id'];
  }): Promise<AxiosResponse<GuideUnitProgress>> {
    return api.get(`guides/progress?unit_id=${unitId}`);
  },
  saveLessonProgress({
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: GuideType['id'];
    unitId: GuideUnitType['id'];
    lessonId: GuideLessonType['id'];
  }) {
    return api.post('guides/progress', {
      guide_id: guideId,
      unit_id: unitId,
      lesson_id: lessonId,
    });
  },
  sendMessage({
    message,
    context,
    signal,
  }: {
    message: string;
    context?: string;
    signal: AbortSignal;
  }): Promise<AxiosResponse<{ completion_id: string; status: string }>> {
    const payload = context ? { message, context } : { message };

    return api.post('guides/v2/completions', payload, {
      signal,
    });
  },
  getMessageAnswer({
    messageId,
    signal,
  }: {
    messageId: string;
    signal: AbortSignal;
  }): Promise<
    AxiosResponse<{
      completion: string;
      feedback: string;
      is_correct: boolean;
    }>
  > {
    return api.get(`/guides/completions/${messageId}/`, {
      signal,
    });
  },
};

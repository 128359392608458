import { GuideType } from '../../../entities/guide';
import { GuideDuration } from '../../../entities/guide-duration';
import './small-guide-card.css';

export function SmallGuideCard({
  guide,
  onClick,
}: {
  guide: GuideType;
  onClick: () => void;
}) {
  return (
    <div className="small-guide-card" role="button" onClick={onClick}>
      <img
        width="80"
        height="80"
        src={guide.image}
        role="presentation"
        alt=""
      />

      <GuideDuration
        className="small-guide-card__duration"
        unitsCount={guide.units_count}
        duration={guide.duration}
        size="sm"
      />

      <h1 className="small-guide-card__title">{guide.name}</h1>
    </div>
  );
}

import { motion } from 'framer-motion';
import { find, values } from 'lodash-es';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLocationQuery } from '../../../../../hooks/use-location-query';
import { GuideLessonWidget } from '../../../../guides';
import {
  GuideChunk,
  GuideLessonDetailsContentType,
  GuideLessonDetailsType,
} from '../../../../guides/entities/guide-lesson';
import { useChallengeLessonQuery } from '../../../entities/lesson';
import {
  useChallengeLessonSaveProgressMutation,
  useChallengeLessonsProgress,
} from '../../../entities/lessons-progress';
import { ChallengesAnalytics } from '../../../shared/analytics';

export function ChallengeLessonPage() {
  const navigation = useHistory();
  const { challengeId, taskId } = useParams<{
    challengeId: string;
    taskId: string;
  }>();
  const locationQuery = useLocationQuery();

  const isOnboardingGuide = useMemo(
    () => locationQuery.get('guideType') === 'onboarding',
    [locationQuery]
  );

  const challengeLessonQuery = useChallengeLessonQuery({
    challengeId,
    taskId,
  });

  const challengeLessonProgressQuery = useChallengeLessonsProgress({
    challengeId,
  });

  const saveLessonProgressMutation = useChallengeLessonSaveProgressMutation({
    challengeId,
    taskId,
  });

  const lessonProgress = useMemo(() => {
    if (challengeLessonProgressQuery.data) {
      const progress = values(challengeLessonProgressQuery.data);
      return find(progress, ['id', taskId])?.status;
    }
  }, [challengeLessonProgressQuery.data]);

  function onFinishLesson() {
    const query = isOnboardingGuide ? `?guideType=onboarding` : '';

    if (lessonProgress !== 'completed') {
      return saveLessonProgressMutation.mutateAsync().finally(() => {
        navigation.replace(
          `/challenges/${challengeId}/${taskId}/complete${query}`
        );
      });
    }

    navigation.replace(`/challenges/${challengeId}/${taskId}/complete${query}`);
  }

  function onGoBack() {
    if (isOnboardingGuide) {
      navigation.replace(`/challenges/${challengeId}`);
    } else {
      navigation.replace(`/challenges/${challengeId}/tasks`);
    }
  }

  function onLessonChunkView(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    ChallengesAnalytics.lessonChunkView({
      challenge_id: challengeId,
      task_id: taskId,
      page_id: props.pageId,
      chunk_id: props.chunkId,
      type: props.place,
    });
  }

  function onSelectQuizOption(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    ChallengesAnalytics.onSelectQuizOption({
      challenge_id: challengeId,
      task_id: taskId,
      page_id: props.pageId,
      chunk_id: props.chunkId,
    });
  }

  function onTriggerQuizHint(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    ChallengesAnalytics.onTriggerQuizHint({
      challenge_id: challengeId,
      task_id: taskId,
      page_id: props.pageId,
      chunk_id: props.chunkId,
    });
  }

  function onQuizSubmit(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
    isCorrect: boolean;
  }) {
    ChallengesAnalytics.onQuizSubmit({
      challenge_id: challengeId,
      task_id: taskId,
      page_id: props.pageId,
      chunk_id: props.chunkId,
      is_correct: props.isCorrect,
    });
  }

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <GuideLessonWidget
        guide={challengeLessonQuery.data}
        isError={challengeLessonQuery.isError}
        isLoading={challengeLessonQuery.isLoading}
        isFinishLessonLoading={saveLessonProgressMutation.isLoading}
        lessonProgress={lessonProgress}
        events={{
          onFinishLesson,
          onRefetch: challengeLessonQuery.refetch,
          onGoBack,
          onLessonChunkView,
          onSelectQuizOption,
          onTriggerQuizHint,
          onQuizSubmit,
        }}
      />
    </motion.section>
  );
}

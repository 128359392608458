import { useGrowthBook } from '@growthbook/growthbook-react';

export default function useHeadingsExperiment() {
  const growthbook = useGrowthBook();
  const { arrow } = growthbook?.getAttributes() ?? {};

  const isArrowAi = arrow === 'ai';
  const isArrowRemoteWork = arrow === 'remote_work';

  if (!isArrowAi && !isArrowRemoteWork) {
    return {
      value: 'default',
      isTest: false,
    };
  }

  const testName = isArrowAi
    ? 'exp_ai_upsell_headings'
    : 'exp_remote_work_upsell_headings';

  const testValue = growthbook?.getFeatureValue(testName, 'default');

  return {
    value: testValue,
    isTest: testValue === 'test',
  };
}

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#24234C',
    padding: '16px 20px',
    border: '1.5px #E2E5E9 solid',
    borderRadius: '16px',
    width: 340,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.03)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFFFFF',
    fontSize: '20px',
    '&:before': {
      border: '1px solid #E2E5E9',
    },
  },
}));

export default HtmlTooltip;

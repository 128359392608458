import { motion } from 'framer-motion';

import { GuideLessonType } from '../../../../guides/entities/guide';
import './assets/lesson-vector.css';

interface LessonVectorProps {
  unitId: string;
  lesson: GuideLessonType;
  selectedLessonId: string;
  getVectorStrokeColor: (unitId: string, lesson: GuideLessonType) => string;
}

export function LessonVector({
  unitId,
  lesson,
  selectedLessonId,
  getVectorStrokeColor,
}: LessonVectorProps) {
  return (
    <svg
      className="learning-pathway-content__item__vector"
      viewBox="0 0 176 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3L165 3C169.418 3 173 6.58172 173 11V72"
        stroke={getVectorStrokeColor(unitId, lesson)}
        strokeWidth="3"
      />
      {selectedLessonId === lesson.id && (
        <motion.path
          d="M0 3L165 3C169.418 3 173 6.58172 173 11V72"
          stroke="#19AA32"
          strokeWidth="3"
          initial={{ pathLength: 0, opacity: 1 }}
          animate={{
            pathLength: 1,
            opacity: 0,
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: 'easeOut',
          }}
        />
      )}
    </svg>
  );
}

import { isEmpty, last } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailsButton } from '../../../../ai-bots/entities/details-button';
import { NewMessage } from '../../../../ai-bots/entities/message';
import { ChatForm } from '../../../../ai-bots/feature/chat-form';
import { UiNavigation } from '../../../../ai-bots/shared/ui';
import {
  GuideLessonDetailsContentType,
  GuideLessonDetailsTaskType,
  GuideLessonDetailsType,
} from '../../../entities/guide-lesson';
import { useGuideLessonTaskStore } from '../../../entities/guide-lesson-task';
import {
  GuideMessage,
  GuideMessageType,
  GuideNewMessageType,
} from '../../../entities/guide-message';
import { useSendGuideMessage } from '../../../entities/guide-message/model';
import { GuideErrorFeedback, GuideSuccessFeedback } from '../../guide-feedback';
import { useLockScreen } from '../model';
import './guide-task-modal.css';

function GuideTaskExerciseToast({
  isVisible,
  content,
  onClose,
}: {
  isVisible: boolean;
  content: GuideLessonDetailsTaskType['content'];
  onClose: () => void;
}) {
  //TODO: add animation
  const { t } = useTranslation();

  return isVisible ? (
    <div className="guide-task-toast">
      <div className="guide-task-toast__header">
        <h4 className="guide-task-toast__heading">
          {t('guides.lesson-task.title')}
        </h4>
        <button
          type="button"
          className="guide-task-toast__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#F9FAFB"
              d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      {content.map(item => (
        <p key={item.id} className="guide-task-toast__description">
          {item.description}
        </p>
      ))}
    </div>
  ) : null;
}

function useTaskExerciseToast({
  events,
}: {
  events: {
    onTriggerToast: (isVisible: boolean) => void;
  };
}) {
  const [isTaskExerciseToastVisible, setIsTaskExerciseToastVisible] =
    useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTaskExerciseToastVisible(true);

      events.onTriggerToast(true);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function onCloseToast() {
    setIsTaskExerciseToastVisible(false);
  }

  function onTriggerToast() {
    setIsTaskExerciseToastVisible(v => {
      events.onTriggerToast(!v);

      return !v;
    });
  }

  function onShowToast() {
    setIsTaskExerciseToastVisible(true);
    events.onTriggerToast(true);
  }

  return {
    isTaskExerciseToastVisible,
    onCloseToast,
    onTriggerToast,
    onShowToast,
  };
}

function getLastTaskMessage(
  taskMessages: Array<GuideMessageType>,
  task: GuideLessonDetailsTaskType
) {
  const lastMessage = last(taskMessages);

  if (lastMessage && lastMessage.taskId === task.id) {
    return lastMessage;
  }

  return null;
}

export function GuideTaskModal({
  pageId,
  type,
  guideType,
  task,
  events,
}: {
  pageId: GuideLessonDetailsContentType['id'];
  type: GuideLessonDetailsType['type'];
  guideType: GuideLessonDetailsType['type'];
  task: GuideLessonDetailsTaskType;
  events: {
    onClose: () => void;
    onExerciseToastTrigger?: (isVisible: boolean) => void;
    onSubmitMessage?: (isRetrySubmission: boolean) => void;
    onReceivesAnswer?: (props: {
      isSuccess: boolean;
      isSubmissionCorrect: boolean;
    }) => void;
  };
}) {
  const { t } = useTranslation();

  const chatListRef = useRef<HTMLDivElement>(null);

  useLockScreen(chatListRef);

  const [newMessage, setNewMessage] = useState<GuideNewMessageType | null>(
    null
  );

  const {
    isTaskExerciseToastVisible,
    onCloseToast,
    onTriggerToast,
    onShowToast,
  } = useTaskExerciseToast({
    events: {
      onTriggerToast: isVisible => events?.onExerciseToastTrigger?.(isVisible),
    },
  });

  const { sendGuideMessage, abortSendGuideMessage } = useSendGuideMessage();
  const {
    taskMessages,
    addTaskMessage,
    clearTaskMessages,
    addCompletedTask,
    removeLastTaskMessage,
  } = useGuideLessonTaskStore();

  const isPracticeGuide = guideType === 'practice';

  const hasNewMessage = !isEmpty(newMessage);
  const lastMessage = getLastTaskMessage(taskMessages, task);

  const isLastMessageResponseEmpty =
    Boolean(lastMessage) && isEmpty(lastMessage?.response);

  const messageFeedback = lastMessage?.feedback;
  const isMessageCorrect = lastMessage?.isCorrect;

  const isPendingAnswer = hasNewMessage || isLastMessageResponseEmpty;

  function scrollToBottom() {
    if (chatListRef.current) {
      // @note setTimeout - For deferred execution after rendering
      setTimeout(() =>
        chatListRef.current!.scroll(0, chatListRef.current!.scrollHeight)
      );
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [newMessage, taskMessages]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  function onRegeneratePrompt() {
    onSubmitMessage(newMessage!.text, true);
  }

  function onRetry() {
    removeLastTaskMessage();
    onShowToast();
  }

  function onTaskCompleted() {
    events.onClose();
    /**
     * @description
     * it is added after 400 ms, for a smoother display of
     * the animation of the task status change
     *
     * If you remove it, the scroll is reset,
     * because useAutoAnimate triggers
     * the animation of the task card
     *
     * TODO: think about better solution
     * */
    setTimeout(() => {
      addCompletedTask(task.id);
    }, 400);
  }

  function onSubmitMessage(text: string, isRetrySubmission: boolean = false) {
    events.onSubmitMessage?.(isRetrySubmission);

    setNewMessage({ text, errorMessage: '' });

    onCloseToast();

    sendGuideMessage({
      text,
      task,
      isPractice: type === 'practice',
    })
      .then(message => {
        setNewMessage(null);
        addTaskMessage(message);

        events.onReceivesAnswer?.({
          isSuccess: true,
          isSubmissionCorrect: type === 'practice' ? message.isCorrect : true,
        });
      })
      .catch(cause => {
        setNewMessage({ text, errorMessage: t('ai-bots.default-error') });

        events.onReceivesAnswer?.({
          isSuccess: false,
          isSubmissionCorrect: false,
        });

        throw new Error('ERROR_GUIDE_SUBMIT_TASK_MESSAGE', { cause });
      });
  }

  useEffect(() => {
    return () => {
      if (!isPracticeGuide) {
        setNewMessage(null);
        clearTaskMessages();
      }

      abortSendGuideMessage();
    };
  }, []);

  return (
    <main className="vh-full guide-task-modal">
      <UiNavigation
        title="ChatGPT-4"
        action={<DetailsButton onClick={onTriggerToast} />}
        onBackButtonClicked={events.onClose}
      />

      <div className="guide-task-modal__content">
        <GuideTaskExerciseToast
          isVisible={isTaskExerciseToastVisible}
          content={task.content}
          onClose={onCloseToast}
        />

        <div ref={chatListRef} className="guide-task-modal__chat-list">
          {taskMessages.map(message => (
            <GuideMessage key={message.id} message={message} />
          ))}
          {newMessage && <NewMessage newMessage={newMessage} />}
        </div>
      </div>

      <div>
        {!isMessageCorrect && messageFeedback && (
          <GuideErrorFeedback feedback={messageFeedback} onRetry={onRetry} />
        )}

        {isMessageCorrect && (
          <GuideSuccessFeedback
            feedback={messageFeedback}
            onContinue={onTaskCompleted}
          />
        )}

        {!isMessageCorrect && !messageFeedback && (
          <ChatForm
            newMessage={newMessage}
            isPendingAnswer={isPendingAnswer}
            onSubmit={text => onSubmitMessage(text)}
            onRegeneratePrompt={onRegeneratePrompt}
          />
        )}
      </div>
    </main>
  );
}

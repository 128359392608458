import { useQueryClient } from '@tanstack/react-query';

import { UpsellCascadeAPI } from '../api';
import { UPSELL_CASCADE_QUERY_KEY } from '../config';

export function useUpsellCascadePrefetch() {
  const queryClient = useQueryClient();

  function prefetchUpsellCascadeQuery() {
    return queryClient
      .prefetchQuery({
        queryKey: UPSELL_CASCADE_QUERY_KEY,
        queryFn: () => UpsellCascadeAPI.getOffers().then(({ data }) => data),
      })
      .catch(cause => {
        throw new Error('ERROR_PREFETCH_UPSELL_CASCADE', { cause });
      });
  }

  return {
    prefetchUpsellCascadeQuery,
  };
}

import { trim } from 'lodash-es';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';

import './search-form.css';

let searchTimeout: ReturnType<typeof setTimeout>;

export function SearchForm({
  placeholder,
  onSearch,
  onClick,
}: {
  placeholder?: string;
  onSearch: (text: string) => any;
  onClick?: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    clearTimeout(searchTimeout);
    setQuery(e.target.value);

    searchTimeout = setTimeout(() => {
      onSearch(trim(e.target.value));
    }, 500);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    inputRef.current?.blur();
  }

  return (
    <form onSubmit={onSubmit}>
      <label className="search-form" onClick={onClick}>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={e => onChange(e)}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          fill="none"
        >
          <path
            fill="#565B66"
            d="M6.667 3.167a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-5.334 4a5.333 5.333 0 1 1 9.547 3.27l3.591 3.592a.667.667 0 0 1-.942.943L9.937 11.38a5.333 5.333 0 0 1-8.604-4.213Z"
          />
        </svg>
      </label>
    </form>
  );
}

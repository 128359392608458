import classNames from 'classnames';
import { get, map } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../entities/personal-profile';
import { UiPrimaryButton } from '../../../shared/ui';
import { GOALS_CONTENT } from '../../config';
import { PersonalGoalAnalytics } from '../../shared/analytics';
import { usePersonalGoalStore } from '../../shared/model';
import './selector-screen.css';

export function SelectorScreen({
  place,
  events,
}: {
  place: 'home_page' | 'profile';
  events: {
    onClose: () => void;
    onContinue: () => void;
  };
}) {
  const { t } = useTranslation();
  const personalProfileQuery = usePersonalProfileQuery();
  const goal = get(personalProfileQuery.data, 'properties.goal[0]');

  const { currentGoal, setCurrentGoal } = usePersonalGoalStore();

  useEffect(() => {
    setCurrentGoal(goal ?? '');
  }, [goal]);

  function onSetGoal(goal: string) {
    setCurrentGoal(goal);
  }

  function onContinue() {
    PersonalGoalAnalytics.onSelectButtonClick({
      place,
      selected_goal: currentGoal,
    });

    events.onContinue();
  }

  return (
    <section className="goal-selector-screen">
      <div className="goal-selector-screen__header">
        <h2 className="goal-selector-screen__title">
          {t('goals.selector-screen.title')}
        </h2>
        <button
          type="button"
          className="goal-selector-screen__close-button"
          onClick={events.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <rect width="24" height="24" fill="#F1F1F1" rx="4" />
            <path
              fill="#24234C"
              d="M7.529 7.529c.26-.26.682-.26.942 0L12 11.057l3.528-3.528a.667.667 0 1 1 .943.942L12.943 12l3.528 3.529a.667.667 0 1 1-.943.942L12 12.943 8.47 16.47a.667.667 0 0 1-.942-.942L11.057 12 7.53 8.471a.667.667 0 0 1 0-.942Z"
            />
          </svg>
        </button>
      </div>

      <p className="goal-selector-screen__description">
        {t('goals.selector-screen.description')}
      </p>

      <ul className="goal-selector-screen__list">
        {map(GOALS_CONTENT, (value, key) => (
          <li key={key} className="goal-selector-screen__item">
            <button
              type="button"
              className={classNames('goal-selector-screen__select-button', {
                'goal-selector-screen__select-button--active':
                  key === currentGoal,
              })}
              onClick={() => onSetGoal(key)}
            >
              <span>
                {value.icon && (
                  <img
                    width="24"
                    height="24"
                    src={value.icon}
                    role="presentation"
                    alt=""
                  />
                )}

                <strong>{value.title}</strong>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <rect
                  width="19"
                  height="19"
                  x=".5"
                  y=".5"
                  fill="#5653FE"
                  rx="9.5"
                />
                <rect
                  width="19"
                  height="19"
                  x=".5"
                  y=".5"
                  stroke="#5653FE"
                  rx="9.5"
                />
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.667 6.5 8.25 12.917 5.333 10"
                />
              </svg>
            </button>
          </li>
        ))}
      </ul>

      <UiPrimaryButton
        className="goal-selector-screen__action-button"
        onClick={onContinue}
      >
        {t('goals.selector-screen.action-button')}
      </UiPrimaryButton>
    </section>
  );
}

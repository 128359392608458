import { AnimatePresence, Transition, motion } from 'framer-motion';
import { shuffle } from 'lodash-es';
import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackFormAnalytics } from '../analytics';
import { PillButton } from './PillButton';
import RatingAnswer from './RatingAnswer';

const slideVariants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
    scale: 0.95,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    scale: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 300 : -300,
    opacity: 0,
    scale: 0.95,
  }),
};

const slideTransition: Transition = {
  x: {
    type: 'spring',
    stiffness: 300,
    damping: 30,
    duration: 0.4,
  },
  opacity: {
    duration: 0.25,
    ease: 'easeInOut',
  },
  scale: {
    duration: 0.4,
    ease: [0.4, 0, 0.2, 1],
  },
};

interface FeedbackFormProps {
  isPositive: boolean | null;
  setIsPositive: (value: boolean | null) => void;
  currentStep: number;
  setCurrentStep: (value: number) => void;
  selectedFeedback: string[];
  setSelectedFeedback: Dispatch<React.SetStateAction<string[]>>;
}

export function FeedbackForm({
  isPositive,
  setIsPositive,
  currentStep,
  setCurrentStep,
  selectedFeedback,
  setSelectedFeedback,
}: FeedbackFormProps) {
  const [containerHeight, setContainerHeight] = useState<number>(66);

  const stepRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const { t } = useTranslation();

  const feedback = useMemo(() => {
    return {
      positive: shuffle(
        t('guides.lesson-complete.feedback-form.badges.positive', {
          returnObjects: true,
        }) as string[]
      ),
      negative: shuffle(
        t('guides.lesson-complete.feedback-form.badges.negative', {
          returnObjects: true,
        }) as string[]
      ),
    };
  }, [t]);

  const feedbackPills = isPositive ? feedback.positive : feedback.negative;

  const handleNextStep = () => {
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
    }, 100);
  };

  const handleRating = (positive: boolean) => {
    setIsPositive(positive);

    FeedbackFormAnalytics.onRateClick({
      rate: isPositive ? 'like' : 'dislike',
    });
    handleNextStep();
  };

  const handleTellUs = () => {
    FeedbackFormAnalytics.onTellUs({
      rate: isPositive ? 'like' : 'dislike',
    });
    handleNextStep();
  };

  function toggleFeedback(label: string) {
    setSelectedFeedback(prevSelected => {
      if (prevSelected.includes(label)) {
        return prevSelected.filter(item => item !== label);
      }
      return [...prevSelected, label];
    });
  }

  useEffect(() => {
    const updateHeight = () => {
      const currentStepRef = stepRefs[currentStep]?.current;
      if (currentStepRef) {
        const height = currentStepRef.offsetHeight;
        requestAnimationFrame(() => {
          setContainerHeight(height);
        });
      }
    };

    updateHeight();

    const timer = setTimeout(updateHeight, 50);
    return () => clearTimeout(timer);
  }, [currentStep, selectedFeedback, isPositive]);

  return (
    <div className="px-6">
      <div className="border border-[#E2E5E9] rounded-lg bg-[#F5F7F9] relative">
        <div
          className="py-3 px-4 relative overflow-hidden"
          style={{
            height: containerHeight,
            minHeight: '66px',
            transition: 'height 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          <AnimatePresence custom={currentStep}>
            {currentStep === 0 && (
              <motion.div
                ref={stepRefs[0]}
                key="step0"
                variants={slideVariants}
                animate="center"
                exit="exit"
                transition={slideTransition}
                custom={currentStep}
                className="absolute left-0 right-0 top-0 px-4 py-3"
              >
                <div className="flex justify-between items-center">
                  <p className="font-semibold text-[16px]">
                    {t('guides.lesson-complete.feedback-form.step-1')}
                  </p>
                  <div className="flex gap-x-3">
                    <RatingAnswer
                      isActive={isPositive === true}
                      onClick={() => handleRating(true)}
                    />
                    <RatingAnswer
                      isActive={isPositive === false}
                      isNegative
                      onClick={() => handleRating(false)}
                    />
                  </div>
                </div>
              </motion.div>
            )}

            {currentStep === 1 && (
              <motion.div
                ref={stepRefs[1]}
                key="step1"
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={slideTransition}
                custom={currentStep}
                className="absolute left-0 right-0 top-0 px-4 py-3"
              >
                <div className="flex items-center gap-x-4 justify-between">
                  <p className="font-semibold text-base flex-1 min-w-0 max-w-[195px]">
                    {t('guides.lesson-complete.feedback-form.step-2-title')}
                  </p>
                  <button
                    onClick={handleTellUs}
                    className="flex-shrink-0 bg-[#E2E5E9] py-3 px-6 rounded-[32px] text-[14px] hover:bg-gray-300 transition-colors"
                  >
                    {t(
                      'guides.lesson-complete.feedback-form.step-2-description'
                    )}
                  </button>
                </div>
              </motion.div>
            )}

            {currentStep === 2 && (
              <motion.div
                ref={stepRefs[2]}
                key="step2"
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={slideTransition}
                custom={currentStep}
                className="absolute left-0 right-0 top-0 px-4 py-3"
              >
                <div>
                  <p className="font-semibold text-[16px]">
                    {t('guides.lesson-complete.feedback-form.step-3')}
                  </p>
                  <div className="pt-4 flex flex-wrap gap-2">
                    {feedbackPills.map(label => (
                      <PillButton
                        key={label}
                        onClick={() => toggleFeedback(label)}
                        selected={selectedFeedback.includes(label)}
                      >
                        {label}
                      </PillButton>
                    ))}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';

import { isAfterDate } from '..';

export default function useAiToolsExperiment() {
  const gb = useGrowthBook();
  const aiToolsExpDate = useFeatureValue('exp_ai_tools_date', null);
  const { registered_at: registeredAt } = gb?.getAttributes() ?? {};

  const isExperimentActive = aiToolsExpDate && (typeof registeredAt === 'string')
   && isAfterDate(registeredAt, aiToolsExpDate);

  const value = gb?.getFeatureValue('exp_ai_tools', 'default');

  return {
    value:  isExperimentActive ? value : 'default',
    isTest: isExperimentActive ? value === 'test' : false,
  };
}

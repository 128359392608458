import { UiLoadingSkeleton } from '../../../shared/ui';
import './ai-bot-sessions.css';

export function SessionsLoading() {
  return (
    <div className="ai-bot-sessions">
      <div className="ai-bot-sessions__search-form">
        <UiLoadingSkeleton style={{ height: '40px' }} />
      </div>
      <div className="ai-bot-sessions__list">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <div key={i} style={{ padding: '.5rem 1rem' }}>
              <UiLoadingSkeleton style={{ height: '64px' }} />
            </div>
          ))}
      </div>
    </div>
  );
}

import styled from 'styled-components';

export const BaseBottomsheetContainer = styled.div`
  .overlay {
    position: fixed;
    inset: 0;
    z-index: 301;
    background-color: black;
  }
  .wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 400;
    margin: 0 auto;
    max-width: 500px;
  }
  .content {
    background: #f5f7f9;
  }
`;

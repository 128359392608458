import { useQueryClient } from '@tanstack/react-query';

import { UpsellSingleAPI } from '../api';
import { UPSELL_SINGLE_QUERY_KEY } from '../config';

export function useUpsellSinglePrefetch() {
  const queryClient = useQueryClient();

  function prefetchUpsellSingleQuery({
    paywallConfigId,
  }: {
    paywallConfigId: string;
  }) {
    return queryClient
      .prefetchQuery({
        queryKey: [...UPSELL_SINGLE_QUERY_KEY, paywallConfigId],
        queryFn: () => UpsellSingleAPI.getOffer(paywallConfigId),
      })
      .catch(cause => {
        throw new Error('ERROR_PREFETCH_UPSELL_SINGLE', { cause });
      });
  }

  return {
    prefetchUpsellSingleQuery,
  };
}

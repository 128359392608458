import { analyticsService } from '../../../../../services/analytics.service';

export const FeedbackFormAnalytics = {
  onRateClick({ rate }: { rate: string }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place: 'lesson_complete',
      action: 'rate',
      rating: rate,
    });
  },
  onTellUs({ rate }: { rate: string }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'lesson_complete',
      action: 'tell_us',
      rating: rate,
    });
  },
  onSelectBubble({ bubbles }: { bubbles: string }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'lesson_complete',
      action: 'leave_feedback',
      feedback_bubbles: bubbles,
    });
  },
};

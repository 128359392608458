import { AiBotCategory } from '../../../../../entities/ai-bot';
import './assets/select-category-button.css';

export function SelectCategoryButton({
  isActive,
  category,
  onClick,
}: {
  isActive?: boolean;
  category: AiBotCategory;
  onClick: (id: AiBotCategory['id']) => void;
}) {
  return (
    <button
      type="button"
      className={`select-category-button ${
        isActive ? 'select-category-button--active' : ''
      }`}
      onClick={() => onClick(category.id)}
    >
      {category.icon && (
        <img src={category.icon} width={16} height={16} alt="" />
      )}
      {category.name}
    </button>
  );
}

import { useQuery } from '@tanstack/react-query';
import { isNil, size, values } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import { PAYMENT_METHODS } from '../payment-method';
import { SolidPaymentsFormAPI } from './api';
import { CreateFormPropsType, FormResponseType, PaymentForms } from './type';

const SECONDS_2 = 2000;

let getFormDataTimeout: ReturnType<typeof setTimeout>;
let abortController = new AbortController();

function isCardPaymentFormIncluded(forms: PaymentForms) {
  /**
   * @description In getFormData request,
   * CARD is payment method for credit cards and apple/google pay
   * */
  return Boolean(forms.find(form => form.type === PAYMENT_METHODS.CARD));
}

export function useSolidPaymentsForm(props: Partial<CreateFormPropsType>) {
  useEffect(() => {
    return () => {
      clearTimeout(getFormDataTimeout);
      abortController.abort();
    };
  }, []);

  const isFormDataQueryEnabled = useMemo(() => {
    const valuesArray = values(props);

    return size(valuesArray.filter(v => !isNil(v))) === size(valuesArray);
  }, [props]);

  function getFormData({
    orderId,
    userId,
  }: {
    orderId: string;
    userId: number;
  }): Promise<FormResponseType> {
    return new Promise((resolve, reject) => {
      async function getFormDataPromise() {
        clearTimeout(getFormDataTimeout);

        try {
          const formData = await SolidPaymentsFormAPI.getFormData({
            orderId,
            userId,
            signal: abortController.signal,
          });

          if (isCardPaymentFormIncluded(formData.data.forms)) {
            resolve(formData.data);
          } else {
            startGetFormPolling();
          }
        } catch (cause) {
          const error = new Error('ERROR_SOLID_PAYMENTS_FORMS_GET_FORM_DATA', {
            cause,
          });

          reject(error);
        }
      }

      function startGetFormPolling() {
        getFormDataTimeout = setTimeout(() => {
          getFormDataPromise();
        }, SECONDS_2);
      }

      getFormDataPromise();
    });
  }

  return useQuery({
    queryKey: ['solid-payments-form', props.offerId, props.pricingId],
    queryFn: async () => {
      abortController = new AbortController();

      if (
        typeof props.offerId === 'undefined' ||
        typeof props.pricingId === 'undefined' ||
        typeof props.userId === 'undefined' ||
        typeof props.email === 'undefined' ||
        typeof props.countryCode === 'undefined' ||
        typeof props.paywallConfigId === 'undefined'
      ) {
        throw new Error('ERROR_SOLID_PAYMENTS_FORM_EMPTY_PROPS');
      }

      const createFormData = await SolidPaymentsFormAPI.createForm({
        countryCode: props.countryCode,
        pricingId: props.pricingId,
        offerId: props.offerId,
        userId: props.userId,
        email: props.email,
        paywallConfigId: props.paywallConfigId,
        signal: abortController.signal,
      });

      if (isCardPaymentFormIncluded(createFormData.data.forms)) {
        return createFormData.data;
      }

      return await getFormData({
        orderId: createFormData.data.order_id,
        userId: props.userId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isFormDataQueryEnabled,
  });
}

import Bugsnag from '@bugsnag/js';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import CompleteImage from '../../../../../images/guides/complete.svg';
import { analyticsService } from '../../../../../services/analytics.service';
import useAAExperiment from '../../../../../shared/lib/hooks/use-aa-test-experiment';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import { ALLOWED_ARROWS } from '../../../../guides-v2/shared/lib/consts';
import { UiPrimaryButton } from '../../../../shared/ui';
import { FeedbackForm } from '../../../features/feedback-form';
import { FeedbackFormAnalytics } from '../../../features/feedback-form/analytics';
import { GuidesAnalytics } from '../../../shared/analytics';
import useFeedbackFormExperiment from '../../../shared/hooks/use-feedback-form-experiment';
import { calculateNextStep } from '../../../shared/lib';
import { useGuideByIdQuery } from '../../guide-by-id-page/model';
import './guide-complete-page.css';

const SURVEY_PROBABILITY = 0.5;

export function GuideCompletePage() {
  const { t } = useTranslation();

  const navigation = useHistory();
  const {
    guideId,
    unitId,
    lessonId,
  }: { guideId: string; unitId: string; lessonId: string } = useParams();

  const guideByIdQuery = useGuideByIdQuery({ id: guideId });

  const [isPositive, setIsPositive] = useState<boolean | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);

  const { isTest: isFeedbackFormActive, value: feedbackFormValue } =
    useFeedbackFormExperiment();

  const growthbookNextGuideFeature = useFeatureValue(
    'ab_guides_next_guide',
    'default'
  );

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const { value: aaTestValue } = useAAExperiment();

  const isPathway = useMemo(
    () =>
      aiPathwayExperiment === 'test' &&
      guideByIdQuery.data?.tags?.some(tag => ALLOWED_ARROWS.includes(tag)),
    [aiPathwayExperiment, guideByIdQuery.data?.tags, ALLOWED_ARROWS]
  );

  const redirectUrl = useMemo(() => {
    return isPathway ? `/guide-pathway/${guideId}` : `/guides/${guideId}`;
  }, [aiPathwayExperiment, guideByIdQuery.data?.tags, guideId]);

  const formattedFeedbackPills = useMemo(() => {
    return selectedFeedback.join(', ');
  }, [selectedFeedback]);

  const isNextGuideFeatureDefault = ['default', 'clean'].includes(
    growthbookNextGuideFeature
  );
  const isNextGuideFeatureTest = growthbookNextGuideFeature === 'test';

  useEffect(() => {
    GuidesAnalytics.lessonCompletePageView({
      guideId,
      unitId,
      lessonId,
      ...(isPathway && { aaTestValue: aaTestValue }),
    });
  }, [isPathway]);

  function onContinue() {
    GuidesAnalytics.onLessonCompleteCloseClick({
      guideId,
      unitId,
      lessonId,
    });
    if (currentStep === 0 && isFeedbackFormActive) {
      FeedbackFormAnalytics.onRateClick({
        rate: 'skip',
      });
    }

    if (currentStep > 1 && isFeedbackFormActive) {
      FeedbackFormAnalytics.onSelectBubble({ bubbles: formattedFeedbackPills });
    }

    onGoNextPage();
  }

  function onClose() {
    GuidesAnalytics.onLessonCompleteContinueClick({
      guideId,
      unitId,
      lessonId,
    });

    if (currentStep === 0 && isFeedbackFormActive) {
      FeedbackFormAnalytics.onRateClick({
        rate: 'skip',
      });
    }

    if (currentStep > 1 && isFeedbackFormActive) {
      FeedbackFormAnalytics.onSelectBubble({
        bubbles: formattedFeedbackPills,
      });
    }

    onGoNextPage();
  }

  function onGoNextPage() {
    if (
      Math.random() > SURVEY_PROBABILITY &&
      isNextGuideFeatureDefault &&
      !isFeedbackFormActive
    ) {
      navigation.push(
        `/guides/${guideId}/${unitId}/${lessonId}/survey?pathway=${
          isPathway ? 'test' : ''
        }`
      );
    } else {
      navigation.push(redirectUrl);
    }
  }

  function onGoHome() {
    navigation.push(redirectUrl);
  }

  function onGoToNextLesson() {
    GuidesAnalytics.onLessonCompleteNextLessonClick({
      guideId,
      unitId,
      lessonId,
    });

    if (!guideByIdQuery.data) {
      return navigation.push('/');
    }

    try {
      const nextStep = calculateNextStep({
        guide: guideByIdQuery.data,
        unitId,
        lessonId,
      });

      if (nextStep.type === 'guide-end') {
        return navigation.push(`/`);
      }

      return navigation.push(
        `/guides/${nextStep.guideId}/${nextStep.unitId}/${
          nextStep.lessonId
        }?pathway=${isPathway ? 'test' : ''}`
      );
    } catch (cause) {
      navigation.push('/');
      Bugsnag.notify(new Error('ERROR_ON_GO_NEXT_LESSON', { cause }));
    }
  }

  return (
    <main className="guide-complete-page min-vh-full">
      <div className="guide-complete-page__header">
        <button
          type="button"
          className="guide-complete-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div className="guide-complete-page__content">
        <img
          width="168"
          height="168"
          className="guide-complete-page__image"
          src={CompleteImage}
          alt=""
          role="presentation"
        />

        <h1 className="guide-complete-page__title">
          {t('guides.lesson-complete.title')}
        </h1>
        <p className="guide-complete-page__description">
          {t('guides.lesson-complete.description')}
        </p>
      </div>

      {isFeedbackFormActive && (
        <FeedbackForm
          isPositive={isPositive}
          setIsPositive={setIsPositive}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}

      <div className="guide-complete-page__actions">
        {isNextGuideFeatureTest ? (
          <>
            <button
              type="button"
              className="guide-complete-page__go-home-button"
              onClick={onGoHome}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
              >
                <path
                  fill="#24234C"
                  d="M11.836 2.253a1 1 0 0 1 1.328 0l9 8a1 1 0 0 1-1.328 1.494l-.336-.298V19a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-7.55l-.336.297a1 1 0 0 1-1.328-1.494l9-8ZM6.5 9.67V19h3v-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5h3V9.671l-6-5.333-6 5.333Zm7 9.329v-4h-2v4h2Z"
                />
              </svg>
            </button>
            <UiPrimaryButton
              className="guide-complete-page__next-lesson-button"
              onClick={onGoToNextLesson}
            >
              <>
                {t('guides.next-lesson-button')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M9.293 18.707a1 1 0 0 1 0-1.414L14.586 12 9.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"
                  />
                </svg>
              </>
            </UiPrimaryButton>
          </>
        ) : (
          <UiPrimaryButton onClick={onContinue}>
            {t('guides.continue-button')}
          </UiPrimaryButton>
        )}
      </div>
    </main>
  );
}

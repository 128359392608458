export class AuthStore {
  static email: string;
  static password: string;

  static setEmail(value: string) {
    this.email = value;
  }

  static setPassword(value: string) {
    this.password = value;
  }
}

import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const ChallengesAnalytics = {
  homePageView() {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'home_page',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onCardClick(props: { challenge_id: string; is_completed: boolean }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'home_page',
      feature_version: 'v1',
      action: 'open_challenge',
      challenge_id: props.challenge_id,
      is_completed: props.is_completed,
      language: i18next.language,
    });
  },
  infoPageView(props: { challenge_id: string }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'info_page',
      challenge_id: props.challenge_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onJoinButtonClick(props: {
    challenge_id: string;
    is_released: boolean;
    is_waitlist_joined: boolean;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'info_page',
      action: 'join_now',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      is_released: props.is_released,
      is_waitlist_joined: props.is_waitlist_joined,
      language: i18next.language,
    });
  },
  tasksPageView(props: {
    challenge_id: string;
    progress: number;
    total_days: number;
  }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'calendar_page',
      challenge_id: props.challenge_id,
      progress: props.progress,
      total_days: props.total_days,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onTaskCardClick(props: {
    challenge_id: string;
    task_id: string;
    is_locked: boolean;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'calendar_page',
      action: 'select_task',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      is_locked: props.is_locked,
      language: i18next.language,
    });
  },
  onTaskStartButtonClick(props: { challenge_id: string; task_id: string }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'calendar_page',
      action: 'start_now',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      language: i18next.language,
    });
  },
  nextTaskConfirmationToastView(props: { challenge_id: string }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'calendar_page_locked_popup',
      challenge_id: props.challenge_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onNextTaskConfirmationToastActionClick(props: {
    challenge_id: string;
    task_id: string;
    is_instant_unlock: boolean;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'calendar_page_locked_popup',
      action: 'pre_start_task',
      is_instant_unlock: props.is_instant_unlock,
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  nextTaskConfirmationBottomSheetView(props: {
    challenge_id: string;
    task_id: string;
  }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'calendar_page_next_task_popup',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onNextTaskConfirmationBottomSheetActionClick(props: {
    challenge_id: string;
    task_id: string;
    is_do_not_show_again: boolean;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'calendar_page_next_task_popup',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      is_do_not_show_again: props.is_do_not_show_again,
      language: i18next.language,
    });
  },
  lessonChunkView(props: {
    challenge_id: string;
    task_id: string;
    page_id: string;
    chunk_id: string;
    type: string;
  }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'task_page',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      page_id: props.page_id,
      chunk_id: props.chunk_id,
      type: props.type,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onSelectQuizOption(props: {
    challenge_id: string;
    task_id: string;
    page_id: string;
    chunk_id: string;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'task_page',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      page_id: props.page_id,
      chunk_id: props.chunk_id,
      action: 'select_quiz_option',
      language: i18next.language,
    });
  },
  onQuizSubmit(props: {
    challenge_id: string;
    task_id: string;
    page_id: string;
    chunk_id: string;
    is_correct: boolean;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'task_page',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      page_id: props.page_id,
      chunk_id: props.chunk_id,
      is_correct: props.is_correct,
      action: 'submit_quiz_option',
      language: i18next.language,
    });
  },
  onTriggerQuizHint(props: {
    challenge_id: string;
    task_id: string;
    page_id: string;
    chunk_id: string;
  }) {
    analyticsService.sendEvent('webapp_challenges_click', {
      place: 'task_page',
      feature_version: 'v1',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      page_id: props.page_id,
      chunk_id: props.chunk_id,
      action: 'show_quiz_hint',
      language: i18next.language,
    });
  },
  taskCompletePageView(props: { challenge_id: string; task_id: string }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'task_complete',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  challengeCompletedPageView(props: { challenge_id: string; task_id: string }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'challenge_complete',
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onCompleteActionClick(props: {
    challenge_id: string;
    task_id: string;
    action: 'close' | 'continue';
  }) {
    analyticsService.sendEvent('webapp_challenges_view', {
      place: 'task_complete',
      action: props.action,
      challenge_id: props.challenge_id,
      task_id: props.task_id,
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onSurveyPageView(props: { challengeId: string; taskId: string }) {
    analyticsService.sendEvent('webapp_survey_view', {
      challenge_id: props.challengeId,
      task_id: props.taskId,
      feature: 'challenges',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onCloseSurveyPage(props: { challengeId: string; taskId: string }) {
    analyticsService.sendEvent('webapp_survey_click', {
      challenge_id: props.challengeId,
      task_id: props.taskId,
      feature: 'challenges',
      feature_version: 'v1',
      action: 'close',
      language: i18next.language,
    });
  },
  onRateClick(props: { challengeId: string; taskId: string; rating: number }) {
    analyticsService.sendEvent('webapp_survey_click', {
      challenge_id: props.challengeId,
      task_id: props.taskId,
      feature: 'challenges',
      feature_version: 'v1',
      action: 'rate',
      rating: props.rating,
      language: i18next.language,
    });
  },
  onSubmitFeedback(props: {
    challengeId: string;
    taskId: string;
    rating: number;
    feedback: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      challenge_id: props.challengeId,
      task_id: props.taskId,
      feature: 'challenges',
      feature_version: 'v1',
      action: 'submit_feedback',
      rating: props.rating,
      feedback: props.feedback,
    });
  },
};

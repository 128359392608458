import amplitudeAnalytics from 'amplitude-js';
import Cookies from 'js-cookie';

import { createSentinelSDK } from '../analytics';
import { getGeo } from '../api/new-geo';
import { getI18NLanguage } from '../utils/Language/Language';

const amplitude = amplitudeAnalytics.getInstance();
const sentinel = getGeo().then(({ data }) => createSentinelSDK({ geo: data }));

export const analyticsService = {
  init: (userId?: string | number) => {
    const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY as string;
    amplitude.init(amplitudeKey);
    sentinel.then(({ identity }) => {
      identity.setUserId(userId as number);
    });
  },
  setUserId: (userId: string) => {
    amplitude.setUserId(userId);
    sentinel.then(({ identity }) => {
      identity.setUserId(Number(userId));
    });
  },
  setAmplitudeUserProperties: (props: any) => {
    amplitude.setUserProperties(props);
    sentinel.then(({ user }) => {
      user.setUserMetaData(props);
    });
  },
  sendEvent: (
    eventType: string,
    eventProperties: any = {},
    probability: number = 1
  ) => {
    const additionalEventProps = {
      language: Cookies.get('sys_lang') || getI18NLanguage(),
    };
    const fullProps = Object.assign({}, eventProperties, additionalEventProps);

    if (Math.random() < probability) {
      amplitude.logEvent(eventType, fullProps);
    }
    sentinel.then(({ analytics }) => {
      analytics.trackEvent(eventType, fullProps);
    });
  },
};

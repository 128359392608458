import type { AxiosResponse } from 'axios';

import { api } from '../../../services/http.service';

export interface TestpilotReturnType {
  value: string;
}

export const TestpilotApi = {
  getExperiment({
    experiment,
    uniqueId,
  }: {
    experiment: string;
    uniqueId: string;
  }): Promise<AxiosResponse<TestpilotReturnType>> {
    return api.get(
      `testpilot/experiments/${experiment}/value/?unique_id=${uniqueId}`
    );
  },
};

import { useQuery } from '@tanstack/react-query';

import { PaymentMethodAPI } from './api';

export function useLastPaymentMethodQuery() {
  return useQuery({
    queryKey: ['last-payment-method'],
    queryFn: () =>
      PaymentMethodAPI.getLastPaymentMethod().then(res => res.data),
    staleTime: Infinity,
  });
}

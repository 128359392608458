import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { LessonLayoutProgress } from './LessonLayoutProgress';
import './assets/lesson-layout.css';

export const LessonLayout = ({
  children,
  onClose,
  progress,
}: {
  progress: number;
  children: ReactNode;
  onClose: () => void;
}) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main
        className="ui-base-layout min-vh-full lesson-layout"
        style={{ width: '100%' }}
      >
        <LessonLayoutProgress progress={progress} onClose={onClose} />
        {children}
      </main>
    </motion.div>
  );
};

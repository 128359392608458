import { RefObject, useEffect } from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

export function useLockScreen(ref: RefObject<HTMLDivElement>) {
  useEffect(() => {
    lock([ref.current!], {
      overflowType: 'clip',
    });

    return () => {
      unlock([ref.current!]);
    };
  }, []);
}

import classNames from 'classnames';

//TODO: refactor, move to shared folder
import { UiLoadingSkeleton } from '../../../ai-bots/shared/ui';

export function UpsellOffersLoading({ className }: { className?: string }) {
  return (
    <div className={classNames('upsell-offers', className)}>
      <UiLoadingSkeleton
        style={{
          height: '88px',
          borderRadius: '8px',
        }}
      />
      <UiLoadingSkeleton
        style={{
          height: '88px',
          borderRadius: '8px',
        }}
      />
      <UiLoadingSkeleton
        style={{
          height: '88px',
          borderRadius: '8px',
        }}
      />
    </div>
  );
}

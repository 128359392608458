import { FC } from 'react';
import styled from 'styled-components';

interface SpinnerProps {
  hasShadow?: boolean;
}

const CustomSpinner: FC<SpinnerProps> = ({ hasShadow }) => (
  <Wrapper hasShadow={true}>
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
  </Wrapper>
);

const Wrapper = styled.div<SpinnerProps>`
  display: flex;
  flex: 1;
  background: ${({ hasShadow }) => (hasShadow ? 'rgba(0, 0, 0, 0.3)' : '')};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;
const StyledSpinner = styled.svg`
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite;
  z-index: 10001;
  width: 80px;
  height: 80px;

  & .path {
    stroke: #5653fe;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default CustomSpinner;

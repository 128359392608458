import classNames from 'classnames';
import { Fragment, useState } from 'react';

import './image-skeleton.css';

export function ImageSkeleton({
  src,
  alt = '',
  width,
  height,
  className = '',
  placeholderAspectRatio,
}: {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
  placeholderAspectRatio?: string;
}) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Fragment>
      {isLoading && (
        <div
          className={classNames('image-skeleton__placeholder', className)}
          style={{ width, height, aspectRatio: placeholderAspectRatio }}
        />
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{ display: isLoading ? 'none' : 'block' }}
        className={className}
      />
    </Fragment>
  );
}

import { useEffect } from 'react';

import Checkmark from './assets/svg/checkmark.svg';

const TOAST_EXPIRATION_MS = 1500;

export const CopyMessageToast = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(onClose, TOAST_EXPIRATION_MS);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [isVisible, onClose]);

  return (
    <div
      className={`guide-task-toast fixed transform transition-all duration-300 ease-in-out ${
        isVisible
          ? 'opacity-100'
          : ' opacity-0 pointer-events-none'
      }`}
    >
      <div className="guide-task-toastheader justify-start flex">
        <button
          type="button"
          className="guide-task-toastclose-button"
          onClick={onClose}
        >
          <img
            alt=""
            src={Checkmark}
            width={24}
            height={24}
          />
        </button>
        <h4 className="guide-task-toast__heading ml-1 text-[14px]">
          Message coppied
        </h4>
      </div>
    </div>
  );
};

export default CopyMessageToast;

import { AxiosPromise } from 'axios';

import { api } from '../../../../services/http.service';

export type ChallengesListProgressType = {
  is_joined: boolean;
  is_completed: boolean;
  completed_days_count: number;
  days_count: number;
};

export const ChallengesListProgressAPI = {
  getChallengesListProgress(): AxiosPromise<
    Record<string, ChallengesListProgressType>
  > {
    return api.get('daily-challenges/progress');
  },
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './assets/choice-toast.css';

export function ChoiceToast({
  isInitialVisible,
}: {
  isInitialVisible: boolean;
}) {
  const { t } = useTranslation();
  const [isChoiceToastVisible, setChoiceToastVisible] =
    useState(isInitialVisible);

  return isChoiceToastVisible ? (
    <section className="choice-toast">
      <div className="choice-toast__header">
        <div className="choice-toast__title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm12.664-3.247a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 0 1 1.494-1.328l1.92 2.159 4.586-5.16a1 1 0 0 1 1.411-.082Z"
              clipRule="evenodd"
            />
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
              clipRule="evenodd"
            />
          </svg>
          {t('upsell-cascade.choice-toast.title')}
        </div>
        <button
          type="button"
          className="choice-toast__close"
          onClick={() => setChoiceToastVisible(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#F9FAFB"
              d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div className="choice-toast__description">
        {t('upsell-cascade.choice-toast.description')}
      </div>
    </section>
  ) : (
    <></>
  );
}

import './assets/ai-bot-message.css';
import CustomerAvatarIcon from './assets/customer-avatar.svg';

export function CustomerMessage({ text }: { text: string }) {
  return (
    <div className="ai-bot-message">
      <img
        width="24"
        height="24"
        src={CustomerAvatarIcon}
        role="presentation"
        alt=""
      />
      <p>{text}</p>
    </div>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { AiBot } from '../../../entities/ai-bot';
import { ChatTag } from '../../../entities/chat-tag';
import { DeleteSessionButton } from '../../../entities/delete-session-button';
import { useCreateSessionMutation } from '../../../entities/session';
import { setInitialSessionDetailsData } from '../../../entities/session/model';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { UiSpinner } from '../../../shared/ui/spinner';
import { ChatDetailsName } from './ChatDetailsName';
import './chat-details-bottomsheet.css';

const ERROR_VISIBLE_DELAY = 2000;

export function ChatDetailsBottomSheet({
  chatBotId,
  sessionId,
  newChatBotName,
  chatDetails,
  isVisible,
  onDismiss,
  onDeleteSession,
}: {
  chatBotId: string;
  sessionId?: string;
  newChatBotName?: string;
  chatDetails?: AiBot;
  isVisible: boolean;
  onDismiss: () => void;
  onDeleteSession?: () => void;
  onChangeName?: (newName: string) => void;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();
  const location = useLocation<{ from?: string }>();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');
  const createSessionMutation = useCreateSessionMutation({ chatBotId });
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId, sessionId });

  const isLoading = createSessionMutation.isLoading;

  useEffect(() => {
    if (isVisible && chatDetails) {
      ChatPageAnalytics.chatDetailsModalView();
    }
  }, [isVisible, chatDetails]);

  if (!chatDetails) {
    return <></>;
  }

  function createSession() {
    setErrorMessage('');
    createSessionMutation
      .mutateAsync()
      .then(data => {
        onDismiss();
        setInitialSessionDetailsData({ queryClient, data });

        navigation.push(`/ai-bots/session/${chatBotId}/${data.id}`, {
          from: location.state?.from,
        });
      })
      .catch(cause => {
        setErrorMessage('Something went wrong. Please try again.');
        setTimeout(() => setErrorMessage(''), ERROR_VISIBLE_DELAY);
        throw new Error('ERROR_CREATE_NEW_SESSION', { cause });
      });

    ChatPageAnalytics.onNewChatButtonClick();
  }

  function deleteSession() {
    onDeleteSession?.();
    ChatPageAnalytics.onChatDetailsModalDeleteButtonClick();
  }

  return (
    <BottomSheet
      key="chat-details-bottomsheet"
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={() => onDismiss()}
    >
      {isLoading && (
        <div className="chat-details-bottomsheet-loading">
          <UiSpinner />
        </div>
      )}
      <div className="chat-details-bottomsheet">
        <div className="chat-details-bottomsheet__content">
          <img
            className="chat-details-bottomsheet__image"
            width="64"
            height="64"
            src={chatDetails.image || ''} //@note For custom bots add default image (or make image required)
            role="presentation"
            alt=""
          />
          <div className="chat-details-bottomsheet__title">
            <ChatDetailsName
              name={newChatBotName ? newChatBotName : chatDetails.name}
              chatBotId={chatBotId}
              sessionId={sessionId}
            />

            {newChatBotName && <h3>{chatDetails.name}</h3>}
            <div className="chat-details-bottomsheet__tags">
              {chatDetails.tags.map(t => (
                <ChatTag key={t.id} name={t.name} />
              ))}
            </div>
          </div>
        </div>

        <p className="chat-details-bottomsheet__description">
          {chatDetails.description}
        </p>

        {errorMessage && (
          <p className="chat-details-bottomsheet__error">{errorMessage}</p>
        )}

        <div className="chat-details-bottomsheet__actions">
          <button
            type="button"
            className="chat-details-bottomsheet__new-chat-button"
            onClick={createSession}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
            >
              <path
                fill="#fff"
                d="M8 2.667c.368 0 .667.298.667.666v4h4a.667.667 0 1 1 0 1.334h-4v4a.667.667 0 1 1-1.334 0v-4h-4a.667.667 0 0 1 0-1.334h4v-4c0-.368.299-.666.667-.666Z"
              />
            </svg>
            {t('ai-bots.chat.new-title')}
          </button>

          {sessionId && <DeleteSessionButton onClick={deleteSession} />}
        </div>
      </div>
    </BottomSheet>
  );
}

import classNames from 'classnames';

import './ui-spinner.css';

export function UiSpinner({ className }: { className?: string }) {
  return (
    <svg
      className={classNames('ui-spinner', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <path
        fill="#aaa"
        fillRule="evenodd"
        d="M8 1a6.994 6.994 0 0 0-6.467 4.321 7.001 7.001 0 0 0 9.146 9.146A7 7 0 0 0 15 8h1c0 1.582-.47 3.129-1.348 4.445a7.995 7.995 0 0 1-12.309 1.211 8.002 8.002 0 0 1-2.19-4.095 8.009 8.009 0 0 1 3.403-8.212A7.983 7.983 0 0 1 8 0v1z"
      />
    </svg>
  );
}

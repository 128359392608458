import { AxiosPromise } from 'axios';

import { api } from '../../../../services/http.service';

export interface ChallengeInfoTaskType {
  id: string;
  title: string;
  image: string;
  day: number;
}

export interface ChallengeInfoType {
  id: string;
  name: string;
  description: string;
  image: string;
  image_v2: string;
  duration: string;
  level: string;
  detail: string;
  is_released: boolean;
  social_proof_image: string;
  reviews: Array<{
    name: string;
    review: string;
  }>;
  tasks_count: number;
  ordering: number;
  tasks: Array<ChallengeInfoTaskType>;
}

export const ChallengesInfoAPI = {
  getInfo(id: string): AxiosPromise<ChallengeInfoType> {
    return api.get(`/daily-challenges/${id}`);
  },
};

import type { AxiosResponse } from 'axios';

import { api } from '../../../../../services/http.service';
import type { AiBot } from '../../../entities/ai-bot';
import { AiBotCategoryV2 } from '../../../entities/ai-bot/types';

export interface AiBotsListType {
  items: Array<AiBotCategoryV2>;
}

export const AiBotsCategoriesAPI = {
  getAiBotsCategories(): Promise<AxiosResponse<AiBotsListType>> {
    return api.get('/ai-chatbots/categories?limit=6&include_tools=true');
  },

  saveUserIntersets(categoryIds: string[]) {
    return api.post('ai-chatbots/interests', {
      category_ids: categoryIds,
    });
  },
};

import styled from 'styled-components';

import { IStyledNavbarProps } from '../../../types/StyledTypes';

export const StyledNavbar = styled.div<IStyledNavbarProps>`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  height: 66px;
  padding: 0 24px;
  display: flex;
  flex-direction: ${(props: any) => (props.alignLogoCenter ? 'column' : 'row')};
  justify-content: ${(props: any) =>
    props.alignLogoCenter ? 'center' : 'space-between'};
  align-items: center;
  z-index: 2;
  background-color: ${(props: any) => props.theme.colors.white};
  border-bottom: ${props => (props.isShadow ? '1px solid #e0e4e9' : '')};
  // eslint-disable-next-line no-template-curly-in-string
  box-shadow: ${props =>
    props.isShadow
      ? '2px 2px 2px ${props => props.theme.colors.grayShadow}'
      : ''};
  position: ${props => (props.isSticky ? 'sticky' : 'relative')};
  top: 0;
  .logo {
    display: flex;
    align-items: center;
  }
  .back-closep-icon {
    position: relative;
    z-index: 3;
  }
  .settings-container {
    min-height: 24px;
    min-width: 24px;
    position: absolute;
    top: 21px;
    right: 24px;
  }
  .navbar_amount {
    display: flex;
    align-items: flex-end;
    position: relative;

    .amount_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #48484a;
      margin-left: 13px;
    }
  }
  .route_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
  }
  .route-content {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }
  .route_header {
    font-size: 16px;
    font-weight: 600;
  }
  .lives,
  .trades,
  .streakes,
  .items {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 8px;
    }
    .count {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #48484a;
    }
    .a_cash_title {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #636366;
    }
    .a_cash_amount {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .trades,
  .lives {
    margin-right: ${props => (props.isShowStreaks ? '24px' : '0px')};
    position: relative;
  }
  .streakes {
    position: relative;
  }

  .trades_modal {
    width: 100%;
    margin: auto;
    max-width: 500px;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 66px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .trades_button {
    margin-top: 21px;
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    background-color: #5653fe;
    border-radius: 8px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 200%;
    border: none;
    height: 48px;
    img {
      margin: 0 0 0 8px;
    }
  }
  .button_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 200%;
  }
  .modal {
    z-index: 10;
    padding: 24px 24px;
    background-color: #ffffff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .inner_modal {
    display: flex;
    align-items: center;
  }

  .shadow {
    height: 100%;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #000000;
    opacity: 0.4;
    z-index: 2;
  }

  .triangle {
    bottom: -1px;
    height: 12px;
    left: 0;
    overflow: hidden;
    position: absolute;
    z-index: 5;
    width: 100%;
    ::after {
      border: 1px solid #c8ced7;
      box-sizing: border-box;
      content: '';
      height: 30px;
      left: 50%;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: top left;
      width: 30px;
      background-color: white;
    }
  }

  .triangle_streaks {
    bottom: -1px;
    height: 12px;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;

    ::after {
      border: 1px solid #c8ced7;
      box-sizing: border-box;
      content: '';
      height: 30px;
      right: 14px;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: top left;
      width: 30px;
      background-color: white;
    }
  }

  .trades_modal_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #636366;
  }

  .hideModal {
    display: none;
  }
  .showModal {
    display: block;
  }

  .thunder_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .wrapper_small {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    padding: 14px 14px;
    width: 100%;
    margin-bottom: 16px;
  }

  .wrapper_reminder {
    position: relative;
    background: linear-gradient(84.81deg, #5653fe 17.63%, #c1bfff 128.87%);
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    padding: 14px 14px;
    width: 100%;
  }

  .wrapper_reminder_small {
    position: absolute;
    top: 0;
    right: 0;
    background: #ff9900;
    border-radius: 3px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
  }

  .text_reminder {
    max-width: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .gray {
    filter: grayscale(1);
    filter: url(filters.svg#grayscale); /* Firefox 3.5+ */
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  }
  .rotated {
    transform: rotate(270deg);
  }

  .rotated-180 {
    transform: rotate(180deg);
  }

  .skip-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #40444d;
  }

  .flag {
    margin-right: 4px;
  }
  .cross-icon {
    margin-right: 16px;
  }
  .avatar-container {
    position: relative;
  }
  .avatar {
    margin-right: 8px;
  }
  .mentor-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #24234c;
  }
  .status-circle {
    position: absolute;
    width: 8px;
    height: 8px;
    left: 24px;
    top: 24px;
    background: #ffff;
    border-radius: 100%;
  }
  .green-circle {
    position: absolute;
    width: 6px;
    height: 6px;
    left: 1px;
    top: 1px;
    background: #7abf4c;
    border-radius: 100%;
  }
  .gem-info {
    padding: 4px 10px;
    background: #f5f5f5;
    border-radius: 64px;
    margin-left: 8px;
  }
  .gem-amount {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #40444d;
    margin-right: 4px;
  }
`;

export const TransactionNavbar = styled(StyledNavbar)`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => (props.isShadow ? '#FFFFFF' : '#F5F7F9')};
  box-shadow: ${props =>
    props.isShadow ? '0px 1px 4px rgba(0, 0, 13, 0.05)' : ''};
  .navbar_right_side {
    text-align: right;

    .cash_available {
      display: flex;
      flex-direction: row;
    }

    .cash_available_title {
      font-size: 14px;
      color: ${(props: any) => props.theme.colors.grayText2};
      font-weight: 400;
    }

    .cash_amount {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .navbar_right_side_cash {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 8px;
    }
    .cash_amount {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #48484a;
    }
  }
`;

export const BackNavbarContainer = styled.div<any>`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  height: 64px;
  background-color: #ffffff;
  padding: 0 12px;
  z-index: 10;
  border-bottom: ${props => (props.isShadow ? '1px solid #E5E7EB' : '')};
`;

export const LogoNavbarContainer = styled.div<any>`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 64px;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
  border-bottom: ${props => (props.isShadow ? '1px solid #E5E7EB' : '')};
`;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChallengeListItemType } from '../../list';
import type { ChallengesListProgressType } from '../../progress';
import './challenge-joined-card.css';

export function ChallengeJoinedCard(props: {
  item: ChallengeListItemType & ChallengesListProgressType;
  events: {
    onClick: () => void;
  };
}) {
  const { t } = useTranslation();

  const progress = useMemo(
    () =>
      Math.round(
        (props.item.completed_days_count / props.item.days_count) * 100
      ),
    [props.item.days_count, props.item.completed_days_count]
  );

  return (
    <button
      type="button"
      className="challenge-joined-card"
      onClick={props.events.onClick}
    >
      <img
        width="72"
        height="72"
        src={props.item.image}
        role="presentation"
        alt=""
      />

      <span className="challenge-joined-card__content">
        <strong className="challenge-joined-card__title">
          {props.item.name}
        </strong>
        <span className="challenge-joined-card__progress-title">
          {t('challenges.joined.progress', {
            count: props.item.completed_days_count,
            total: props.item.days_count,
          })}
        </span>
        <span className="challenge-joined-card__progress-container">
          <span
            className="challenge-joined-card__progress-line"
            style={{ width: `${progress}%` }}
          ></span>
        </span>
      </span>
    </button>
  );
}

import i18next from 'i18next';

import BE_MY_OWN_BOSS_ICON from '../assets/be_my_own_boss.png';
import FINANCIAL_FREEDOM from '../assets/financial_freedom.png';
import GROW_WEALTH_ICON from '../assets/grow_wealth.png';
import PROFESSIONAL_GROW_ICON from '../assets/professional_growth.png';
import TRAVEL_WORLD_ICON from '../assets/travel_world.png';
import WORK_LIFE_BALANCE from '../assets/work_life_balance.png';

export const GOALS = {
  BE_MY_OWN_BOSS: 'be_my_own_boss',
  GROW_WEALTH: 'grow_wealth',
  TRAVEL_WORLD: 'travel_world',
  FINANCIAL_FREEDOM: 'financial_freedom',
  PROFESSIONAL_GROWTH: 'professional_growth',
  WORK_LIFE_BALANCE: 'work_life_balance',
  SOMETHING_ELSE: 'something_else',
  AI: 'ai',
} as const;

type ValueOf<T> = T[keyof T];

export type GoalType = ValueOf<typeof GOALS>;

export const GOALS_CONTENT: Record<
  string,
  { title: string; shortTitle: string; icon?: string }
> = {
  get [GOALS.GROW_WEALTH]() {
    return {
      title: i18next.t('goals.goals.grow_wealth'),
      shortTitle: i18next.t('goals.short-goals.grow_wealth'),
      icon: GROW_WEALTH_ICON,
    };
  },
  get [GOALS.BE_MY_OWN_BOSS]() {
    return {
      title: i18next.t('goals.goals.be_my_own_boss'),
      shortTitle: i18next.t('goals.short-goals.be_my_own_boss'),
      icon: BE_MY_OWN_BOSS_ICON,
    };
  },
  get [GOALS.TRAVEL_WORLD]() {
    return {
      title: i18next.t('goals.goals.travel_world'),
      shortTitle: i18next.t('goals.short-goals.travel_world'),
      icon: TRAVEL_WORLD_ICON,
    };
  },
  get [GOALS.FINANCIAL_FREEDOM]() {
    return {
      title: i18next.t('goals.goals.financial_freedom'),
      shortTitle: i18next.t('goals.short-goals.financial_freedom'),
      icon: FINANCIAL_FREEDOM,
    };
  },
  get [GOALS.PROFESSIONAL_GROWTH]() {
    return {
      title: i18next.t('goals.goals.professional_growth'),
      shortTitle: i18next.t('goals.short-goals.professional_growth'),
      icon: PROFESSIONAL_GROW_ICON,
    };
  },
  get [GOALS.WORK_LIFE_BALANCE]() {
    return {
      title: i18next.t('goals.goals.work_life_balance'),
      shortTitle: i18next.t('goals.short-goals.work_life_balance'),
      icon: WORK_LIFE_BALANCE,
    };
  },
  get [GOALS.SOMETHING_ELSE]() {
    return {
      title: i18next.t('goals.goals.something_else'),
      shortTitle: i18next.t('goals.short-goals.something_else'),
    };
  },
};

import { useTranslation } from 'react-i18next';

import EmptyResultsImage from '../../../shared/assets/empty-bots.svg';
import './empty-results.css';

export function EmptyResults({ withText = true }: { withText?: boolean }) {
  const { t } = useTranslation();
  return (
    <div className="empty-results">
      <img
        width="302"
        height="130"
        src={EmptyResultsImage}
        role="presentation"
        alt=""
      />
      {withText && <p>{t('ai-bots.search.empty-results')}</p>}
    </div>
  );
}

import { useTranslation } from 'react-i18next';

import { GuideType } from '../../../../guides/entities/guide';
import { GuideDuration } from '../../../../guides/entities/guide-duration';
import './assets/other-guides-list.css';

export function OtherGuidesList({
  guides,
  onGuideClick,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType) => void;
}) {
  const { t } = useTranslation();

  return (
    <section className="other-guides-list">
      <div className="other-guides-list__list">
        {guides.map(guide => (
          <button
            key={guide.id}
            className="other-guides-list__item"
            onClick={() => onGuideClick(guide)}
          >
            <img
              className="other-guides-list__item-image"
              width="64"
              height="64"
              src={guide.image}
              role="presentation"
              alt=""
            />

            <GuideDuration
              className="small-guide-card__duration"
              unitsCount={guide.units_count}
              duration={guide.duration}
              size="sm"
            />

            <strong className="other-guides-list__item-title">
              {guide.name}
            </strong>
          </button>
        ))}
      </div>
    </section>
  );
}

import type { AiBotCategory } from '../../../entities/ai-bot';
import './assets/category-button.css';

export function CategoryButton({
  isActive,
  category,
  onClick,
}: {
  isActive?: boolean;
  category: AiBotCategory;
  onClick: (id: AiBotCategory['id']) => void;
}) {
  return (
    <button
      disabled={isActive}
      type="button"
      className={`ai-bots-category-button-v2 ${
        isActive ? 'ai-bots-category-button-v2--active' : ''
      }`}
      onClick={() => onClick(category.id)}
    >
      {category.icon && (
        <img src={category.icon} width={16} height={16} alt="" />
      )}
      {category.name}
    </button>
  );
}

import VisibilityIcon from '@mui/icons-material/Visibility';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  showCheckMailModal,
  showCongratulationsOnReset,
  showLoadingSpinner,
  showResetPasswordForm,
} from '../../../../context/atoms/GlobalPopup';
import { IUserState } from '../../../../context/atoms/User';
import { analyticsService } from '../../../../services/analytics.service';
import cognitoService from '../../../../services/cognito.service';
import {
  FlexWrapper,
  Heading,
  QuestionContainer,
  Subtitle,
} from '../PasswordReset.styles';

const ResetPassword = () => {
  const setLoading = useSetRecoilState(showLoadingSpinner);
  const [IUser, setIUser] = useRecoilState(IUserState);
  const setShowCheckMail = useSetRecoilState(showCheckMailModal);
  const setShowCongratsOnReset = useSetRecoilState(showCongratulationsOnReset);
  const showResetPassword = useRecoilValue(showResetPasswordForm);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({});
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmValidation, setPasswordConfirmValidation] = useState(
    {}
  );
  const [formValid, setFormValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();

  const validatePassword = () => {
    if (password) {
      let hasLowerCase = /[a-z]/.test(password);
      let hasNumbers = /\d/.test(password);
      let isLongEnough = password.length > 5;
      const re_whitespace = /\s/;

      if (re_whitespace.test(password)) {
        setPasswordValidation({
          error: true,
          helperText: `* ${t(
            'Your password cannot contain whitespace characters'
          )}`,
        });
        return;
      }

      if (!hasLowerCase || !hasNumbers || !isLongEnough) {
        setPasswordValidation({
          error: true,
          helperText: `* ${t(
            'Your password should be at least 6 characters and contain a number and a letter'
          )}`,
        });
        return;
      }
    }

    if (passwordConfirm) {
      validatePasswordConfirm();
      return;
    }

    if (password && passwordConfirm && password === passwordConfirm) {
      setPasswordConfirmValidation({});
      return;
    }
  };

  const validatePasswordConfirm = () => {
    const re_whitespace = /\s/;

    if (re_whitespace.test(passwordConfirm)) {
      setPasswordConfirmValidation({
        error: true,
        helperText: `"* ${t(
          'Your password cannot contain whitespace characters'
        )}`,
      });
      return;
    }

    if (password && passwordConfirm && password !== passwordConfirm) {
      setPasswordConfirmValidation({
        error: true,
        helperText: `* ${t('Passwords do not match')}`,
      });
      return;
    }

    if (password && passwordConfirm && password === passwordConfirm) {
      setPasswordConfirmValidation({});
      return;
    }
  };

  useEffect(() => {
    let passwordValid = password && !passwordValidation.hasOwnProperty('error');
    let passwordConfirmValid =
      passwordConfirm &&
      !passwordConfirmValidation.hasOwnProperty('error') &&
      password === passwordConfirm;

    if (passwordValid && passwordConfirmValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [passwordValidation, passwordConfirmValidation]);

  const handleFormSubmit = () => {
    if (!formValid) return;

    onSubmit();
  };

  const onSubmit = () => {
    const re_whitespace = /\s/;

    if (re_whitespace.test(password) || re_whitespace.test(passwordConfirm)) {
      setErrorMsg(`* ${t('Password cannot contain white space')}`);
      return;
    }
    setErrorMsg('');
    setLoading(true);
    submitForgot(password);
  };

  const submitForgot = (newPassword: string) => {
    const newUser = {
      email: IUser.email,
      password: newPassword,
      code: IUser.code,
      username: IUser.username,
      user_id: IUser.user_id,
    };

    setIUser(newUser);

    cognitoService
      .forgotPasswordSubmit(IUser.email, IUser.code, newPassword)
      .then(() => {
        // check if ok
        analyticsService.sendEvent('pr_funnel_reset_password_finish', {
          email: IUser.email,
          success: true,
        });
        signIn(newPassword);
      })
      .catch((error: any) => {
        // check if ok
        analyticsService.sendEvent('pr_funnel_reset_password_finish', {
          email: IUser.email,
          success: false,
          error: error.message,
        });
        const newCheckMailState = {
          open: true,
          errorMessage: error.message,
        };
        setShowCheckMail(newCheckMailState);
        setLoading(false);
      });
  };

  const signIn = (newPassword: string) => {
    cognitoService.cleanUpCognitoCookies();
    cognitoService
      .signIn(IUser.email, newPassword)
      .then(() => {
        cognitoService.getSession().then(() => {
          setShowCongratsOnReset(true);
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {showResetPassword && (
        <QuestionContainer>
          <Heading>{t('Set your new password')}</Heading>
          <Subtitle>
            {t(
              'Your new password must be different to previously used passwords.'
            )}
          </Subtitle>

          <FlexWrapper>
            <form onSubmit={handleFormSubmit}>
              <TextField
                {...passwordValidation}
                sx={{ width: 1 }}
                id="password"
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                name="password"
                margin="normal"
                placeholder={t('Enter your password') || 'Enter your password'}
                autoComplete="new-password"
                onChange={e => {
                  setPassword(e.target.value);
                  setPasswordValidation({});
                }}
                onBlur={() => validatePassword()}
                InputProps={{
                  endAdornment: (
                    <button
                      onClick={e => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      style={{ all: 'unset' }}
                    >
                      <VisibilityIcon style={{ color: '#6c757d' }} />
                    </button>
                  ),
                }}
              />

              <TextField
                {...passwordConfirmValidation}
                sx={{ width: 1 }}
                id="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                label={t('Confirm') + ' ' + t('Password')}
                name="confirmPassword"
                onChange={e => {
                  setPasswordConfirm(e.target.value);
                  setPasswordConfirmValidation({});
                }}
                onBlur={() => validatePasswordConfirm()}
                margin="normal"
                placeholder={t('Confirm your password')!}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <button
                      onClick={e => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      style={{ all: 'unset' }}
                    >
                      <VisibilityIcon style={{ color: '#6c757d' }} />
                    </button>
                  ),
                }}
              />
            </form>

            <div className="small">
              {errorMsg ? (
                <>
                  <p>*{t(errorMsg)}</p>
                </>
              ) : null}
            </div>
          </FlexWrapper>

          <button
            style={{
              backgroundColor: '#5653FE',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              padding: '10px 24px',
              width: '100%',
              margin: '24px 0 18px',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '200%',
            }}
            className={formValid ? '' : 'disabled'}
            onClick={handleFormSubmit}
          >
            {' '}
            {t('Reset password')}
          </button>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Subtitle>
              {t('Return to')} <Link to="/auth/signin">{t('Log In')}</Link>
            </Subtitle>
          </div>
        </QuestionContainer>
      )}
    </>
  );
};

export default ResetPassword;

import { useQuery } from '@tanstack/react-query';

import { UpsellContentApi } from '../api';

export function useGigaUpsellContent(name: string, language: string) {
  return useQuery({
    queryKey: ['giga-upsell-content', name, language],
    queryFn: () =>
      UpsellContentApi.getUpsellContent(language, name).then(res => res.data),
    staleTime: Infinity,
  });
}

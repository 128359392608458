import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import page_not_found_icon from '../../../images/page_not_found_icon.svg';
import { PageNotFoundContainer } from './PageNotFound.styles';

const PageNotFound: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <PageNotFoundContainer>
      <div className="content">
        <div></div>
        <img src={page_not_found_icon} alt="404 icon" />
        <div>
          <p className="header">{t('Ooops! Page not found!')}</p>
          <p className="paragraph">
            {t(
              'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.'
            )}
          </p>
        </div>
        <div className="go_back">
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            {t('Go back')}
          </button>
        </div>
      </div>
    </PageNotFoundContainer>
  );
};

export default PageNotFound;

import i18next from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { OnboardingAnalytics } from '../../../../onboarding/shared/analytics';
import { UiPrimaryButton } from '../../../../shared/ui';
import './assets/guides-onboarding-bottomsheet.css';
import WelcomeGuidesImageES from './assets/welcome-guides-image-es.png';
import WelcomeGuidesImageEN from './assets/welcome-guides-image.png';

export function GuidesOnboardingBottomSheet({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();
  const isESLocale = i18next.language === 'es';

  useEffect(() => {
    if (isVisible) {
      OnboardingAnalytics.guidesOnboardingView();
    }
  }, [isVisible]);

  function onCloseBottomSheet() {
    OnboardingAnalytics.onGuidesOnboardingClose();
    onDismiss();
  }

  return (
    <BottomSheet
      key="guides-onboarding-bottomsheet"
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={() => onCloseBottomSheet()}
    >
      <section className="guides-onboarding-bottomsheet">
        <div className="guides-onboarding-bottomsheet__container">
          <div className="guides-onboarding-bottomsheet__image-container">
            <img
              width="312"
              height="160"
              src={isESLocale ? WelcomeGuidesImageES : WelcomeGuidesImageEN}
              role="presentation"
              alt=""
            />
          </div>

          <div className="guides-onboarding-bottomsheet__content">
            <div className="guides-onboarding-bottomsheet__text">
              <h2>{t('guides.onboarding.title')}</h2>
              <p>{t('guides.onboarding.description')}</p>
            </div>
            <div className="guides-onboarding-bottomsheet__check-list">
              <h2>{t('guides.onboarding.check-list.title')}</h2>
              <div className="guides-onboarding-bottomsheet__check">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect width="20" height="20" fill="#5653FE" rx="10" />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m14.667 6.5-6.416 6.417L5.334 10"
                  />
                </svg>
                <p>{t('guides.onboarding.check-list.check-onboarding')}</p>
              </div>
              <div className="guides-onboarding-bottomsheet__check">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    fill="#fff"
                    rx="9.5"
                  />
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    stroke="#C4C7CC"
                    rx="9.5"
                  />
                </svg>
                <p>{t('guides.onboarding.check-list.check-guides')}</p>
              </div>
            </div>
          </div>

          <div className="guides-onboarding-bottomsheet__actions">
            <UiPrimaryButton onClick={onCloseBottomSheet}>
              {t('guides.onboarding.action')}
            </UiPrimaryButton>
          </div>
        </div>
      </section>
    </BottomSheet>
  );
}

import classNames from 'classnames';

import BackArrow from '../../../../../../images/back_arrow.svg';
import './assets/ui-navigation.css';

export function UiNavigation({
  title,
  action,
  className,
  onBackButtonClicked,
}: {
  title: string | JSX.Element | JSX.Element[];
  action?: JSX.Element | JSX.Element[];
  className?: string;
  onBackButtonClicked: () => void;
}) {
  return (
    <nav className={classNames('ui-navigation', className)}>
      <div>
        <button
          type="button"
          className="ui-navigation__back-button"
          onClick={() => onBackButtonClicked()}
        >
          <img width="24" height="24" src={BackArrow} alt="go back" />
        </button>
      </div>
      <div className="ui-navigation__title">
        {typeof title === 'string' ? <h1>{title}</h1> : title}
      </div>
      <div>{action}</div>
    </nav>
  );
}

import classNames from 'classnames';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import DiscountImageEN from '../../../../images/upsell_discount_en.png';
import DiscountImageES from '../../../../images/upsell_discount_es.png';
import { getUpsellImageUrl } from '../../../../shared/lib';
import { ImageSkeleton } from '../../../guides/features/image-skeleton';
import { UiPrimaryButton } from '../../../shared/ui';
import { GigaUpsell } from '../entities/giga-upsell-content/types';
import './assets/chase-bottomsheet.css';

export function ChaseBottomsheet({
  isVisible,
  events,
  upsellType,
  headingExpValue,
  data,
}: {
  isVisible: boolean;
  events: {
    onClose: () => void;
  };
  upsellType: string;
  headingExpValue?: string | null;
  data: GigaUpsell['pages']['chase'];
}) {
  const isESLocale = i18next.language === 'es';

  const isGigaUpsell = upsellType === 'giga_upsell';

  const gigaUpsellImage = isESLocale ? DiscountImageES : DiscountImageEN;
  const chaseImage = getUpsellImageUrl(data.chaseGroup.image);

  return (
    <BottomSheet key="new-upsell-chase-bottomsheet" open={isVisible}>
      <div className="upsell-chase-bottomsheet">
        <div className="upsell-chase-bottomsheet__container">
          <h1
            className={classNames('upsell-chase-bottomsheet__title', {
              'upsell-chase-bottomsheet__title-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            {data.chaseGroup.title}
          </h1>
          <p
            className={classNames('upsell-chase-bottomsheet__description', {
              'upsell-chase-bottomsheet__description-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            <Trans
              i18nKey={data.chaseGroup.description}
              components={{ b: <b /> }}
            />
          </p>
          <ImageSkeleton
            className="upsell-chase-bottomsheet__image"
            width="100%"
            height="218px"
            src={isGigaUpsell ? gigaUpsellImage : chaseImage}
            alt=""
          />
          <div
            className={classNames('upsell-chase-bottomsheet__quote', {
              'upsell-chase-bottomsheet__quote-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            <Trans
              i18nKey={data.chaseGroup.quote as string}
              components={{ b: <b /> }}
            />
          </div>
          <div className="upsell-chase-bottomsheet__actions">
            <UiPrimaryButton onClick={events.onClose}>
              {data.chaseGroup.button}
            </UiPrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}

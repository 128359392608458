import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { UiPrimaryButton } from '../../../../shared/ui';
import { useGuideLessonFillTheGapStore } from '../../../entities/guide-lesson-fill-the-gap';
import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import CheckIcon from './assets/check-icon.svg';
import NextIcon from './assets/next-icon.svg';
import './guide-lesson-fill-the-gap.css';

function TaskCompleted() {
  const { t } = useTranslation();

  return (
    <div className="task-completed">
      <img width={20} height={14} src={CheckIcon} alt="" />
      <span>{t('guides.lesson-task.completed')}</span>
    </div>
  );
}

function TaskSkipped() {
  const { t } = useTranslation();

  return (
    <div className="task-skipped">
      <img width={14} height={14} src={NextIcon} alt="" />
      <span>{t('guides.lesson-task.skipped')}</span>
    </div>
  );
}

export function GuideLessonFillTheGap({
  task,
  onOpenTask,
}: {
  task: GuideLessonDetailsFillTheGapType;
  onOpenTask: (task: GuideLessonDetailsFillTheGapType) => void;
}) {
  const { t } = useTranslation();
  const [actionContainer] = useAutoAnimate();
  const { completedTasksIds, skippedTasksIds } = useGuideLessonFillTheGapStore(
    useShallow(state => ({
      completedTasksIds: state.completedFillTheGapIds,
      skippedTasksIds: state.skippedFillTheGapIds,
    }))
  );

  const isTaskCompleted = useMemo(
    () => completedTasksIds.includes(task.id),
    [completedTasksIds]
  );

  const isTaskSkipped = useMemo(
    () => skippedTasksIds.includes(task.id),
    [skippedTasksIds]
  );

  return (
    <div className="guide-lesson-task">
      {task.content.map(item => (
        <Fragment key={item.id}>
          <h1 className="guide-lesson-task__title">{item.title}</h1>
          <p className="guide-lesson-task__text">{item.description}</p>
        </Fragment>
      ))}

      <div ref={actionContainer} className="guide-lesson-task__actions">
        {isTaskCompleted && <TaskCompleted />}
        {isTaskSkipped && <TaskSkipped />}
        {!isTaskSkipped && !isTaskCompleted && (
          <UiPrimaryButton onClick={() => onOpenTask(task)}>
            {t('guides.open-playground-button')}
          </UiPrimaryButton>
        )}
      </div>
    </div>
  );
}

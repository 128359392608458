import './challenge-card.css';

interface ChallengeCardPropsType {
  imageUrl: string;
  title: string;
  duration: string;
  level: string;
  events: {
    onClick: () => void;
  };
}

export function ChallengeCard(props: ChallengeCardPropsType) {
  return (
    <div className="challenge-card" onClick={props.events.onClick}>
      <img
        width="296"
        height="200"
        src={props.imageUrl}
        role="presentation"
        alt=""
      />

      <div className="challenge-card__content">
        <h3>{props.title}</h3>
        <p>
          <strong>{props.duration}</strong>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="4"
            fill="none"
          >
            <circle cx="2" cy="2" r="2" fill="#6B7280" />
          </svg>
          <strong>{props.level}</strong>
        </p>
      </div>
    </div>
  );
}

import { useFeatureValue } from '@growthbook/growthbook-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { userApi } from '../../../../api/userApi';
import { userQuery } from '../../../../context/atoms/User';

const GUIDES_ONBOARDING_FLAG = 'guides-onboarding-flag';

export function useGuidesOnboarding() {
  const user = useRecoilValue(userQuery);
  const queryClient = useQueryClient();
  const growthbookOnboardingType = useFeatureValue(
    'onboarding-type',
    'default'
  );

  const isNewOnboarding = growthbookOnboardingType === 'test';

  const guidesOnboardingFlagQuery = useQuery({
    queryKey: ['guides-onboarding-flag', GUIDES_ONBOARDING_FLAG],
    queryFn: () =>
      userApi
        .getTourPassed(user?.user_id, GUIDES_ONBOARDING_FLAG)
        .then(data => data.passed),
    staleTime: Infinity,
    enabled: isNewOnboarding,
  });

  const guidesOnboardingFlagMutation = useMutation({
    mutationKey: ['guides-onboarding-flag', GUIDES_ONBOARDING_FLAG],
    mutationFn: () =>
      userApi.postTourPassed(user?.user_id, GUIDES_ONBOARDING_FLAG),
    onSuccess() {
      return queryClient.setQueryData(
        ['guides-onboarding-flag', GUIDES_ONBOARDING_FLAG],
        true
      );
    },
  });

  const isGuidesOnboardingVisible =
    !guidesOnboardingFlagQuery.isLoading &&
    !guidesOnboardingFlagQuery.data &&
    isNewOnboarding;

  function setGuidesOnboardingVisible() {
    if (isGuidesOnboardingVisible) {
      guidesOnboardingFlagMutation.mutate();
    }
  }

  return {
    isGuidesOnboardingVisible,
    setGuidesOnboardingVisible,
  };
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assignIn, find, get } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../context/atoms/User';
import { GOALS } from '../../../modules/personal-goal';
import {
  DEFAULT_PERSONAL_PROFILE,
  PersonalProfileAPI,
  PersonalProfileStorage,
} from '../api';

let isNeedRefetch = false;

export function usePersonalProfileQuery(isFetchingTrigger = false) {
  const userData = useRecoilValue(userDataQuery);
  const queryClient = useQueryClient();
  const [isFetched, setIsFetched] = useState(false);

  const personalProfileQuery = useQuery({
    queryKey: ['personal-profile'],
    queryFn: () =>
      PersonalProfileAPI.getProfile()
        .then(res => {
          isNeedRefetch = false;

          const goal = get(res.data, 'properties.goal[0]', '');

          const hasGoal = Boolean(find(GOALS, g => g === goal));

          const data = hasGoal
            ? res.data
            : assignIn(res.data, {
                properties: {
                  goal: [GOALS.GROW_WEALTH],
                },
              });

          PersonalProfileStorage.setProfile({
            userId: userData?.id as number,
            profile: data,
          });

          return data;
        })
        .catch(cause => {
          throw new Error('ERROR_GET_PERSONAL_PROFILE', { cause });
        }),
    initialData: PersonalProfileStorage.getProfile(userData?.id as number),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isNeedRefetch && !isFetched && isFetchingTrigger) {
      setIsFetched(true);
      personalProfileQuery.refetch();
    }
  }, [isFetched]);

  useEffect(() => {
    if (personalProfileQuery.isError) {
      queryClient.setQueryData(
        ['personal-profile'],
        () => DEFAULT_PERSONAL_PROFILE
      );

      isNeedRefetch = true;
    }
  }, [personalProfileQuery.isError]);

  const isLoading =
    personalProfileQuery.isLoading || personalProfileQuery.isRefetching;

  return {
    isLoading,
    isError: personalProfileQuery.isError,
    data: personalProfileQuery.data,
  };
}

export function usePatchGoalMutation() {
  const userData = useRecoilValue(userDataQuery);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['personal-profile-goal-mutation'],
    mutationFn: (goal: string) =>
      PersonalProfileAPI.updateGoal(userData?.id as number, goal).then(() => {
        queryClient.setQueryData(['personal-profile'], () => {
          const data = PersonalProfileStorage.getProfile(
            userData?.id as number
          );

          return (
            data ?? {
              ...DEFAULT_PERSONAL_PROFILE,
              properties: {
                goal: [goal],
              },
            }
          );
        });
      }),
  });
}

export function usePatchFullName() {
  const userData = useRecoilValue(userDataQuery);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['personal-profile-full-name-mutation'],
    mutationFn: (fullName: string) =>
      PersonalProfileAPI.updateFullName(userData?.id as number, fullName).then(
        () => {
          queryClient.setQueryData(['personal-profile'], () => {
            const data = PersonalProfileStorage.getProfile(
              userData?.id as number
            );

            return (
              data ?? {
                ...DEFAULT_PERSONAL_PROFILE,
                personalization_criteria: {
                  full_name: [fullName],
                },
              }
            );
          });
        }
      ),
  });
}

import { FC } from 'react';

import { StyledLoader } from './Loader.style';

const Loader: FC = () => {
  return (
    <StyledLoader>
      <div className="lds-hourglass"></div>
    </StyledLoader>
  );
};

export default Loader;

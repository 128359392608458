import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';

import http from '../../../../../services/http.service';
import { useAnimatedImage } from '../../../entities/guide-image-animation/model';
import { GuideLessonDetailsChunkType } from '../../../entities/guide-lesson';
import { HeadingChunk } from './HeadingChunk';
import { ImageChunk } from './ImageChunk';
import { TextChunk } from './TextChunk';
import { TitleChunk } from './TitleChunk';
import './guide-lesson-chunk.css';

function ChunkType({
  type,
  value,
  isImageAnimated,
}: {
  type: GuideLessonDetailsChunkType['content'][0]['type'];
  value: GuideLessonDetailsChunkType['content'][0]['value'];
  isImageAnimated?: boolean;
}) {
  const animationData = useAnimatedImage(type, value, isImageAnimated);

  switch (type) {
    case 'image':
      if (isImageAnimated) {
        return (
          <div className="image-chunk">
            {animationData && (
              <Lottie animationData={animationData} loop={false} />
            )}
          </div>
        );
      }
      return <ImageChunk image={value} />;
    case 'title':
      return <TitleChunk value={value} />;
    case 'text':
      return <TextChunk value={value} />;
    case 'heading':
      return <HeadingChunk value={value} />;
  }
}

export function GuideLessonChunk({
  chunk,
  isImageAnimated,
}: {
  chunk: GuideLessonDetailsChunkType;
  isImageAnimated?: boolean;
}) {
  return (
    <div>
      {chunk.content.map(item => (
        <ChunkType
          key={item.id}
          type={item.type}
          value={item.value}
          isImageAnimated={isImageAnimated}
        />
      ))}
    </div>
  );
}

import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { motion } from 'framer-motion';

import { useOnboardingContent } from '@/entities/onboarding-content/model';
import { Loader } from '@/modules/shared';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { UiPrimaryButton } from '../../../../shared/ui';
import { getAllowedArrow } from '../../../../upsell/upsell-cascade/lib';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { getAnalyticsState } from '../../../shared/lib';
import './assets/personal-plan-page.css';

export const OnboardingV2PersonalPlanPage = () => {
  const navigation = useHistory();

  const queryParams = getQueryParamsFromURI();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow =    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0] || 'ai';

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const userArrow = getAllowedArrow(arrow, 'ai');
  const fromQuery = queryParams?.from;

  const { data: onboardingContent } = useOnboardingContent(userArrow);

  const pageContent = onboardingContent?.['welcome-page'];

  const pageBlocks = useMemo(() => {
    const modulesBlock = pageContent?.find(
      item => item.type === 'modules-block',
    )?.value;

    return {
      title:
        (pageContent?.find(item => item.type === 'title')?.value as string)
        || '',
      description:
        (pageContent?.find(item => item.type === 'description')
          ?.value as string) || '',
      actionButton:
        (pageContent?.find(item => item.type === 'action-button')
          ?.value as string) || '',
      modules:
        modulesBlock && typeof modulesBlock !== 'string'
          ? {
            title:
                (modulesBlock?.find(item => item.type === 'title')
                  ?.value as string) || '',
            modulesCount:
                (modulesBlock?.find(item => item.type === 'modules-count')
                  ?.value as string) || '',
            image:
                (modulesBlock?.find(item => item.type === 'image')
                  ?.value as string) || '',
          }
          : {},
    };
  }, [pageContent]);

  const { remoteWorkAnalyticsValue, influencerAnalyticsValue } =    getAnalyticsState({
    query:                userArrow,
    remoteWorExperiment:  'test',
    influencerExperiment: 'test',
    fromQuery,
  });

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  function onNext() {
    OnboardingV2Analytics.onIamReady({
      remote_work:  remoteWorkAnalyticsValue,
      influencer:   influencerAnalyticsValue,
      pathway_test: aiPathwayExperiment,
      lottie_test:  lottieOnboardingExperiment,
    });

    const arrowRedirect = userArrow !== 'main' ? `?arrow=${userArrow}` : '';

    if (aiPathwayExperiment === 'test') {
      const aiToolPath = `/onboarding/v2/ai-tool${arrowRedirect}`;

      return navigation.push(aiToolPath);
    }

    const startOnboardingPath = `/onboarding/v2/start-onboarding${arrowRedirect}`;

    return navigation.push(startOnboardingPath);
  }

  useEffect(() => {
    OnboardingV2Analytics.personalPlanPageView({
      remote_work:  remoteWorkAnalyticsValue,
      influencer:   influencerAnalyticsValue,
      pathway_test: aiPathwayExperiment,
      lottie_test:  lottieOnboardingExperiment,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteWorkAnalyticsValue, influencerAnalyticsValue, aiPathwayExperiment]);

  if (!onboardingContent) {
    return <Loader />;
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="personal-plan-page min-vh-full"
    >
      <div className="personal-plan-page__content">
        <div className="personal-plan-page__header">
          <h1>{pageBlocks.title}</h1>
          <p>{pageBlocks.description}</p>
        </div>
        <div>
          <div className="personal-plan-page__body">
            <div className="personal-plan-page_line" />
            <div className="personal-plan-page__content-header">
              <h2>{pageBlocks.modules.title}</h2>
              <div>{pageBlocks.modules.modulesCount}</div>
            </div>
          </div>
          <div className="personal-plan-page__image-container">
            <img
              className="personal-plan-page__image"
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              src={pageBlocks.modules.image}
              role="presentation"
              alt=""
            />
          </div>
        </div>
        <div className="personal-plan-page__gradient" />
      </div>
      <div className="personal-plan-page__actions">
        <UiPrimaryButton onClick={() => onNext()}>
          {pageBlocks.actionButton}
        </UiPrimaryButton>
      </div>
    </motion.section>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    transform: scale(1);
    position: fixed;
    bottom: 0;
    box-shadow:
      0 11px 15px -7px #0003,
      0 24px 38px 3px #00000024,
      0 9px 46px 8px #0000001f;
    border: 0;

    width: 100vw;
    min-height: 80%;
    align-self: flex-end;
    bottom: 0;
    background: white;
    padding: 0;
    border-radius: 8px;
  }
  border-radius: 8px;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: auto;
    transform: scale(1);
    position: relative;
    box-shadow:
      0 11px 15px -7px #0003,
      0 24px 38px 3px #00000024,
      0 9px 46px 8px #0000001f;
    border: 0;
    max-width: 360px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0;
    border-radius: 8px;
  }
`;

export const Header = styled.div`
  width: 100%;
  transform: translateY(2px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #495057;
  padding: 8px 8px 0px;
  border-bottom: 0 none;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const HeaderIcon = styled.div`
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
  img {
    width: 100%;
  }
`;

export const DialogContainer = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 350px) {
    margin: 0;
  }

  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .subtitle {
    margin-top: 8px;
    text-align: center;
    padding: 8px 0px 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #636366;
    margin-bottom: 24px;
  }

  align-items: center;
  max-width: 360px;
  color: #24234c;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  .img {
    margin-bottom: 24px;
    width: 131px;
    height: auto;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: ${props => props.theme.colors.dark};
    text-align: center;
    max-width: 280px;
  }

  .email-warning {
    margin-top: 12px;
    display: flex;
    justify-self: flex-end;
    padding: 16px;
    border-radius: 5px;
    background-color: #f5f7f9;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #24234c;
    }
  }

  .code-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;

    .code-character {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: none;
      border: 1px solid #ddd;
      width: 40px;
      height: 40px;
      font-size: 24px;
      font-weight: 400;
    }
  }

  #resend-confirmation {
    text-decoration: underline;
  }
`;

export const ResendCodeButton = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;

export const SmallError = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #f44336;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  align-items: center;
  width: 100%;

  .btn-confirm {
    background: #5653fe;
    border: 1px solid #5653fe;
    box-sizing: border-box;
    border-radius: 8px;
    display: block;
    width: 100%;
    height: 48px;
    margin: 0 auto;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
  }

  button:disabled {
    opacity: 0.5;
  }
`;

export const InputField = styled.input`
  background-color: #ffffff;
  width: 100%;
  height: 48px;
  padding: 10px 12px;
  background-color: #f9fafb;
  color: #536471;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  max-width: 280px;
`;

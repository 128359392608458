import { useFeatureValue } from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import { IUserData } from '../../../types/User';
import { calculateDelayTime } from '../lib';
import { useSatisfactionSurveyTourQuery } from './use-satisfaction-survey-tour-query';

const DEFAULT_DELAY_TIME = '1_day';

export function useSatisfactionSurveyModel(userData: IUserData | null) {
  const growthbookDelayTime = useFeatureValue(
    'satisfaction_survey_display_delay_time_after_registration',
    DEFAULT_DELAY_TIME
  );
  const delayTime = useMemo(() => {
    if (userData) {
      return calculateDelayTime({
        initialDate: userData?.registered_at,
        delayTime: growthbookDelayTime,
        defaultDelayTime: DEFAULT_DELAY_TIME,
      });
    }

    return null;
  }, [growthbookDelayTime, userData]);

  const isDelayTimeAfterToday = dayjs().isAfter(delayTime);

  const { isSatisfactionSurveyTourVisible, setSatisfactionSurveyTourVisible } =
    useSatisfactionSurveyTourQuery(userData);

  const [isSatisfactionSurveyVisible, setIsSatisfactionSurveyVisible] =
    useState(false);

  useEffect(() => {
    if (
      isDelayTimeAfterToday &&
      isSatisfactionSurveyTourVisible &&
      userData &&
      userData?.is_onboarded
    ) {
      setIsSatisfactionSurveyVisible(true);
    }
  }, [isDelayTimeAfterToday, isSatisfactionSurveyTourVisible, userData]);

  function onSatisfactionSurveyDismiss() {
    setIsSatisfactionSurveyVisible(false);
    setSatisfactionSurveyTourVisible();
  }

  return {
    isSatisfactionSurveyVisible,
    onSatisfactionSurveyDismiss,
  };
}

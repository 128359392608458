import http from '../services/http.service';

export const authApi = {
  getAutoLoginToken: async () => {
    return http
      .get<{ token: string }>(`/auth/token/`)
      .then(res => res.data)
      .catch(() => {
        return { token: '' };
      });
  },
};

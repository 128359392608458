import styled from 'styled-components';

export const NotStickyButton = styled.div`
  position: relative;
  padding-bottom: 16px;
  background: #ffffff;
  border: none;

  .icon-text-flex:first-of-type {
    margin-top: 0;
  }

  button {
    width: 100%;
    height: 48px;
    background: #5653fe;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
  }

  .disabled {
    opacity: 0.4;
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
  margin-top: 24px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
  font-size: 16px;
  line-height: 28px;
  color: ${props => props.theme.colors.dark_0};
`;

import { useRecoilState } from 'recoil';

import { analyticsService } from '../../services/analytics.service';
import http from '../../services/http.service';
import { ILongUser } from '../../types/User';
import { LongUserState } from '../atoms/User';

function useUserActions() {
  const [longUser, setLongUser] = useRecoilState(LongUserState);

  return {
    createUser,
    checkActiveSubscription,
    getSubscriptionInfo,
    parseUserString,
    checkUsername,
    saveUser,
    getByEmail,
    getEmailOrNull,
    getUserId,
    updateEmail,
    sendAction,
    longUser,
    saveUserLocally,
    getLongUser,
  };

  function checkActiveSubscription(userId: number) {
    if (!longUser.id) {
      return false;
    }

    return http
      .get<any>(`/users/subscriptions/${userId}/`)
      .then(v => !!v.data.has_access)
      .catch(err => {
        return false;
      });
  }

  function getSubscriptionInfo(userId: number) {
    if (!longUser.id) {
      return {};
    }
    return http
      .get<any>(`/users/subscriptions/${userId}/`)
      .then(v => {
        return v.data;
      })
      .catch(err => {
        return null;
      });
  }

  function createUser(email: string, attributionData?: any, id?: string) {
    let body: any = {
      email: email.toLowerCase(),
    };
    if (longUser.id) {
      body.id = longUser.id;
    }
    if (id) {
      body.id = parseInt(id);
    }

    if (
      !!attributionData &&
      typeof attributionData === 'object' &&
      Object.keys(attributionData).length
    ) {
      body.attribution = attributionData;
    }
    return http
      .post<ILongUser>(`/users/create/`, body)
      .then(res => res.data)
      .then(user => {
        analyticsService.setUserId(user.id.toString());
        setLongUser(user);
        saveUserLocally(user);
        return user;
      });
  }

  function saveUserLocally(user: ILongUser) {
    if (typeof window !== 'undefined') {
      setLongUser(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  function checkUsername(username: string) {
    return http.post(`/users/check_username/`, {
      username: username,
    });
  }

  function saveUser(
    email: string,
    username: string,
    verification_email: string
  ) {
    return http.post(`/users/register/`, {
      email: email,
      username: username,
      verification_email: verification_email,
    });
  }

  function getByEmail(email: string) {
    return http
      .get<ILongUser>(`/users/user/?email=${email}`)
      .then(user => {
        // saveUserLocally(user)
      })
      .catch(err => {
        return err;
      });
  }

  function getEmailOrNull(): string | null {
    if (longUser) {
      return longUser.email;
    }
    return null;
  }

  function getUserId(): string | null {
    if (typeof window !== 'undefined') return localStorage.getItem('uid');
    return null;
  }

  function getLongUser(): ILongUser {
    if (longUser.id) {
      return longUser;
    }
    let userString = parseUserString();
    if (userString) {
      setLongUser(userString);
      return userString;
    }

    return {} as ILongUser;
  }

  function parseUserString(): ILongUser | null {
    if (typeof window !== 'undefined') {
      const userString = localStorage.getItem('user');
      return userString ? (JSON.parse(userString) as ILongUser) : null;
    }

    return null;
  }

  function updateEmail(userId: number, verificationEmail: string) {
    return http.post(`/email/update/`, {
      user_id: userId,
      email: verificationEmail,
    });
  }

  function sendAction(user_id: string | null, action: string) {
    //string || number uid
    return http.post(`/email/${user_id}/`, { user_action: action });
  }
}

export { useUserActions };

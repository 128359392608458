import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ChallengesListProgressAPI } from './api';

const LIST_QUERY_KEY = ['challenges-list-progress-query'];

export function useChallengesListProgressQuery() {
  return useQuery({
    queryKey: LIST_QUERY_KEY,
    queryFn: () =>
      ChallengesListProgressAPI.getChallengesListProgress().then(
        res => res.data
      ),
    refetchOnWindowFocus: false,
  });
}

export function usePrefetchChallengesListProgressQuery() {
  const queryClient = useQueryClient();

  function prefetchChallengesListProgressQuery() {
    return queryClient.prefetchQuery({
      queryKey: LIST_QUERY_KEY,
      queryFn: () =>
        ChallengesListProgressAPI.getChallengesListProgress().then(
          res => res.data
        ),
    });
  }

  return {
    prefetchChallengesListProgressQuery,
  };
}

import {
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../../context/atoms/User';
import { ApplePayButton, useApplePay } from '../../../../entities/apple-pay';
import { PAYMENT_METHODS } from '../../../../entities/payment-method';
import { usePersonalProfileQuery } from '../../../../entities/personal-profile';
import { ReviewCard } from '../../../../entities/review-card';
import { useCurrencySignQuery } from '../../../../hooks/use-currency-sign-query';
import { useLocationQuery } from '../../../../hooks/use-location-query';
import { analyticsService } from '../../../../services/analytics.service';
import { isIOS16OrLater } from '../../../../shared/lib';
import { formatPrice } from '../../../../utils/price';
import { Loader } from '../../../shared';
import CustomSpinner from '../../../shared/CustomSpinner/CustomSpinner';
import { UiPrimaryButton } from '../../../shared/ui';
import { UpsellAnalytics } from '../../shared/analytics';
import { useWebToAppExperiment } from '../../shared/hooks/use-web-to-app-experiment';
import { getWebToAppUrl } from '../../shared/lib';
import { UiHeader } from '../../shared/ui';
import { getArrow } from '../../upsell-cascade/lib';
import { useBuyUpsellOfferMutation, useUpsellSingleQuery } from '../model';
import { ChaseBottomsheet } from './ChaseBottomsheet';
import { SmokePurchaseBottomsheet } from './SmokePurchaseBottomsheet';
import CalendarIcon from './assets/calendar-icon.svg';
import ChatIcon from './assets/chat-icon.svg';
import CheckIcon from './assets/check-icon.svg';
import FileEditIcon from './assets/file-edit-icon.svg';
import SearchIcon from './assets/search-icon.svg';
import SparkleIcon from './assets/sparkle-icon.svg';
import TimeIcon from './assets/time-icon.svg';
import './assets/upsell-assistant.css';
import './assets/upsell.css';

function UpsellReviews({ upsellType }: { upsellType: string }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}`,
  });

  const reviewsList = t('reviews.reviews', {
    returnObjects: true,
  }) as Array<{ reviewerName: string; reviewText: string }>;

  return (
    <div className="upsell-assistant__reviews">
      <h3 className="upsell__reviews-title upsell-assistant__reviews-title">
        {t('reviews.title')}
      </h3>

      <div className="upsell__reviews-list">
        {Array.isArray(reviewsList) &&
          reviewsList.map((item, i) => (
            <ReviewCard
              key={`upsell-review__${i}`}
              name={item.reviewerName}
              text={item.reviewText}
              className="upsell-assistant__review-card"
            />
          ))}
      </div>
    </div>
  );
}

function UpsellBillingDescription(props: {
  hasVat: boolean;
  discountedPrice: string;
  upsellType: string;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${props.upsellType}`,
  });

  return (
    <div className="upsell__billing-description">
      <Trans
        i18nKey={
          props.hasVat
            ? t('billing-description-with-vat')
            : t('billing-description')
        }
        values={{ discountPrice: props.discountedPrice }}
        components={{ b: <b /> }}
      />
    </div>
  );
}

function UpsellRequirementsBlock({ upsellType }: { upsellType: string }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}.requirements`,
  });

  const requirementsList = ['first', 'second', 'third'];

  return (
    <div className="upsell-assistant__benefits">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h1
          className="upsell__title upsell-assistant__title"
          style={{ maxWidth: 350 }}
        >
          <Trans
            i18nKey={t('title')}
            components={{
              span: <span />,
            }}
          />
        </h1>
      </div>

      <ul className="upsell-assistant__benefits-list">
        {requirementsList.map(benefitType => (
          <li className="upsell-assistant__benefits-item" key={benefitType}>
            <img src={CheckIcon} alt="" width="24" height="24" />
            <span style={{ fontSize: '16px' }}>
              <Trans i18nKey={t(benefitType)} components={{ b: <b /> }} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function UpsellPotentialBlock({ upsellType }: { upsellType: string }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}.potential`,
  });

  const badges = t('badges', { returnObjects: true }) as string[];
  return (
    <div className="upsell-assistant__potential">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h1
          className="upsell__title upsell-assistant__title"
          style={{ maxWidth: 350 }}
        >
          <Trans
            i18nKey={t('title')}
            components={{
              span: <span />,
            }}
          />
        </h1>
      </div>
      <h2 className="upsell-assistant__subtitle">{t('subtitle')}</h2>
      <div className="upsell-assistant__potential-image">
        <img src={t('image') as string} alt="" />
      </div>
      <div className="upsell-assistant__potential__badges">
        {Array.isArray(badges) &&
          badges.map(badge => (
            <div className="upsell-assistant__potential__badge" key={badge}>
              <p>{badge}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

function UpsellFeaturesBlock({ upsellType }: { upsellType: string }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}.features`,
  });

  const featureList = ['save-time', 'productivity', 'income'];

  const getIconSrc = (featureType: string) => {
    switch (featureType) {
      case 'save-time':
        return SparkleIcon;
      case 'productivity':
        return CalendarIcon;
      case 'income':
        return SearchIcon;
      default:
        return SparkleIcon;
    }
  };

  return (
    <div className="upsell-assistant__features">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h1
          className="upsell__title upsell-assistant__title"
          style={{ maxWidth: 350 }}
        >
          <Trans
            i18nKey={t('title')}
            components={{
              span: <span />,
            }}
          />
        </h1>
      </div>

      <ul className="upsell-assistant__features-list">
        {featureList.map(feature => (
          <li className="upsell-assistant__features-item" key={feature}>
            <img src={getIconSrc(feature)} alt="" width="24" height="24" />
            <div>
              <h3>{t(`list.${feature}.title`)}</h3>
              <p>{t(`list.${feature}.description`)}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="upsell-assistant__features-image">
        <img src={t('image') as string} alt="" />
      </div>
    </div>
  );
}

function UpsellBenefitsBlock({ upsellType }: { upsellType: string }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}`,
  });

  const benefitsList = ['maximize', 'save-time', 'copy-paste'];

  const getIconSrc = (benefitType: string) => {
    switch (benefitType) {
      case 'maximize':
        return TimeIcon;
      case 'save-time':
        return FileEditIcon;
      case 'copy-paste':
        return ChatIcon;
      default:
        return SparkleIcon;
    }
  };

  return (
    <div className="upsell-assistant__advantages">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h1
          className="upsell__title upsell-assistant__title"
          style={{ maxWidth: 350 }}
        >
          <Trans
            i18nKey={t('benefits.title')}
            components={{
              span: <span />,
            }}
          />
        </h1>
      </div>
      <h2 className="upsell-assistant__subtitle">{t('benefits.subtitle')}</h2>

      <div className="upsell-assistant__advantages-image">
        <img src={t('benefits.image') as string} role="presentation" alt="" />
      </div>

      <ul className="upsell-assistant__advantages-list">
        {benefitsList.map(benefitType => (
          <li className="upsell-assistant__advantages-item" key={benefitType}>
            <img src={getIconSrc(benefitType)} alt="" width="24" height="24" />
            <span>
              <Trans
                i18nKey={t(`benefits.${benefitType}`)}
                components={{ b: <b /> }}
              />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function useRefCallback() {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const setRefCallback = useCallback(node => {
    if (node !== null) {
      setRef(node);
    }
  }, []);

  return {
    ref,
    setRefCallback,
  };
}

const PAYWALL_CONFIG_ID_ASSISTANT = 'upsell_smoke_test_ai_assistant';
const PAYWALL_CONFIG_ID_BUSINESS = 'upsell_smoke_test_ai_business';

export function UpsellSingleGigaAssistantPage() {
  const navigation = useHistory();
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const locationQuery = useLocationQuery();
  const fromQuery = locationQuery.get('from');

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userData = useRecoilValue(userDataQuery);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const filteredArrow = getArrow(arrow);

  const isArrowAi = arrow === 'ai';

  const translationPrefix = isArrowAi ? 'business' : 'assistent';
  const paywallConfigId = isArrowAi
    ? PAYWALL_CONFIG_ID_BUSINESS
    : PAYWALL_CONFIG_ID_ASSISTANT;

  const growthBook = useGrowthBook();

  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${translationPrefix}`,
  });

  const webToAppExperiment = useWebToAppExperiment();

  const growthBookUpsellGigaValue = useFeatureValue(
    'exp_upsell_megaupsell',
    'default'
  );

  const [isUpsellBottomSheetVisible, setIsUpsellBottomSheetVisible] =
    useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isDiscountUpsell, setIsDiscountUpsell] = useState(false);

  const { ref: topPricingRef, setRefCallback: setTopPricingRef } =
    useRefCallback();
  const { ref: bottomPricingRef, setRefCallback: setBottomPricingRef } =
    useRefCallback();

  const [isTopBenefitsVisible, setIsTopBenefitsVisible] = useState(false);
  const [isBottomBenefitsVisible, setIsBottomBenefitsVisible] = useState(false);

  const [isAboveThanTopBenefitsBlock, setIsAboveThanTopBenefitsBlock] =
    useState(false);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const upsellOfferSingleQuery = useUpsellSingleQuery({
    paywallConfigId,
  });

  const currencySignQuery = useCurrencySignQuery();

  const initialPricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return upsellOfferSingleQuery.data.initialPrice;
    }
  }, [upsellOfferSingleQuery.data]);

  const pricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return isDiscountUpsell
        ? upsellOfferSingleQuery.data.discountedPrice
        : upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data, isDiscountUpsell]);

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay]
  );
  const upsellOfferSingleMutation = useBuyUpsellOfferMutation();

  const fullPrice = useMemo(
    () =>
      pricing && currencySignQuery.data
        ? `${currencySignQuery.data}${formatPrice(pricing.full_price)}`
        : '',
    [pricing, currencySignQuery.data]
  );

  const discountedPrice = useMemo(
    () =>
      pricing && currencySignQuery.data
        ? `${currencySignQuery.data}${formatPrice(pricing.discount_price)}`
        : '',
    [pricing, currencySignQuery.data]
  );

  const currencyCode = useMemo(
    () =>
      upsellOfferSingleQuery.data ? upsellOfferSingleQuery.data.currency : '',
    [upsellOfferSingleQuery.data]
  );

  const hasVat = useMemo(() => Boolean(pricing && pricing.vat), [pricing]);

  const isOfferPaywallConfig = paywallConfigId.includes('_single');

  function goToNextPage(isPurchased = false) {
    window.scroll(0, 0);

    const path = '/upsell-cascade?assistant=test';

    const pathWithReportQuery = isPurchased
      ? path + '&isReportOfferAdded=true'
      : path;

    const pathWithQueryParams = `${pathWithReportQuery}&arrow=${fromQuery}`;

    if (fromQuery) {
      return navigation.push(pathWithQueryParams);
    }

    return navigation.push(pathWithReportQuery);
  }

  function onSkip() {
    UpsellAnalytics.onSkip({
      position: 'top',
      place: 'signup_gigaupsell',
      paywall_config_id: isOfferPaywallConfig
        ? 'offer_single'
        : 'upsells_notion_report',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
      upsellName: 'default',
    });

    if (isDiscountUpsell) {
      return goToNextPage();
    }

    setIsUpsellBottomSheetVisible(true);
  }

  function onShowDiscountedUpsell() {
    setIsUpsellBottomSheetVisible(false);
    setIsDiscountUpsell(true);
    window.scroll(0, 0);
  }

  function onSmokePurchase() {
    setTimeout(() => {
      goToNextPage();
    }, 500);
  }

  useEffect(() => {
    if (topPricingRef && bottomPricingRef) {
      const intersectionObserver = new IntersectionObserver(
        intersectionObserverCallback,
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      intersectionObserver.observe(topPricingRef);
      intersectionObserver.observe(bottomPricingRef);

      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [topPricingRef, bottomPricingRef]);

  function intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.target === topPricingRef) {
        setIsAboveThanTopBenefitsBlock(entry.boundingClientRect.top > 0);
        setIsTopBenefitsVisible(entry.isIntersecting);
      }

      if (entry.target === bottomPricingRef) {
        setIsBottomBenefitsVisible(entry.isIntersecting);
      }
    });
  }

  function onPurchaseClick() {
    if ((isTopBenefitsVisible || isBottomBenefitsVisible) && !isApplePay) {
      return onPurchaseNewUpsell();
    }

    const scrollToTopBlock = () => {
      if (topPricingRef) {
        const yOffset = -200;
        const y =
          topPricingRef.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    const scrollToBottomBlock = () => {
      if (bottomPricingRef) {
        return bottomPricingRef.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };

    if (isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToTopBlock();
    }

    if (!isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToBottomBlock();
    }

    if (isApplePay) {
      return scrollToTopBlock();
    }
  }

  function onPurchaseNewUpsell() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });

      upsellOfferSingleMutation
        .mutateAsync({
          offerId: upsellOfferSingleQuery.data.id,
          paywallConfigId,
          pricingId: pricing.id,
        })
        .catch(cause => {
          throw new Error('ERROR_PURCHASE_NEW_UPSELL', { cause });
        })
        .finally(() => {
          setIsPurchased(true);
        });
    }
  }

  function onApplePaySuccess() {
    setTimeout(() => {
      goToNextPage(true);
    }, 500);
  }

  function onApplePayTryToPay() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: PAYMENT_METHODS.APPLE_PAY,
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });
    }
  }

  function onApplePayLoaderClick() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onApplePayLoaderClick({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        is_oneclick: isOneClick,
      });
    }
  }

  useEffect(() => {
    if (
      upsellOfferSingleQuery.data &&
      pricing &&
      !lastPaymentMethodQuery.isLoading &&
      isUpsellAvailable
    ) {
      UpsellAnalytics.pageView({
        place: 'signup_gigaupsell',
        offerId: upsellOfferSingleQuery.data.id,
        pricingId: pricing.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        exp_upsell_upsell_cascade_redesign: false,
        is_oneclick: isOneClick,
      });

      UpsellAnalytics.legacyPageView({
        price: pricing.discount_price,
        currency: currencyCode,
        isDiscountUpsell,
        isOneClick,
      });
    }
  }, [
    upsellOfferSingleQuery.data,
    pricing,
    lastPaymentMethodQuery.isLoading,
    isUpsellAvailable,
  ]);

  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      UpsellAnalytics.onAllUpsellSkip({
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });

      if (isIOS16OrLater() && isWebToAppScreenShow) {
        navigation.replace(getWebToAppUrl(webToAppExperiment));
      } else {
        const redirectUrl = fromQuery
          ? `/onboarding/v2?arrow=${fromQuery}`
          : filteredArrow
            ? `/onboarding/v2?arrow=${filteredArrow}`
            : '/onboarding/v2';
        navigation.replace(redirectUrl);
      }
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  useEffect(() => {
    if (isIOS16OrLater() && isWebToAppScreenShow) {
      analyticsService.setAmplitudeUserProperties({
        exp_web2a_v2: webToAppExperiment,
      });
    }
  }, [webToAppExperiment]);

  useEffect(() => {
    if (!userPersonalProfile.data || !userData) {
      return;
    }

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      registered_at: dayjs(userData?.registered_at).format('YYYY-MM-DDTHH:mm'),
      arrow: userPersonalProfile.data.personalization_criteria?.arrow?.[0],
    });
  }, [userPersonalProfile.data, userData]);

  if (lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  return (
    <>
      {upsellOfferSingleMutation.isLoading && <CustomSpinner />}

      <main className="upsell vh-full">
        <UiHeader
          className="upsell-header"
          events={{
            onSkip: () => onSkip(),
          }}
        />

        <div className="upsell-content-assistant upsell-assistant__content">
          {isDiscountUpsell && pricing && initialPricing && (
            <div className="upsell-assistant__discount-container">
              <div className="upsell-assistant__discount">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  fill="none"
                >
                  <rect width="56" height="56" fill="#5653FE" rx="28" />
                  <path
                    fill="#EEF"
                    fillRule="evenodd"
                    d="M17.938 16.125A5.687 5.687 0 0 1 28 12.49a5.687 5.687 0 0 1 8.858 7.135H38.5A4.376 4.376 0 0 1 42.875 24v2.187a1.313 1.313 0 0 1-1.313 1.313H29.838a.525.525 0 0 1-.526-.525V20.92A5.716 5.716 0 0 1 28 19.76a5.715 5.715 0 0 1-1.313 1.158v6.057a.525.525 0 0 1-.524.525H14.437a1.313 1.313 0 0 1-1.312-1.313V24a4.376 4.376 0 0 1 4.375-4.375h1.642a5.663 5.663 0 0 1-1.204-3.5Zm8.75 0a3.063 3.063 0 1 0-6.126 0 3.063 3.063 0 0 0 6.125 0Zm2.625 0a3.063 3.063 0 1 0 6.125 0 3.063 3.063 0 0 0-6.126 0Z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#EEF"
                    d="M26.688 30.387a.525.525 0 0 0-.525-.524h-9.277a1.459 1.459 0 0 0-1.435 1.21 20.283 20.283 0 0 0 0 6.853l.392 2.291a3.513 3.513 0 0 0 3.071 2.898l1.864.209c1.785.2 3.578.325 5.374.376a.52.52 0 0 0 .535-.524V30.387Zm3.16 13.313a.52.52 0 0 1-.535-.524V30.387a.525.525 0 0 1 .524-.524h9.277c.71 0 1.316.51 1.435 1.21.39 2.269.39 4.585 0 6.853l-.39 2.291a3.514 3.514 0 0 1-3.073 2.898l-1.864.209c-1.785.2-3.578.325-5.374.376Z"
                  />
                </svg>

                <div className="upsell-assistant__discount-content">
                  <h6 className="upsell-assistant__discount-subtitle">
                    <Trans
                      i18nKey={t('discount.subtitle', {
                        discount: initialPricing.discount,
                      })}
                      components={{
                        b: <b />,
                      }}
                    />
                  </h6>
                  <h5 className="upsell-assistant__discount-title">
                    <Trans
                      i18nKey={t('discount.title')}
                      components={{
                        b: <b />,
                      }}
                    />
                  </h5>
                </div>
              </div>
            </div>
          )}
          <div className="upsell__hero-block">
            <h1 className="upsell__title upsell-assistant__title">
              <Trans
                i18nKey={t('title')}
                components={{
                  span: <span />,
                }}
              />
            </h1>
            <h2 className="upsell-assistant__subtitle">{t('subtitle')}</h2>

            <div className="upsell-assistant__hero-image">
              <img
                src={t('requirements.image') as string}
                role="presentation"
                alt=""
              />
            </div>
          </div>

          <UpsellRequirementsBlock upsellType={translationPrefix} />

          <UpsellPotentialBlock upsellType={translationPrefix} />

          <div ref={setTopPricingRef} className="upsell-assistant__pricing">
            <h2 className="upsell-assistant__pricing-title">
              {t('pricing.title')}
            </h2>

            <div className="upsell-assistant__price">
              <div className="upsell-assistant__price-label">
                {t('pricing.first')}
              </div>
              <div className="upsell-assistant__price-content">
                <div className="upsell-assistant__price-amount">
                  {discountedPrice}
                </div>
                <div className="upsell-assistant__price-amount upsell-assistant__price-strike">
                  {fullPrice}
                </div>
              </div>
            </div>

            <div className="upsell-assistant__price">
              <div className="upsell-assistant__price-label">
                {t('pricing.second')}
              </div>
              <div className="upsell-assistant__price-content">
                <div className="upsell-assistant__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>

                <div className="upsell-assistant__price-amount upsell-assistant__price-strike">
                  {currencySignQuery.data ?? '$'}19.99
                </div>
              </div>
            </div>

            <UpsellBillingDescription
              hasVat={hasVat}
              discountedPrice={discountedPrice}
              upsellType={translationPrefix}
            />

            {upsellOfferSingleQuery.data && pricing && (
              <div className="upsell_purchase-button upsell-assistant_purchase-button">
                {isApplePay ? (
                  <ApplePayButton
                    offerId={upsellOfferSingleQuery.data.id}
                    pricingId={pricing.id}
                    paywallConfigId={paywallConfigId}
                    events={{
                      onTryToPay: onApplePayTryToPay,
                      onLoaderClick: onApplePayLoaderClick,
                      onSuccess: onApplePaySuccess,
                    }}
                  />
                ) : (
                  <UiPrimaryButton
                    className="upsell__add-button"
                    onClick={onPurchaseNewUpsell}
                  >
                    {t('purchase-button')}
                  </UiPrimaryButton>
                )}
              </div>
            )}
          </div>

          <UpsellFeaturesBlock upsellType={translationPrefix} />
          <UpsellReviews upsellType={translationPrefix} />
          <UpsellBenefitsBlock upsellType={translationPrefix} />

          <div ref={setBottomPricingRef} className="upsell-assistant__pricing">
            <h2 className="upsell__title upsell-assistant__title">
              {t('pricing.title')}
            </h2>

            <div className="upsell-assistant__price">
              <div className="upsell-assistant__price-label">
                {t('pricing.first')}
              </div>
              <div className="upsell-assistant__price-content">
                <div className="upsell-assistant__price-amount">
                  {discountedPrice}
                </div>
                <div className="upsell-assistant__price-amount upsell-assistant__price-strike">
                  {fullPrice}
                </div>
              </div>
            </div>

            <div className="upsell-assistant__price">
              <div className="upsell-assistant__price-label">
                {t('pricing.second')}
              </div>
              <div className="upsell-assistant__price-content">
                <div className="upsell-assistant__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>

                <div className="upsell-assistant__price-amount upsell-assistant__price-strike">
                  {currencySignQuery.data ?? '$'}19.99
                </div>
              </div>
            </div>

            <UpsellBillingDescription
              hasVat={hasVat}
              discountedPrice={discountedPrice}
              upsellType={translationPrefix}
            />
          </div>

          {!isTopBenefitsVisible && upsellOfferSingleQuery.data && pricing && (
            <div className="upsell_purchase-button upsell-assistant_purchase-bottom-button upsell-assistant__purchase-sticky-button">
              <UiPrimaryButton
                className="upsell__add-button"
                onClick={onPurchaseClick}
              >
                {t('purchase-button')}
              </UiPrimaryButton>
            </div>
          )}
        </div>
      </main>

      {/* <ChaseBottomsheet
        isVisible={isUpsellBottomSheetVisible}
        events={{
          onClose: onShowDiscountedUpsell,
        }}
        upsellType={isArrowAi ? 'business' : 'assistant'}
      />
      <SmokePurchaseBottomsheet
        isVisible={isPurchased}
        events={{
          onClose: onSmokePurchase,
        }}
      /> */}
    </>
  );
}

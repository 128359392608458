import { AiBotMessageType } from '../types';

import { AiImageMessage } from './AiImageMessage';
import { AiMessage } from './AiMessage';
import { CustomerMessage } from './CustomerMessage';
import { MessageLoading } from './MessageLoading';

const MessageType = ({
  message,
  onOpenImage,
  onCopyText,
}: {
  message: AiBotMessageType;
  onOpenImage?: (url: string) => void;
  onCopyText: (text: string) => void;
}) => (message.type && message.type.includes('image') ? (
  <AiImageMessage
    url={message.response}
    onOpenImage={onOpenImage}
  />
) : (
  <AiMessage
    text={message.response}
    onCopy={onCopyText}
  />
));

export const ChatMessage = ({
  message,
  onOpenImage,
  onCopyText,
}: {
  message: AiBotMessageType;
  onOpenImage?: (url: string) => void;
  onCopyText: (text: string) => void;
}) => (
  <div>
    <CustomerMessage
      key={`customer_message_${message.id}`}
      text={message.text}
    />
    {message.response ? (
      <MessageType
        key={`ai_message_${message.id}`}
        message={message}
        onOpenImage={onOpenImage}
        onCopyText={onCopyText}
      />
    ) : (
      <MessageLoading key={`message_loading_${message.id}`} />
    )}
  </div>
);

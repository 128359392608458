import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const HomePageAnalytics = {
  view({ goal }: { goal: string }) {
    analyticsService.sendEvent('webapp_home_view', {
      goal,
      language: i18next.language,
      feature_version: `v1`,
    });
  },
  onGuideClick({
    goal,
    place,
    guideId,
    rank,
    category,
    guideName,
  }: {
    goal: string;
    place: 'home_page' | 'view_all';
    guideId: string;
    rank?: number;
    guideName: string;
    category: string; //'recommended' | 'others' | 'pick_up' | GoalType
  }) {
    analyticsService.sendEvent('webapp_home_click', {
      goal,
      place,
      category,
      guide_id: guideId,
      rank,
      guide_name: guideName,
      language: i18next.language,
      feature_version: `v1`,
    });
  },
  onViewAllClick({
    category,
  }: {
    category: string; //'recommended' | 'others' | 'pick_up' | GoalType
  }) {
    analyticsService.sendEvent('webapp_home_click', {
      action: 'view_all',
      category,
      language: i18next.language,
      feature_version: `v1`,
    });
  },
};

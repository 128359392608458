import { GuideLessonType } from '../../../../guides/entities/guide';
import CoastCompletedIcon from './assets/coast-completed.svg';
import CoastLastCompletedIcon from './assets/coast-last-completed.svg';
import CoastLastLessonIcon from './assets/coast-last-lesson.svg';
import CoastPracticeIcon from './assets/coast-practice.svg';
import CoastStartIcon from './assets/coast-start.svg';
import CoastTheoryIcon from './assets/coast-theory.svg';

export default function CoastIcon({
  lessonType,
  status,
  isActive,
}: {
  lessonType: GuideLessonType['type'];
  status:
    | 'completed'
    | 'not_started'
    | 'closed'
    | 'lastCompleted'
    | 'lastLesson';
  isActive: boolean;
}) {
  let iconSrc;

  switch (status) {
    case 'lastCompleted':
      iconSrc = CoastLastCompletedIcon;
      break;
    case 'completed':
      iconSrc = CoastCompletedIcon;
      break;
    case 'lastLesson':
      iconSrc = CoastLastLessonIcon;
      break;
    default:
      if (lessonType === 'theory') {
        iconSrc = CoastTheoryIcon;
        break;
      }
      iconSrc = CoastPracticeIcon;
      break;
  }

  if (isActive) {
    iconSrc = CoastStartIcon;
  }

  return (
    <img
      src={iconSrc}
      style={{ pointerEvents: 'none' }}
      unselectable="on"
      alt=""
    />
  );
}

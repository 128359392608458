import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useMemo } from 'react';

import { PAYMENT_METHODS, useLastPaymentMethodQuery } from '../payment-method';

const isHttps = window.location.protocol === 'https:';
const isApplePayAvailableInSystem = Boolean(
  isHttps && window.ApplePaySession && window.ApplePaySession.canMakePayments()
);

export function useApplePay() {
  const isApplePayRequiredGrowthbookFeatureFlagOn = useFeatureIsOn(
    'is_apple_pay_enabled'
  );
  const lastPaymentMethodQuery = useLastPaymentMethodQuery();

  const isLastPaymentMethodApplePay = useMemo(
    () =>
      isApplePayRequiredGrowthbookFeatureFlagOn &&
      Boolean(
        lastPaymentMethodQuery.data &&
          lastPaymentMethodQuery.data.payment_type === PAYMENT_METHODS.APPLE_PAY
      ),
    [lastPaymentMethodQuery, isApplePayRequiredGrowthbookFeatureFlagOn]
  );

  const isApplePay = useMemo(
    () => isLastPaymentMethodApplePay && isApplePayAvailableInSystem,
    [isLastPaymentMethodApplePay]
  );

  const isOneClick = !isApplePay;

  return {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  };
}

import { useRecoilValue } from 'recoil';

import { userDataQuery } from '@/context/atoms/User';

import { useWhatsNewQuery } from '../../model';

export function useWhatsNew(value: string) {
  const userData = useRecoilValue(userDataQuery);

  const { whatsNewQuery, whatsNewMutation } = useWhatsNewQuery(value, userData?.id);

  const isWhatsNewVisible = !whatsNewQuery.isLoading && !whatsNewQuery.data;

  function setWhatsNewHidden() {
    if (isWhatsNewVisible) {
      whatsNewMutation.mutate();
    }
  }

  return {
    isWhatsNewVisible,
    setWhatsNewHidden,
  };
}

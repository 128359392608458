import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { UiPrimaryButton } from '../../../../shared/ui';
import { GuideLessonDetailsTaskType } from '../../../entities/guide-lesson';
import { useGuideLessonTaskStore } from '../../../entities/guide-lesson-task';
import './guide-lesson-task.css';

function TaskCompleted() {
  const { t } = useTranslation();

  return (
    <div className="task-completed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
      >
        <path
          stroke="#19AA32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m11.667 4-6.416 6.417L2.334 7.5"
        />
      </svg>
      <span>{t('guides.lesson-task.completed')}</span>
    </div>
  );
}

function TaskSkipped() {
  const { t } = useTranslation();

  return (
    <div className="task-skipped">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
      >
        <path
          fill="#6B7280"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M5.42 11.413a.583.583 0 0 1 0-.825L8.509 7.5 5.421 4.413a.583.583 0 0 1 .825-.825l3.5 3.5a.583.583 0 0 1 0 .825l-3.5 3.5a.583.583 0 0 1-.825 0Z"
        />
      </svg>
      <span>{t('guides.lesson-task.skipped')}</span>
    </div>
  );
}

export function GuideLessonTask({
  task,
  onOpenTask,
}: {
  task: GuideLessonDetailsTaskType;
  onOpenTask: (task: GuideLessonDetailsTaskType) => void;
}) {
  const { t } = useTranslation();
  const [actionContainer] = useAutoAnimate();
  const { completedTasksIds, skippedTasksIds } = useGuideLessonTaskStore(
    useShallow(state => ({
      completedTasksIds: state.completedTasksIds,
      skippedTasksIds: state.skippedTasksIds,
    }))
  );

  const isTaskCompleted = useMemo(
    () => completedTasksIds.includes(task.id),
    [completedTasksIds]
  );

  const isTaskSkipped = useMemo(
    () => skippedTasksIds.includes(task.id),
    [skippedTasksIds]
  );

  return (
    <div className="guide-lesson-task">
      <h1 className="guide-lesson-task__title">
        {t('guides.lesson-task.title')}
      </h1>
      {task.content.map(item => (
        <p key={item.id} className="guide-lesson-task__text">
          {item.description}
        </p>
      ))}

      <div ref={actionContainer} className="guide-lesson-task__actions">
        {isTaskCompleted && <TaskCompleted />}
        {isTaskSkipped && <TaskSkipped />}
        {!isTaskSkipped && !isTaskCompleted && (
          <UiPrimaryButton onClick={() => onOpenTask(task)}>
            {t('guides.open-playground-button')}
          </UiPrimaryButton>
        )}
      </div>
    </div>
  );
}

import classNames from 'classnames';

import './checkmark-progress.scss';

export function CheckMarkProgress({ progress }: { progress: number }) {
  const isCompleted = progress === 100;
  return (
    <div className="flex items-center mt-4">
      <div className="checkmark-progress">
        <div
          className={classNames('checkmark-progress-line', {
            'checkmark-progress-line__completed': isCompleted,
          })}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div
        className={
          '-ml-[7px] flex flex-col justify-center items-center border-white border-solid bg-[#e5e7eb] border-[3px] h-[30px] rounded-[32px] w-[30px]'
        }
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className={classNames('checkmark-progress-line__icon', {
            'checkmark-progress-line__icon--active': isCompleted,
          })}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="mingcute:certificate-fill">
            <g id="Group">
              <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.05731 1.39998C7.29331 1.1643 7.60906 1.02562 7.94228 1.0113C8.27549 0.996985 8.60198 1.10807 8.85731 1.32265L8.94265 1.40065L10.2093 2.66665H12C12.3362 2.66671 12.6601 2.79383 12.9066 3.02254C13.1531 3.25125 13.3041 3.56466 13.3293 3.89998L13.3333 3.99998V5.79065L14.6 7.05731C14.8358 7.29334 14.9746 7.60923 14.989 7.9426C15.0033 8.27598 14.8921 8.6026 14.6773 8.85798L14.5993 8.94265L13.3326 10.2093V12C13.3328 12.3364 13.2057 12.6604 12.977 12.907C12.7483 13.1537 12.4348 13.3048 12.0993 13.33L12 13.3333H10.21L8.94331 14.6C8.70729 14.8359 8.3914 14.9746 8.05803 14.989C7.72465 15.0033 7.39803 14.8921 7.14265 14.6773L7.05798 14.6L5.79131 13.3333H3.99998C3.6636 13.3334 3.3396 13.2064 3.09295 12.9776C2.84629 12.7489 2.69521 12.4354 2.66998 12.1L2.66665 12V10.2093L1.39998 8.94265C1.16411 8.70662 1.02531 8.39073 1.01099 8.05736C0.996677 7.72399 1.10788 7.39736 1.32265 7.14198L1.39998 7.05731L2.66665 5.79065V3.99998C2.66671 3.66371 2.79383 3.33988 3.02254 3.09337C3.25125 2.84686 3.56466 2.69587 3.89998 2.67065L3.99998 2.66665H5.79065L7.05731 1.39998ZM10.0526 5.98865L7.22331 8.81798L6.04465 7.63931C5.91955 7.51431 5.74993 7.44412 5.57308 7.44418C5.39623 7.44424 5.22665 7.51455 5.10165 7.63965C4.97664 7.76474 4.90645 7.93437 4.90651 8.11122C4.90657 8.28806 4.97689 8.45764 5.10198 8.58265L6.70465 10.1853C6.77275 10.2534 6.85361 10.3075 6.9426 10.3444C7.03159 10.3812 7.12698 10.4002 7.22331 10.4002C7.31965 10.4002 7.41503 10.3812 7.50403 10.3444C7.59302 10.3075 7.67388 10.2534 7.74198 10.1853L10.9953 6.93131C11.1168 6.80558 11.1839 6.63718 11.1824 6.46238C11.1809 6.28758 11.1108 6.12037 10.9872 5.99677C10.8636 5.87316 10.6964 5.80305 10.5216 5.80153C10.3468 5.80001 10.1784 5.86721 10.0526 5.98865Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

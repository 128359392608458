import { useFeatureValue } from '@growthbook/growthbook-react';
import { flatMap, keys, map, maxBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { usePrefetchTrustpilotLinkQuery } from '../../../../../entities/trustpilot';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton, UiNavigation } from '../../../../ai-bots/shared/ui';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import { AppFooter } from '../../../../shared';
import {
  GuideDetailsType,
  GuideLessonType,
  GuideUnitType,
} from '../../../entities/guide';
import { GuideDuration } from '../../../entities/guide-duration';
import {
  GuideProgressByIdType,
  useGuideByIdProgressQuery,
} from '../../../entities/guide-progress';
import { GuideUnit } from '../../../features/unit';
import { GuidesAnalytics } from '../../../shared/analytics';
import { calculateNextStep } from '../../../shared/lib';
import { useGuideByIdQuery } from '../model';
import './guide-by-id-page.css';

function getUnitProgress(
  progress: GuideProgressByIdType,
  id: GuideUnitType['id']
) {
  return progress.units[id];
}

function getLastActiveLesson(progress?: GuideProgressByIdType) {
  if (!progress) {
    return undefined;
  }

  const units = map(keys(progress.units), id => ({
    ...progress.units[id],
    unitId: id,
  }));
  const lessons = flatMap(units, u =>
    map(keys(u.lessons), id => ({
      ...u.lessons[id],
      lessonId: id,
      unitId: u.unitId,
    }))
  );

  return maxBy(lessons, v => v.last_update);
}

function getNextLesson(
  guideByIdQuery: GuideDetailsType,
  progress?: GuideProgressByIdType
) {
  const lastActiveLesson = getLastActiveLesson(progress);

  if (lastActiveLesson && lastActiveLesson.status === 'completed') {
    const nextStep = calculateNextStep({
      guide: guideByIdQuery,
      unitId: lastActiveLesson.unitId,
      lessonId: lastActiveLesson.lessonId,
    });

    return nextStep.type === 'guide-end' ? null : nextStep;
  }

  return null;
}

export function GuideByIdPage() {
  const navigation = useHistory();
  const { guideId } = useParams<{ guideId: string }>();
  const location = useLocation<{ from?: string; category?: string }>();

  const { prefetchTrustpilotLink } = usePrefetchTrustpilotLinkQuery();

  useEffect(() => {
    prefetchTrustpilotLink();
  }, []);

  const guideByIdQuery = useGuideByIdQuery({ id: guideId });
  const guideProgressByIdQuery = useGuideByIdProgressQuery({ id: guideId });
  const currentGuideProgressPercent = useMemo(
    () =>
      guideProgressByIdQuery.data && guideProgressByIdQuery.data.progress > 0
        ? guideProgressByIdQuery.data.progress * 100
        : 0,
    [guideProgressByIdQuery.data]
  );

  const growthbookAbHomePageFeatureValue = useFeatureValue(
    'ab_home_page',
    'default'
  );

  useEffect(() => {
    if (guideByIdQuery.data && guideProgressByIdQuery.data) {
      GuidesAnalytics.guidePageView({
        guideId,
        progress: guideProgressByIdQuery.data.progress,
        ab_home_page: growthbookAbHomePageFeatureValue,
      });
    }
  }, [guideByIdQuery.data, guideProgressByIdQuery.data]);

  const lastActiveLesson = useMemo(
    () => getLastActiveLesson(guideProgressByIdQuery.data),
    [guideProgressByIdQuery.data]
  );

  const nextLesson = useMemo(
    () =>
      guideByIdQuery.data
        ? getNextLesson(guideByIdQuery.data, guideProgressByIdQuery.data)
        : null,
    [guideByIdQuery.data, guideProgressByIdQuery.data]
  );

  const initialVisibleUnit = useMemo(() => {
    if (nextLesson) {
      return nextLesson.unitId;
    }

    if (lastActiveLesson) {
      return lastActiveLesson.unitId;
    }

    return null;
  }, [nextLesson, lastActiveLesson]);

  function onGoToLesson({
    unit,
    lesson,
  }: {
    unit: GuideUnitType;
    lesson: GuideLessonType;
  }) {
    navigation.push(`/guides/${guideId}/${unit.id}/${lesson.id}`);
  }

  function onBackButtonClicked() {
    const category = location.state?.category;
    const isCameFromViewAll = location.state?.from?.includes('view_all');

    if (isCameFromViewAll && category) {
      navigation.replace(`/guides/category/${category}`);
    } else {
      navigation.replace('/');
    }
  }

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={
          guideByIdQuery.isLoading ? (
            <UiLoadingSkeleton
              style={{
                height: '24px',
                width: '150px',
              }}
            />
          ) : (
            guideByIdQuery.data?.name ?? ''
          )
        }
        onBackButtonClicked={onBackButtonClicked}
      />

      <section className="ui-base-layout__content guide-by-id-page">
        {guideByIdQuery.isError ? (
          <FullPageError onRetry={() => guideByIdQuery.refetch()} />
        ) : (
          <>
            {guideByIdQuery.data && guideProgressByIdQuery.data && (
              <>
                <div className="guide-by-id-page__header">
                  <img
                    width="104"
                    height="104"
                    src={guideByIdQuery.data.image}
                    role="presentation"
                    alt=""
                  />

                  <GuideDuration
                    className="guide-by-id-page__duration"
                    unitsCount={guideByIdQuery.data.units_count}
                    duration={guideByIdQuery.data.duration}
                  />

                  <h1 className="guide-by-id-page__title">
                    {guideByIdQuery.data.name}
                  </h1>
                  <h2 className="guide-by-id-page__description">
                    {guideByIdQuery.data.description}
                  </h2>

                  <div className="guide-by-id-page__progress-container">
                    <div className="guide-by-id-page__progress">
                      <div
                        className="guide-by-id-page__progress-line"
                        style={{ width: `${currentGuideProgressPercent}%` }}
                      ></div>
                    </div>
                    <span>{currentGuideProgressPercent}%</span>
                  </div>
                </div>

                <div className="guide-by-id-page__units">
                  {guideByIdQuery.data.content.map((unit, i) => (
                    <GuideUnit
                      key={unit.id}
                      guideId={guideId}
                      nextLesson={nextLesson}
                      isInitialVisible={
                        initialVisibleUnit
                          ? initialVisibleUnit === unit.id
                          : i === 0
                      }
                      unit={unit}
                      progress={getUnitProgress(
                        guideProgressByIdQuery.data,
                        unit.id
                      )}
                      events={{
                        onGoToLesson: lesson => onGoToLesson({ unit, lesson }),
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </section>

      <AppFooter />
    </main>
  );
}

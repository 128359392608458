export function getWebToAppUrl(expreiment: string) {
  if (expreiment === 'test') {
    return '/onboarding/v2';
  }
  return '/web-to-app';
}

export function getAndroidWebToAppUrl(expreiment: string) {
  if (expreiment === 'test') {
    return '/web-to-app';
  }
  return '/onboarding/v2';
}

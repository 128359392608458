import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GuideLessonType,
  GuideUnitType,
} from '../../../../guides/entities/guide';
import { GuideProgressByIdType } from '../../../../guides/entities/guide-progress';
import { GuideCoast } from '../../../entites/guide-coast';
import { LessonTooltip } from '../../../shared/ui/LessonTooltip';
import StartTooltip from '../../../shared/ui/LessonTooltip/ui/StartTooltip';

interface PathwayCoastsProps {
  unit: GuideUnitType;
  progress: GuideProgressByIdType;
  selectedLessonId: string;
  tooltipValue?: GuideLessonType;
  unitRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  titleRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  activeUnitRef: React.RefObject<HTMLDivElement>;
  unitIndex: number;
  onLessonClick: (unit: GuideUnitType, lesson: GuideLessonType) => void;
  onTooltipClose: () => void;
  onGoToLesson: (params: {
    unit: GuideUnitType;
    lesson: GuideLessonType;
  }) => void;
  getUnitProgress: (
    progress: GuideProgressByIdType,
    id: GuideUnitType['id']
  ) => any;
  getNextUnit: (unit: GuideUnitType) => GuideUnitType;
}

export function PathwayCoasts({
  unit,
  progress,
  selectedLessonId,
  tooltipValue,
  unitRefs,
  titleRefs,
  activeUnitRef,
  unitIndex,
  onLessonClick,
  onTooltipClose,
  onGoToLesson,
  getUnitProgress,
  getNextUnit,
}: PathwayCoastsProps) {
  const { t } = useTranslation();
  const lessons = unit.content;

  return (
    <div className="learning-pathway-content-overlay">
      {lessons.map((lesson, j) => (
        <div
          className="learning-pathway-content-overlay__item"
          key={lesson.id}
          data-unit-id={unit.id}
        >
          <div className="learning-pathway-content__item__coast-container">
            <div
              className="learning-pathway-content__item__coast-container__item"
              data-unit-id={unit.id}
              ref={
                lesson.ordering === unit.content.length
                  ? el => (unitRefs.current[unitIndex] = el)
                  : null
              }
            >
              <LessonTooltip
                isLeftAligned={j % 2 === 0}
                tooltipValue={lesson}
                onClose={onTooltipClose}
                onStartLesson={() => onGoToLesson({ unit, lesson })}
              >
                <div onClick={() => onLessonClick(unit, lesson)}>
                  <StartTooltip
                    open={selectedLessonId === lesson.id && !tooltipValue}
                    title={<h2>{t('guides-v2.start')}</h2>}
                    PopperProps={{
                      disablePortal: true,
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -50],
                          },
                        },
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['top'],
                          },
                        },
                      ],
                    }}
                    arrow
                    placement="top"
                  >
                    <div
                      ref={
                        selectedLessonId === lesson.id ? activeUnitRef : null
                      }
                    >
                      <GuideCoast
                        className={classNames(
                          'learning-pathway-content__item__coast',
                          tooltipValue?.id === lesson.id &&
                            'learning-pathway-content__item__coast__active',
                          `learning-pathway-content__item__coast__${lesson.id}`
                        )}
                        lesson={lesson}
                        progress={getUnitProgress(progress, unit.id)}
                        isActive={selectedLessonId === lesson.id}
                        onClick={() => {}}
                      />
                    </div>
                  </StartTooltip>
                </div>
              </LessonTooltip>
            </div>

            <h2
              data-unit-id={getNextUnit(unit).id}
              ref={
                lesson.ordering === unit.content.length
                  ? el => (titleRefs.current[unitIndex] = el)
                  : null
              }
              className="learning-pathway__coast__title"
            >
              {lesson.name}
            </h2>
          </div>
        </div>
      ))}
    </div>
  );
}

import Bugsnag from '@bugsnag/js';
import { flatMap, keys, map, maxBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { usePrefetchTrustpilotLinkQuery } from '../../../../../entities/trustpilot';
import { analyticsService } from '../../../../../services/analytics.service';
import useAAExperiment from '../../../../../shared/lib/hooks/use-aa-test-experiment';
import useCertificatesExperiment from '../../../../../shared/lib/hooks/use-certificates-experiment';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import {
  GuideDetailsType,
  GuideUnitType,
} from '../../../../guides/entities/guide';
import {
  GuideProgressByIdType,
  useGuideByIdProgressQuery,
} from '../../../../guides/entities/guide-progress';
import { useGuidesListQuery } from '../../../../guides/entities/guides-list';
import { useGuidesListProgressQuery } from '../../../../guides/entities/guides-list-progress';
import { useGuideByIdQuery } from '../../../../guides/pages/guide-by-id-page/model';
import { calculateNextStep } from '../../../../guides/shared/lib';
import { useCertificateQuery } from '../../../entites/guide-certificate';
import { GuideCertificateCard } from '../../../features/guide-certificate-card/ui/GuideCertificateCard';
import { NextGuide } from '../../../features/next-guide/ui/NextGuide';
import { PathwayUnits } from '../../../features/pathway-units';
import { GuidesV2Analytics } from '../../../shared/analytics';
import { filterGuides } from '../../../shared/lib';
import { PathwayNavigation } from '../../../shared/ui/navigation';
import './learning-pathway.css';

function getLastActiveLesson(progress?: GuideProgressByIdType) {
  if (!progress) {
    return undefined;
  }

  const units = map(keys(progress.units), id => ({
    ...progress.units[id],
    unitId: id,
  }));
  const lessons = flatMap(units, u =>
    map(keys(u.lessons), id => ({
      ...u.lessons[id],
      lessonId: id,
      unitId: u.unitId,
    }))
  );

  return maxBy(lessons, v => v.last_update);
}

function getNextLesson(
  guideByIdQuery: GuideDetailsType,
  progress?: GuideProgressByIdType
) {
  const lastActiveLesson = getLastActiveLesson(progress);

  if (lastActiveLesson && lastActiveLesson.status === 'completed') {
    const nextStep = calculateNextStep({
      guide: guideByIdQuery,
      unitId: lastActiveLesson.unitId,
      lessonId: lastActiveLesson.lessonId,
    });

    return nextStep.type === 'guide-end' ? null : nextStep;
  }

  return null;
}

export interface Toast {
  guideName: string;
  unit: GuideUnitType;
}

export const LearningPathway = () => {
  const navigation = useHistory();
  const { guideId } = useParams<{ guideId: string }>();
  const location = useLocation<{ from?: string; category?: string }>();

  const { value: aaTestValue } = useAAExperiment();

  const [toastValue, setToastValue] = useState<Toast>();
  const toastRef = useRef<HTMLDivElement>(null);

  const { isTest: isCertificateEnabled } = useCertificatesExperiment();
  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const { prefetchTrustpilotLink } = usePrefetchTrustpilotLinkQuery();

  useEffect(() => {
    prefetchTrustpilotLink().catch(() =>
      Bugsnag.notify(new Error('ERROR_NO_TRUSTPILOT_LINK'))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const guideByIdQuery = useGuideByIdQuery({ id: guideId });
  const guidesListQuery = useGuidesListQuery({});
  const guideProgressByIdQuery = useGuideByIdProgressQuery({ id: guideId });
  const guidesListProgressQuery = useGuidesListProgressQuery();
  const guidesCertificateQuery = useCertificateQuery({ id: guideId });

  const filteredNextGuides = useMemo(
    () =>
      filterGuides(
        guidesListQuery.data,
        guidesListProgressQuery.data,
        userArrow
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [guidesListQuery.data, guidesListProgressQuery.data]
  );

  const nextLesson = useMemo(
    () =>
      guideByIdQuery.data
        ? getNextLesson(guideByIdQuery.data, guideProgressByIdQuery.data)
        : null,
    [guideByIdQuery.data, guideProgressByIdQuery.data]
  );

  const onBackButtonClicked = () => {
    const category = location.state?.category;
    const isCameFromViewAll = location.state?.from?.includes('view_all');

    if (isCameFromViewAll && category) {
      navigation.replace(`/guides/category/${category}`);
    } else {
      navigation.replace('/');
    }
  };

  const onRetryClick = () => {
    guideByIdQuery.refetch().catch(() => {});
  };

  useEffect(() => {
    if (guideByIdQuery.data && guideProgressByIdQuery.data) {
      setToastValue({
        guideName: guideByIdQuery.data.name,
        unit: guideByIdQuery.data.content[0],
      });
    }
  }, [guideByIdQuery.data, guideProgressByIdQuery.data]);

  useEffect(() => {
    analyticsService.setAmplitudeUserProperties({
      exp_aa_demo: aaTestValue,
    });
  }, [aaTestValue]);

  useEffect(() => {
    const unitId = nextLesson?.unitId || guideByIdQuery.data?.content[0].id;

    if (guideByIdQuery.data) {
      GuidesV2Analytics.pathwayPageView({
        guide_id: guideId,
        unit_id: unitId || '',
        aaTestValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guideByIdQuery.data, nextLesson]);

  return (
    <main className="ui-base-layout min-vh-full">
      <PathwayNavigation
        title={
          guideByIdQuery.isLoading ? (
            <UiLoadingSkeleton
              style={{
                height: '24px',
                width: '150px',
              }}
            />
          ) : (
            guideByIdQuery.data?.name ?? ''
          )
        }
        toastValue={toastValue}
        toastRef={toastRef}
        onBackButtonClicked={onBackButtonClicked}
        guides={filteredNextGuides}
        progress={guidesListProgressQuery.data}
        selectedGuide={guideByIdQuery.data}
      />

      <section className="ui-pathway-layout learning-pathway">
        {guideByIdQuery.isError ? (
          <FullPageError onRetry={() => onRetryClick()} />
        ) : (
          guideByIdQuery.data &&
          guideProgressByIdQuery.data &&
          guidesCertificateQuery.data && (
            <>
              <PathwayUnits
                toastRef={toastRef}
                setToastValue={setToastValue}
                guide={guideByIdQuery.data}
                progress={guideProgressByIdQuery.data}
                nextLesson={nextLesson}
              />
              {isCertificateEnabled && (
                <GuideCertificateCard
                  progress={guideProgressByIdQuery.data}
                  guideCertificate={guidesCertificateQuery.data}
                  guide={guideByIdQuery.data}
                />
              )}
              <NextGuide
                selectedGuide={guideByIdQuery.data}
                guides={filteredNextGuides}
              />
            </>
          )
        )}
      </section>
    </main>
  );
};

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AiBotTool } from '../../../entities/ai-bot/types';
import { useAiBotsCategoriesQuery } from '../../../feature/ai-bots-categories';
import { MainPageAnalytics } from '../../../shared/analytics';
import { AiBotsListContainer } from './AiBotsListContainer';
import './assets/ai-bots-list-v2.css';
import CheveronRightIcon from './assets/arrow-right.svg';

export function AiBotsList({
  withSearchForm,
  onCategoryButtonClicked,
  onClickToBot,
}: {
  withSearchForm?: boolean;
  onCategoryButtonClicked?: (id: string) => void;
  onClickToBot: (bot: AiBotTool) => void;
}) {
  const { t } = useTranslation();
  const { data } = useAiBotsCategoriesQuery();

  const navigation = useHistory();

  function onViewAllClick() {
    MainPageAnalytics.onViewAllClick();
    navigation.replace('/ai-bots/view-all');
  }

  if (!data) {
    return <></>;
  }

  return (
    <section className="ai-bots-list-v2">
      <div className="flex justify-between">
        <h1 className="ai-bots-list-v2__title">{t('ai-tools.list-title')}</h1>
        <div
          onClick={onViewAllClick}
          className="flex items-center pr-4 cursor-pointer"
        >
          <p className="text-[14px] leading-[22px] text-[#565B66]">
            {t('ai-tools.view-all')}
          </p>
          <img
            className=""
            width="16px"
            height="16px"
            src={CheveronRightIcon}
            alt="chevron right"
          />
        </div>
      </div>
      <AiBotsListContainer
        isCardsLimited
        data={data}
        withSearchForm={withSearchForm}
        onCategoryButtonClicked={onCategoryButtonClicked}
        onClickToBot={onClickToBot}
      />
    </section>
  );
}

export function DetailsButton({ onClick }: { onClick: () => void }) {
  return (
    <button type="button" onClick={() => onClick()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="#565B66"
          d="M16 8a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM6 16c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10S6 21.523 6 16Z"
        />
        <path
          fill="#565B66"
          d="M16 18a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1ZM14.5 20.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
        />
        <path
          fill="#565B66"
          d="M16.39 11.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 1 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.855.04 1.733.347 2.409.979C19.587 11.44 20 12.368 20 13.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443Z"
        />
      </svg>
    </button>
  );
}

import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';

export const GuidesCertificateCompleteAnalytics = {
  onView({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_guides_view', {
      place: 'guide_complete',
      guide_id: guideId,
      language: i18next.language,
    });
  },
  onRatingSubmit({ guideId, rating }: { guideId: string; rating: number }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'guide_complete',
      action: 'rate',
      rating: rating,
      guide_id: guideId,
      language: i18next.language,
    });
  },
  onCloseClick({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'guide_complete',
      action: 'close',
      guide_id: guideId,
      language: i18next,
    });
  },
  onContinue({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_guides_click', {
      place: 'guide_complete',
      action: 'continue',
      guide_id: guideId,
    });
  },
};

import classNames from 'classnames';
import type { TFunction } from 'i18next';

import './ui-primary-button.css';

export function UiPrimaryButton({
  withPulseAnimation,
  children,
  disabled,
  onClick,
  className,
}: {
  withPulseAnimation?: boolean;
  disabled?: boolean;
  children: string | ReturnType<TFunction> | JSX.Element | JSX.Element[];
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(
        'ui-primary-button',
        {
          'ui-primary-button--pulse-animation': withPulseAnimation,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

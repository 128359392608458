import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.scss';

export const AiImageMessage = ({
  url,
  onOpenImage,
}: {
  url: string | null;
  onOpenImage?: (url: string) => void;
}) => (
  <div className="ai-bot-message-v2 ai-bot-message_ai-message bg-white">
    <img
      width="24"
      height="24"
      src={AiAvatarIcon}
      role="presentation"
      alt="Avatar logo"
    />
    <div className="ai-bot-message__image-container">
      {url && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <img
          className="ai-bot-message__image"
          src={url}
          alt="Ai bot response"
          onClick={() => onOpenImage?.(url)}
        />
      )}
    </div>
  </div>
);

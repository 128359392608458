const Units = [
  'day',
  'week',
  'month',
  'quarter',
  'year',
  'hour',
  'minute',
  'second',
  'millisecond',
] as const;

export function checkDelayTime(value: string) {
  const regexString = `^\\d+_(?:${Units.join('|')})$`;
  const regex = new RegExp(regexString);
  return regex.test(value);
}

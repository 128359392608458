import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { GuideLessonDetailsFillTheGapType } from '../../guide-lesson/types';

type FillTheGapId = GuideLessonDetailsFillTheGapType['id'];

interface GuideLessonFillTheGapStoreStateType {
  skippedFillTheGapIds: Array<FillTheGapId>;
  completedFillTheGapIds: Array<FillTheGapId>;
  currentOpenFillTheGap: Array<FillTheGapId>;
  addSkippedFillTheGap: (id: FillTheGapId) => void;
  addCompletedFillTheGap: (id: FillTheGapId) => void;
  clear: () => void;
}

export const useGuideLessonFillTheGapStore =
  create<GuideLessonFillTheGapStoreStateType>()(
    immer(set => ({
      skippedFillTheGapIds: [],
      completedFillTheGapIds: [],
      currentOpenFillTheGap: [],
      addSkippedFillTheGap: (id: FillTheGapId) =>
        set(state => {
          state.skippedFillTheGapIds.push(id);
        }),
      addCompletedFillTheGap: (id: FillTheGapId) =>
        set(state => {
          state.completedFillTheGapIds.push(id);
        }),
      clear: () =>
        set(state => {
          state.skippedFillTheGapIds = [];
          state.completedFillTheGapIds = [];
        }),
    }))
  );

import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import warning_icon from '../../../../images/warning_icon.svg';
import { PrivacyCheckboxContainer } from './PrivacyCheckbox.styles';

interface IProps {
  isChecked: boolean;
  toggleCheck: () => void;
  isShowError: boolean;
}

const PrivacyCheckbox: FC<IProps> = ({
  isChecked,
  toggleCheck,
  isShowError,
}) => {
  const { t } = useTranslation();

  return (
    <PrivacyCheckboxContainer isShowError={isShowError}>
      <div className="row" onClick={toggleCheck}>
        <input type="checkbox" checked={isChecked} className="checkbox" />
        <p className="disclaimer">
          <Trans
            i18nKey="I agree with Terms, Privacy Policy, Subscription Terms"
            defaults={
              'I agree with <termsLink>Terms</termsLink>, <privacyLink>Privacy Policy</privacyLink>, <subscriptionyLink>Subscription Terms</subscriptionyLink>'
            }
            components={{
              termsLink: (
                <a
                  href="https://legal.coursiv.io/terms"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              privacyLink: (
                <a
                  href="https://legal.coursiv.io/privacy"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              subscriptionyLink: (
                <a
                  href="https://legal.coursiv.io/subscription"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
      </div>
      <div className={`row ${!isShowError ? 'hidden' : ''}`}>
        <img src={warning_icon} alt="" width={16} height={16} />
        <p className="disclaimer error-message">
          {t('Please agree with the Terms to create account')}
        </p>
      </div>
    </PrivacyCheckboxContainer>
  );
};

export default PrivacyCheckbox;

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingChunk } from '../../../../../entities/onboarding-content/types';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import {
  GuideLessonDetailsChunkType,
  GuideLessonDetailsQuizType,
} from '../../../../guides/entities/guide-lesson';
import { useGuideLessonQuizStore } from '../../../../guides/entities/guide-lesson-quiz';
import { GuideLessonChunk } from '../../../../guides/features/guide-lesson-chunk';
import { GuideLessonQuiz } from '../../../../guides/features/guide-lesson-quiz';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingV2Analytics } from '../../analytics';
import { useLottieAiExperiment } from '../../hooks/use-lottie-ai-experiment';
import { Chunk, ContentBlock, QuizBlock } from '../lib/types';
import { smoothScrollToBottom, smoothScrollToTop } from '../lib/utils';
import './assets/lesson-content-layout.css';

export function LessonContentLayout({
  onNext,
  setProgress,
  coveredProgress,
  isFinish,
  lastVisibleChunkIndex,
  setLastVisibleIndex,
  chunks,
  arrow,
}: {
  onNext: () => void;
  setProgress: Dispatch<SetStateAction<number>>;
  coveredProgress: number;
  isFinish?: boolean;
  lastVisibleChunkIndex: number;
  setLastVisibleIndex: Dispatch<SetStateAction<number>>;
  chunks: Array<OnboardingChunk>;
  arrow: string | undefined;
}) {
  const { t } = useTranslation();
  const [contentRef] = useAutoAnimate();

  const gb = useGrowthBook();
  const isGbReady = gb?.ready;

  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const [isActionsHidden, setIsActionsHidden] = useState(false);

  const CHUNK_WEIGHT = 1 / chunks.length;
  function onContinue() {
    setProgress(prev => prev + CHUNK_WEIGHT * coveredProgress);
    if (lastVisibleChunkIndex === chunks.length - 1) {
      onNext();
      return;
    }
    if (chunks[lastVisibleChunkIndex + 1].content[0].type === 'quiz') {
      setIsActionsHidden(true);
    }
    setLastVisibleIndex(prev => prev + 1);
  }
  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const lottieOnboardingExperiment = useLottieAiExperiment();
  const isOnboardingAnimated = lottieOnboardingExperiment === 'test';

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    setIsActionButtonDisabled(true);
    if (lastVisibleChunkIndex === 0) {
      smoothScrollToTop();
    } else {
      smoothScrollToBottom();
    }
    timeout = setTimeout(() => {
      setIsActionButtonDisabled(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [lastVisibleChunkIndex]);

  useEffect(() => {
    if (!isGbReady) {
      return;
    }

    OnboardingV2Analytics.onChunkView({
      chunk_type:
        chunks[lastVisibleChunkIndex].content[0].type === 'quiz'
          ? 'quiz'
          : 'info',
      chunk_index: lastVisibleChunkIndex,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }, [lastVisibleChunkIndex]);

  const { clear } = useGuideLessonQuizStore();

  useEffect(() => {
    clear();
    return () => {
      clear();
    };
  }, []);

  return (
    <div className="lesson-content-layout">
      <div className="lesson-content-layout__content" ref={contentRef}>
        {chunks
          .filter((_, index) => index <= lastVisibleChunkIndex)
          .map((chunk, index) => (
            <div
              key={chunk.id}
              data-chunk-id={index}
              className="lesson-content-layout__chunk"
            >
              {chunk.type === 'quiz' ? (
                <GuideLessonQuiz
                  type={'practice'}
                  screenId={chunk.id}
                  quiz={chunk as GuideLessonDetailsQuizType}
                  events={{
                    onQuizSubmit: ({ isCorrect, selectedAnswer }) => {
                      setIsActionsHidden(false);
                      OnboardingV2Analytics.onQuizAnswer({
                        answer: selectedAnswer.text as string,
                        chunk_index: index,
                        is_correct: isCorrect,
                        pathway_test: aiPathwayExperiment,
                        lottie_test: lottieOnboardingExperiment,
                      });
                    },
                  }}
                />
              ) : (
                <div className="lesson-content-layout__info">
                  <GuideLessonChunk
                    chunk={chunk as GuideLessonDetailsChunkType}
                    isImageAnimated={isOnboardingAnimated}
                  />
                </div>
              )}
            </div>
          ))}
      </div>

      {!isActionsHidden ? (
        <div className="guide-lesson-action">
          <UiPrimaryButton
            disabled={isActionButtonDisabled}
            onClick={onContinue}
          >
            {lastVisibleChunkIndex === chunks.length - 1 && isFinish
              ? t('Finish')
              : t('Continue')}
          </UiPrimaryButton>
        </div>
      ) : (
        <div style={{ height: '60px' }}></div>
      )}
    </div>
  );
}

import classNames from 'classnames';
import { motion } from 'framer-motion';
import {
  extend,
  filter,
  find,
  findIndex,
  isEmpty,
  map,
  reduce,
  size,
} from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton, UiNavigation } from '../../../../ai-bots/shared/ui';
import { AppFooter } from '../../../../shared';
import { UiPrimaryButton } from '../../../../shared/ui';
import {
  ChallengeInfoTaskType,
  useChallengeInfoQuery,
} from '../../../entities/info';
import {
  useChallengeLessonsProgress,
  useForceOpenChallengeMutation,
} from '../../../entities/lessons-progress';
import {
  NextTaskConfirmationBottomSheet,
  useNextTaskConfirmationBottomSheet,
} from '../../../entities/next-task-confirmation-bottomsheet';
import { NextTaskConfirmationToast } from '../../../entities/next-task-confirmation-toast';
import { ChallengesAnalytics } from '../../../shared/analytics';
import './challenge-tasks-page.css';

function LockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <rect width="17" height="17" x=".5" y=".5" fill="#C4C7CC" rx="8.5" />
      <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5" />
      <path
        fill="#fff"
        d="M9 5c.824 0 1.5.676 1.5 1.5V8h-3V6.5C7.5 5.676 8.176 5 9 5Zm2.5 3V6.5C11.5 5.124 10.376 4 9 4a2.506 2.506 0 0 0-2.5 2.5V8H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-.5ZM6 9h6v4H6V9Z"
      />
      <path fill="#fff" d="M6 13V9h6v4H6Z" />
    </svg>
  );
}

function UnlockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5" />
      <rect width="17" height="17" x=".5" y=".5" stroke="#C4C7CC" rx="8.5" />
    </svg>
  );
}

function CompleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <rect width="17" height="17" x=".5" y=".5" fill="#19AA32" rx="8.5" />
      <rect width="17" height="17" x=".5" y=".5" stroke="#19AA32" rx="8.5" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13.197 5.85-5.775 5.775L4.797 9"
      />
    </svg>
  );
}

function SelectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <rect width="17" height="17" x=".5" y=".5" fill="#fff" rx="8.5" />
      <rect width="17" height="17" x=".5" y=".5" stroke="#5653FE" rx="8.5" />
      <circle cx="9" cy="9" r="5" fill="#5653FE" />
    </svg>
  );
}

function TaskStatusIcon(props: { status: string; isSelected: boolean }) {
  if (props.status === 'closed') {
    return <LockIcon />;
  }

  if (props.status === 'open' && !props.isSelected) {
    return <UnlockIcon />;
  }

  if (props.status === 'open' && props.isSelected) {
    return <SelectIcon />;
  }

  return <CompleteIcon />;
}

function CalendarStatusIcon(props: { status: string; isSelected: boolean }) {
  if (props.status === 'closed') {
    return <LockIcon />;
  }

  if (props.status === 'open' && !props.isSelected) {
    return <UnlockIcon />;
  }

  if (props.status === 'open') {
    return <SelectIcon />;
  }

  return <CompleteIcon />;
}

export function ChallengeTasksPage() {
  const navigation = useHistory();
  const { t } = useTranslation();

  const { challengeId } = useParams<{ challengeId: string }>();
  const challengeInfoQuery = useChallengeInfoQuery(challengeId);
  const challengeLessonsProgressQuery = useChallengeLessonsProgress({
    challengeId,
  });
  const challengeForceOpenMutation = useForceOpenChallengeMutation({
    challengeId,
  });

  const [selectedTaskId, setSelectedTaskId] = useState<string>();
  const { isNextTaskConfirmationNeeded, setNextTaskConfirmationNeeded } =
    useNextTaskConfirmationBottomSheet();

  const [
    isNextTaskConfirmationToastVisible,
    setIsNextTaskConfirmationToastVisible,
  ] = useState(false);
  const [
    isNextTaskConfirmationBottomSheetVisible,
    setIsNextTaskConfirmationBottomSheetVisible,
  ] = useState(false);

  const isLoading = useMemo(
    () =>
      challengeInfoQuery.isLoading || challengeLessonsProgressQuery.isLoading,
    [challengeInfoQuery.isLoading, challengeLessonsProgressQuery.isLoading]
  );

  const isError = useMemo(
    () => challengeInfoQuery.isError || challengeLessonsProgressQuery.isError,
    [challengeInfoQuery.isError, challengeLessonsProgressQuery.isError]
  );

  const tasks = useMemo(
    () =>
      filter(
        reduce(
          challengeInfoQuery.data?.tasks,
          (r: Array<ChallengeInfoTaskType & { status: string }>, v) => {
            const taskProgress = find(challengeLessonsProgressQuery.data, [
              'id',
              v.id,
            ]);

            if (taskProgress) {
              r.push(extend(v, { status: taskProgress.status }));
            }

            return r;
          },
          []
        ),
        task => task.day > 0
      ),
    [challengeInfoQuery.data, challengeLessonsProgressQuery.data]
  );

  const tasksCalendar = useMemo(
    () =>
      map(tasks, task => ({
        id: task.id,
        title: `D${task.day}`,
        status: task.status,
      })),
    [tasks, challengeLessonsProgressQuery.data]
  );

  const firstClosedTaskId = useMemo(
    () => find(tasks, ['status', 'closed'])?.id,
    [tasks]
  );

  const currentProgressPercent = useMemo(() => {
    if (!isEmpty(tasks)) {
      const completedTasksCount = size(filter(tasks, ['status', 'completed']));

      return Math.round((completedTasksCount / size(tasks)) * 100);
    }

    return 0;
  }, [tasks]);

  const isJoined = useMemo(
    () =>
      !isEmpty(challengeLessonsProgressQuery.data) &&
      size(
        filter(challengeLessonsProgressQuery.data, ['status', 'completed'])
      ) > 0,
    [challengeLessonsProgressQuery.data]
  );

  const isAllTasksCompleted = useMemo(
    () =>
      !isEmpty(tasks) &&
      size(filter(tasks, ['status', 'completed'])) === size(tasks),
    [tasks]
  );

  useEffect(() => {
    if (!isEmpty(tasks)) {
      ChallengesAnalytics.tasksPageView({
        challenge_id: challengeId,
        total_days: size(tasks),
        progress: size(filter(tasks, ['status', 'completed'])),
      });
    }
  }, [tasks]);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      const firstOpenTask = find(tasks, ['status', 'open']);

      if (firstOpenTask) {
        selectTask({ id: firstOpenTask.id, status: firstOpenTask.status });
      }
    }
  }, [tasks]);

  function onRefetch() {
    challengeInfoQuery.refetch();
    challengeLessonsProgressQuery.refetch();
  }

  function scrollByCalendar(id: string) {
    const calendarElement = document.getElementById(`calendar-item-${id}`);
    const calendarListElement = document.getElementById(
      'challenges-calendar-list'
    );

    if (calendarListElement && calendarElement) {
      const elementPosition = calendarElement?.offsetLeft - 20;

      calendarListElement?.scroll({
        left: elementPosition,
        behavior: 'smooth',
      });
    }
  }

  function scrollByTasks(id: string) {
    const taskElement = document.getElementById(`challenges-task-${id}`);

    if (taskElement) {
      const headerOffset = 134;
      const elementPosition = taskElement.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scroll({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  function selectTask(props: { id: string; status: string }) {
    ChallengesAnalytics.onTaskCardClick({
      challenge_id: challengeId,
      task_id: props.id,
      is_locked: props.status === 'closed',
    });

    if (props.status !== 'closed') {
      setSelectedTaskId(props.id);

      scrollByCalendar(props.id);
      scrollByTasks(props.id);
    }

    if (props.status === 'closed' && tasks.every(t => t.status !== 'open')) {
      const taskIndex = findIndex(tasks, ['id', props.id]);

      if (taskIndex > 0) {
        const previousTask = tasks[taskIndex - 1];

        if (previousTask.status === 'completed') {
          showNextTaskConfirmationToast();
        }
      }
    }
  }

  function showNextTaskConfirmationToast() {
    ChallengesAnalytics.nextTaskConfirmationToastView({
      challenge_id: challengeId,
    });

    setIsNextTaskConfirmationToastVisible(true);
  }

  function goToTask(id: string) {
    navigation.replace(`/challenges/${challengeId}/${id}`);
  }

  function startTask() {
    if (selectedTaskId) {
      ChallengesAnalytics.onTaskStartButtonClick({
        challenge_id: challengeId,
        task_id: selectedTaskId,
      });

      goToTask(selectedTaskId);
    }
  }

  function openNextTask() {
    challengeForceOpenMutation.mutate();

    if (firstClosedTaskId) {
      goToTask(firstClosedTaskId);
    }
  }

  function onNextTaskConfirmationToastHide() {
    setIsNextTaskConfirmationToastVisible(false);
  }

  function onNextTaskConfirmationToastConfirm() {
    if (selectedTaskId) {
      ChallengesAnalytics.onNextTaskConfirmationToastActionClick({
        challenge_id: challengeId,
        task_id: selectedTaskId,
        is_instant_unlock: !isNextTaskConfirmationNeeded,
      });
    }

    onNextTaskConfirmationToastHide();
    if (isNextTaskConfirmationNeeded) {
      if (selectedTaskId) {
        ChallengesAnalytics.nextTaskConfirmationBottomSheetView({
          challenge_id: challengeId,
          task_id: selectedTaskId,
        });
      }

      setIsNextTaskConfirmationBottomSheetVisible(true);
    } else {
      openNextTask();
    }
  }

  function onNextTaskConfirmationBottomSheetHide() {
    setIsNextTaskConfirmationBottomSheetVisible(false);
  }

  function onNextTaskConfirmationBottomSheetConfirm(
    isDontShowAgainChecked: boolean
  ) {
    if (selectedTaskId) {
      ChallengesAnalytics.onNextTaskConfirmationBottomSheetActionClick({
        challenge_id: challengeId,
        task_id: selectedTaskId,
        is_do_not_show_again: isDontShowAgainChecked,
      });
    }

    onNextTaskConfirmationBottomSheetHide();

    if (isDontShowAgainChecked) {
      setNextTaskConfirmationNeeded();
    }

    openNextTask();
  }

  function onBackButtonClicked() {
    if (isJoined) {
      navigation.replace(`/challenges`);
    } else {
      navigation.replace(`/challenges/${challengeId}`);
    }
  }

  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <main className="ui-base-layout min-vh-full challenges-tasks-page">
        <UiNavigation
          title={
            isLoading ? (
              <UiLoadingSkeleton
                style={{
                  height: '24px',
                  width: '150px',
                }}
              />
            ) : (
              challengeInfoQuery.data?.name ?? ''
            )
          }
          className="challenges-tasks-page__navigation"
          onBackButtonClicked={onBackButtonClicked}
        />

        {isError ? (
          <div className="ui-base-layout__content">
            <FullPageError onRetry={onRefetch} />
          </div>
        ) : (
          <>
            <header className="challenges-tasks-page__header">
              {isLoading ? (
                <>
                  <UiLoadingSkeleton
                    style={{
                      height: '28px',
                      width: '200px',
                    }}
                  />
                  <UiLoadingSkeleton
                    style={{
                      marginTop: '8px',
                      height: '24px',
                      width: '280px',
                    }}
                  />
                  <UiLoadingSkeleton
                    style={{
                      marginTop: '16px',
                      height: '18px',
                      width: '100%',
                      borderRadius: '999px',
                    }}
                  />
                </>
              ) : (
                <>
                  <h1 className="challenges-tasks-page__title">
                    {challengeInfoQuery.data?.name ?? ''}
                  </h1>
                  <p className="challenges-tasks-page__description">
                    {challengeInfoQuery.data?.description}
                  </p>

                  {isAllTasksCompleted ? (
                    <div className="challenges-tasks-page__completed-badge">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="15"
                        fill="none"
                      >
                        <path
                          stroke="#19AA32"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m11.67 4-6.417 6.417L2.336 7.5"
                        />
                      </svg>
                      <strong>{t('challenges.completed-badge')}</strong>
                    </div>
                  ) : (
                    <div className="challenges-tasks-page__progress-container">
                      <div className="challenges-tasks-page__progress">
                        <div
                          className="challenges-tasks-page__progress-line"
                          style={{ width: `${currentProgressPercent}%` }}
                        ></div>
                      </div>
                      <span>{currentProgressPercent}%</span>
                    </div>
                  )}
                </>
              )}
            </header>

            <div className="challenges-tasks-page__tasks-calendar">
              {isLoading ? (
                <ul className="challenges-tasks-page__calendar-list">
                  {Array(10)
                    .fill('')
                    .map((_, i) => (
                      <li
                        key={`calendar-item-skeleton-${i}`}
                        className="challenges-tasks-page__calendar-item"
                      >
                        <button
                          type="button"
                          className="challenges-tasks-page__calendar-button"
                        >
                          <UiLoadingSkeleton
                            style={{
                              width: '17px',
                              height: '17px',
                              borderRadius: '100%',
                            }}
                          />

                          <UiLoadingSkeleton
                            style={{
                              marginTop: '8px',
                              width: '19px',
                              height: '16px',
                            }}
                          />
                        </button>
                      </li>
                    ))}
                </ul>
              ) : (
                <ul
                  id={'challenges-calendar-list'}
                  className="challenges-tasks-page__calendar-list"
                >
                  {tasksCalendar.map(item => (
                    <li
                      id={`calendar-item-${item.id}`}
                      key={`calendar-item-${item.id}`}
                      className="challenges-tasks-page__calendar-item"
                    >
                      <button
                        type="button"
                        className={classNames(
                          'challenges-tasks-page__calendar-button',
                          {
                            '--active': selectedTaskId === item.id,
                          }
                        )}
                        onClick={() =>
                          selectTask({ id: item.id, status: item.status })
                        }
                      >
                        <CalendarStatusIcon
                          status={item.status}
                          isSelected={selectedTaskId === item.id}
                        />
                        <strong>{item.title}</strong>
                      </button>
                    </li>
                  ))}
                </ul>
              )}

              <hr className="challenges-tasks-page__calendar-divider" />
            </div>

            <section className="ui-base-layout__content challenges-tasks-page__content">
              <ul className="challenges-tasks-page__tasks">
                {tasks.map(task => (
                  <li
                    id={`challenges-task-${task.id}`}
                    key={task.id}
                    className="challenges-tasks-page__task"
                  >
                    <button
                      type="button"
                      className={classNames(
                        'challenges-tasks-page__task-button',
                        {
                          '--active': task.id === selectedTaskId,
                        }
                      )}
                      onClick={() =>
                        selectTask({ id: task.id, status: task.status })
                      }
                    >
                      <span className="challenges-tasks-page__task-content">
                        <img
                          width="48"
                          height="48"
                          src={task.image}
                          role="presentation"
                          alt=""
                        />

                        <span className="challenges-tasks-page__task-heading">
                          <strong className="challenges-tasks-page__task-title">
                            {t('challenges.task.day-title', {
                              count: task.day,
                            })}
                          </strong>
                          <span className="challenges-tasks-page__task-description">
                            {task.title}
                          </span>
                        </span>
                      </span>

                      <TaskStatusIcon
                        status={task.status}
                        isSelected={task.id === selectedTaskId}
                      />
                    </button>
                  </li>
                ))}
              </ul>

              {Boolean(selectedTaskId) && (
                <div className="challenges-tasks-page__actions">
                  <UiPrimaryButton
                    className="challenges-tasks-page__action"
                    onClick={startTask}
                  >
                    {t('challenges.start-button')}
                  </UiPrimaryButton>
                </div>
              )}
            </section>
          </>
        )}

        <AppFooter />

        <NextTaskConfirmationToast
          isVisible={isNextTaskConfirmationToastVisible}
          events={{
            onHide: onNextTaskConfirmationToastHide,
            onConfirm: onNextTaskConfirmationToastConfirm,
          }}
        />

        <NextTaskConfirmationBottomSheet
          isVisible={isNextTaskConfirmationBottomSheetVisible}
          events={{
            onHide: onNextTaskConfirmationBottomSheetHide,
            onConfirm: onNextTaskConfirmationBottomSheetConfirm,
          }}
        />
      </main>
    </motion.section>
  );
}

import styled from 'styled-components';

export const PrivacyCheckboxContainer = styled.div<{ isShowError: boolean }>`
  .disclaimer {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    margin-left: 8px;

    color: #48484a;
  }

  .error-message {
    color: #d43333;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 12px 0;
  }

  input[type='checkbox'] {
    -webkit-appearance: ${props => (props.isShowError ? 'none' : '')};
    -moz-appearance: ${props => (props.isShowError ? 'none' : '')};
    appearance: ${props => (props.isShowError ? 'none' : '')};
    display: ${props => (props.isShowError ? 'inline-block' : '')};
    background-clip: content-box;

    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: ${props =>
      props.isShowError ? '1px solid #D43333' : '1px solid #C63D23'};
    border-radius: 3px;
  }

  input[type='checkbox']:checked {
    background: #5653fe;
    accent-color: #5653fe;
  }

  input[type='checkbox']:focus {
    background: #5653fe;
    accent-color: #5653fe;
  }

  .hidden {
    opacity: 0;
  }

  a {
    text-decoration: underline;
    color: #48484a;
  }
`;

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UiRetryButton } from '../../../ui';
import Illustration from '../../shared/assets/illustration.svg';
import './full-page-error.css';

export function FullPageError({
  withFooter = true,
  onRetry,
}: {
  withFooter?: boolean;
  onRetry: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('full-page-error', {
        'full-page-error--with-footer': withFooter,
      })}
    >
      <div className="full-page-error__content">
        <img
          width="312"
          height="240"
          src={Illustration}
          role="presentation"
          alt=""
        />

        <h1>{t('ai-bots.error-block.title')}</h1>
        <p>{t('ai-bots.error-block.description')}</p>
      </div>

      <UiRetryButton
        className="full-page-error__retry-button"
        text={t('ai-bots.error-block.retry-button')}
        onRetry={() => onRetry()}
      />
    </div>
  );
}

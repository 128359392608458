import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

type QuestionType =
  | 'motivation'
  | 'skills_required'
  | 'favorite_learning_format'
  | 'expertise_level'
  | 'goal';

export const OnboardingAnalytics = {
  welcomePageView({
    abOnboardingQuizValue,
  }: {
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'welcome_page',
      language: i18next.language,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  infoPageView({ abOnboardingQuizValue }: { abOnboardingQuizValue: string }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'info_page',
      language: i18next.language,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  infoPageSlideView({
    index,
    abOnboardingQuizValue,
  }: {
    index: number;
    abOnboardingQuizValue: string;
  }) {
    const slides = ['zero2hero', 'innovation', 'transform_career'];

    analyticsService.sendEvent('webapp_onboarding_view', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'info_page',
      language: i18next.language,
      content: slides[index],
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  levelPickerPageView({
    abOnboardingQuizValue,
  }: {
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'level_page',
      language: i18next.language,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  onSelectLevel({
    level,
    abOnboardingQuizValue,
  }: {
    level: 'beginner' | 'intermediate' | 'advanced';
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'level_page',
      language: i18next.language,
      action: 'select_level',
      value: level,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  levelInfoPageView({
    abOnboardingQuizValue,
  }: {
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'final_page',
      language: i18next.language,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  onContinueClickInLevelInfoPage({
    abOnboardingQuizValue,
  }: {
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      version: 'v2',
      exp_onboarding_win_v2: 'clean',
      place: 'final_page',
      language: i18next.language,
      action: 'finish_onboarding',
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  guidesOnboardingView() {
    analyticsService.sendEvent('webapp_feature_preview_modal_view', {
      feature: 'guides',
      exp_onboarding_win_v2: 'clean',
      language: i18next.language,
    });
  },
  onGuidesOnboardingClose() {
    analyticsService.sendEvent('webapp_feature_preview_modal_click', {
      feature: 'guides',
      exp_onboarding_win_v2: 'clean',
      language: i18next.language,
      action: 'close_modal',
    });
  },
  onQuizPageView({
    question,
    abOnboardingQuizValue,
  }: {
    question: QuestionType;
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_view', {
      place: `onboarding_quiz`,
      question,
      language: i18next.language,
      version: `v1`,
      exp_onboarding_win_v2: 'clean',
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
  onQuizAnswerSubmit({
    question,
    answer,
    abOnboardingQuizValue,
  }: {
    question: QuestionType;
    answer: string;
    abOnboardingQuizValue: string;
  }) {
    analyticsService.sendEvent('webapp_onboarding_click', {
      place: `onboarding_quiz`,
      exp_onboarding_win_v2: 'clean',
      action: 'submit_answer',
      question,
      answer,
      language: i18next.language,
      version: `v1`,
      ab_onboarding_quiz: abOnboardingQuizValue,
    });
  },
};

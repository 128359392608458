import type { AxiosResponse } from 'axios';

import { api } from '../../services/http.service';

export interface GuideCertificateType {
  guide_id: string;
  name: string;
  progress: number;
  image: string;
  pdf_url: string;
  is_completed: boolean;
  blank_image: string;
  success_image: string;
}

export const GuideCertificateAPI = {
  getCertificate(
    guideId: string
  ): Promise<AxiosResponse<GuideCertificateType>> {
    return api.get(`/certificates/progress/${guideId}`);
  },
  createCertificate(
    guideId: string
  ): Promise<AxiosResponse<GuideCertificateType>> {
    return api.post(`/certificates/${guideId}`);
  },
  getCertificatesList(
    arrow: string
  ): Promise<AxiosResponse<GuideCertificateType[]>> {
    return api.get(`/certificates/progress?arrow=${arrow}`);
  },
};

import { cloneDeep, extend } from 'lodash-es';

import { Properties } from './types';

export class User {
  private _metaData: Properties | null;

  constructor() {
    this._metaData = null;
  }

  get userMetaData() {
    return this._metaData;
  }

  setUserMetaData(properties: Properties) {
    this._metaData = extend(cloneDeep(this._metaData), properties);
  }
}

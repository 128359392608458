import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../../guides/entities/guide';
import { GuideDuration } from '../../../../guides/entities/guide-duration';
import './assets/guides-other-small-list.css';

export function GuidesOtherSmallListLoading() {
  const { t } = useTranslation();

  return (
    <section className="guides-other-small-list">
      <h1 className="guides-other-small-list__title">
        {t('guides.recommends.title')}
      </h1>
      <h1 className="guides-other-small-list__subtitle">
        {t('guides.recommends.description')}
      </h1>

      <div className="guides-other-small-list__list-loading">
        {Array(2)
          .fill('')
          .map((_, i) => (
            <UiLoadingSkeleton
              key={`guides-other-small-list-loading__${i}`}
              style={{
                width: '100%',
                height: '162px',
                borderRadius: '8px',
              }}
            />
          ))}
      </div>
    </section>
  );
}

export function GuidesOtherSmallList({
  guides,
  onGuideClick,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType) => void;
}) {
  const { t } = useTranslation();

  return (
    <section className="guides-other-small-list">
      <div className="guides-other-small-list__title-container">
        <h1 className="guides-other-small-list__title">
          {t('guides-v2.recommended-guides')}
        </h1>
        <Link
          to="/other-guides"
          className="guides-other-small-list__other-guides-link"
        >
          {t('guides-v2.view-all')}
        </Link>
      </div>
      <h2 className="guides-other-small-list__subtitle">
        {t('guides-v2.based_on_goals')}
      </h2>

      <div className="guides-other-small-list__list">
        {guides.slice(0, 5).map(guide => (
          <button
            key={guide.id}
            className="guides-other-small-list__item"
            onClick={() => onGuideClick(guide)}
          >
            <img
              className="guides-other-small-list__item-image"
              width="64"
              height="64"
              src={guide.image}
              role="presentation"
              alt=""
            />

            <GuideDuration
              className="small-guide-card__duration"
              unitsCount={guide.units_count}
              duration={guide.duration}
              size="sm"
            />

            <strong className="guides-other-small-list__item-title">
              {guide.name}
            </strong>
          </button>
        ))}
      </div>
    </section>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { useDeleteSessionMutation } from '../../../entities/session';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { UiSpinner } from '../../../shared/ui/spinner';
import './delete-session-confirmation.css';

const ERROR_VISIBLE_DELAY = 2000;

export function DeleteSessionConfirmationBottomSheet({
  sessionId,
  chatBotId,
  isVisible,
  onDismiss,
  onCancelled,
  onConfirmed,
}: {
  sessionId: string;
  chatBotId: string;
  isVisible: boolean;
  onCancelled?: () => void;
  onConfirmed?: () => void;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');
  const deleteSessionMutation = useDeleteSessionMutation({
    queryClient,
    sessionId,
  });
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId, sessionId });

  useEffect(() => {
    if (isVisible) {
      ChatPageAnalytics.deleteConfirmationModalView();
    }
  }, [isVisible]);

  function onDeleteConfirmed() {
    setErrorMessage('');
    deleteSessionMutation
      .mutateAsync()
      .then(() => onConfirmed?.())
      .catch(cause => {
        //TODO: fix types in 18next
        const baseErrorMessage = t('ai-bots.default-error');
        setErrorMessage(baseErrorMessage);
        setTimeout(() => setErrorMessage(''), ERROR_VISIBLE_DELAY);
        throw new Error('ERROR_DELETE_SESSION', { cause });
      });

    ChatPageAnalytics.onDeleteConfirmationModalConfirmClick();
  }

  return (
    <BottomSheet
      key="delete-session-confirmation-bottomsheet"
      expandOnContentDrag={true}
      open={isVisible}
      onDismiss={() => onDismiss()}
    >
      {deleteSessionMutation.isLoading && (
        <div className="delete-session-loading">
          <UiSpinner />
        </div>
      )}
      <div className="delete-session-confirmation">
        <h1 className="delete-session-confirmation_title">
          {t('ai-bots.delete-confirmation.title')}
        </h1>
        <p className="delete-session-confirmation_description">
          {t('ai-bots.delete-confirmation.description')}
        </p>
        {errorMessage && (
          <p className="delete-session-confirmation__error">{errorMessage}</p>
        )}
        <div className="delete-session-confirmation__actions">
          <button
            type="button"
            className="delete-session-confirmation__cancel-button"
            onClick={onCancelled}
          >
            {t('ai-bots.delete-confirmation.cancel-button')}
          </button>
          <button
            type="button"
            className="delete-session-confirmation__confirm-button"
            onClick={onDeleteConfirmed}
          >
            {t('ai-bots.delete-confirmation.confirm-button')}
          </button>
        </div>
      </div>
    </BottomSheet>
  );
}

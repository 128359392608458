import { Amplify, Auth } from 'aws-amplify';
import Cookies from 'js-cookie';

import { IAuthUser, IUser } from '../types/User';
import { getUtmNotif } from '../utils/QueryParams/QueryParams';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'development'
          ? 'localhost'
          : process.env.REACT_APP_COGNITO_COOKIE_STORAGE,
      secure: process.env.NODE_ENV !== 'development',
      path: '/',
      expires: 365,
    },
    authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE,
  },
});

const signIn = async (email: string, password: string) => {
  return await Auth.signIn({
    username: email,
    password: password,
  });
};

const redirectToSignin = () => {
  if (
    typeof window === 'undefined' ||
    window.location.pathname.includes('auth')
  )
    return;

  window.location.pathname = '/auth/signin';
};

const getSession = async () => {
  return await Auth.currentSession();
};

const getToken = async () => {
  return await Auth.currentSession()
    .then(session => {
      return session.getAccessToken().getJwtToken();
    })
    .catch(error => {
      redirectToSignin();
      return null;
    });
};

const getUser = async () => {
  return await Auth.currentUserInfo()
    .then((value: any) => {
      if (value.attributes) {
        return {
          user_id: parseInt(value.attributes['custom:user_id']),
          username: value.attributes['custom:username'],
          email: value.attributes.email,
          email_verified: value.attributes.email_verified,
          cognito_id: value.attributes.sub,
        } as IUser;
      }
      return null;
    })
    .catch(e => {
      getUtmNotif();
      redirectToSignin();

      return null;
    });
};

const logOut = async () => {
  Cookies.remove('prosperiUser');
  Auth.signOut().then(res => {
    redirectToSignin();
  });
};

const getCurrentUser = async () => {
  return await Auth.currentUserInfo()
    .then((value: any) => {
      if (value.attributes) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
};

const signUp = (user: IAuthUser) => {
  return Auth.signUp({
    username: user.email,
    password: user.password,
    attributes: {
      'custom:username': user.username,
      'custom:user_id': user.user_id,
    },
  });
};

const cleanUpCognitoCookies = () => {
  const cognitoCookie = 'CognitoIdentityServiceProvider';
  const allCookies = Object.keys(Cookies.get());

  for (let cookie of allCookies) {
    if (cookie.includes(cognitoCookie)) {
      Cookies.remove(cookie, {
        path: '/',
        domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE,
      });
    }
  }
};

const forgotPassword = (email: string) => {
  return Auth.forgotPassword(email);
};

const forgotPasswordSubmit = (
  username: string,
  code: string,
  password: string
) => {
  return Auth.forgotPasswordSubmit(username, code, password);
};

const resendConfirmation = (email: string) => {
  return Auth.resendSignUp(email);
};

export default {
  getUser,
  getToken,
  logOut,
  signIn,
  signUp,
  getSession,
  cleanUpCognitoCookies,
  getCurrentUser,
  forgotPassword,
  forgotPasswordSubmit,
  resendConfirmation,
};

import i18next from 'i18next';

import { analyticsService } from '../../../services/analytics.service';

type SatisfactionLevel =
  | 'not_satisfied'
  | 'slightly_satisfied'
  | 'somewhat_satisfied'
  | 'satisfied'
  | 'very_satisfied';

export const SatisfactionSurveyAnalytics = {
  view() {
    analyticsService.sendEvent('webapp_survey_view', {
      feature: 'satisfaction_survey',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onLevelButtonClick({ levelType }: { levelType: SatisfactionLevel }) {
    analyticsService.sendEvent('webapp_survey_click', {
      feature: 'satisfaction_survey',
      feature_version: 'v1',
      rating: levelType,
      action: 'rate',
      language: i18next.language,
    });
  },
  onSubmitFeedback({
    feedback,
    levelType,
    reason,
  }: {
    feedback: string;
    levelType: SatisfactionLevel;
    reason: string;
  }) {
    analyticsService.sendEvent('webapp_survey_click', {
      feature: 'satisfaction_survey',
      feature_version: 'v1',
      rating: levelType,
      action: 'leave_feedback',
      feedback,
      reason,
      language: i18next.language,
    });
  },

  onDismiss() {
    analyticsService.sendEvent('webapp_survey_click', {
      feature: 'satisfaction_survey',
      feature_version: 'v1',
      action: 'close',
      language: i18next.language,
    });
  },
};

//

import { Fragment, useState } from 'react';

import AiAvatarIcon from '../../../../ai-bots/entities/message/ui/assets/ai-avatar.svg';
import './fill-in-image.css';

export function FillInImage({
  images,
  avatar,
  onHeightChange,
}: {
  images: string[];
  avatar?: string;
  onHeightChange: () => void;
}) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
    onHeightChange();
  };

  return (
    <div className="fill-in-image">
      <img
        src={avatar || AiAvatarIcon}
        className="fill-in-image__avatar"
        role="presentation"
        alt=""
      />
      <div
        className={`fill-in-image__${images.length === 1 ? 'image' : 'images'}`}
      >
        {images.map(image => (
          <Fragment key={image}>
            {isLoading && <div className="fill-in-image__placeholder" />}
            <div key={image} style={{ display: isLoading ? 'none' : 'block' }}>
              <img src={image} alt="" onLoad={handleImageLoad} />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

import classNames from 'classnames';

import './quiz-button.css';

export function QuizButton({
  size = 'md',
  children,
  flexWidth = false,
  isActive = false,
  isDisabled = false,
  withCheckmark = true,
  className = '',
  events,
}: {
  size?: 'sm' | 'md';
  children: string | JSX.Element | JSX.Element[];
  flexWidth?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  withCheckmark?: boolean;
  className?: string;
  events: {
    onClick: () => void;
  };
}) {
  return (
    <button
      type="button"
      disabled={isDisabled}
      className={classNames('quiz-button', className, {
        'quiz-button--md': size === 'md',
        'quiz-button--sm': size === 'sm',
        'quiz-button--active': isActive,
        'quiz-button--full-width': !flexWidth,
      })}
      onClick={events.onClick}
    >
      <span className="quiz-button__content">{children}</span>

      {withCheckmark && (
        <svg
          className="quiz-button__checkmark"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <rect width="19" height="19" x=".5" y=".5" fill="#5653FE" rx="9.5" />
          <rect
            width="19"
            height="19"
            x=".5"
            y=".5"
            stroke="#5653FE"
            rx="9.5"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14.666 6.5 8.25 12.917 5.333 10"
          />
        </svg>
      )}
    </button>
  );
}

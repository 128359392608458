import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { ImageLightbox } from '../../../../shared/image-lightbox';
import { useChatBotDetailsQuery } from '../../../entities/chat-details';
import { DetailsButton } from '../../../entities/details-button';
import { useSessionDetailsQuery, useSessionMessagesQuery } from '../../../entities/session';
import { ChatDetailsBottomSheet } from '../../../feature/chat-details-bottomsheet';
import { DeleteSessionConfirmationBottomSheet } from '../../../feature/delete-session-confirmation';
import { useChatPageAnalytics } from '../../../shared/analytics';
import { FullPageError } from '../../../shared/errors';
import { UiLoadingSkeleton, UiNavigation } from '../../../shared/ui';
import { AiBotSessionChat } from '../../../widgets/chat';

export const AiBotsSessionPageV2 = () => {
  const navigation = useHistory();
  const location = useLocation<{ from?: string }>();
  const params = useParams<{ chatBotId: string; sessionId: string }>();
  const { chatBotId, sessionId } = params;

  const [isDetailsBottomSheetVisible, setIsDetailsBottomSheetVisible] =    useState(false);
  const [
    isDeleteSessionConfirmationVisible,
    setIsDeleteSessionConfirmationVisible,
  ] = useState(false);
  const [
    isDeleteSessionConfirmationOpenFromDetails,
    setIsDeleteSessionConfirmationOpenFromDetails,
  ] = useState(false);
  const [isImageLightboxVisible, setIsImageLightboxVisible] = useState(false);
  const [imageLightboxUrl, setImageLightboxUrl] = useState('');

  const chatDetailsQuery = useChatBotDetailsQuery({ chatBotId });
  const sessionDetailsQuery = useSessionDetailsQuery({ sessionId });
  const sessionMessagesQuery = useSessionMessagesQuery({ sessionId });
  const ChatPageAnalytics = useChatPageAnalytics({ chatBotId, sessionId });
  // TODO: Think about a more elegant way for once calls
  const [
    isChatPageViewAnalyticsEventCalled,
    setIsChatPageViewAnalyticsEventCalled,
  ] = useState(false);

  const isError =    chatDetailsQuery.isError
    || sessionDetailsQuery.isError
    || sessionMessagesQuery.isError;

  useEffect(() => {
    // TODO: call just one times
    if (
      !isChatPageViewAnalyticsEventCalled
      && chatDetailsQuery.data
      && sessionDetailsQuery.data
      && sessionMessagesQuery.data
    ) {
      ChatPageAnalytics.chatPageView({
        location: 'existing_chat',
      });
      setIsChatPageViewAnalyticsEventCalled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChatPageViewAnalyticsEventCalled,
    chatDetailsQuery.data,
    sessionDetailsQuery.data,
    sessionMessagesQuery.data,
  ]);

  function retryFetchQueries() {
    if (chatDetailsQuery.isError) {
      chatDetailsQuery.refetch().catch(() => {});
    }

    if (sessionDetailsQuery.isError) {
      sessionDetailsQuery.refetch().catch(() => {});
    }

    if (sessionMessagesQuery.isError) {
      sessionMessagesQuery.refetch().catch(() => {});
    }
  }

  const isCameFromSessionsPage =    location.state?.from?.includes('ai-bots/sessions');

  function onGoBack() {
    if (isCameFromSessionsPage) {
      navigation.replace('/ai-bots/sessions');
    } else {
      navigation.replace('/ai-bots');
    }
  }

  function onDeleteSessionConfirmed() {
    setIsDeleteSessionConfirmationVisible(false);
    onGoBack();
  }

  function onDeleteSessionCancelled() {
    setIsDeleteSessionConfirmationVisible(false);

    if (isDeleteSessionConfirmationOpenFromDetails) {
      setIsDeleteSessionConfirmationOpenFromDetails(false);
      setIsDetailsBottomSheetVisible(true);
    }
  }

  function onDeleteSessionDismissed() {
    setIsDeleteSessionConfirmationOpenFromDetails(false);
    setIsDeleteSessionConfirmationVisible(false);
  }

  function onShowDeleteConfirmationFromDetails() {
    setIsDetailsBottomSheetVisible(false);
    setIsDeleteSessionConfirmationOpenFromDetails(true);
    setIsDeleteSessionConfirmationVisible(true);

    ChatPageAnalytics.onChatDetailsModalDeleteButtonClick();
  }

  function onShowInfo() {
    setIsDetailsBottomSheetVisible(true);

    ChatPageAnalytics.onInfoButtonClick();
  }

  function onDetailsButtonClick() {
    setIsDetailsBottomSheetVisible(true);

    ChatPageAnalytics.onInfoQuestionButtonClick();
  }

  function onShowDeleteConfirmation() {
    setIsDeleteSessionConfirmationVisible(true);

    ChatPageAnalytics.onChatInfoDeleteButtonClick();
  }

  function onOpenLightbox(url: string) {
    setImageLightboxUrl(url);
    setIsImageLightboxVisible(true);
  }

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        title={
          sessionDetailsQuery.isLoading ? (
            <UiLoadingSkeleton
              style={{
                height: '24px',
                width:  '80px',
              }}
            />
          ) : (
            sessionDetailsQuery.data?.name ?? 'Chat'
          )
        }
        action={
          !chatDetailsQuery.isLoading ? (
            <DetailsButton onClick={() => onDetailsButtonClick()} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )
        }
        onBackButtonClicked={() => onGoBack()}
      />

      {isError ? (
        <FullPageError
          withFooter={false}
          onRetry={() => retryFetchQueries()}
        />
      ) : (
        <AiBotSessionChat
          chatBotId={chatBotId}
          sessionId={sessionId}
          onShowInfo={() => onShowInfo()}
          onDeleteSession={() => onShowDeleteConfirmation()}
          onOpenImage={url => onOpenLightbox(url)}
        />
      )}

      <DeleteSessionConfirmationBottomSheet
        chatBotId={chatBotId}
        sessionId={sessionId}
        isVisible={isDeleteSessionConfirmationVisible}
        onDismiss={() => onDeleteSessionDismissed()}
        onCancelled={() => onDeleteSessionCancelled()}
        onConfirmed={() => onDeleteSessionConfirmed()}
      />

      <ChatDetailsBottomSheet
        chatBotId={chatBotId}
        sessionId={sessionId}
        newChatBotName={sessionDetailsQuery.data?.name}
        chatDetails={chatDetailsQuery.data}
        isVisible={isDetailsBottomSheetVisible}
        onDismiss={() => setIsDetailsBottomSheetVisible(false)}
        onDeleteSession={() => onShowDeleteConfirmationFromDetails()}
      />

      {isImageLightboxVisible && (
        <ImageLightbox
          url={imageLightboxUrl}
          onClose={() => setIsImageLightboxVisible(false)}
        />
      )}
    </main>
  );
};

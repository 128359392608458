import { useFeatureValue } from '@growthbook/growthbook-react';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userAccessQuery, userQuery } from '../../../../../context/atoms/User';
import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiBaseLayout, UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../../guides/entities/guide';
import { useGuidesListQuery } from '../../../../guides/entities/guides-list';
import { useGuidesListProgressQuery } from '../../../../guides/entities/guides-list-progress';
import { GuidesListLoading } from '../../../../guides/pages/main-page/ui/GuidesList';
import { GuidesOnboardingBottomSheet } from '../../../../guides/widgets/onboarding';
import { OngoingGuideV2 } from '../../../features/ongoing-guide-v2';
import { GuidesV2Analytics } from '../../../shared/analytics';
import { getOngoingGuideByArrow } from '../../../shared/lib';
import { GuidesListArrow } from './GuidesListArrow';
import {
  GuidesOtherSmallList,
  GuidesOtherSmallListLoading,
} from './GuidesOtherSmallList';
import './assets/guides-main-page.css';

export function GuidesMainPageV2() {
  const { t } = useTranslation();
  const navigation = useHistory();
  const location = useLocation<{ from?: string }>();
  const isCameFromOnboarding = location.state?.from?.includes('onboarding');

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const user = useRecoilValue(userQuery);
  const userAccess = useRecoilValue(userAccessQuery(user?.user_id));

  useEffect(() => {
    GuidesV2Analytics.mainPageView();
  }, []);

  const guidesListProgressQuery = useGuidesListProgressQuery();

  const guidesListQuery = useGuidesListQuery();

  const [filteredByArrowGuides, filteredGuides] = useMemo(() => {
    return [
      guidesListQuery.data?.filter(guide =>
        guide.tags.some(tag => tag.toLowerCase() === userArrow)
      ),
      guidesListQuery.data?.filter(guide =>
        guide.tags.some(tag => tag.toLowerCase() !== userArrow)
      ),
    ];
  }, [guidesListQuery.data]);

  const [isGuidesOnboardingVisible, setIsGuidesOnboardingVisible] =
    useState(false);

  const ongoingGuide = useMemo(
    () =>
      getOngoingGuideByArrow({
        guides: guidesListQuery.data,
        progressList: guidesListProgressQuery.data,
        withFallback: true,
        arrow: userArrow,
      }),
    [guidesListProgressQuery.data, guidesListQuery.data]
  );

  function onGoToGuide(guide: GuideType, isPathway: boolean = false) {
    if (ongoingGuide?.guide?.id === guide.id) {
      GuidesV2Analytics.onOngoingGuideClick({
        guideId: guide.id,
      });
    } else {
      GuidesV2Analytics.onGuideCardClick({
        guideId: guide.id,
      });
    }
    navigation.push(
      isPathway ? `/guide-pathway/${guide.id}` : `/guides/${guide.id}`
    );
  }

  function hideGuidesOnboarding() {
    setIsGuidesOnboardingVisible(false);
  }

  useEffect(() => {
    if (
      isCameFromOnboarding &&
      ongoingGuide?.guide?.id &&
      userAccess.has_access
    ) {
      navigation.push(`/guide-pathway/${ongoingGuide.guide.id}`);
    }

    if (!isNil(userAccess.has_access) && !userAccess.has_access) {
      navigation.push('/profile');
    }
  }, [isCameFromOnboarding, ongoingGuide, userAccess]);

  return (
    <>
      <UiBaseLayout>
        {guidesListQuery.isError ? (
          <FullPageError onRetry={() => guidesListQuery.refetch()} />
        ) : (
          <section className="guides-main-page">
            <header className="guides-main-page__header">
              <h1 className="guides-main-page__title">
                {t('guides-v2.title')}
              </h1>
            </header>

            <div className="guides-main-page__ongoing-guide">
              {guidesListQuery.isLoading ? (
                <UiLoadingSkeleton
                  style={{
                    width: '100%',
                    height: '340px',
                    borderRadius: '8px',
                  }}
                />
              ) : (
                ongoingGuide &&
                ongoingGuide.guide && (
                  <OngoingGuideV2
                    guide={ongoingGuide.guide}
                    progress={ongoingGuide.progress}
                    onContinue={onGoToGuide}
                  />
                )
              )}
            </div>

            <div className="guides-main-page__guides-list">
              {guidesListQuery.isLoading ? (
                <GuidesListLoading />
              ) : (
                filteredByArrowGuides &&
                guidesListProgressQuery.data && (
                  <GuidesListArrow
                    guides={filteredByArrowGuides}
                    onGuideClick={onGoToGuide}
                    progressList={guidesListProgressQuery.data}
                  />
                )
              )}
            </div>
            <div className="guides-main-page__other-guides">
              {guidesListQuery.isLoading ? (
                <GuidesOtherSmallListLoading />
              ) : (
                filteredGuides && (
                  <GuidesOtherSmallList
                    guides={filteredGuides}
                    onGuideClick={onGoToGuide}
                  />
                )
              )}
            </div>
          </section>
        )}
      </UiBaseLayout>

      <GuidesOnboardingBottomSheet
        isVisible={isGuidesOnboardingVisible}
        onDismiss={hideGuidesOnboarding}
      />
    </>
  );
}

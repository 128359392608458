interface QueryParams {
  [key: string]: string;
}

const LEADGEN_EM_STORAGE_KEY = 'utm_notif';

export const getQueryParamsFromURI = (): QueryParams => {
  const params: QueryParams = {};

  if (typeof window !== 'undefined' && window.location.href.includes('?')) {
    const queryString: string = window.location.href.split('?')[1];

    queryString.split('&').forEach((p: string) => {
      const [key, rawValue] = p.split('=');
      let value: string = rawValue;

      if (key === 'email') {
        value = value.replaceAll('%20', '+');
      }

      params[key] = decodeURIComponent(value);
    });
  }

  return params;
};

const setUtmNotif = (notif: string) => {
  localStorage.setItem(LEADGEN_EM_STORAGE_KEY, notif);
};

export const getUtmNotif = () => {
  const params = getQueryParamsFromURI();

  if (localStorage.getItem(LEADGEN_EM_STORAGE_KEY)) {
    return localStorage.getItem(LEADGEN_EM_STORAGE_KEY);
  }

  if (params[LEADGEN_EM_STORAGE_KEY]) {
    setUtmNotif(params[LEADGEN_EM_STORAGE_KEY]);

    return params[LEADGEN_EM_STORAGE_KEY];
  }

  return null;
};

export const removeUtmNotif = () => {
  localStorage.removeItem(LEADGEN_EM_STORAGE_KEY);
};

import axios from 'axios';
import { encode } from 'js-base64';
import { cloneDeep, extend, join, tap, values } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { Identity } from './identity';
import {
  AttributionOptions,
  AttributionPayload,
  AttributionStatus,
  Properties,
  SentinelConfig,
} from './types';

export class Attribution {
  private _payload: AttributionPayload;

  readonly attributionId: string;

  private _timeoutId: ReturnType<typeof setTimeout> | undefined;

  private _isSending: boolean = false;

  constructor(
    private config: SentinelConfig,
    private identity: Identity
  ) {
    this.attributionId = this._getOrGenerateAttributionId();

    this._payload = {
      click_id: this.config.attribution?.clickId ?? null,
      utm_source: this.config.attribution?.utm?.source ?? null,
      utm_campaign: this.config.attribution?.utm?.campaign ?? null,
      utm_adset: this.config.attribution?.utm?.adset ?? null,
      utm_ad: this.config.attribution?.utm?.ad ?? null,
      utm_adgroup: this.config.attribution?.utm?.adgroup ?? null,
      utm_keyword: this.config.attribution?.utm?.keyword ?? null,
      utm_placement: this.config.attribution?.utm?.placement ?? null,
      properties: {},
      extra: {
        aff_cid: this.config.attribution?.clickId ?? null,
        uid: this.identity.userId,
      },
    };

    this._sendCachedAttributions();
  }

  private _generateAttributionHash() {
    const utmValues = join([
      ...values(this.config?.attribution?.utm),
      this.config?.attribution?.clickId,
    ]);

    return encode(utmValues);
  }

  private _sendCachedAttributions() {
    if (this.config.attribution?.cachedProperties) {
      this.update(this.config.attribution?.cachedProperties, {
        immediately: true,
      }).then(() => {
        this.config.events?.onCachedAttributionSend?.();
      });
    }
  }

  private _getOrGenerateAttributionId() {
    const generatedAttributionHash = this._generateAttributionHash();

    if (
      this.config.attribution?.id &&
      this.config.attribution.hash === generatedAttributionHash
    ) {
      return this.config.attribution.id;
    }

    const newAttributionId = uuidv4();

    this.config.events?.onAttributionHashUpdated?.(generatedAttributionHash);
    this.config.events?.onAttributionIdUpdated?.(newAttributionId);

    return newAttributionId;
  }

  private _mergeProperties(properties: Properties) {
    this._payload = tap(cloneDeep(this._payload), v => {
      extend(v.properties, properties);
    });
  }

  private _resetProperties() {
    this._payload = tap(cloneDeep(this._payload), v => {
      v.properties = {};
    });
  }

  private _sendData() {
    if (!this.config.attribution) {
      const error = new Error('Sentinel attribution config error');
      this.config.events?.onError?.(error);

      throw error;
    }

    this._isSending = true;

    const payload = cloneDeep(this._payload);
    this._resetProperties();

    return axios({
      method: 'POST',
      url: this.config.attribution.endpoint,
      data: payload,
      headers: {
        'x-zmrn-attribution-id': this.attributionId,
        'x-zmrn-country-code': this.config.geo.country.alpha2,
      },
    })
      .then(() => {
        this._isSending = false;
        this._timeoutId = undefined;
        return { status: 'SUCCESS' } as const;
      })
      .catch(cause => {
        this._mergeProperties(payload.properties);
        this._isSending = false;

        return Promise.reject(cause);
      });
  }

  update(
    properties: Properties,
    options?: AttributionOptions
  ): Promise<{ status: AttributionStatus }> {
    if (!this.config.attribution) {
      const error = new Error('Sentinel attribution config error');
      this.config.events?.onError?.(error);

      throw error;
    }

    if (!properties) {
      const error = new Error('Sentinel attribution empty properties');
      this.config.events?.onError?.(error);

      throw error;
    }

    this._mergeProperties(properties);

    return new Promise((resolve, reject) => {
      if (this._isSending) {
        resolve({
          status: 'PENDING',
        });
      }

      const updateAttribution = () => {
        clearTimeout(this._timeoutId);

        this._sendData()
          .then(resolve)
          .catch(cause => {
            this.config.events?.onAttributionUpdateError?.(cause);
            reject(cause);
          });
      };

      if (options?.immediately || !this.config.attribution?.frequency) {
        updateAttribution();
      } else if (!this._timeoutId) {
        this._timeoutId = setTimeout(
          updateAttribution,
          this.config.attribution?.frequency
        );
        resolve({ status: 'PENDING' });
      }
    });
  }
}

import { useChatBotDetailsQuery } from '../../../entities/chat-details';

import './chat-preset-prompts.css';

export const ChatPresetPrompts = ({
  onSubmit,
  isDisabled,
  chatBotId,
}: {
  onSubmit: (text: string, isPresetPrompt?: boolean) => void;
  isDisabled: boolean;
  chatBotId: string;
}) => {
  const { data } = useChatBotDetailsQuery({
    chatBotId,
  });

  const presetPrompts = data?.preset_prompts;

  const onSubmitPrompt = (prompt: string) => {
    onSubmit(prompt, true);
  };

  if (!presetPrompts || isDisabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="overflow-x-auto flex gap-2 mb-[31px] pl-4 preset-prompts">
      {presetPrompts.map(prompt => (
        <button
          type="button"
          key={prompt}
          className="preset-prompts--card p-3 bg-gray-100 rounded-lg
            cursor-pointer hover:bg-gray-200 text-sm w-fit whitespace-normal flex-shrink-0"
          onClick={() => onSubmitPrompt(prompt)}
        >
          <p className="max-w-[250px]">{prompt}</p>
        </button>
      ))}
    </div>
  );
};

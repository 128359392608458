import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';

export const ViewAllAnalytics = {
  botsListView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'explore_other_tools',
      language: i18next.language,
    });
  },
  onBotClick({ botId }: { botId: string }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'explore_other_tools',
      action: 'create_new_chat',
      bot_id: botId,
      language: i18next.language,
    });
  },
  onCategoryFilterClick({ id }: { id: string }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'explore_other_tools',
      location: 'other_filter',
      action: 'filter_results',
      area: id,
      language: i18next.language,
    });
  },
  onSearchBarClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'explore_other_tools',
      location: 'search_bar',
      action: 'search_bots',
      language: i18next.language,
    });
  },
  onSearchBarSubmit() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'explore_other_tools',
      location: 'search_bar',
      action: 'submit_search',
      language: i18next.language,
    });
  },
};

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const CHALLENGES_TABS = {
  ALL: 'all-tab',
  COMPLETED: 'completed-tab',
};

export type ChallengesTab =
  | typeof CHALLENGES_TABS.ALL
  | typeof CHALLENGES_TABS.COMPLETED;

interface ChallengesMainPageStore {
  selectedTab: ChallengesTab;
  setSelectedTab: (v: ChallengesTab) => void;
}

export const useChallengesMainPageStore = create<ChallengesMainPageStore>()(
  immer(set => ({
    selectedTab: CHALLENGES_TABS.ALL,
    setSelectedTab: (v: ChallengesTab) =>
      set(state => {
        state.selectedTab = v;
      }),
  }))
);

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import FilledStarIcon from '../../../../satisfaction-survey/ui/assets/star-filled.svg';
import StarIcon from '../../../../satisfaction-survey/ui/assets/star.svg';

const LEVEL_TYPES = {
  NOT_SATISFIED: 1,
  SLIGHTLY_SATISFIED: 2,
  SOMEWHAT_SATISFIED: 3,
  SATISFIED: 4,
  VERY_SATISFIED: 5,
} as const;

const LEVELS = [
  LEVEL_TYPES.NOT_SATISFIED,
  LEVEL_TYPES.SLIGHTLY_SATISFIED,
  LEVEL_TYPES.SOMEWHAT_SATISFIED,
  LEVEL_TYPES.SATISFIED,
  LEVEL_TYPES.VERY_SATISFIED,
];

type LevelType = (typeof LEVEL_TYPES)[keyof typeof LEVEL_TYPES];

interface SatisfactionSurveyProps {
  selectedLevel?: LevelType;
  onSelectLevel: (level: LevelType) => void;
}

export const GuideCertificateCompleteSurvey = ({
  selectedLevel,
  onSelectLevel,
}: SatisfactionSurveyProps) => {
  const { t } = useTranslation();

  const getStarIcon = (level: LevelType) => {
    if (!selectedLevel) {
      return StarIcon;
    }
    return LEVELS.indexOf(selectedLevel) >= LEVELS.indexOf(level)
      ? FilledStarIcon
      : StarIcon;
  };

  return (
    <div className="satisfaction-survey__levels_container py-4">
      <h3 className="px-2 mb-1 text-lg font-semibold">
        {t('guides-v2.complete-page.survey.title')}
      </h3>
      <ul className="satisfaction-survey__levels">
        {LEVELS.map(level => (
          <li key={level}>
            <button
              className={classNames('satisfaction-survey__level', {
                'satisfaction-survey__level--active': selectedLevel === level,
              })}
              onClick={() => onSelectLevel(level)}
            >
              <span>
                <img
                  width="56"
                  height="56"
                  src={getStarIcon(level)}
                  role="presentation"
                  alt=""
                />
              </span>
            </button>
          </li>
        ))}
      </ul>
      <div className="satisfaction-survey__level-title">
        <p>{t('guides-v2.complete-page.survey.not-engaging')}</p>
        <p>{t('guides-v2.complete-page.survey.engaging')}</p>
      </div>
    </div>
  );
};

export { LEVEL_TYPES, type LevelType };

import { AxiosResponse } from 'axios';

import { api } from '../../../../services/http.service';
import { UPSELL_CASCADE_PAYWALL_CONFIG } from '../config';

export interface UpsellCascadeType {
  id: string;
  is_trial: boolean;
  currency: string;
  pricings: Array<{
    id: string;
    full_price: number;
    discount_price: number;
    discount: number;
    position: number;
    solid_product_id: string;
    vat: number;
    discount_price_vat_amount: number;
    original_pricing: {
      discount_price: number;
      display_price: number;
      full_price: number;
      original_display_price: number;
    };
    badge_text: string;
  }>;
  product_type: string;
  title: string;
  description: string;
  period: number;
  position: number;
  quantity: number;
  weight: number;
}

export const UpsellCascadeAPI = {
  getOffers(
    paywallConfigId?: string
  ): Promise<AxiosResponse<Array<UpsellCascadeType>>> {
    return api.get('/offers-v2/offers/?pricing_repr=daily', {
      headers: {
        'Paywall-Config-Id': paywallConfigId || UPSELL_CASCADE_PAYWALL_CONFIG,
      },
    });
  },
  buyOffer({
    userId,
    pricingId,
    offerId,
    paywallConfigId,
  }: {
    userId: number;
    pricingId: string;
    offerId: string;
    paywallConfigId?: string;
  }): Promise<AxiosResponse<void>> {
    return api.post(
      '/payments/solid/orders/one-click',
      {
        user_id: userId,
        offer_id: offerId,
        pricing_id: pricingId,
      },
      {
        headers: {
          'Paywall-Config-Id': paywallConfigId || UPSELL_CASCADE_PAYWALL_CONFIG,
        },
      }
    );
  },
};

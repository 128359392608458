import { useQuery } from '@tanstack/react-query';

import { GuideType } from '../../../entities/guide';
import { GuidesAPI } from '../../../shared/api';

export function useGuideByIdQuery({ id }: { id: GuideType['id'] }) {
  return useQuery({
    queryKey: ['guide-by-id', id],
    queryFn: () =>
      GuidesAPI.getGuideDetailsById({ id }).then(({ data }) => data),
  });
}

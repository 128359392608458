import { useQuery } from '@tanstack/react-query';

import { GuidesAPI } from '../../shared/api';

export function useGuidesListProgressQuery() {
  return useQuery({
    queryKey: ['guides-list-progress'],
    queryFn: () => GuidesAPI.getGuidesListProgress().then(({ data }) => data),
  });
}

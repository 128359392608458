import i18next from 'i18next';

import { AiBotsIcon, ChallengesIcon, GuidesIcon, ProfileIcon } from './icons';

export const HOME_PAGE = 'home';
export const GUIDES_PAGE = 'guides';
export const AI_BOTS_PAGE = 'ai-bots';
export const PROFILE_PAGE = 'profile';
export const CHALLENGES_PAGE = 'challenges';

export const FOOTER_TABS = [
  {
    type: GUIDES_PAGE,
    route: '/',
    get title() {
      return i18next.t('guides.title');
    },
    activeRoutes: ['/', '/guides', '/home'],
    IconComponent: GuidesIcon,
  },
  {
    type: CHALLENGES_PAGE,
    route: '/challenges',
    get title() {
      return i18next.t('challenges.title');
    },
    activeRoutes: ['/challenges'],
    IconComponent: ChallengesIcon,
  },
  {
    type: AI_BOTS_PAGE,
    route: '/ai-bots',
    get title() {
      return i18next.t('ai-bots.footer-title');
    },
    activeRoutes: ['/ai-bots'],
    IconComponent: AiBotsIcon,
  },
  {
    type: PROFILE_PAGE,
    route: '/profile',
    get title() {
      return i18next.t('Profile');
    },
    activeRoutes: ['/profile'],
    IconComponent: ProfileIcon,
  },
];

import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';

export const SessionsPageAnalytics = {
  botsListView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'sessions_page',
      language: i18next.language,
    });
  },
  onStartChatClick() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'sessions_page',
      action: 'start_chat',
    });
  },
  botsListModalView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'bot_selection_modal',
      language: i18next.language,
    });
  },
  onSessionClick({
    botName,
    botId,
    sessionId,
  }: {
    botName: string;
    botId: string;
    sessionId: string;
  }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'sessions_page',
      location: 'existing_chat',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'open_existing_chat',
      language: i18next.language,
    });
  },
  onClickToCreateChat() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'sessions_page',
      action: 'create_new_chat',
      language: i18next.language,
    });
  },
  onBotClick({ botName, botId }: { botName: string; botId: string }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'bot_selection_modal',
      bot_name: botName,
      bot_id: botId,
      action: 'create_new_chat',
      language: i18next.language,
    });
  },
  onCategoryFilterClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'bot_selection_modal',
      action: 'filter_results',
      language: i18next.language,
    });
  },
  onSearchFormClick({
    place,
  }: {
    place: 'sessions_page' | 'bot_selection_modal';
  }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place,
      action: 'search_chat',
      language: i18next.language,
    });
  },
  onSearchFormSubmit({
    place,
    resultCount,
  }: {
    place: 'sessions_page' | 'bot_selection_modal';
    resultCount: number;
  }) {
    analyticsService.sendEvent('webapp_bots_search_submit', {
      place,
      action: 'submit_search',
      result_count: resultCount,
      language: i18next.language,
    });
  },
};

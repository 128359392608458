import classNames from 'classnames';

import { CheckmarkCorrectIcon } from '../../../../guides/features/guide-lesson-quiz/ui/QuizOptions';
import { AiToolContent } from '../lib/types';
import './assets/ai-tool-page.css';

export function AiToolCard({
  tool,
  isSelected,
  onSelect,
}: {
  tool: AiToolContent;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <div
      className={classNames('ai-tool-page__card', {
        'ai-tool-page__card--selected': isSelected,
      })}
      onClick={onSelect}
    >
      <div className="ai-tool-page__card-content">
        <img
          className="ai-tool-page__card-image"
          src={tool.icon}
          alt={tool.name}
        />
        <div>
          <h1>{tool.name}</h1>
          <p>{tool.description}</p>
        </div>
      </div>
      <div
        className="ai-tool-page__checkmark-container"
        style={{ visibility: isSelected ? 'visible' : 'hidden' }}
      >
        <CheckmarkCorrectIcon className="ai-tool-page__checkmark" />
      </div>
    </div>
  );
}
